export
const LeafletDrawUtils = {
  startDrawPolygon: () => {
    const drawPolygonButton = document.querySelector('.leaflet-draw-draw-polygon');
    drawPolygonButton && drawPolygonButton.click();
    const mouseMarker = document.querySelector('.leaflet-mouse-marker');
    mouseMarker && mouseMarker.addEventListener('keyup', (e) => e.stopPropagation());
  },
  beforeDraw: () => {
    const cancelEditButton = document.querySelector('.leaflet-control-container [title="Cancel editing, discards all changes"]');
    cancelEditButton && cancelEditButton.click();
  },
  afterDraw: () => {
    const editButton = document.querySelector('.leaflet-draw-edit-edit');
    editButton && editButton.click();
    const cancelEditButton = document.querySelector('.leaflet-control-container [title="Cancel editing, discards all changes"]');
    cancelEditButton && cancelEditButton.click();
    editButton && editButton.click();
  },
  stopDraw: () => {
    const calcelDrawButton = document.querySelector('.leaflet-draw-section:nth-of-type(1) ul li:nth-of-type(3) a');
    calcelDrawButton && calcelDrawButton.click();
    const clearButton = document.querySelector('[class="leaflet-draw-edit-remove"]');

    if (clearButton) {
      clearButton.click();
      const clearAllButton = document.querySelector('.leaflet-draw-section:nth-of-type(2) ul li:nth-of-type(3) a');
      clearAllButton && clearAllButton.click();
    }
  }
};
