import React from 'react';
import block from 'bem-cn-lite';
import DivIcon from 'react-leaflet-div-icon';
import parseHtml from 'html-react-parser';
import ReactDOMServer from 'react-dom/server';

import { PropTypes } from 'prop-types';
import { Icon } from '../common/components';
import { getPaletteColorClass, getCoordsGlobalPrecision } from './../../base/utils';
import icons from '../common/icons';
import { store } from '../..';
import { actions as sectionActions} from '../sections/common/actions';

const b = block('LeafletMapMarker');

export default
class LeafletMapMarker extends React.PureComponent {
  static propTypes = {
    isEditing: PropTypes.bool.isRequired,
    isSelected: PropTypes.bool.isRequired,
    isLoaded: PropTypes.bool.isRequired,
    isMissed: PropTypes.bool.isRequired,
    hasWarning: PropTypes.bool.isRequired,
    isFree: PropTypes.bool.isRequired,
    isNightStay: PropTypes.bool,
    isDimmed: PropTypes.bool,
    status: PropTypes.number,
    onClick: PropTypes.func,
    point: PropTypes.object.isRequired,
    manifestId: PropTypes.number.isRequired
  }

  constructor() {
    super();
    this.markerHtml = null;
    this.markerElement = null;
  }

  _updateMarkerElement = (props) => {
    const html = this._renderHtmlMarker(
      props.isFree ? null : props.point.order,
      props.point.assignedCourierId || props.point.pointCourierId || props.point.waybillCourierId,
      props.isEditing || props.isSelected,
      props.isDimmed,
      props.point.depot,
      props.point.garage,
      props.point.pickupPoint,
      props.isEditing,
      props.isLoaded,
      props.isMissed,
      props.hasWarning,
      props.status,
      props.isNightStay
    );

    this.markerHtml = html;
    this.markerElement = parseHtml(html);
  }

  UNSAFE_componentWillMount() {
    this._updateMarkerElement(this.props);
  }

  UNSAFE_componentWillUpdate(nextProps) {
    this._updateMarkerElement(nextProps);
  }

  _onChangeMarkerPosition = (e) => {
    const latLng = e.target._latlng;
    store.dispatch(sectionActions.pointCoordsUpdated(
      this.props.point.id,
      [
        Number(latLng.lat.toFixed(getCoordsGlobalPrecision())),
        Number(latLng.lng.toFixed(getCoordsGlobalPrecision()))
      ],
      'map'
    ));
  }

  _renderIconAsHTML = (depot, garage, pickup, missed, isNightStay) => {
    let icon = '';
    let mask = 0;
    mask += depot ? 1 : 0;
    mask += garage ? 2 : 0;
    mask += missed ? 4 : 0;
    mask += isNightStay ? 8 : 0;
    mask += pickup ? 16 : 0;

    icon = mask === 1 ? icons.MARKER_DEPOT : icon;
    icon = mask === 2 ? icons.MARKER_GARAGE : icon;
    icon = mask === 3 ? icons.MARKER_GARAGEDEPOT : icon;
    icon = mask >= 4 ? icons.MARKER_ERROR : icon;
    icon = mask >= 8 ? icons.MARKER_SLEEP : icon;
    icon = mask >= 16 ? icons.MARKER_DEPOT : icon;

    return ReactDOMServer.renderToStaticMarkup(<Icon svgName={icon}/>);
  }

  _getMarkerOrder = (position) => {
    if (this.props.point.pointCourierId
      && this.props.point.waybillCourierId
      && this.props.point.pointCourierId !== this.props.point.waybillCourierId
    )
      return '-';
    return position || '-';
  }

  _renderMarkerContent = (depot, garage, pickup, position, loaded, missed, isNightStay) => {
    if (!loaded)
      return '';
    if (depot || garage || pickup || missed || isNightStay)
      return this._renderIconAsHTML(depot, garage, pickup, missed, isNightStay);
    return this._getMarkerOrder(position);
  }

  _renderHtmlMarker = (position, courierId, selected, dimmed, depot, garage, pickup, editing, loaded, missed, warning, status, isNightStay) => `
    <div class='${b('BeakShadow')}'></div>
    <div class='${b('Beak',
    {
      'Loading': !loaded,
      'Dimmed': dimmed && !selected,
      'Selected': selected,
      'Warning': !missed && warning,
    })}'></div>
      <div class='${b('Body',
      {
        'Loading': !loaded,
        'Dimmed': dimmed && !selected,
        'Selected': selected,
        'Base': depot || garage,
        'Missed': missed,
        'Warning': !missed && warning,
        'Big': isNightStay || pickup,
      })}
      ${getPaletteColorClass(courierId)}'>
      <span>${this._renderMarkerContent(depot, garage, pickup, position, loaded, missed, isNightStay)}</span>
      <div class='${b('VisitedStatus', {
        'InWork': status && status === 1,
        'Success': status && status === 2,
        'Failed': status && status === 5,
      })}'/>
    </div>
    ${selected && editing
        ? `<div class='${b('Move')}'>
            <div class='${b('MoveArrow', {'Rotate45': true,'Base': depot || garage})}'></div>
            <div class='${b('MoveArrow', {'Rotate135': true, 'Base': depot || garage})}'></div>
            <div class='${b('MoveArrow', {'Rotate225': true, 'Base': depot || garage})}'></div>
            <div class='${b('MoveArrow', {'Rotate315': true, 'Base': depot || garage})}'></div>
          </div>`
        : ''
    }`;

  render() {
    return <DivIcon
      draggable={this.props.isEditing}
      onDragEnd={this._onChangeMarkerPosition}
      onClick={this.props.onClick}
      className={b()}
      key={`${this.props.point.id}_${this.props.isEditing}`}
      iconSize={[32, 42]}
      iconAnchor={[16, 42]}
      position={this.props.point.coords}
      html={this.markerHtml}
    >
      {this.markerElement}
    </DivIcon>;
  }
}
