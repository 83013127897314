import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';
import { getGeocodeOptions, getGeocodeItem, getCoordsGlobalPrecision } from '../../base/utils';

const b = block('Input');

export default
class AddressInput extends React.PureComponent {
  static fieldName = 'address';

  static propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    coordsName: PropTypes.string.isRequired,
    onChangeValue: PropTypes.func.isRequired,
    disabled: PropTypes.bool
  }

  constructor(props) {
    super(props);
    this.geocodeRequestTimer = null;
    this.fromView = String;

    this.state = {
      hintSelected: false,
      hintInFocus: undefined,
      hints: []
    };
  }

  _onFocus = () => this.setState({ hintSelected: false });

  _onCaptionChanged = (value) => this.props.onChangeValue
    && this.props.onChangeValue(this.props.name, value);

  _onCoordsChanged = (value) => this.props.onChangeValue
    && this.props.onChangeValue(this.props.coordsName, value.map((c) => Number(c.toFixed(getCoordsGlobalPrecision()))));

  _onBlur = () => {
    if (this.props.value)
      getGeocodeItem(this.props.value)
        .then((data) => {
          if (data && data.caption && !this.state.hintSelected) {
            this._onCoordsChanged(data.coords);
            this.setState({ hintSelected: true });
          }
        });
  }

  _onHintSelected = (hindIndex) => {
    const h = this.state.hints[hindIndex];

    this.setState({
      hintSelected: true,
      hintInFocus: undefined
    }, () => {
      this._onCaptionChanged(h.caption);
      this._onCoordsChanged(h.value);
    });
  }

  _onChange = (e) => {
    this._onCaptionChanged(e.target.value);
    clearTimeout(this.geocodeRequestTimer);
    this.geocodeRequestTimer = setTimeout(this._getGeocodeOptions, 400);

    this.setState({ hintSelected: false });
  }

  _getGeocodeOptions = () => {
    getGeocodeOptions(this.props.value)
      .then((options) => {
        this.setState({
          hints: options,
        });
      });
  }

  _navigateHints = (e) => {
    if (this._hasVisibleHints()) {
      let newHintInFocus = this.state.hintInFocus;

      if (e.key === 'ArrowDown') {
        newHintInFocus = this.state.hintInFocus >= 0 && this.state.hintInFocus < this.state.hints.length - 1
          ? this.state.hintInFocus + 1
          : 0;
      }
      else if (e.key === 'ArrowUp') {
        newHintInFocus = this.state.hintInFocus > 0 && this.state.hintInFocus < this.state.hints.length
          ? this.state.hintInFocus - 1
          : this.state.hints.length - 1;
      }
      else if (e.key === 'Enter' && newHintInFocus !== undefined) {
        this._onHintSelected(newHintInFocus);
      }

      this.setState({ hintInFocus: newHintInFocus });
    }
  }

  _hasVisibleHints = () => !this.state.hintSelected
    && this.state.hints
    && this.state.hints.length > 0;

  _renderHints = () => this.state.hints
    .map((h, i) => <li
      key={h.key}
      onClick={() => this._onHintSelected(i)}
      className={b('OptionItem', { 'Focused': this.state.hintInFocus === i })}
    >
      {h.caption}
    </li>);

  render() {
    return [
      <input
        key={'input'}
        autoComplete={'off'}
        id={this.props.name}
        className={b()}
        name={this.props.name}
        value={this.props.value || ''}
        onChange={this._onChange}
        onFocus={this._onFocus}
        onBlur={this._onBlur}
        type='text'
        disabled={this.props.disabled}
        onKeyDown={this._navigateHints}
      />,
      this._hasVisibleHints()
        && <div
          key={'hints'}
          className={b('Options')}
        >
          {this._renderHints()}
        </div>
    ];
  }
}
