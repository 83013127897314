import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';

import I18N from '../../common/i18n';
import SectionList from '../common/SectionList';
import { Button } from '../../common/components';
import { AppSections, Sections } from '../../common/enums';

const b = block('ListSection');

export default
class MeasureList extends React.PureComponent {
  static title = I18N.MEASURES_LIST_TITLE

  static propTypes = {
    itemElement: PropTypes.func,
    crud: PropTypes.object,
  }

  render() {
    return (
      <div className={b()}>
        <div className={b('Controls')}>
          <Button
            to={`/${AppSections.CATALOG}/${Sections.MEASURES}/new`}
            className={b('Button')}
            rightHint={I18N.MEASURES_ADD_BUTTON_HELP}
          >{I18N.MEASURES_ADD_BUTTON}</Button>
          <Button
            to={`/${AppSections.CATALOG}/${Sections.MEASURES}/select`}
            className={b('Button')}
            leftHint={I18N.MEASURES_SELECT_HELP}
          >{I18N.MEASURES_SELECT}</Button>
        </div>
        <SectionList
          queries={this.props.crud}
          itemElement={this.props.itemElement}
        />
      </div>
    );
  }
}
