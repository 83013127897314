import * as ExcelJs from 'exceljs/dist/exceljs.min.js';
import Stream from 'stream';
import { getData, decodeDateRU, decodeTime } from 'base/utils';
import fileDownload from 'js-file-download';
import {
  loadWaybillsAsync,
  loadPointsDetailedAsync,
  loadManifestDataAsync,
  loadCouriersDetailedAsync
} from 'modules/manifest/utils';
import I18N from '../../../common/i18n';

const CustomActions = {
  getGarbageWaybillXML: async (manifestId) => {
    const DATA_STARTS_FROM = 3;

    const manifestData = await loadManifestDataAsync(manifestId);
    const waybills = await loadWaybillsAsync(manifestId);
    const points = await loadPointsDetailedAsync(manifestId);
    const couriers = await loadCouriersDetailedAsync(manifestId);

    const isFirstMile = (manifestData.name).startsWith('Первая миля')
    const template = isFirstMile ? 'template1' : 'template2'
    const data = await getData(`/static/custom_solutions/maximus/${template}.xlsx`);

    const workbook = new ExcelJs.Workbook();
    workbook.xlsx.load(data)
      .then(() => {
        const dest = new Stream();
        dest.write = function(fileData) {
          return fileDownload(fileData, `Маршрутные листы ${manifestData.name}.xlsx`);
        };

        const pointsArray = [];
        points.forEach((p) => (pointsArray[p.id] = p));
        const worksheet = workbook.getWorksheet(1);

        couriers.forEach((c) => {
          const courierData = [];
          const courierWaybills = waybills.filter((w) => w.courier_id === c.id);
          if (!courierWaybills.length)
            return;

          const newSheet = workbook.addWorksheet('empty');
          newSheet.model = {...worksheet.model, name: c.name, id: c.id };

          newSheet.getCell('A1').value = c.name
          if (isFirstMile) {
            newSheet.mergeCells('A1:C1')
          } else {
            newSheet.mergeCells('A1:H1')
          }

          // Count number of lines wich will be contain comment in excel cell
          const numberExcelLines = (cellTExt) => {
            const maxLines = 27 // Maximum number of lines of each cell
            const maxLineLength = 84 // Maximum lenght of line in cell text

            const lines = cellTExt.split(/\r\n|\r|\n/)
            // Array of comment for cells. If comment length more then maxLines, it devides
            const comment_cells = []
            // Content lines of commetn text. After all it join's with '\n'
            let comment = []
            // Count of lines in excell cell. It's not equal to text lines
            let excel_lines = 0
            lines.forEach((line) => {
              let string_lines = 0
              if (line.length <= 84) {
                string_lines += 1;
              } else {
                // Count real lines wuch will be in excel cell
                string_lines += Math.ceil(line.length/84);
              }

              if (excel_lines + string_lines >= maxLines) {
                comment_cells.push(comment.join('\n'))
                comment = []
                excel_lines = 0
              }

              excel_lines += string_lines
              comment.push(line)
            })
            if (comment.length > 0) {
              comment_cells.push(comment.join('\n'))
            }

            return comment_cells
          }

          const getDataRow = (isFirstMile, emptyRows, comment, point_id) => {
            if (isFirstMile) {
              if (emptyRows) {
                return [ '', '', comment.trim() ]
              }
              else {
                return [ point_num, pointsArray[point_id].name || '', comment.trim() ]
              }
            } else {
              if (emptyRows) {
                return [ '', '', '', '', '', comment.trim(), '', '' ]
              }
              else {
                return [
                  point_num,
                  pointsArray[point_id].name || '',
                  pointsArray[point_id].address || '',
                  pointsArray[point_id].contact_person || '',
                  pointsArray[point_id].phone || '',
                  comment.trim(),
                  I18N.formatDecimalHoursAsTime(pointsArray[point_id].work_start) || '',
                  I18N.formatDecimalHoursAsTime(pointsArray[point_id].work_end) || ''
                ]
              }
            }
          }

          let point_num = 1
          courierWaybills.forEach((w, i) => {
            if (!pointsArray[w.point_id].garage && !pointsArray[w.point_id].depot) {
              numberExcelLines((pointsArray[w.point_id].comment || '').trim()).forEach(
                (comment, index) => {
                  console.log(`REPORT index=${index}, comment - '${comment}'`)
                  // If first part of comment - insert other cell values
                  if (index === 0) {
                    courierData.push(getDataRow(isFirstMile, false, comment, w.point_id));
                    point_num += 1
                  }
                  // Paste just comment without values in other cells
                  else {
                    courierData.push(getDataRow(isFirstMile, true, comment, w.point_id));
                  }
                })
              }
            });

          newSheet.spliceRows.apply(newSheet, [DATA_STARTS_FROM, 0, ...courierData]);

          newSheet.eachRow((row, rowNumber) => {
            row.hidden = false
            row.eachCell.call(row, (cell) => {
              if (rowNumber >= DATA_STARTS_FROM && rowNumber <= courierData.length + DATA_STARTS_FROM) {
                cell.border = {
                  top: { style: 'thin' },
                  left: { style: 'thin' },
                  bottom: { style: 'thin' },
                  right: { style: 'thin' }
                };
                // cell.alignment = { wrapText: true };
              }
            });
          });
        });

        workbook.removeWorksheet(worksheet.id);

        return workbook.xlsx.write(dest);
      });
  }
};

export default CustomActions;
