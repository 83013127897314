import gql from 'graphql-tag';
import bindApollo from '../common/queries';
import { Sections } from '../../common/enums';

const zoneListFields = `
  points_count,
  points_weight,
  couriers_count,
`;

const zonesDefaultFieds = `
  id,
  name,
  manifest_id,
  geometry,
`;

const zoneCardFields = `
  couriers,
  points,
`;

export
const queries = {
  SECTION: Sections.ZONES,

  CREATE: gql`
    mutation CREATE_MANIFEST_ZONE($manifest_id: int!, $data: String!) {
      result(manifest_id: $manifest_id data: $data) {
        ${zonesDefaultFieds}
        ${zoneCardFields}
      }
    }
  `,

  DELETE: gql`
    mutation DELETE_MANIFEST_ZONE($id: Int!) {
      result(id: $id)
    }
  `,

  DELETE_LIST: gql`
    mutation DELETE_MANIFEST_ZONES($data: Array!) {
      result(data: $data)
    }
  `,

  LIST: gql`
    query MANIFEST_ZONES ($manifest_id: Int) {
      result(manifest_id: $manifest_id crutch: "zones") {
        ${zonesDefaultFieds}
        ${zoneListFields}
      }
    }
  `,

  GET_ONE: gql`
    query MANIFEST_ZONE ($id: Int!) {
      result(id: $id) {
        ${zonesDefaultFieds}
        ${zoneCardFields}
      }
    }
  `,

  UPDATE: gql`
    mutation UPDATE_MANIFEST_ZONE($manifest_id: int!, $data: String!) {
      result(manifest_id: $manifest_id data: $data) {
        ${zonesDefaultFieds}
        ${zoneCardFields}
      }
    }
  `,

  CLONE: gql`
    mutation COPY_ZONES($from_id: Int $to_id: Int $with_linked_points: Bool $with_linked_couriers: Bool $with_couriers_points: Bool) {
      result(from_id: $from_id to_id: $to_id with_linked_points: $with_linked_points with_linked_couriers: $with_linked_couriers with_couriers_points: $with_couriers_points) {
        null
      }
    }
  `
};

export
const CRUD = bindApollo(queries);
