import React from 'react';
import { PropTypes } from 'prop-types';
import block from 'bem-cn-lite';
import { toast } from 'react-toastify';

import InfoBadge from '../../common/components/InfoBadge';
import Button from '../../common/components/Button';
import ConfirmButton from '../../common/components/ConfirmButton';
import ListItem from '../common/ListItem';
import I18N from '../../common/i18n';
import icons from '../../common/icons';
import { Sections } from '../../common/enums';
import { getSectionLinkBody, getWaybillXML } from '../../../base/utils';
import { client } from '../../..';
import { queries as pointQueries } from '../points/queries';
import { CRUD } from './queries';
import { loadWaybillsAsync } from '../../manifest/utils';

const b = block('ListItem');

export default
class CourierListItem extends React.PureComponent {

  static section = Sections.COURIERS;

  static propTypes = {
    item: PropTypes.object.isRequired,
    onDelete: PropTypes.func,
    manifestId: PropTypes.number,
    getWaybills: PropTypes.func,
    showControls: PropTypes.bool,
    listNumber: PropTypes.number,
  }

  _getIcon = (vehicleType, outoforder) => {
    let icon = null;
    if (outoforder) {
      icon = vehicleType === 'hgv' ? icons.ITEM_TRUCK_OFF : icon;
      icon = vehicleType === 'car' ? icons.ITEM_CAR_OFF : icon;
      icon = vehicleType === 'foot' ? icons.ITEM_WALK_OFF : icon;
    }
    else {
      icon = vehicleType === 'hgv' ? icons.ITEM_TRUCK : icon;
      icon = vehicleType === 'car' ? icons.ITEM_CAR : icon;
      icon = vehicleType === 'foot' ? icons.ITEM_WALK : icon;
    }
    return icon;
  }

  _getTimeLoad = (workStart, workEnd, totalTime) => `${I18N.formatDecimalHours(totalTime)} (${Math.abs(Math.round(100 / (workStart - workEnd) * totalTime))}%)`;

  _getWeightLoad = (maxWeight, totalWeight) => `${totalWeight || 0} (${Math.abs(Math.round(100 / maxWeight * totalWeight))}%)`;

  _getVolumeLoad = (maxVolume, totalVolume) => `${totalVolume || 0} (${Math.abs(Math.round(100 / maxVolume * totalVolume))}%)`;

  _getCourierWaybills = async(manifestId, itemId) => {
    const waybills = await loadWaybillsAsync(manifestId);

    const courierPoints = waybills.filter((wPoint) => wPoint.courier_id === itemId);

    const { data: { result: points } } = await client.query({
      query: pointQueries.LIST_DETAILED,
      variables: {
        'manifest_id': manifestId,
      },
    });

    if (courierPoints && points) {
      toast.success(`${I18N.COURIERS_GET_WAYBILL}. ${I18N.NOTIFY_SUCCESS}`);

      getWaybillXML(
        points,
        courierPoints,
        I18N.MISC_WAYBILLS_COURIER.replace('*', itemId)
      );
    }
    else {
      toast.error(`${I18N.COURIERS_GET_WAYBILL}. ${I18N.NOTIFY_ERROR}`);
    }
  }

  _switchPower = (update, courierId, outoforder) => {
    const p = update({
      variables: {
        id: courierId,
        data: {
          'outoforder': !outoforder,
        }
      }
    });
    p.then((res) => {
      toast.success(`${I18N.ITEM_POWER_ON} / ${I18N.ITEM_POWER_OFF}. ${I18N.NOTIFY_SUCCESS}`);
    });
    p.catch((res) => {
      toast.error(`${I18N.ITEM_POWER_ON} / ${I18N.ITEM_POWER_OFF}. ${I18N.NOTIFY_ERROR}`);
      console.log('update error');
    });
    return p;
  }

  render() {
    const courier = this.props.item;

    return (
      <ListItem
        id={courier.id}
        listNumber={this.props.listNumber}
        number={courier.points_served && courier.id}
        icon={this._getIcon(courier.vehicle_type, courier.outoforder)}
        iconStroke={4}
        link={`${getSectionLinkBody(this.props.manifestId)}${Sections.COURIERS}/${this.props.manifestId ? courier.id : `${courier.id}/edit`}`}
        key={courier.id}
        title={`${courier.name} :: ${courier.id}`}
      >
        <div className='ListItem__InfoBadges'>
          {/* <InfoBadge iconName={icons.BADGE_SCHEDULE} value={`
            ${I18N.formatDecimalHoursAsTime(courier.work_start)} -
            ${I18N.formatDecimalHoursAsTime(courier.work_end)}
          `}/> */}
          <InfoBadge iconName={icons.BADGE_WEIGHT} value={this._getWeightLoad(courier.max_weight, courier.total_weight)}/>
          <InfoBadge iconName={icons.BADGE_TIME} value={this._getTimeLoad(courier.work_start, courier.work_end, courier.total_time)}/>
          <InfoBadge iconName={icons.BADGE_POINT} value={courier.points_served}/>
          <InfoBadge iconName={icons.ITEM_ZONE} value={courier.zones && courier.zones.length}/>
        </div>
        <div className={b('Controls', { 'AlwaysShow': this.props.showControls })}>
          {CRUD.UPDATE(
            {
              'manifest_id': this.props.manifestId,
              'title_filter': '',
            },
            (update) => (
              <ConfirmButton
                confirmMessage={`${I18N.COURIERS_POWER}`}
                icon={courier.outoforder ? icons.COMMON_POWER_OFF : icons.COMMON_POWER_ON}
                leftHint={courier.outoforder ? I18N.ITEM_POWER_ON : I18N.ITEM_POWER_OFF}
                onClick={() => this._switchPower(update, courier.id, courier.outoforder)}/>
            )
          )}
          {this.props.onDelete &&
            <ConfirmButton
              confirmMessage={`${I18N.COURIERS_DELETE_CONFIRM} «${courier.name}»`}
              icon={icons.COMMON_DELETE}
              leftHint={I18N.ITEM_DELETE}
              onClick={() => this.props.onDelete(courier.id)}/>}
          <Button
            icon={icons.COMMON_DOWNLOAD}
            leftHint={I18N.COURIERS_GET_WAYBILL}
            onClick={this.props.getWaybills || (() => this._getCourierWaybills(this.props.manifestId, courier.id))}
          />
          <Button
            icon={icons.COMMON_EDIT}
            leftHint={I18N.ITEM_EDIT}
            to={`${getSectionLinkBody(this.props.manifestId)}${Sections.COURIERS}/${courier.id}/edit`}
          />
          <Button
            icon={icons.COMMON_EDIT_WAYBILL}
            leftHint={I18N.ITEM_EDIT_WAYBILL}
            to={`${getSectionLinkBody(this.props.manifestId)}${Sections.EDIT_WAYBILL}/${courier.id}`}
          />
        </div>
      </ListItem>
    );
  }
}
