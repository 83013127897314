import fileDownload from 'js-file-download';
import { queryApiMethod, b64toBlob } from '../../base/utils';
import { toast } from 'react-toastify';
import I18N from 'modules/common/i18n';

const CustomActions = {
  downloadManifestPhotos: async (manifestId) => {
    const t = toast.info(I18N.NOTIFY_INPROCESS, {autoClose: false});
    try {
      const data = await queryApiMethod({
        'operationName': 'DOWNLOAD_MANIFEST_PHOTOS',
        'variables': {'id': manifestId}
      });
      fileDownload(b64toBlob(data), `Photos_${manifestId}.zip`);
    }
    catch (e) {
      console.log(e.message);
      toast.error(I18N.NOTIFY_NO_DATA);
    }
    toast.dismiss(t);
  }
};

export default CustomActions;
