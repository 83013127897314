import React from 'react';
import { PropTypes } from 'prop-types';
import _ from 'lodash';

import SectionListPreloaded from './SectionListPreloaded';

export default
class SectionListComplete extends React.PureComponent {
  static propTypes = {
    queries: PropTypes.object.isRequired,
    variables: PropTypes.object,
    itemElement: PropTypes.func.isRequired,
    manifestId: PropTypes.number,
    onDeleted: PropTypes.func,
    propertiesFilter: PropTypes.func,
  };

  render() {
    return (
      this.props.queries.LIST(
        {
          'manifest_id': this.props.manifestId,
        },
        (qListQuery) => (
          this.props.queries.DELETE(
            {
              'manifest_id': this.props.manifestId,
            },
            (qDeleteQuery) => (
              <SectionListPreloaded
                {...this.props}
                qDeleteQuery={qDeleteQuery}
                items={_.get(qListQuery, 'data.result', [])}
              />
            )
          )
        )
      )
    );
  }
}
