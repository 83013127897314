import React from 'react';
import block from 'bem-cn-lite';

const b = block('LoadingIndicator');

const LoadingIndicator = () => (
  <div className={b()}>
    <svg version="1.1" x="0px" y="0px" viewBox="0 0 448 448">
      <path className={b('AnimationDash')} d="M121.995,96.494l204.01,255.012C336.481,364.601,327.158,384,310.388,384H137.613
        c-16.77,0-26.094-19.399-15.617-32.494l204.01-255.012C336.481,83.399,327.158,64,310.388,64H137.613
        C120.842,64,111.519,83.399,121.995,96.494z"/>
    </svg>
  </div>
);

export default LoadingIndicator;
