import React from 'react';
import block from 'bem-cn-lite';
import _ from 'lodash';
import { PropTypes } from 'prop-types';
import { getCoordsGlobalPrecision, getNumberPrecision } from '../../base/utils';
import { Polyline } from 'react-leaflet';
import { LeafletDrawUtils } from './leafletDrawUtils';
import { latLng } from 'leaflet';

const b = block('LeafletMap');

export default
class LeafletDrawingPaths extends React.PureComponent {
  static propTypes = {
    manifestId: PropTypes.number.isRequired,
    drawnCoords: PropTypes.arrayOf(PropTypes.shape([
      PropTypes.number,
      PropTypes.number
    ])),
    selectedPoints: PropTypes.arrayOf(PropTypes.number),
    mapPoints: PropTypes.object,
    mapZoom: PropTypes.number,
    selectedCourierId: PropTypes.number,
    waybills: PropTypes.object,
    updateSelectedPoints: PropTypes.func,
  };

  static STICK_DISTANCE = 20; // pixels

  constructor() {
    super();
    this.state = {
      drawnCoords: []
    };
  }

  componentWillUnmount() {
    LeafletDrawUtils.stopDraw();
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.drawnCoords, this.props.drawnCoords)) {
      this._onDrawnGeometryChange(this.props.drawnCoords);
    }
    else {
      this._onSelectedPointsChange(prevProps);
    }
  }

  _getOrderedPoints = (cv, rv, nv) => {
    if (cv || nv) {
      const vertex = {...cv || nv};
      const newOrderedPoints = [...this.props.selectedPoints];
      vertex.latLng = latLng(vertex.coords[0], vertex.coords[1]);
      let closestId = null;
      let closestDistance = LeafletDrawingPaths.STICK_DISTANCE * 2e5 / Math.pow(2, this.props.mapZoom);
      let closestCoords = [...vertex.coords];

      for (const k of Object.keys(this.props.mapPoints)) {
        const coords = this.props.mapPoints[k].coords;
        if (coords && vertex.latLng.distanceTo(coords) < closestDistance) {
          closestDistance = vertex.latLng.distanceTo(coords);
          closestCoords = coords;
          closestId = this.props.mapPoints[k].id;
        }
      }

      vertex.latLng.lat = closestCoords[0];
      vertex.latLng.lng = closestCoords[1];

      if (!closestId && cv)
        newOrderedPoints.splice(vertex.index, 1);
      else if (nv)
        newOrderedPoints.splice(vertex.index, 0, closestId);
      else
        newOrderedPoints.splice(vertex.index, 1, closestId);
      return newOrderedPoints;
    }
    else if (rv) {
      const vertex = rv;
      const newOrderedPoints = [...this.props.selectedPoints];
      newOrderedPoints.splice(vertex.index, 1);
      return newOrderedPoints;
    }
    return [...this.props.selectedPoints];
  }

  _correctPrecision = (newCoords, precision) => {
    newCoords[0] = Number(newCoords[0].toFixed(precision));
    newCoords[1] = Number(newCoords[1].toFixed(precision));
  }

  _onDrawnGeometryChange = (newCoords) => {
    const precision = getCoordsGlobalPrecision();

    const removedVertex = this.state.drawnCoords.length > newCoords.length
      && (() => {
        const index = this.state.drawnCoords.findIndex((c, i) => {
          if (!newCoords[i] || c[0] !== newCoords[i][0] || c[1] !== newCoords[i][1])
            return true;
          return false;
        });
        return { coords: null, index: index };
      })();

    const newVertex = this.state.drawnCoords.length < newCoords.length
      && (() => {
        const index = newCoords.findIndex((nc, i) => {
          if (nc[0] !== this.state.drawnCoords[i][0] || nc[1] !== this.state.drawnCoords[i][1]) {
            this._correctPrecision(nc, precision);
            return true;
          }
          return false;
        });
        return { coords: newCoords[index], index: index };
      })();

    // The only way I found to detect changed vertex with leaflet drawing tool
    const changedVertex = (() => {
      const index = newCoords.findIndex((nc) => {
        if (getNumberPrecision(nc[0]) > precision || getNumberPrecision(nc[1]) > precision) {
          this._correctPrecision(nc, precision);
          return true;
        }
        return false;
      });
      return index >= 0 ? { coords: newCoords[index], index: index } : false;
    })();

    const orderedPoints = this._getOrderedPoints(changedVertex, removedVertex, newVertex);
    LeafletDrawUtils.afterDraw();
    this.props.updateSelectedPoints(orderedPoints);
  }

  _onSelectedPointsChange = (prevProps) => {
    const newCoords = [];

    for (const p of this.props.selectedPoints)
      if (this.props.mapPoints[p])
        newCoords.push(this.props.mapPoints[p].coords);

    if (!_.isEqual(prevProps.selectedPoints, this.props.selectedPoints)) {
      LeafletDrawUtils.beforeDraw();
      this.setState({
        drawnCoords: newCoords
      }, () => {
        LeafletDrawUtils.afterDraw();
      });
    }
  }

  render() {
    return this.state.drawnCoords.length
      ? <Polyline
        className={b('PolylineConnectingPoints')}
        key={this.state.drawnCoords}
        positions={this.state.drawnCoords}
      />
      : null;
  }
}
