import React from 'react';
import { push } from 'react-router-redux';
import { toast } from 'react-toastify';

import { CRUD } from './queries';
import I18N from '../../common/i18n';
import { store } from '../../..';
import { AppSections, Sections } from '../../common/enums';
import MeasureForm from './MeasureForm';

const defaultMeasure = Object.freeze({
  'weight_units': 1,
  'volume_units': 1,
});

export default
class MeasureCreate extends React.PureComponent {
  static title = I18N.MEASURES_CREATE_TITLE

  _create = (create, data) => {
    const p = create({
      variables: {
        'data': data
      }
    });
    p.then((res) => {
      toast.success(`${I18N.MEASURES_CREATE_TITLE}. ${I18N.NOTIFY_SUCCESS}`);
      store.dispatch(push(`/${AppSections.CATALOG}/${Sections.MEASURES}`));
    });
    p.catch((res) => {
      toast.error(`${I18N.MEASURES_CREATE_TITLE}. ${I18N.NOTIFY_ERROR}`);
      console.log('created error', res);
    });
    return p;
  }

  render() {
    return (
      CRUD.CREATE(
        null,
        (create) => (
          <MeasureForm
            {...this.props}
            submitText={I18N.SUBMIT_CREATE}
            item={defaultMeasure}
            submit={(data) => this._create(create, data)} />
        )
      )
    );
  }
}
