import React from 'react';
import {
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';
import {Helmet} from "react-helmet";
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'react-image-lightbox/style.css';

import { Layout } from './modules/layout/components';
import Manifest from './modules/manifest/components/Manifest';
import ModalWindow from './modules/dialogs/ModalWindow';
import AuthenticatedContent from './modules/auth/components/AuthenticatedContent';
import Catalog from './modules/catalog/components/Catalog';
import { AppSections, SectionMatchParams as SMP } from './modules/common/enums';
import i18n from './modules/common/i18n';
import sprite from './svg/sprite.svg';

export const ConnectedSwitch = connect((state) => ({
  location: state.router.location
}))(Switch);

const Root = () => (
  <AuthenticatedContent>
    <svg style={{display: 'block', height: 0}} src={sprite}></svg>
    <Helmet htmlAttributes={{ lang: i18n._lang }}/>
    <Layout>
      <ModalWindow />
      <ToastContainer
        position="bottom-center"
        autoClose={4000}
        hideProgressBar
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
      <ConnectedSwitch>
        <Route exact path="/" render={() => (<Redirect to={`/${AppSections.MANIFEST}`}/>)}/>
        <Route path={`/${AppSections.MANIFEST}/:${SMP.MANIFEST_ID}?/:${SMP.SECTION}?/:${SMP.ITEM_ID}?/:${SMP.ITEM_STATUS}?`} render={(props) => <Manifest {...props}/>}/>
        <Route path={`/${AppSections.REQUEST}`} render={() => (<div>Раздел заявок</div>)}/>
        <Route path={`/${AppSections.HISTORY}`} render={() => (<div>Раздел истории</div>)}/>
        <Route path={`/${AppSections.REPORTS}`} render={() => (<div>Раздел отчетов</div>)}/>
        <Route path={`/${AppSections.CATALOG}/:${SMP.SECTION}?/:${SMP.ITEM_ID}?/:${SMP.ITEM_STATUS}?`} render={(props) => (<Catalog {...props}/>)}/>
        <Route path={`/${AppSections.SETTINGS}`} render={() => (<div>Раздел настроек</div>)}/>
      </ConnectedSwitch>
    </Layout>
  </AuthenticatedContent>
);

export default Root;
