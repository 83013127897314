import React from 'react';
import block from 'bem-cn-lite';
import { Polygon } from 'react-leaflet';

import { 
  // client, 
  routeTo 
} from '../../index';
// import { queries } from '../manifest/queries';
import { getPaletteColorClass, decodeGeometry, closePolygon, getSectionLinkBody } from './../../base/utils';
import { 
  // AppSections, 
  Sections, 
  // ApolloFetchPolicy 
} from '../common/enums';
import { actions as sectionActions } from '../sections/common/actions';

import { PropTypes } from 'prop-types';
import { loadZonesAsync } from '../manifest/utils';

const b = block('LeafletMap');

export default
class LeafletMapZonesLayer extends React.Component {
  static propTypes = {
    showZones: PropTypes.bool,
    manifestId: PropTypes.number,
  }

  constructor(props) {
    super(props);
    this.state = {
      zones: {}
    };
  }

  componentDidMount() {
    this.zoneUpdateEvent = sectionActions.zoneGeometryUpdated.subscribe(
      (s, action) => {
        if (action.source !== 'map') {
          this.setState((state) => {
            const newState = state;
            newState.zones[action.id] = {...newState.zones[action.id], geometry: action.geometry};
            return {...newState};
          });
        }
      });
    this.zoneCreateEvent = sectionActions.zoneCreated.subscribe(
      (s, action) => {
        if (action.source !== 'map') {
          this.setState((state) => {
            const newState = state;
            newState.zones[action.id] = {
              id: action.id,
              geometry: action.geometry,
            };
            return {...newState};
          });
        }
      });
    this.zoneDeleteEvent = sectionActions.zoneDeleted.subscribe(
      (s, action) => {
        if (action.source !== 'map') {
          this.setState((state) => {
            const newState = state;
            delete newState.zones[action.id];
            return {...newState};
          });
        }
      });
    this._loadZones();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.manifestId !== this.props.manifestId)
      this._loadZones();
  }

  componentWillUnmount() {
    this.zoneCreateEvent.unsubscribe();
    this.zoneDeleteEvent.unsubscribe();
    this.zoneUpdateEvent.unsubscribe();
  }

  _loadZones = async () => {
    if (this.props.manifestId !== 0) {
      const zonesRaw = await loadZonesAsync(this.props.manifestId);
      const zonesFiltered = {};

      for (const z of zonesRaw)
        zonesFiltered[z.id] = {id: z.id, geometry: closePolygon(decodeGeometry(z.geometry))};

      this.setState((state) => {
        if (state.zones[-1])
          zonesFiltered[-1] = state.zones[-1];
        // Loading all zones may finish after opening the zone form for creating.
        // In this case we want to preserve the new point (with id -1) coordinates.
        return {zones: zonesFiltered};
      });
    }
  }

  _routeToZone = (e) => routeTo(`${getSectionLinkBody(this.props.manifestId)}${Sections.ZONES}/${e.target.options.zoneId}/edit`);

  render() {
    if (!this.props.showZones)
      return null;

    const elements = [];
    for (const zoneId of Object.keys(this.state.zones)) {
      const zone = this.state.zones[zoneId];
      if (zone.geometry) {
        elements.push(
          <Polygon
            className={`leaflet-zonepart ${b('ZoneGeometryShadow')}`}
            key={`zone_${zone.id}_shadow`}
            positions={zone.geometry}
          />,
          <Polygon
            zoneId={zone.id}
            className={`leaflet-zonepart ${b('ZoneGeometryBody')}`}
            key={`zone_${zone.id}_body`}
            positions={zone.geometry}
            onclick={this._routeToZone}
          />,
          <Polygon
            className={`${`leaflet-zonepart ${b('ZoneGeometryColor')}`} ${getPaletteColorClass(zone.id)}`}
            key={`zone_${zone.id}_color`}
            positions={zone.geometry}
          />,
        );
      }
    }
    return elements;
  }
}
