import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';

import I18N from 'modules/common/i18n';
import Button from 'modules/common/components/Button';
import { store } from 'index';
import { actions as sectionActions } from 'modules/sections/common/actions';
import { AuxilaryTabKinds } from '../common/enums';
import { decToMask } from '../../base/utils';

const b = block('Input');

export default
class SelectItemsInput extends React.PureComponent {
  static fieldName = 'items'

  static propTypes = {
    size: PropTypes.number,
    caption: PropTypes.string.isRequired,
    sourceActions: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.number,
    onChangeValue: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
  }

  constructor(props) {
    super(props);
    this.props = props || { fromView: (val) => {
      if (val.length) {
        let num = Number(val.replace(',', '.'));
        num = props && isFinite(props.min) && num < props.min ? props.min : num;
        num = props && isFinite(props.max) && num > props.max ? props.min : num;
        return num;
      }
      return null;
      } };
  }

  componentDidMount() {
    this.selectionUpdateEvent = sectionActions.fieldValueUpdated.subscribe(
      (s, action) => {
        if (this.props.onChangeValue && action.fieldName === this.props.name) {
          this.props.onChangeValue(this.props.name, action.value);
        }
      }
    );
  }

  componentWillUnmount() {
    this.selectionUpdateEvent.unsubscribe();
  }

  _onClick = () => {
    store.dispatch(this.props.sourceActions.openSelectList(
      AuxilaryTabKinds.SELECT_FLAGS,
      this.props.name,
      this.props.value,
    ));
  }

  _toViewList = (value) => decToMask(value).length;

  render() {
    return (
      <div key={'body'} className={b('ButtonPick')}>
        <Button onClick={this._onClick}>{I18N.FIELD_START_SELECT}</Button>
        <div className={b('ButtonPickData')}>
          <div className={b('DataCount')}>
            {I18N.FIELD_SELECTED_COUNT}
            <b>{this._toViewList(this.props.value)}</b>
          </div>
        </div>
      </div>
    );
  }
}
