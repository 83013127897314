import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';

const b = block('Input');

class CheckboxInput extends React.PureComponent {
    static fieldName = 'checkbox'

	constructor(props) {
		super(props);
		this.fromView = (props && props.fromView) || ((val) => isFinite(val) ? Boolean(Number(val)) : Boolean(val));
		this.toView = (props && props.toView) || ((val) => Boolean(val));
	}

	_onChange = (e) => {
		const val = this.fromView(e.target.checked);
		this.props.onChangeValue && this.props.onChangeValue(e.target.name, val);
	}

	render() {
		return (
			<div className={b('Checkbox')}>
				<div className={b('CheckboxInput')}>
					<div className={b('CheckboxMarker', {'Enabled': this.toView(this.props.value)})}/>
				</div>
				<input
					type='checkbox'
					className={b('CheckboxHidden')}
					defaultChecked={this.toView(this.props.value)}
					id={this.props.name}
					name={this.props.name}
					onChange={this._onChange}
					disabled={this.props.disabled}
				/>
			</div>
		);
	}
}

export default CheckboxInput

CheckboxInput.propTypes = {
	size: PropTypes.number,
	caption: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.any,
	onChangeValue: PropTypes.func.isRequired,
	fromView: PropTypes.func,
	toView: PropTypes.func,
	disabled: PropTypes.bool
}

