import React from 'react';
import { PropTypes } from 'prop-types';
import block from 'bem-cn-lite';

import InfoBadge from '../../common/components/InfoBadge';
import Button from '../../common/components/Button';
import ConfirmButton from '../../common/components/ConfirmButton';
import ListItem from '../common/ListItem';
import I18N from '../../common/i18n';
import icons from '../../common/icons';
import { 
  Sections, 
  // AppSections, 
  SvgColors 
} from '../../common/enums';
import { getSectionLinkBody } from '../../../base/utils';

const b = block('ListItem');

export default
class PointListItem extends React.PureComponent {

  static section = Sections.POINTS;

  static propTypes = {
    onDelete: PropTypes.func,
    item: PropTypes.object.isRequired,
    manifestId: PropTypes.number,
    showControls: PropTypes.bool,
    listNumber: PropTypes.number,
  }

  _getPointIcon = (point) => {
    if (!point.lat_lon)
      return icons.ITEM_WAYPOINT_ERROR;
    if (point.garage || point.depot)
      return icons.ITEM_WAYPOINT_BASE;
    if (point.pickup_point)
      return icons.ITEM_WAYPOINT_PICKUP;
    return icons.ITEM_WAYPOINT_ADDRESS;
  }

  _getIconColor = (point) => {
    if (!point.lat_lon)
      return SvgColors.RED;
    if (point.garage || point.depot)
      return SvgColors.DARK;
    return SvgColors.GREY;
  }

  render() {
    const point = this.props.item;
    const icon = this._getPointIcon(point);
    const color = this._getIconColor(point);

    return <ListItem
      id={point.id}
      link={`${getSectionLinkBody(this.props.manifestId)}${Sections.POINTS}/${this.props.manifestId > 0 ? point.id : `${point.id}/edit`}`}
      icon={icon}
      iconStroke={4}
      iconColor={color}
      listNumber={this.props.listNumber}
      key={point.id}
      title={`${point.name} :: ${point.id}`}
      details={point.address}
    >
      <div className={b('Controls', { 'AlwaysShow': this.props.showControls })}>
        {this.props.onDelete &&
          <ConfirmButton
            confirmMessage={`${I18N.POINTS_DELETE_CONFIRM} «${point.name}»`}
            icon={icons.COMMON_DELETE} leftHint={I18N.ITEM_DELETE}
            onClick={() => this.props.onDelete(point.id)}/>
        }
        <Button icon={icons.COMMON_EDIT} leftHint={I18N.ITEM_EDIT}
          to={`${getSectionLinkBody(this.props.manifestId)}${Sections.POINTS}/${point.id}/edit`} />
      </div>
      <div className={b('InfoBadges')}>
        <InfoBadge iconName={icons.BADGE_SCHEDULE} value={`
          ${I18N.formatDecimalHoursAsTime(point.work_start)} -
          ${I18N.formatDecimalHoursAsTime(point.work_end)}
        `}/>
        {point.break_start !== point.break_end
          && <InfoBadge iconName={icons.BADGE_BREAK} value={`
            ${I18N.formatDecimalHoursAsTime(point.break_start)} -
            ${I18N.formatDecimalHoursAsTime(point.break_end)}
          `}/>}
        <InfoBadge iconName={icons.BADGE_WEIGHT} value={(point.weight || 0).toString() + (point.backhaul_weight ? "\u21BA" + point.backhaul_weight.toString() : "") }/>
        <InfoBadge iconName={icons.BADGE_VALUE} value={(point.volume || 0).toString() + (point.backhaul_volume ? "\u21BA" + point.backhaul_volume.toString() : "")}/>
        <InfoBadge iconName={icons.BADGE_CARGO} value={I18N.formatDecimalHours(point.shipping_time)}/>
        {point.from_id && <InfoBadge
          to={`${getSectionLinkBody(this.props.manifestId)}${Sections.POINTS}/${point.from_id}`}
          iconName={icons.BADGE_FROM_ID}
          value={point.from_id}/>}
      </div>
    </ListItem>;
  }
}
