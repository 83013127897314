import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';

import { lookupFromView, lookupToView } from '../../base/utils';
import I18N from '../common/i18n';

const b = block('Input');

export default
class LookupInput extends React.Component {
  static fieldName = 'lookup';

  static propTypes = {
    items: PropTypes.array,
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    onChangeValue: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    hasNull: PropTypes.bool,
    hasUndefined: PropTypes.bool,
    toView: PropTypes.func,
    fromView: PropTypes.func,
  }

  constructor(props) {
    super(props);
    this.props = props || { fromView: lookupFromView };
  }

  _onChange = (e) => {
    const val = this.props.fromView ? this.props.fromView(e.target.value) : lookupFromView(e.target.value);
    this.props.onChangeValue && this.props.onChangeValue(e.target.name, val);
  }

  _renderOptions = () => {
    const options = [];
    if (this.props.hasUndefined)
      options.push(<option key={'undefined'} value={-2}>{I18N.FIELD_UNDEFINED_VALUE}</option>);
    if (this.props.hasNull)
      options.push(<option key={'null'} value={-1}>{I18N.FIELD_NULL_VALUE}</option>);
    for (const item of this.props.items)
      options.push(<option key={item.value} value={item.value}>{item.caption}</option>);
    return options;
  }

  render() {
    const toView = this.props.toView || lookupToView;
    return (
      <select
        value={toView(this.props.value)}
        id={this.props.name}
        className={b()}
        name={this.props.name}
        onChangeCapture={this._onChange}
        onChange={this._onChange}
        disabled={this.props.disabled}
      >
        {this._renderOptions()}
      </select>
    );
  }
}
