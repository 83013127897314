import React from 'react';
import { PropTypes } from 'prop-types';
import { toast } from 'react-toastify';
import { push } from 'react-router-redux';
import block from 'bem-cn-lite';

import { CRUD } from './queries';
import I18N from '../../common/i18n';
import { store } from '../../..';
import { 
  // AppSections, 
  Sections 
} from './../../common/enums';
import CourierForm from './CourierForm';
import { getSectionLinkBody } from '../../../base/utils';
import FormSwitcher from '../common/FormSwitcher';
import * as inputs from '../../inputs';
import ItemForm from '../common/ItemForm';

const b = block('FormWrapper');

const defaultCourier = Object.freeze({
  'work_start': 0,
  'work_end': 23.99,
  'max_weight': 5000,
  'max_volume': 5000,
  'vehicle_type': 'car',
});

const cloneFields = [
  {
    inputClass: inputs.CheckboxInput,
    caption: I18N.COURIER_CLONE_LINKED_POINTS,
    name: 'with_linked_points',
    help: I18N.COURIER_CLONE_LINKED_POINTS_HELP,
  },
  {
    inputClass: inputs.CheckboxInput,
    caption: I18N.POINTS_CLONE_LINKED_ZONES,
    name: 'with_linked_zones',
    help: I18N.POINTS_CLONE_LINKED_ZONES_HELP,
  },
  {
    inputClass: inputs.SelectItemsInput,
    caption: I18N.COURIER_CLONE_SELECT,
    name: 'ids',
    section: Sections.COURIERS,
    help: I18N.COURIER_CLONE_SELECT_HELP,
    fromCatalog: true,
  }
];

const defaultClone = Object.freeze({
  'with_linked_points': true,
  'with_linked_zones': true,
  'ids': [],
});

export default
class CourierCreate extends React.PureComponent {
  static title = I18N.COURIERS_CREATE_TITLE

  static propTypes = {
    manifestId: PropTypes.number,
    orgId: PropTypes.number,
  }

  constructor(props) {
    super(props);
    this.state = {
      fromCatalog: false
    };
  }

  _create = (create, data) => {
    const p = create({
      variables: {
        'manifest_id': this.props.manifestId || -this.props.orgId,
        'data': data
      }
    });
    p.then((res) => {
      toast.success(`${I18N.COURIERS_CREATE_TITLE}. ${I18N.NOTIFY_SUCCESS}`);
      store.dispatch(push(
        `${getSectionLinkBody(this.props.manifestId)}${Sections.COURIERS}`
      ));
    });
    p.catch((res) => {
      toast.error(`${I18N.COURIERS_CREATE_TITLE}. ${I18N.NOTIFY_ERROR}`);
      console.log('created error');
    });
    return p;
  }

  _clone = (clone, data) => {
    const p = clone({
      variables: {
        'from_id': -this.props.orgId,
        'to_id': this.props.manifestId,
        'ids': data.ids,
        'with_linked_points': data.with_linked_points,
        'with_linked_zones': data.with_linked_zones
      }
    });
    p.then((res) => {
      toast.success(`${I18N.MISC_ITEM_FROM_CATALOG}. ${I18N.NOTIFY_SUCCESS}`);
      store.dispatch(push(
        `${getSectionLinkBody(this.props.manifestId)}${Sections.COURIERS}`
      ));
    });
    p.catch((res) => {
      toast.error(`${I18N.MISC_ITEM_FROM_CATALOG}. ${I18N.NOTIFY_ERROR}`);
      console.log('clone error');
    });
    return p;
  }

  render() {
    return <div className={b()}>
      {this.props.manifestId && <FormSwitcher
        formComponent={this}
        stateValueName={'fromCatalog'}
        options={[
          { caption: I18N.MISC_ITEM_CURRENT_MANIFEST, value: false },
          { caption: I18N.MISC_ITEM_FROM_CATALOG, value: true },
        ]}
      />}
      {this.state.fromCatalog
        ? CRUD.CLONE(
          {
            'manifest_id': this.props.manifestId || -this.props.orgId,
          },
          (clone) => <ItemForm
            {...this.props}
            item={defaultClone}
            submitText={I18N.SUBMIT_DEFAULT}
            fields={cloneFields}
            submit={(data) => this._clone(clone, data)}
          />)
        : CRUD.CREATE(
          {
            'manifest_id': this.props.manifestId || -this.props.orgId,
            'title_filter': ''
          },
          (create) => <CourierForm
            {...this.props}
            manifestId={this.props.manifestId || -this.props.orgId}
            item={defaultCourier}
            submitText={I18N.SUBMIT_CREATE}
            submit={(data) => this._create(create, data)} />)}
    </div>;
  }
}
