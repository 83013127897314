import React from 'react';
import block from 'bem-cn-lite';

const b = block('FlexibleScroll');

const FlexibleScroll = ({children}) => <div className={b()}>
  <div className={b('Scroller')}>
    {children}
  </div>
</div>;

export default FlexibleScroll;
