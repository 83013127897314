import React from 'react';
import { PropTypes } from 'prop-types';
import { push } from 'react-router-redux';
import block from 'bem-cn-lite';
import { toast } from 'react-toastify';

import ItemForm from '../common/ItemForm';
import { CRUD } from '../manifests/queries';
import I18N from '../../common/i18n';
import { withApolloAsyncLoad } from '../../../base/utils';
import { store } from '../../..';
import * as inputs from '../../inputs';
import { AppSections } from '../../common/enums';
import FormSwitcher from '../common/FormSwitcher';

const b = block('OptimizationForm');

const commonFields = [
  {
    inputClass: inputs.LookupInput,
    caption: I18N.OPTIMIZATION_SPECNAME,
    name: 'optimization',
    help: I18N.OPTIMIZATION_SPECNAME_HELP,
    items: [
      { value: 'mincluster', caption: I18N.OPTIMIZATION_OPTION_MINCLUSTER},
      { value: 'mindistcluster', caption: I18N.OPTIMIZATION_OPTION_MINDISTCLUSTER},
      { value: 'mincost', caption: I18N.OPTIMIZATION_OPTION_MINCOST},
      { value: 'mintime', caption: I18N.OPTIMIZATION_OPTION_MINTIME}
    ],
    size: 2,
  },
  {
    inputClass: inputs.CheckboxInput,
    caption: I18N.OPTIMIZATION_TRAFFIC_JAMS,
    name: 'traffic_jams',
    help: I18N.OPTIMIZATION_TRAFFIC_JAMS_HELP,
    size: 2,
  }
];

const fields = [
  ...commonFields,
  {
    inputClass: inputs.CheckboxInput,
    caption: I18N.OPTIMIZATION_STRICT_MODE,
    name: 'strict_mode',
    help: I18N.OPTIMIZATION_STRICT_MODE_HELP,
    size: 2,
  },
  {
    inputClass: inputs.CheckboxInput,
    caption: I18N.OPTIMIZATION_CLUSTERIZE,
    name: 'task_type',
    fromView: (val) => val ? 'clusterize' : 'optimize',
    toView: (val) => val === 'clusterize',
    help: I18N.OPTIMIZATION_CLUSTERIZE_HELP,
    size: 2,
  },
  // { inputClass: inputs.LookupInput, caption: I18N.OPTIMIZATION_CLUSTERIZATION, name: 'clusterization', size: 2,
  //   items: [
  //     { value: 'auto', caption: I18N.OPTIMIZATION_OPTION_AUTO},
  //     { value: 'none', caption: I18N.OPTIMIZATION_OPTION_NONE},
  //   ]
  // },
  {
    inputClass: inputs.RangeInput,
    caption: I18N.OPTIMIZATION_MAXITER,
    name: 'maxiter',
    help: I18N.OPTIMIZATION_MAXITER_HELP,
    min: 1000,
    max: 100000,
    step: 100,
    size: 2
  },
  {
    inputClass: inputs.RangeInput,
    caption: I18N.OPTIMIZATION_RUNS,
    name: 'runs',
    help: I18N.OPTIMIZATION_RUNS_HELP,
    min: 1,
    max: 10,
    step: 1,
    size: 2
  },
  // { inputClass: inputs.NumberInput, caption: I18N.OPTIMIZATION_NNEIGHBORS, name: 'n_neighbors' },
  // { inputClass: inputs.NumberInput, caption: I18N.OPTIMIZATION_SPEED_MULTIPLIER, name: 'speed_coeff' },
  {
    inputClass: inputs.RangeInput,
    caption: I18N.OPTIMIZATION_SPEED_MULTIPLIER,
    name: 'speed_coeff',
    help: I18N.OPTIMIZATION_SPEED_MULTIPLIER_HELP,
    min: 0,
    max: 2,
    step: 0.25,
    size: 2
  },
  {
    inputClass: inputs.RangeInput,
    caption: I18N.OPTIMIZATION_BALANCE_AGENTS,
    name: 'balance_agents',
    help: I18N.OPTIMIZATION_BALANCE_AGENTS_HELP,
    min: 0,
    max: 1,
    step: 0.1,
    size: 2
  },
  // { inputClass: inputs.CheckboxInput, caption: I18N.OPTIMIZATION_BYAGENT, name: 'by_agent'},
  {
    inputClass: inputs.CheckboxInput,
    caption: I18N.OPTIMIZATION_BYZONE,
    name: 'by_zone',
    help: I18N.OPTIMIZATION_BYZONE_HELP,
  },
  // { inputClass: inputs.CheckboxInput, caption: I18N.OPTIMIZATION_IMPROVE_CLUSTERS, name: 'improve_clusters'},
  // { inputClass: inputs.CheckboxInput, caption: I18N.OPTIMIZATION_BALANCE_BY_WEIGHT, name: 'balance_by_weight'},
  // { inputClass: inputs.CheckboxInput, caption: I18N.OPTIMIZATION_DONT_MAKE_ROUTES, name: 'dont_make_routes', size: 2},
  // { inputClass: inputs.CheckboxInput, caption: I18N.OPTIMIZATION_CROW_MATRICES, name: 'crow_matrices'},
  // { inputClass: inputs.CheckboxInput, caption: I18N.OPTIMIZATION_SKIP_ROUTES, name: 'skip_routes'},
  {
    inputClass: inputs.CheckboxInput,
    caption: I18N.OPTIMIZATION_BY_GROUP,
    name: 'by_group',
    help: I18N.OPTIMIZATION_BY_GROUP_HELP,
  },
  {
    inputClass: inputs.NumberInput,
    caption: I18N.OPTIMIZATION_TRUCKER_DAYS,
    name: 'n_days',
    help: I18N.OPTIMIZATION_TRUCKER_DAYS_HELP,
    size: 2,
  },
];

export default
class OptimizationForm extends React.PureComponent {
  static title = I18N.OPTIMIZATION_FORM_TITLE

  static propTypes = {
    manifestId: PropTypes.number.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      onlyCommonFields: true,
    };
  }

  _update = (update, data) => {
    const p = update({
      variables: {
        id: this.props.manifestId,
        data: data,
      }
    });
    p.then((res) => {
      store.dispatch(push(`/${AppSections.MANIFEST}/${this.props.manifestId}`));
      toast.success(`${I18N.OPTIMIZATION_FORM_SUBMIT}. ${I18N.NOTIFY_SUCCESS}`);
    });
    p.catch((res) => {
      toast.error(`${I18N.OPTIMIZATION_FORM_SUBMIT}. ${I18N.NOTIFY_ERROR}`);
      console.log('update error');
    });
    return p;
  }

  _renderForm = (optimizationData) => (
    CRUD.UPDATE(
      {},
      (update) => <div className={b()}>
        <FormSwitcher
          formComponent={this}
          stateValueName={'onlyCommonFields'}
          options={[
            { caption: I18N.OPTIMIZATION_FORM_COMMON, value: true },
            { caption: I18N.OPTIMIZATION_FORM_ADVANCED, value: false },
          ]}
        />
        {this.state.onlyCommonFields
          ? <ItemForm
            {...this.props}
            key={'commonFields'}
            submitText={I18N.OPTIMIZATION_FORM_SUBMIT}
            fields={commonFields}
            item={optimizationData}
            submit={(data) => this._update(update, data)} />
          : <ItemForm
            {...this.props}
            key={'fields'}
            submitText={I18N.OPTIMIZATION_FORM_SUBMIT}
            fields={fields}
            item={optimizationData}
            submit={(data) => this._update(update, data)} />
        }
      </div>
    ));

  render() {
    return (
      CRUD.GET_ONE(
        {id: this.props.manifestId},
        (result) => withApolloAsyncLoad(this._renderForm, result)
      )
    );
  }
}
