import React from 'react';
import { connect } from 'react-redux';
// import _ from 'lodash';
import { PropTypes } from 'prop-types';
import { EditControl } from 'react-leaflet-draw';
import { FeatureGroup } from 'react-leaflet';

import { reducer as mapReducer, OperationType } from './actions';
import { actions as sectionActions } from '../sections/common/actions';
import { actions as mapActions } from '../map/actions';
import LeafletDrawingPaths from './LeafletDrawingPaths';
import LeafletDrawingPolygons from './LeafletDrawingPolygons';


class LeafletMapDrawingLayer extends React.Component {
  static propTypes = {
    manifestId: PropTypes.number.isRequired,
    operation: PropTypes.string,
    coords: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
    mapZoom: PropTypes.number,
    onChange: PropTypes.func,
    mapPoints: PropTypes.object,
    waybills: PropTypes.object,
    fieldName: PropTypes.string,
    selectedCourierId: PropTypes.number,
    updateSelectedPoints: PropTypes.func,
    fieldValueUpdated: PropTypes.func,
    selectedPoints: PropTypes.arrayOf(PropTypes.number)
  }

  constructor() {
    super();
    this.state = {
      drawnCoords: [],
      drawing: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.operation && !prevProps.operation)
      this._onDrawStart(prevProps);
    else if (!this.props.operation && prevProps.operation)
      this._onDrawStop();
  }

  _latLngToCoords(polygon) {
    const polygonCoordsArray = [];
    for (const point of polygon)
      polygonCoordsArray.push([point.lat, point.lng]);
    return polygonCoordsArray;
  }

  onDrawComplete = (e) => {
    const newCoords = e.layer.editing.latlngs[0].length > 1 ? e.layer.editing.latlngs[0] : e.layer.editing.latlngs[0][0];
    const coords = this._latLngToCoords(newCoords);
    e.layer.remove();
    this.setState({ drawnCoords: coords });
  }

  onVertexEdited = (e) => {
    const leafletCoords = e.poly.editing.latlngs[0].length > 1 ? e.poly.editing.latlngs[0] : e.poly.editing.latlngs[0][0];
    const coords = this._latLngToCoords(leafletCoords);
    this.setState({ drawnCoords: coords });
  }

  _onDrawStart = (prevProps) => {
    if (this.props.operation === OperationType.CONNECT_POINTS) {
      this.setState({
        drawnCoords: this.props.selectedPoints.map((p) => this.props.mapPoints[p].coords),
        drawing: true,
      });
    }
    else if (!this.state.drawnCoords.length && this.props.coords && this.props.coords.length)
      this.setState({
        drawnCoords: this.props.coords,
        drawing: true,
      });
    else {
      this.setState({
        drawing: true,
      });
    }
  };

  _onDrawStop = () => {
    this.setState({
      drawnCoords: [],
      drawing: false,
    });
  };

  render() {
    return (
      <FeatureGroup>
        <EditControl
          position='bottomright'
          onEditVertex={this.onVertexEdited}
          onCreated={this.onDrawComplete}
          draw={{
            rectangle: false,
            circle: false,
            marker: false,
            circlemarker: false,
          }}
        />
        {this.state.drawing
          && this.props.operation !== OperationType.CONNECT_POINTS
          && <LeafletDrawingPolygons
            drawnCoords={this.state.drawnCoords}
            {...this.props}
          />}
        {this.state.drawing
          && this.props.operation === OperationType.CONNECT_POINTS
          && <LeafletDrawingPaths
            drawnCoords={this.state.drawnCoords}
            {...this.props}
          />}
      </FeatureGroup>
    );
  }
}

export default
connect(
  (state) => ({
    operation: state[mapReducer.name].operation,
    coords: state[mapReducer.name].coords,
    fieldName: state[mapReducer.name].fieldName,
    selectedCourierId: state[mapReducer.name].selectedCourierId,
    selectedPoints: state[mapReducer.name].selectedPoints,
  }),
  (dispatch) => ({
    updateSelectedPoints: (selectedPoints) => dispatch(mapActions.updateSelectedPoints(selectedPoints)),
    fieldValueUpdated: (fieldName, coords) => dispatch(sectionActions.fieldValueUpdated(fieldName, coords, 'map')),
  })
)(LeafletMapDrawingLayer);
