export
const AppSections = {
  MANIFEST: 'manifest',
  REQUEST: 'request',
  HISTORY: 'history',
  REPORTS: 'reports',
  CATALOG: 'catalog',
  SETTINGS: 'settings',
};

export
const ButtonKinds = {
  WARNING: 'WARNING',
  DANGER: 'DANGER',
  UNIMPORTANT: 'UNIMPORTANT',
};

export const Sections = {
  MANIFEST_LIST: 'manifest_list',
  MANIFEST_CREATE: 'new',
  MANIFEST_EDIT: 'edit',
  MANIFEST_CLONE: 'manifest_clone',
  POINTS: 'points',
  COURIERS: 'couriers',
  ZONES: 'zones',
  GROUPS: 'groups',
  USERS: 'users',
  MEASURES: 'measures',
  DETAILS: 'details',
  OPTIMIZATION: 'optimization',
  POINTS_DETAILS: 'points_detais',
  CUSTOM_FORM: 'custom_form',
  REPORT_FORM: 'report_form',
  EDIT_WAYBILL: 'edit_waybill',
};

export const Forms = {
  NEW: 'new',
  EDIT: 'edit'
};

export const MapElements = {
  LINES: 'displayLines',
  PATH: 'displayPath',
  POINTS: 'displayPoints',
  POINTS_ASSIGNED: 'displayAssignedPoints',
  CLUSTERS: 'displayClusters',
  MAP: 'displayMap',
  ZONES: 'displayZones',
  TRACKS: 'displayTracks',
};

export const SectionMatchParams = {
  MANIFEST_ID: 'manifestId',
  SECTION: 'section',
  ITEM_ID: 'itemId',
  ITEM_STATUS: 'itemStatus',
};

export
const ApolloFetchPolicy = {
  CACHE_FIRST: 'cache-first',
  CACHE_AND_NETWORK: 'cache-and-network',
  NETWORK_ONLY: 'network-only',
  CACHE_ONLY: 'cache-only',
  NO_CACHE: 'no-cache',
};

export
const AuxilaryTabKinds = {
  RAW_TEXT: 'rawText',
  SELECT_ITEMS: 'selectItems',
  SELECT_DAYS: 'selectDays',
  SELECT_FLAGS: 'selectFlags',
  SHOW_PHOTOS: 'showPhotos',
};

export
const SvgColors = {
  GREY: 'grey',
  RED: 'red',
  DARK: 'dark',
};

export
const RouteCode = {
  ST: "ST",
  UL: "UL",
  RL: "RL",
  RU: "RU",
  LD: "LD",
  UD: "UD",
  RG: "RG",
  RT: "RT",
  EW: "EW",
  BR: "BR",
  LH: "LH",

  ERR: "ERR",
  T: "T",
  W: "W",
  S: "S",
  L: "L",
  OL: "OL",
  OLD: "OLD",
  WZ: "WZ",
  WV: "WV",
  PV: "PV",
  NW: "NW",
  NL: "NL",
};
