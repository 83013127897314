import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { reducer, actions } from './actions';

class ModalWindow extends React.PureComponent {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    contentProps: PropTypes.object,
    actions: PropTypes.object
  };

  render() {
    if (!this.props.open)
      return false;

    const Content = this.props.content;
    return (
      <Content
        {...this.props.contentProps}
        closeModal={this.props.actions.closeModal}
      />
    );
  }
}

export default
connect(
  (state) => ({
    open: state[reducer.name].open,
    content: state[reducer.name].content,
    contentProps: state[reducer.name].contentProps,
  }),
  (dispatch) => ({
    "actions": bindActionCreators(actions, dispatch)
  })
)(ModalWindow);
