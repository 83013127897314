import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';

import { CheckboxInput } from 'modules/inputs';

const b = block('SelectableListItem');

export
const withSelectableListItem = (ListItem, onItemSelected, checkItemSelected) => class
  WithSelectableListItem extends React.PureComponent {

  static propTypes = {
    id: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      enabled: checkItemSelected(props.id)
    };
  }

  _onChangeValue = (name, value) => {
    onItemSelected(this.props.id, value);
    this.setState({enabled: value});
  }

  _toggleSelection = (e) => {
    this._onChangeValue(null, !this.state.enabled);
  }

  render() {
    return <div onClick={this._toggleSelection} className={b()}>
      <div className={b('CheckButton')}>
        <CheckboxInput value={this.state.enabled} onChangeValue={this._onChangeValue} type={'checkbox'}/>
      </div>
      <div className={b('ListItem')}>
        <ListItem {...this.props}/>
      </div>
    </div>;
  }
};
