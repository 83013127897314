import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withApollo } from 'react-apollo';
import { PropTypes } from 'prop-types';
// import { queries } from '../queries';

import { reducer, actions } from '../actions';
import LoginForm from './LoginForm';
import { client } from '../../..';

class AuthenticatedContent extends React.PureComponent {
  static propTypes = {
    children: PropTypes.any.isRequired,
    actions: PropTypes.object,
    token: PropTypes.string,
    user: PropTypes.object,
  }

  // loadCurrentUser() {
  //   const p = client.query({query: queries.CURRENT_USER});
  //   p.then((result) => {
  //     if (result.data && this.props)
  //       this.props.actions.loadUser(result.data.result);
  //   });
  //   p.catch(() => {
  //     this.props.actions.logoutUser();
  //   });
  // }

  // _onUpdate() {
  //   if (this.props.token && !this.props.user)
  //     this.loadCurrentUser();
  // }

  // USAFE_componentWillMount() { 
  //   this._onUpdate();
  // }

  // componentDidUpdate() {
  //   this._onUpdate();
  // }

  	render(){
		if(!this.props.token){
			client.resetStore();
			return <LoginForm
				// loadCurrentUser={this.loadCurrentUser}
				loginUser={this.props.actions.loginUser}
			/>;
		}
		return this.props.children
	}
}

export default connect(
	(state) => ({
		user: state[reducer.name].user,
		token: state[reducer.name].token
	}),
	(dispatch) => ({
		"actions": bindActionCreators(actions, dispatch)
	})
)(withApollo(AuthenticatedContent));
