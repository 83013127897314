import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';

// import Icon from './../../common/components/Icon';
// import { Link } from 'react-router-dom';
import icons from '../../common/icons';
import { history } from '../../..';
import { Button } from '../../common/components';

const b = block('ManifestTab');

export default
class ManifestTab extends React.PureComponent {
  static propTypes = {
    children: PropTypes.any.isRequired,
    closeLink: PropTypes.string,
    onClose: PropTypes.func,
    noBackButton: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
  }

  render() {
    return (
      <div className={b()}>
        <div className={b('Head')}>
          {!this.props.noBackButton
            ? <Button className={b('BackButton')} icon={icons.COMMON_BACK} onClick={history.goBack}/>
            : <div className={b('TitleIndent')}/>
          }
          <div className={b('Title')}>{this.props.title}</div>
          <Button className={b('CloseButton')} icon={icons.COMMON_CLOSE} onClick={this.props.onClose} to={this.props.closeLink}/>
        </div>
        <div className={b('Body')}>
          {this.props.children}
        </div>
      </div>
    );
  }
}
