import React from 'react';
import { PropTypes } from 'prop-types';
import _ from 'lodash';
import { toast } from 'react-toastify';

import IncrementalList from '../../sections/common/IncrementalList';
import { LoadingIndicator } from '../../common/components';
import { decodeDate } from '../../../base/utils';
import I18N from '../../common/i18n';


export default class SectionListPreloaded extends React.PureComponent {
	static propTypes = {
		itemsPerPage: PropTypes.number,
		variables: PropTypes.object,
		items: PropTypes.arrayOf(PropTypes.any),
		itemElement: PropTypes.func.isRequired,
		manifestId: PropTypes.number,
		onDeleted: PropTypes.func,
		propertiesFilter: PropTypes.func,
		qDeleteQuery: PropTypes.func,
	};

	constructor(props) {
		super(props);
		this.state = {
			loaded: false,
			filteredData: [],
			visibleData: [],
			limit: props.variables['limit'] || 0,
			offset: props.variables['offset'] || 0,
			titleFilter: props.variables['title_filter'] || null,
			dateFilter: props.variables['date_filter'] || null,
		};
	}

	componentDidMount() {
		this._applyData(this.props.items);
	}

	componentDidUpdate(prevProps) {
		if (!_.isEqual(prevProps.variables, this.props.variables)) {
			this.setState({
				limit: this.props.variables['limit'] || 0,
				offset: this.props.variables['offset'] || 0,
				titleFilter: this.props.variables['title_filter'] || null,
				dateFilter: this.props.variables['date_filter'] || null,
				loaded: false,
			}, () => this._applyData(this.props.items));
		} else if (!_.isEqual(prevProps.items, this.props.items)) {
			this.setState({
				loaded: false
			}, () => this._applyData(this.props.items));
		}
	}

	_applyData = (items) => {
		if (!this.state.loaded && items !== undefined) {
			const filteredData = this._applyFilters(items || []);
			this.setState({
				loaded: true,
				filteredData: filteredData,
				visibleData: this.state.limit ? filteredData.slice(0, this.state.limit) : filteredData,
			});
		}
	}

	_applyFilters(items) {
		let filteredItems = items;
		if (items && items.length) {
			filteredItems = this._filterByProperties(filteredItems);
			filteredItems = this._filterByTitle(filteredItems);
			filteredItems = this._filterByDate(filteredItems);
		}
		return filteredItems;
	}

	_filterByTitle(items) {
		if (this.state.titleFilter)
			return items.filter((item) => item.name.match(this.state.titleFilter));
		return items;
	}

	_filterByDate(items) {
		if (this.state.dateFilter && this.state.dateFilter.length)
			return items.filter((item) => this.state.dateFilter.find((date) => decodeDate(date) === decodeDate(item.date)));
		return items;
	}

	_filterByProperties(items) {
		if (this.props.propertiesFilter)
			return items.filter(this.props.propertiesFilter);
		return items;
	}

	doDelete = (itemId) => {
		const p = this.props.qDeleteQuery({
			variables: {
				id: itemId
			}
		});
		p.then((res) => {
			this.props.onDeleted && this.props.onDeleted(itemId);
			toast.success(`${I18N.NOTIFY_DELETE} ${I18N.NOTIFY_SUCCESS}`);
			// console.log('deleted');
		});
		p.catch((res) => {
			toast.error(`${I18N.NOTIFY_DELETE} ${I18N.NOTIFY_ERROR}`);
			// console.log('delete error');
		});
	}

	loadMore = () => {
		if (this.state.loaded && this.state.limit && this.state.visibleData.length < this.state.filteredData.length ) {
			this.setState({
				offset: this.state.offset + this.state.limit,
				visibleData: this.state.filteredData.slice(0, this.state.offset + this.state.limit),
			});
		}
	}

	_renderItemList = (items) => <IncrementalList
		manifestId={this.props.manifestId}
		loadMore={this.loadMore}
		doDelete={this.doDelete}
		itemElement={this.props.itemElement}
		items={items}
	/>;

	render() {
		return this.state.loaded
		? this._renderItemList(this.state.visibleData)
		: <LoadingIndicator/>;
	}
}
