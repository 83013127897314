import React from 'react';
import { PropTypes } from 'prop-types';
import { Mutation } from 'react-apollo';
// import _ from 'lodash';

// import I18N from '../../common/i18n';
import { ApolloFetchPolicy } from '../../common/enums';
import ItemForm from '../common/ItemForm';
import * as inputs from '../../inputs';
import { queries } from '..';

export default
class GeneratedForm extends React.PureComponent {
  static propTypes = {
    manifestId: PropTypes.number.isRequired,
    onSubmit: PropTypes.func,
    fieldsData: PropTypes.array,
    mutationQuery: PropTypes.object,
  }

  _getInputComponent = (type) => {
    const inputComponent = Object.values(inputs).find((input) => input.fieldName === type);
    if (!inputComponent)
      throw new Error(`Unknown input type in custom form: ${type}`);
    return inputComponent;
  }

  _generateFields = (uiFields) => uiFields && uiFields.map((f) => ({
    inputClass: this._getInputComponent(f.type),
    size: f.size,
    caption: f.caption,
    name: f.name,
    section: f.type === 'items' && f.section,
    scope: f.type === 'action' && f.scope,
    arguments: f.type === 'action' && [this.props.manifestId],
    valueField: f.type === 'db_lookup' && 'id',
    captionField: f.type === 'db_lookup' && 'name',
    queryParams: f.type === 'db_lookup' && {
      query: queries[f.section].list,
      fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
      variables: {
        'manifest_id': this.props.manifestId,
      }
    },
  }));

  onNewStateReady = (oldState, newState) => {
    Object.values(newState)
      .filter((i) => i.inputClass && i.inputClass.fieldName === 'action')
      .forEach((a) => (a.arguments = [this.props.manifestId, newState]));
  }

  render() {
    return <Mutation mutation={this.props.mutationQuery}>{
      (update) => <ItemForm
        {...this.props}
        fields={this._generateFields(this.props.fieldsData)}
        onNewStateReady={this.onNewStateReady}
        submit={this.props.onSubmit
          && ((data) => this.props.onSubmit(update, data))}
      />}
    </Mutation>;
  }
}
