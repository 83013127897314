import gql from 'graphql-tag';

import bindApollo from '../common/queries';
import { manifestOptimizationFields, manifestDefaultFieldsFragment } from '../../manifest/queries';

export
const queries = {
  CREATE: gql`
    mutation CREATE_MANIFEST($data: String!) {
      result(data: $data) {
        ${manifestOptimizationFields}
      }
    }`,

  DELETE: gql`
    mutation DELETE_MANIFEST($id: Int!) {
      result(id: $id)
    }`,

  DELETE_LIST: gql`
    mutation DELETE_MANIFESTS($data: Array!) {
      result(data: $data)
    }
  `,

  LIST: gql`
    query MANIFEST_LIST ($limit: Int $offset: Int $title_filter: String) {
      result(limit: $limit offset: $offset title_filter: $title_filter) @connection(key: "manifests", filter: ["title_filter"]) {
        ${manifestDefaultFieldsFragment}
      }
    }`,

  GET_ONE: gql`
    query MANIFEST ($id: Int!) {
      result(id: $id) {
        ${manifestOptimizationFields}
      }
  }`,

  UPDATE: gql`
    mutation UPDATE_MANIFEST($id: Int!, $name: String!) {
      result(id: $id, name: $name) {
        ${manifestOptimizationFields}
      }
  }`
};

export
const CRUD = bindApollo(queries);
