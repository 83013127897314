import React from 'react';
import { PropTypes } from 'prop-types';

import InfoBadge from '../../common/components/InfoBadge';
import Button from '../../common/components/Button';
import ConfirmButton from '../../common/components/ConfirmButton';
import ListItem from '../common/ListItem';
import I18N from '../../common/i18n';
import icons from '../../common/icons';
import { Sections } from '../../common/enums';
import { getSectionLinkBody } from '../../../base/utils';
import ImmidiateUploadButton from '../../common/components/ImmidiateUploadButton';
import { actions as sourceActions } from '../../catalog/actions';
import { queries as pointQueries } from '../points/queries';
import { importfields as pointFields } from '../points/PointForm';

export default
class GroupListItem extends React.PureComponent {
  static section = Sections.GROUPS;

  static propTypes = {
    item: PropTypes.object.isRequired,
    onDelete: PropTypes.func,
    manifestId: PropTypes.number,
  }

  _getGroupIcon = (section) => {
    switch (section) {
    case Sections.COURIERS:
      return icons.ITEM_GROUP_COURIERS;
    case Sections.POINTS:
      return icons.ITEM_GROUP_POINTS;
    case Sections.ZONES:
      return icons.ITEM_GROUP_ZONES;
    default:
      throw new Error('Forbidden or empty section to group');
    }
  }

  _getGroupNumber = (section) => {
    switch (section) {
    case Sections.COURIERS:
      return 1;
    case Sections.POINTS:
      return 7;
    case Sections.ZONES:
      return 5;
    default:
      throw new Error('Forbidden or empty section to group');
    }
  }

  _getCounterIcon = (section) => {
    switch (section) {
    case Sections.COURIERS:
      return icons.MANIFEST_COURIERS;
    case Sections.POINTS:
      return icons.MANIFEST_POINTS;
    case Sections.ZONES:
      return icons.MANIFEST_ZONES;
    default:
      throw new Error('Forbidden or empty section to group');
    }
  }

  render() {
    const group = this.props.item;

    return (
      <ListItem
        id={group.id}
        number={this._getGroupNumber(group.section)}
        icon={this._getGroupIcon(group.section)}
        iconStroke={4}
        link={`${getSectionLinkBody(this.props.manifestId)}${Sections.GROUPS}/${group.id}/edit`}
        key={group.id}
        title={`${group.name} :: ${group.id}`}
      >
        <div className='ListItem__InfoBadges'>
          <InfoBadge iconName={this._getCounterIcon(group.section)} value={group.items_count}/>
        </div>
        <div className='ListItem__Controls'>
          {this.props.onDelete &&
            <ConfirmButton
              confirmMessage={`${I18N.GROUPS_DELETE_CONFIRM} «${group.name}»`}
              icon={icons.COMMON_DELETE} leftHint={I18N.ITEM_DELETE}
              onClick={() => this.props.onDelete(group.id)}/>}
          <Button
            icon={icons.COMMON_EDIT}
            leftHint={I18N.ITEM_EDIT}
            to={`${getSectionLinkBody(this.props.manifestId)}${Sections.GROUPS}/${group.id}/edit`}/>
          {group.section === Sections.POINTS
            && <ImmidiateUploadButton
              sourceActions={sourceActions}
              uploadQuery={pointQueries.IMPORT_POINTS}
              section={group.section}
              fieldsConvert={pointFields}
              groupId={group.id}
              icon={icons.COMMON_DOWNLOAD}
              manifestId={this.props.manifestId}
              leftHint={I18N.ITEM_GROUP_IMPORT}/>}
        </div>
      </ListItem>
    );
  }
}
