import React from 'react';
import block from 'bem-cn-lite';
import { Link } from 'react-router-dom';

import { MiniStatus } from '../enums';
import Icon from './../../common/components/Icon';
import I18N from '../../common/i18n';
import { PropTypes } from 'prop-types';
import icons from '../../common/icons';
import RadialProgressBar from '../../common/components/RadialProgressBar';
import { AppSections, Sections } from '../../common/enums';

const b = block('ManifestMiniStatus');

export default
class ManifestMiniStatus extends React.PureComponent {
  static propTypes = {
    manifestStatus: PropTypes.oneOf(Object.keys(MiniStatus)),
    manifestId: PropTypes.number,
    progressData: PropTypes.string,
  }

  _getStatusMessage(manifestStatus) {
    switch (manifestStatus) {
    case MiniStatus.ENQUEUED:
      return I18N.MANIFEST_STATUS_ENQUEUED;
    case MiniStatus.ERROR:
      return I18N.MANIFEST_STATUS_ERROR;
    case MiniStatus.WARNING:
      return I18N.MANIFEST_STATUS_WARNING;
    case MiniStatus.SUCCESS:
      return I18N.MANIFEST_STATUS_SUCCESS;
    case MiniStatus.PREPARING:
      return `${I18N.MANIFEST_STATUS_PREPARING}: ${Number(this.props.progressData).toFixed(0)}%`;
    case MiniStatus.WAITING:
      return `${I18N.MANIFEST_STATUS_WAITING}: ${Number(this.props.progressData).toFixed(0)}%`;
    case MiniStatus.PROCESSING:
      return `${I18N.MANIFEST_STATUS_PROCESSING}: ${Number(this.props.progressData).toFixed(0)}%`;
    default:
      throw new Error("Bad calculation status");
    }
  }

  _renderIcon(manifestStatus) {
    const iconModifires = {
      'Success': this.props.manifestStatus === MiniStatus.SUCCESS,
      'Warning': this.props.manifestStatus === MiniStatus.WARNING,
      'Error': this.props.manifestStatus === MiniStatus.ERROR,
      'Enqueued': this.props.manifestStatus === MiniStatus.ENQUEUED,
    };

    switch (manifestStatus) {
    case MiniStatus.ENQUEUED:
      return <div className={b('Icon', iconModifires)}><Icon svgName={icons.MINISTATUS_ENQUEUED}/></div>;
    case MiniStatus.ERROR:
      return <div className={b('Icon', iconModifires)}><Icon svgName={icons.MINISTATUS_WARNING}/></div>;
    case MiniStatus.WARNING:
      return <div className={b('Icon', iconModifires)}><Icon svgName={icons.MINISTATUS_WARNING}/></div>;
    case MiniStatus.SUCCESS:
      return <div className={b('Icon', iconModifires)}><Icon svgName={icons.MINISTATUS_SUCCESS}/></div>;
    case MiniStatus.PREPARING:
      return <div className={b('Icon')}><RadialProgressBar progress={Number(this.props.progressData)}/></div>;
    case MiniStatus.PROCESSING:
      return <div className={b('Icon')}><RadialProgressBar progress={Number(this.props.progressData)}/></div>;
    default:
      return null;
    }
  }

  render() {
    return (
      <Link to={`/${AppSections.MANIFEST}/${this.props.manifestId}/${Sections.DETAILS}`}
        className={b('')}
        data-hint={I18N.MANIFEST_LIST_TITLE}
      >
        <div className={b()}>
          { this._renderIcon(this.props.manifestStatus) }
          <div className={b('Message')}>
            {this._getStatusMessage(this.props.manifestStatus)}
          </div>
        </div>
      </Link>
    );
  }
}
