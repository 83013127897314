import React from 'react';
import { PropTypes } from 'prop-types';

import Button from '../../common/components/Button';
import ConfirmButton from '../../common/components/ConfirmButton';
import ListItem from '../common/ListItem';
import I18N from '../../common/i18n';
import icons from '../../common/icons';
import { Sections, AppSections } from '../../common/enums';

export default
class MeasureListItem extends React.PureComponent {

  static section = Sections.ZONES;

  static propTypes = {
    item: PropTypes.object.isRequired,
    onDelete: PropTypes.func,
  }

  render() {
    const measure = this.props.item;

    return (
      <ListItem
        id={measure.id}
        link={`/${AppSections.CATALOG}/${Sections.MEASURES}/${measure.id}/edit`}
        key={measure.id}
        title={`${measure.name} :: ${measure.id}`}
        extra={measure.phone}
      >
        <div className='ListItem__Controls'>
          {this.props.onDelete &&
            <ConfirmButton
              confirmMessage={`${I18N.MEASURES_DELETE_CONFIRM} «${measure.name}»`}
              icon={icons.COMMON_DELETE} leftHint={I18N.ITEM_DELETE}
              onClick={() => this.props.onDelete(measure.id)}/>
          }
          <Button icon={icons.COMMON_EDIT} leftHint={I18N.ITEM_EDIT}
            to={`/${AppSections.CATALOG}/${Sections.MEASURES}/${measure.id}/edit`} />
        </div>
      </ListItem>
    );
  }
}
