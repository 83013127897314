
import React from 'react';
import { PropTypes } from 'prop-types';
import block from 'bem-cn-lite';
import { connect } from 'react-redux';
// import { push } from 'react-router-redux';
import { toast } from 'react-toastify';

import PointListItem from './PointListItem';
import WaybillPointListItem from './WaybillPointListItem';
import IncrementalList from '../common/IncrementalList';
import { CRUD } from './queries';
import { queries } from '../../manifest/queries';
import I18N from '../../common/i18n';
import { withApolloAsyncLoad, getSectionLinkBody } from '../../../base/utils';
import { client, routeTo } from '../../..';
import { reducer as mapReducer, actions as mapActions } from '../../map/actions';
import { 
  // AppSections, 
  Sections 
} from '../../common/enums';
import PointViewForm from './PointViewForm';
import { actions as sectionActions} from '../common/actions';
import { ITEMS_PER_PAGE } from '..';

const b = block('PointView');

class PointView extends React.PureComponent {
  static title = I18N.POINTS_INFO_TITLE

  static propTypes = {
    itemId: PropTypes.number.isRequired,
    manifestId: PropTypes.number,
    selectedPointsIds: PropTypes.arrayOf(PropTypes.number),
    updateSelectedPoints: PropTypes.func,
    pointUpdated: PropTypes.func,
    pointsDeleted: PropTypes.func,
  }

  constructor(props) {
    super(props);
    this.state = {
      pointsWaybill: [],
    };
    this._loadPointsWaybill();
  }

  componentDidMount() {
    this.props.updateSelectedPoints([this.props.itemId]);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.itemId !== this.props.itemId)
      this.props.updateSelectedPoints([this.props.itemId]);
  }

  componentWillUnmount() {
    if (this.props.selectedPointsIds.length === 1)
      this.props.updateSelectedPoints([]);
  }

  _update = (update, data) => {
    const p = update({
      variables: {
        id: this.props.itemId,
        data: data
      }
    });
    p.then((res) => {
      this.props.pointUpdated(this.props.itemId, res.data.result);
    });
    p.catch((res) => {
      console.log('update error');
    });
  }

  _delete = (del) => {
    const p = del({
      variables: {
        id: this.props.itemId
      }
    });
    p.then((res) => {
      this.props.pointsDeleted([this.props.itemId]);
      routeTo(`${getSectionLinkBody(this.props.manifestId)}${Sections.POINTS}`);
      toast.success(`${I18N.NOTIFY_DELETE} ${I18N.NOTIFY_SUCCESS}`);
    });
    p.catch((res) => {
      console.log('update error');
    });
  }

  _loadPointsWaybill = async (offsetMultiplier = 0) => {
    const POINTS_WAYBILL_OFFSET = 100;

    const { data } = await client.query({
      query: queries.POINTS_WAYBILL_LIST,
      variables: {
        'manifest_id': this.props.manifestId,
        'offset': POINTS_WAYBILL_OFFSET * offsetMultiplier,
        'limit': POINTS_WAYBILL_OFFSET,
      },
    });

    if (data.result && data.result.length) {
      this.setState({ pointsWaybill: [...this.state.pointsWaybill, ...data.result] });
      this._loadPointsWaybill(offsetMultiplier + 1);
    }
  }

  _renderView = (point) => {
    const linkedPoints = this.state.pointsWaybill
      .filter((item) => item.point_id === Number(this.props.itemId))
      .map((item) => ({
        //linkTo: `/${AppSections.MANIFEST}/${this.props.manifestId}/${Sections.COURIERS}/${item.courier_id}`,
        ...point,
        ...item
      }));

    return (CRUD.UPDATE(
      {},
      (update) => (
        <div className={b()}>
          {CRUD.DELETE(
            {
              'limit': ITEMS_PER_PAGE,
              'manifest_id': this.props.manifestId,
              'offset': 0,
              'title_filter': ""
            },
            (del) => <PointListItem
              onDelete={() => this._delete(del)}
              manifestId={this.props.manifestId}
              item={point}
              showControls={true}/>
          )}
          <div className={b('Form')}>
            <PointViewForm
              item={point}
              manifestId={this.props.manifestId}
              submitText={I18N.SUBMIT_SAVE}
              submitInInputs={true}
              submit={(data) => this._update(update, data)}
            />
          </div>
          <hr/>
          <h2 key={'title'} >{I18N.POINTS_INFO_WAYBILLS}</h2>
          <IncrementalList
            key={`${this.props.itemId}`}
            manifestId={this.props.manifestId}
            loadMore={() => null}
            items={linkedPoints}
            itemElement={WaybillPointListItem}
          />
        </div>
      )
    ));
  }

  render() {
    return (
      CRUD.GET_ONE(
        {id: this.props.itemId},
        (result) => withApolloAsyncLoad(this._renderView, result)
      )
    );
  }
}

export default
connect(
  (state) => ({
    selectedPointsIds: state[mapReducer.name].selectedPoints,
  }),
  (dispatch) => ({
    'updateSelectedPoints': (points) => dispatch(mapActions.updateSelectedPoints(points)),
    'pointUpdated': (pointId, pointData) => dispatch(sectionActions.pointUpdated(pointId, pointData, 'form')),
    'pointsDeleted': (pointId) => dispatch(sectionActions.pointsDeleted([pointId], 'form'))
  })
)(PointView);
