import React from 'react';
// import _ from 'lodash';
import { store } from '../../..';
import { PropTypes } from 'prop-types';

import { Sections } from 'modules/common/enums';
import ItemForm from '../common/ItemForm';
import I18N from '../../common/i18n';
import { VALIDATE } from '../../inputs/validate';
import {
  TextInput,
  SelectItemsInput,
  LookupInput
} from '../../inputs';

const fields = [
  {
    inputClass: TextInput,
    caption: I18N.GROUPS_NAME,
    name: 'name',
    size: 2,
    validate: VALIDATE.notEmpty
  },
];

const createFields = [
  ...fields,
  {
    inputClass: LookupInput,
    caption: I18N.GROUPS_SECTION,
    name: 'section',
    size: 2,
    items: [
      {value: Sections.POINTS, caption: I18N.MANIFEST_REGULAR_POINTS},
      {value: Sections.COURIERS, caption: I18N.MANIFEST_COURIERS},
      {value: Sections.ZONES, caption: I18N.MANIFEST_ZONES},
    ],
    help: I18N.GROUPS_SECTION_HELP
  }
];

const selectionFields = {
  [Sections.POINTS]: {
    inputClass: SelectItemsInput,
    caption: I18N.MANIFEST_REGULAR_POINTS,
    name: 'items',
    section: Sections.POINTS,
    help: I18N.GROUPS_ENTITIES_HELP,
    size: 2,
  },
  [Sections.COURIERS]: {
    inputClass: SelectItemsInput,
    caption: I18N.MANIFEST_COURIERS,
    name: 'items',
    section: Sections.COURIERS,
    help: I18N.GROUPS_ENTITIES_HELP,
    size: 2,
  },
  [Sections.ZONES]: {
    inputClass: SelectItemsInput,
    caption: I18N.MANIFEST_ZONES,
    name: 'items',
    section: Sections.ZONES,
    help: I18N.GROUPS_ENTITIES_HELP,
    size: 2,
  },
};

export default
class GroupForm extends React.PureComponent {

  static propTypes = {
    sourceActions: PropTypes.object.isRequired,
    item: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      fields: this._getFields(props.item)
    };
  }

  _getFields = (item, section) => {
    if (item.id)
      return [...fields, selectionFields[section || item.section]];
    return section ? [...createFields, selectionFields[section]] : [...createFields, selectionFields[Sections.POINTS]];
  }

  onNewStateReady = (oldState, newState) => {
    if (newState.section && (!oldState.section || oldState.section.value !== newState.section.value)) {
      this.setState({
        fields: this._getFields(this.props.item, newState.section.value)
      }, () => store.dispatch(this.props.sourceActions.closeAuxilaryTab()));
    }
  }

  render() {
    return (
      <ItemForm
        onRef={(ref) => { this.itemForm = ref; } }
        {...this.props}
        fields={this.state.fields}
        onNewStateReady={this.onNewStateReady} />
    );
  }
}
