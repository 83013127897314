import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';
import { client } from 'index';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';

import SectionList from '../common/SectionList';
import { TextFilterInput } from './../../common/components';
import { queries as pointQueries } from '../points/queries';
import { queries as manifestQueries } from '../../manifest/queries';
import I18N from '../../common/i18n';
import { Button } from '../../common/components';
import { Sections } from '../../common/enums';
import { getSectionLinkBody, getWaybillXML, getXLSTemplate } from '../../../base/utils';
import { ITEMS_PER_PAGE } from '..';
import DropDownContainer from '../../common/components/DropDownContainer';
import icons from '../../common/icons';
import { reducer as authReducer } from '../../auth/actions';
import { fields as courierFields} from './CourierForm';
import ImmidiateUploadButton from '../../common/components/ImmidiateUploadButton'; 
import { queries as courierQueries } from './queries';

const b = block('ListSection');

class CourierList extends React.PureComponent {
  static title = I18N.COURIERS_LIST_TITLE

  static propTypes = {
    sourceActions: PropTypes.object,
    manifestId: PropTypes.number,
    orgId: PropTypes.number,
    itemElement: PropTypes.func,
    crud: PropTypes.object,
    extraReqsFields: PropTypes.arrayOf(PropTypes.object),
  };

  constructor() {
    super();
    this.state = {titleFilter: ''};
  }

  _applyTitleFilter = (newTitleFilter) => {
    this.setState({titleFilter: newTitleFilter});
  }

  _getWaybillsByManifest = async (manifestId) => {
    const { data: waybills } = await client.query({
      query: manifestQueries.POINTS_WAYBILL_LIST,
      variables: {
        'manifest_id': manifestId,
      },
    });

    const { data: points } = await client.query({
      query: pointQueries.LIST_DETAILED,
      variables: {
        'manifest_id': manifestId,
      },
    });

    if (waybills.result && points.result) {
      toast.success(`${I18N.MANIFEST_GET_WAYBILLS}. ${I18N.NOTIFY_SUCCESS}`);

      getWaybillXML(
        points.result,
        waybills.result,
        I18N.MISC_WAYBILLS_MANIFEST.replace('*', manifestId)
      );
    }
    else {
      toast.error(`${I18N.MANIFEST_GET_WAYBILLS}. ${I18N.NOTIFY_ERROR}`);
    }
  }

  render() {
    return (
      <div className={b()}>
        <div className={b('Controls')}>
          <Button
            rightHint={I18N.COURIERS_ADD_BUTTON_HINT}
            to={`${getSectionLinkBody(this.props.manifestId)}${Sections.COURIERS}/new`}
            className={b('Button')}
          >
            {I18N.COURIERS_ADD_BUTTON}
          </Button>
          {this.props.manifestId && <Button
            onClick={() => this._getWaybillsByManifest(this.props.manifestId)}
            className={b('Button')}
            leftHint={I18N.MANIFEST_GET_WAYBILLS_HINT}
          >
            {I18N.MANIFEST_GET_WAYBILLS}
          </Button>}
          <DropDownContainer>
            <ImmidiateUploadButton
              leftHint={I18N.MISC_IMPORT_XLS_HINT}
              fieldsConvert={courierFields}
              sourceActions={this.props.sourceActions}
              section={Sections.COURIERS}
              uploadQuery={courierQueries.IMPORT_COURIERS}
              manifestId={this.props.manifestId || -this.props.orgId}
            >{I18N.MISC_IMPORT_XLS}</ImmidiateUploadButton>
            <Button
              icon={icons.COMMON_XLS_TEMPLATE}
              onClick={() => getXLSTemplate(courierFields, this.props.extraReqsFields, I18N.COURIERS_TEMPLATE_FILE_NAME)}
            >{I18N.MISC_DOWNLOAD_XLS}</Button>
            <Button
              icon={icons.COMMON_DELETE}
              to={`${getSectionLinkBody(this.props.manifestId)}${Sections.COURIERS}/delete`}
            >{I18N.MISC_DELETE_MULTIPLE}</Button>
          </DropDownContainer>
        </div>
        <div className={b('Filters')}>
          <TextFilterInput
            placeHolder={I18N.FILTERS_TITLE}
            onChangeValue={this._applyTitleFilter}
            value={this.state.titleFilter} />
        </div>
        <SectionList
          manifestId={this.props.manifestId || -this.props.orgId}
          queries={this.props.crud}
          itemsPerPage={ITEMS_PER_PAGE}
          variables={{
            'manifest_id': this.props.manifestId || -this.props.orgId,
            'title_filter': this.state.titleFilter,
            'limit': ITEMS_PER_PAGE,
            'offset': 0
          }}
          itemElement={this.props.itemElement}
        />
      </div>
    );
  }
}

export default
connect(
  (state) => ({
    extraReqsFields: _.get(state, `${authReducer.name}.user.ui_info.extraFields.${Sections.COURIERS}.fields`, [])
  }),
)(CourierList);
