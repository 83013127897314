import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { toast } from 'react-toastify';

import I18N from '../../common/i18n';
import { CRUD } from './queries';
import CourierListItem from './CourierListItem';
import { queries } from '../../manifest/queries';
import IncrementalList from '../common/IncrementalList';
import WaybillPointListItem from '../points/WaybillPointListItem';
import { withApolloAsyncLoad, getWaybillXML } from '../../../base/utils';
import { actions as mapActions } from '../../map/actions';
import { queries as pointQueries } from '../points/queries';
import { 
  client, 
  store 
} from '../../..';
import { AppSections, Sections } from '../../common/enums';
import { ITEMS_PER_PAGE } from '..';

const b = block('CourierView');

class CourierView extends React.PureComponent {
  static title = I18N.COURIERS_INFO_TITLE

  static propTypes = {
    itemId: PropTypes.number.isRequired,
    manifestId: PropTypes.number,
    route: PropTypes.func,
  }

  constructor(props) {
    super(props);
    this.state = {
      pointsWaybill: [],
      pointsCoords: [],
    };
    store.dispatch(mapActions.selectCourier(props.itemId));
    this._loadPointsWaybill();
    this._loadPointsCoords();
  }

  componentWillUnmount() {
    store.dispatch(mapActions.selectCourier(null));
  }

  _delete = (del) => {
    const p = del({
      variables: {
        id: this.props.itemId
      }
    });
    p.then((res) => {
      this.props.route(`/${AppSections.MANIFEST}/${this.props.manifestId}/${Sections.COURIERS}`);
      toast.success(`${I18N.NOTIFY_DELETE} ${I18N.NOTIFY_SUCCESS}`);
    });
    p.catch((res) => {
      // console.log('update error');
    });
  }

  _loadPointsWaybill = async (offsetMultiplier = 0) => {
    const POINTS_WAYBILL_OFFSET = 100;

    const { data } = await client.query({
      query: queries.POINTS_WAYBILL_LIST,
      variables: {
        'manifest_id': this.props.manifestId,
        'offset': POINTS_WAYBILL_OFFSET * offsetMultiplier,
        'limit': POINTS_WAYBILL_OFFSET,
      },
    });

    if (data.result && data.result.length) {
      this.setState({ pointsWaybill: [...this.state.pointsWaybill, ...data.result] });
      this._loadPointsWaybill(offsetMultiplier + 1);
    }
  }

  _loadPointsCoords = async () => {
    const { data } = await client.query({
      query: pointQueries.LIST,
      variables: {
        'manifest_id': this.props.manifestId,
      },
    });

    this.setState({
      pointsCoords: data.result || this.state.pointsCoords,
    });
  }

  _getWaybillsByManifest = (courierPoints, itemId) => {
    getWaybillXML(
      this.state.pointsCoords,
      courierPoints,
      I18N.MISC_WAYBILLS_COURIER.replace('*', itemId)
    );
  }

  _renderView = (courier) => {
    const courierPoints = this.state.pointsWaybill
      .filter((wPoint) => wPoint.courier_id === courier.id)
      .map((wPoint) => ({
        ...wPoint,
        ...this.state.pointsCoords.find((point) => point.id === wPoint.point_id),
        id: wPoint.id,
        onClick: () => store.dispatch(mapActions.updateSelectedPoints([wPoint.point_id]))
      }));

    return <div className={b()}>
      {CRUD.DELETE(
        {
          'limit': ITEMS_PER_PAGE,
          'manifest_id': this.props.manifestId,
          'offset': 0,
          'title_filter': ""
        },
        (del) => <CourierListItem
          manifestId={this.props.manifestId}
          onDelete={() => this._delete(del)}
          item={courier}
          showControls={true}
          getWaybills={() => this._getWaybillsByManifest(courierPoints, this.props.itemId)}/>
      )}
      <hr/>
      <h2 className={b('WaybillTitle')}>{I18N.POINTS_INFO_WAYBILLS}</h2>
      <IncrementalList
        key={`${this.props.manifestId}_${courier.id}`}
        manifestId={this.props.manifestId}
        loadMore={() => null}
        items={courierPoints}
        itemElement={WaybillPointListItem}
        getKey={(item) => `${item.id}`}
      />
    </div>;
  }

  render() {
    return (
      CRUD.GET_ONE(
        {id: this.props.itemId},
        (result) => withApolloAsyncLoad(this._renderView, result)
      )
    );
  }
}

export default
connect(
  null,
  (dispatch) => ({
    route: (link) => dispatch(push(link))
  })
)(CourierView);
