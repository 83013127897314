import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';

import SectionList from '../common/SectionList';
import I18N from '../../common/i18n';
import { Button } from '../../common/components';
import { Sections } from '../../common/enums';

import { store } from '../../..';
import { actions as sectionActions} from '../common/actions';
import { getSectionLinkBody } from '../../../base/utils';
import DropDownContainer from '../../common/components/DropDownContainer';
import icons from '../../common/icons';

const b = block('ListSection');

export default
class ZoneList extends React.PureComponent {
  static title = I18N.ZONES_LIST_TITLE

  static propTypes = {
    manifestId: PropTypes.number,
    orgId: PropTypes.number,
    itemElement: PropTypes.func,
    crud: PropTypes.object,
  };

  onDeleted = (itemId) => {
    store.dispatch(sectionActions.zoneDeleted(itemId));
  };

  render() {
    return (
      <div className={b()}>
        <div className={b('Controls')}>
          <Button
            to={`${getSectionLinkBody(this.props.manifestId)}${Sections.ZONES}/new`}
            className={b('Button')}
          >
            {I18N.ZONES_ADD_BUTTON}
          </Button>
          <DropDownContainer>
            <Button
              icon={icons.COMMON_DELETE}
              to={`${getSectionLinkBody(this.props.manifestId)}${Sections.ZONES}/delete`}
            >{I18N.MISC_DELETE_MULTIPLE}</Button>
          </DropDownContainer>
        </div>
        <SectionList
          manifestId={this.props.manifestId || -this.props.orgId}
          queries={this.props.crud}
          onDeleted={this.onDeleted}
          variables={{
            'manifest_id': this.props.manifestId || -this.props.orgId,
          }}
          itemElement={this.props.itemElement}
        />
      </div>
    );
  }
}
