import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';

import Icon from '../../common/components/Icon';
import { getPaletteColorClass } from './../../../base/utils';

const b = block('ListItem');

export default
class ListItem extends React.PureComponent {

  static propTypes = {
    id: PropTypes.number.isRequired,
    icon: PropTypes.string,
    number: PropTypes.number,
    listNumber: PropTypes.number,
    iconStroke: PropTypes.number,
    iconColor: PropTypes.string,
    position: PropTypes.number,
    title: PropTypes.string,
    extra: PropTypes.any,
    details: PropTypes.string,
    children: PropTypes.any,
    link: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
  }

  render() {
    return (
      <li className={this.props.className ? this.props.className : b()} onClick={this.props.onClick && this.props.onClick}>
        { Number.isInteger(this.props.number) && !this.props.icon &&
          <div className={b('Icon')}>
            <div className={`${b('NumberBody')} ${getPaletteColorClass(this.props.number)}`}>
              {this.props.position || '-'}
            </div>
          </div> }
        { this.props.link &&
          <Link className={b('Link')} to={this.props.link} /> }
        { this.props.listNumber &&
          <div className={b('ListNumber')}>{this.props.listNumber}</div> }
        { this.props.icon &&
          <div className={b('Icon')}>
            <Icon
              style={this.props.iconStroke && {strokeWidth: `${this.props.iconStroke}px`}}
              className={getPaletteColorClass(this.props.number || this.props.iconColor)}
              svgName={this.props.icon}
            />
          </div> }
        <div className={b('Info')}>
          <div className={b('Title')}>
            <div className={b('TitleMain')}>{this.props.title}</div>
            <div className={b('TitleExtra')}>{this.props.extra}</div>
          </div>
          { this.props.details ? <div className={b('Details')}>{this.props.details}</div> : null}
          { this.props.children }
        </div>
      </li>
    );
  }
}
