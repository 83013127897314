import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import SectionList from '../common/SectionList';
import { TextFilterInput } from './../../common/components';
import { queries } from './queries';
import I18N from '../../common/i18n';
import { Button } from '../../common/components';
import { 
  Sections, 
  // AppSections 
} from '../../common/enums';
import ImmidiateUploadButton from '../../common/components/ImmidiateUploadButton';
import { importfields as pointFields } from './PointForm';
import { actions as sectionActions} from '../common/actions';
import { store } from '../../..';
import { getSectionLinkBody, getXLSTemplate } from '../../../base/utils';
import icons from '../../common/icons';
import { ITEMS_PER_PAGE } from '..';
import { reducer as authReducer } from '../../auth/actions';
import DropDownContainer from '../../common/components/DropDownContainer';

const b = block('ListSection');

class PointList extends React.PureComponent {
  static title = I18N.POINTS_LIST_TITLE

  static propTypes = {
    sourceActions: PropTypes.object,
    manifestId: PropTypes.number,
    orgId: PropTypes.number,
    extraReqsFields: PropTypes.arrayOf(PropTypes.object),
    itemElement: PropTypes.func,
    crud: PropTypes.object,
  };

  constructor() {
    super();
    this.state = {titleFilter: ''};
  }

  _applyTitleFilter = (newTitleFilter) => {
    this.setState({titleFilter: newTitleFilter});
  }

  onDeleted = (itemId) => {
    store.dispatch(sectionActions.pointsDeleted([itemId]));
  };

  render() {
    return (
      <div className={b()}>
        <div className={b('Controls')}>
          <Button
            rightHint={I18N.POINTS_ADD_BASE_BUTTON_HINT}
            to={`${getSectionLinkBody(this.props.manifestId)}${Sections.POINTS}/new/base`}
            className={b('Button')}
          >
            <b>+</b>
            <span>{I18N.POINTS_ADD_BASE_BUTTON}</span>
          </Button>
          <Button
            rightHint={I18N.POINTS_ADD_BUTTON_HINT}
            to={`${getSectionLinkBody(this.props.manifestId)}${Sections.POINTS}/new`}
            className={b('Button')}
          >
            <b>+</b>
            <span>{I18N.POINTS_ADD_BUTTON}</span>
          </Button>
          <ImmidiateUploadButton
            leftHint={I18N.MISC_IMPORT_XLS_HINT}
            fieldsConvert={pointFields}
            sourceActions={this.props.sourceActions}
            section={Sections.POINTS}
            uploadQuery={queries.IMPORT_POINTS} 
            manifestId={this.props.manifestId || -this.props.orgId}
            className={b('Button')}>{I18N.MISC_IMPORT_XLS}</ImmidiateUploadButton>
          <Button
            className={b('Button', { 'Iconed': true })}
            icon={icons.COMMON_XLS_TEMPLATE}
            leftHint={I18N.MISC_DOWNLOAD_XLS_HINT}
            onClick={() => getXLSTemplate(pointFields, this.props.extraReqsFields, I18N.POINTS_TEMPLATE_FILE_NAME)}
          />
          <DropDownContainer>
            <Button
              icon={icons.COMMON_DELETE}
              to={`${getSectionLinkBody(this.props.manifestId)}${Sections.POINTS}/delete`}
            >{I18N.MISC_DELETE_MULTIPLE}</Button>
          </DropDownContainer>
        </div>
        <div className={b('Filters')}>
          <TextFilterInput
            placeHolder={I18N.FILTERS_TITLE}
            onChangeValue={this._applyTitleFilter}
            value={this.state.titleFilter} />
        </div>
        <SectionList
          manifestId={this.props.manifestId || -this.props.orgId}
          queries={this.props.crud}
          onDeleted={this.onDeleted}
          itemsPerPage={ITEMS_PER_PAGE}
          variables={{
            'manifest_id': this.props.manifestId || -this.props.orgId,
            'title_filter': this.state.titleFilter,
            'limit': ITEMS_PER_PAGE,
            'offset': 0
          }}
          itemElement={this.props.itemElement}
        />
      </div>
    );
  }
}

export default
connect(
  (state) => ({
    extraReqsFields: _.get(state, `${authReducer.name}.user.ui_info.extraFields.${Sections.POINTS}.fields`)
  }),
)(PointList);
