import React from 'react';

import ItemForm from '../common/ItemForm';
import I18N from '../../common/i18n';
// import _ from 'lodash';

import * as inputs from '../../inputs';
import { VALIDATE } from '../../inputs/validate';

const fields = [
  {
    inputClass: inputs.TextInput,
    caption: I18N.MEASURES_NAME,
    validate: VALIDATE.notEmpty,
    name: 'name',
    size: 1
  },
  {
    inputClass: inputs.TextInput,
    caption: I18N.MEASURES_DESCRIPTION,
    name: 'description',
    size: 3
  },
  {
    inputClass: inputs.NumberInput,
    caption: I18N.MEASURES_WEIGHT_UNITS,
    help: I18N.MEASURES_UNITS_HELP,
    name: 'weight_units',
    min: 0,
    size: 1
  },
  {
    inputClass: inputs.NumberInput,
    caption: I18N.MEASURES_VOLUME_UNITS,
    help: I18N.MEASURES_UNITS_HELP,
    name: 'volume_units',
    min: 0,
    size: 1,
    spaces: 2
  },
];

export default
class MeasureForm extends React.PureComponent {

  render() {
    return (
      <ItemForm
        {...this.props}
        fields={fields} />
    );
  }
}
