import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';
import Lightbox from 'react-image-lightbox';

import I18N from 'modules/common/i18n';
import { TextLink, Icon } from '../../common/components';
import icons from '../../common/icons';
// import { ENDPOINTS } from '../../../base/api';

const b = block('PhotoGallery');

export default
class PhotoGallery extends React.Component {
  static title = I18N.WAYBILLS_PHOTO

  static propTypes = {
    photos: PropTypes.arrayOf(PropTypes.string)
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedPhoto: 0,
      lightBoxOpened: false,
    };
  }

  _renderLinks = (photos) => {
    const links = [];
    for (let i = 0; i < photos.length; i++) {
      links.push(<TextLink
        key={i}
        className={b('Link')}
        disabled={this.state.selectedPhoto === i}
        onClick={() => this.setState({selectedPhoto: i})}
      >{i + 1}</TextLink>);
    }
    return links;
  }

  render() {
    const curIndex = this.state.selectedPhoto;
    const photosArray = this.props.photos;
    const curPhotoURL = photosArray[curIndex];
    const nextPhotoURL = photosArray[(curIndex + 1) % photosArray.length];
    const prevPhotoURL = photosArray[(curIndex + photosArray.length - 1) % photosArray.length];

    return (
      <div className={b()}>
        {this.state.lightBoxOpened && <Lightbox
          mainSrc={curPhotoURL}
          nextSrc={nextPhotoURL}
          prevSrc={prevPhotoURL}
          onCloseRequest={() => this.setState({ lightBoxOpened: false })}
          onMovePrevRequest={() => this.setState({ selectedPhoto: (curIndex + photosArray.length - 1) % photosArray.length })}
          onMoveNextRequest={() => this.setState({ selectedPhoto: (curIndex + 1) % photosArray.length })}
        />}
        <div className={b('Image')}>
          <div className={b('ZoomSubstrate')}>
            <Icon svgName={icons.COMMON_ZOOM}/>
          </div>
          <img
            key={`photo_${curIndex}`}
            onClick={() => this.setState({lightBoxOpened: true})}
            alt={`${I18N.WAYBILLS_PHOTO}: ${curIndex}`}
            src={curPhotoURL}/>
        </div>
        <div className={b('Selector')}>
          {this._renderLinks(photosArray)}
        </div>
      </div>
    );
  }
}
