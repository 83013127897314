import { decodeTime } from "../../base/utils";

const i18n = {
  _lang: 'ru',
  _locale: 'ru-RU',
  _distUnits: 'km',

  formatDate(date) {
    return date.toLocaleDateString(this._locale);
  },

  formatDistance(meters) {
    return `${((isNaN(meters) ? 0 : meters) / 1000).toFixed(2)} ${this.DISTANCE_HIGH_ABBR}`;
  },

  formatWeight(numKilo) {
    const kilo = isNaN(numKilo) ? 0 : numKilo;
    return kilo < 1000
      ? `${kilo} ${this.WEIGHT_LOW_ABBR}`
      : `${kilo / 1000} ${this.WEIGHT_HIGH_ABBR}`;
  },

  formatDecimalHours(hours) {
    const h = Math.floor(hours);
    const m = Math.round(hours % 1 * 60);
    const hStr = h ? `${h} ${this.HOURS_ABBR} ` : '';
    const mStr = `${m} ${this.MINUTES_ABBR}`;
    return `${hStr}${mStr}`;
  },

  formatDecimalHoursAsTime(numHours) {
    const hours = isNaN(numHours) ? 0 : numHours;
    const d = Math.trunc(hours / 24);
    const h = Math.trunc(hours - d * 24);
    const m = Math.round((hours - d * 24 - h) * 60);
    const dt = new Date();
    dt.setHours(h, m, 0);
    const dayStr = d ? `${d} ${this.DAY_ABBR} ` : '';
    const timeStr = decodeTime(dt);
    return `${dayStr}${timeStr}`;
  },

  printDistance(dist) {
    return String(Math.round(this._distUnits === "mi" ? dist/1.609344 : dist, 3));
  },

  HOURS_ABBR: 'ч.',
  MINUTES_ABBR: 'м.',
  DAY_ABBR: 'д.',
  DISTANCE_HIGH_ABBR: 'км.',
  DISTANCE_LOW_ABBR: 'м.',
  WEIGHT_HIGH_ABBR: 'т.',
  WEIGHT_LOW_ABBR: 'кг.',
  AREA_HECTARE: 'Гектар:',
  DATES_CLOSEST: 'Ближ. дни:',
  DATES_ALL: 'Даты:',

  MANIFEST_TITLE: 'Управление маршрутами',
  MANIFEST_LIST_TITLE: 'Список маршрутов',
  MANIFEST_ADD: 'Добавить маршрут',
  MANIFEST_SELECT: 'Выбрать маршрут',
  MANIFEST_STATUS_ENQUEUED: 'В очереди',
  MANIFEST_STATUS_SUCCESS: 'Маршрут расчитан',
  MANIFEST_STATUS_WARNING: 'Маршрут рассчитан с предупреждениями',
  MANIFEST_STATUS_ERROR: 'Ошибка в расчете',
  MANIFEST_STATUS_PREPARING: 'Рассчет матрицы',
  MANIFEST_STATUS_WAITING: 'Ожидание',
  MANIFEST_STATUS_PROCESSING: 'Рассчет маршрута',
  MANIFEST_STATUS_MISSED_POINTS: 'Есть ошибочные точки',
  MANIFEST_CREATE_TITLE: 'Создать новый маршрут',
  MANIFEST_CLONE_TITLE: 'Клонировать текущий маршрут',
  MANIFEST_EDIT_TITLE: 'Редактирование маршрута',
  MANIFEST_NAME: 'Название маршрута',
  MANIFEST_CURRENT: 'Текущий маршрут:',
  MANIFEST_NAME_HELP: 'Рабочее название маршрута для распознавания его в системе Zig-Zag',
  MANIFEST_DATE: 'Дата начала маршрута',
  MANIFEST_SETTINGS: 'Настройки',
  MANIFEST_SPECAIL_POINTS: 'Базовые адреса',
  MANIFEST_REGULAR_POINTS: 'Точки маршрута',
  MANIFEST_COURIERS: 'Курьеры',
  MANIFEST_ZONES: 'Районы',
  MANIFEST_DELETE_CONFIRM: 'Подтвердите удаление маршрута',
  MANIFEST_GET_WAYBILLS: 'Скачать маршрутные листы',
  MANIFEST_GET_WAYBILLS_HINT: 'Скачать маршрутные листы всех курьеров',
  MANIFEST_FIRST_MESSAGE: 'У нас нет ни одного маршрута, давайте создадим первый.',
  MANIFEST_NO_MAP_POINTS: 'Маршрут создан, но у нас нет точек с координатами, давайте добавим одну.',
  MANIFEST_EDIT_HINT: 'Изменение параметров текущего маршрута',
  MANIFEST_CLONE_HINT: 'Построение нового маршрута с возможностью полного или частичного копирования параметров текущего маршрута',
  MANIFEST_DELETE_HINT: 'Безвозвратное удаление текущего маршрута из системы',
  MANIFEST_MODE_MANUAL: 'Ручной',
  MANIFEST_MODE_MANUAL_HINT: 'Включен режим ручного редактирования. Нажмите кнопку справа для ручного построения маршрута.',
  MANIFEST_MODE_AUTO: 'Авто',
  MANIFEST_MODE_AUTO_HINT: 'Включен режим автоматического построения маршрута. Нажмите кнопку справа, чтобы рассчитать оптимальный маршрут',

  CATALOG_TITLE: 'Справочники',
  CATALOG_COURIERS: 'Справочник курьеров',
  CATALOG_USERS: 'Справочник пользователей',
  CATALOG_POINTS: 'Справочник адресов',
  CATALOG_ZONES: 'Справочник районов',
  CATALOG_MEASURES: 'Справочник единиц измерения',
  CATALOG_GROUPS: 'Справочник групп элементов',
  CATALOG_DOCUMENTS: 'Документы',

  MAP_VIEW_MAP: 'Прозрачность карты. Возможность сделать базовую карту более прозрачной, для фокусировки внимания на слое с построенным маршрутом.',
  MAP_VIEW_POINTS: 'Отобразить точки. Отображение или скрытие всех точек маршрута на карте кроме базовых (склад / гараж), чтобы легче выделить их на карте. Номер точки указывает на очередность выполнения маршрута.',
  MAP_VIEW_ASSIGNED_POINTS: 'Отобразить закрепленные за курьерами точки. Удобно, когда точки распределяются вручную, лишние пропадают после назначения.',
  MAP_VIEW_CLUSTERS: 'Группировка точек. При изменении масштаба карты соседние точки группируются или разбиваются по одной. При объединении точек им присваивается номер, равный количеству точек в группе.',
  MAP_VIEW_LINES: 'Отобразить прямые линии. Соединение точек маршрута напрямую (пунктирными линиями) без учета построенного маршрута.',
  MAP_VIEW_PATH: 'Отобразить линии маршрута. Отображение линий построенного маршрута.',
  MAP_VIEW_ZONES: 'Отобразить районы. Отображение или скрытие ранее созданных районов.',
  MAP_VIEW_TRACKS: 'Отобразить треки. Отображение фактического местонахождения курьера во время выполнения маршрута. Возможно только при использовании курьером мобильного приложения.',

  MAP_CONTROLS_SELECT: 'Выделить точки на карте. Выделение точек областью позволяет просматривать и редактировать данные о них (режим работы, параметры груза, курьеры)',
  MAP_CONTROLS_UNSELECT: 'Закончить и отменить выделение',

  MANIFEST_TAB: 'Маршруты',
  MANIFEST_TAB_HINT: 'Список маршрутов',
  ORDERS_TAB: 'Заявки',
  ORDERS_TAB_HINT: 'Список заявок',
  HISTORY_TAB: 'История',
  HISTORY_TAB_HINT: 'История чего-то',
  REPORTS_TAB: 'Отчеты',
  REPORTS_TAB_HINT: 'Отчеты по выполненным маршрутам',
  DICTS_TAB: 'Справочники',
  DICTS_TAB_HINT: 'Базовая информация (о курьерах, автомобилях, адресах и районах) для создания маршрута. При построении маршрута поля можно автоматически заполнить данными из справочников',
  SETTINGS_TAB: 'Настройки',
  SETTINGS_TAB_HINT: 'Настройки организации',
  YOUTUBE_TAB: 'Обучение',
  YOUTUBE_TAB_HINT: 'Посмотрите обучающие ролики на нашем YouTube канале',
  SUPPORT_TAB: 'Помощь',
  SUPPORT_TAB_HINT: 'Появились вопросы или затруднения? Кликните, чтобы связаться с нашим специалистом через <b>support@zig-zag.org</b> или позвонить по тел. <b>8 (800) 250-41-43</b>',

  COURIERS_TAB: 'Доступные курьеры',
  COURIERS_TAB_HINT: 'Курьеры',
  COURIERS_NAME: 'Название курьера',
  COURIERS_NAME_HINT: 'Введите название курьера (например: имя курьера, госномер автомобиля, ник, псевдоним)',
  COURIERS_ADD_BUTTON: 'Добавить курьера',
  COURIERS_ADD_BUTTON_HINT: 'В данном разделе выбирается тип курьера (автокурьер/пеший курьер) и заносятся данные о режиме работы, расчете оплаты работы, параметрах груза',
  COURIERS_INFO_TITLE: 'Данные курьера',
  COURIERS_PHONE: 'Контактный телефон',
  COURIERS_INFO_WAYBILLS: 'Точки маршрутного листа',
  COURIERS_EDIT_TITLE: 'Редактирование курьера',
  COURIERS_CREATE_TITLE: 'Создание курьера',
  COURIERS_LIST_TITLE: 'Список курьеров',
  COURIERS_DELETE_CONFIRM: 'Подтвердите удаление курьера',
  COURIERS_POWER: 'Если выключить, то машина не попадает в расчет, но сохраняется история посещений точек маршрута. Например, если машина сломалась, надо пересчитать маршрут с учетом посещенных ею адресов.',
  COURIERS_MAX_VOLUME: 'Макс. объем груза',
  COURIERS_MAX_WEIGHT: 'Макс. вес груза',
  COURIERS_MAX_POINTS: 'Макс. число адресов',
  COURIERS_MAX_POINTS_HINT: 'Максимальное количество адресов за рабочий день',
  COURIERS_DRIVER: 'Назначить курьера',
  COURIERS_DRIVER_HINT: 'Назначая курьера Вы распределяете ему маршрут и отправляете маршрутный лист в мобильное приложение',
  COURIERS_CURRENT_POINT: 'Начало движения',
  COURIERS_CURRENT_POINT_HINT: `Точкой начала движения курьера по маршруту может быть:<br/><br/>
  <b>1. Гараж</b><br/>
  <b>2. Склад / гараж</b><br/>
  <b>3. Склад</b>`,
  COURIERS_CURRENT_WEIGHT: 'Начальный вес груза',
  COURIERS_CURRENT_VOLUME: 'Начальный объем груза',
  COURIERS_CURRENT_TIME: 'Время начала движения',
  COURIERS_CURRENT_DISTANCE: 'Начальное пройденное расстояние',
  COURIERS_CURRENT_COST: 'Начальная стоимость доставленного груза',
  COURIERS_NOT_RETURNING: 'Финиш в последней точке маршрута',
  COURIERS_NOT_RETURNING_HINT: 'Расчет и построение маршрута производится до последней точки маршрута. После окончания маршрута курьер не возвращается  в гараж и/или склад/гараж',
  COURIERS_SINGLE_RUN: 'Один выезд со склада',
  COURIERS_SINGLE_RUN_HINT: 'Выбор данного пункта подразумевает, что курьер в течении рабочего дня  будет выезжать со склада только один раз. Во время выполнения задания  не предусматривается возвращение курьера на склад для дозагрузки.',
  COURIERS_LONGHAUL: 'Многодневный маршрут',
  COURIERS_LONGHAUL_HINT: 'Маршрут протяженностью более 24 часов. Строится с учетом остановок на отдых и ночевку.',
  COURIERS_OUTOFORDER: 'Исключить из маршрута',
  COURIERS_OUTOFORDER_HINT: 'Если выбрано, то машина не попадает в расчет, но сохраняется история посещений точек маршрута. Пример использования: машина сломалась, надо пересчитать маршрут с учетом посещенных ею адресов.',
  COURIERS_COST_ROUND: 'Цена выезда на рейс',
  COURIERS_COST_HOUR: 'Цена за час работы',
  COURIERS_COST_KM: 'Цена за км. пути',
  COURIERS_COST_KGKM: 'Цена за кг./км. пути',
  COURIERS_COST_POINT: 'Цена за один адрес',
  COURIERS_N_RUNS: 'Кол-во выездов с базы',
  COURIERS_N_RUNS_HINT: 'Нужно написать максимальное количесвто выездов курьера со склада',
  COURIERS_VEHICLE_TYPE: 'Тип курьера',
  COURIERS_VEHICLE_TYPE_HINT: `Выбирая тип курьера учитывайте параметры груза, способ передвижения, запреты и ограничения движения по типам дорог и массе автомобилей, время въезда:<br/><br/>
  <b>Пеший курьер</b>: передвижение общественным транспортом или пешим способом<br/><br/>
  <b>Легковой автомобиль</b>: движение по всем типам дорог, если масса не превышает 1,5 т.<br/><br/>
  <b>Грузовой автомобиль</b>: максимальная грузоподъемность, могут быть ограничения въезда в определенные зоны при превышении разрешенной максимальной массы<br/><br/>`,
  COURIERS_DEPOT: 'Назначить склад',
  COURIERS_GARAGE: 'Назначить гараж',
  COURIERS_GARAGE_HINT: 'Выберите гараж или склад / гараж если курьер начинает  (и заканчивает) движение  с данных объектов',
  COURIERS_THROUGH_DEPOT: 'Заезжать на склад перед доставкой',
  COURIERS_LAST_POINT: 'Конечная точка маршрута',
  COURIERS_GET_WAYBILL: 'Скачать маршрутный лист',
  COURIERS_TEMPLATE_FILE_NAME: 'Импорт курьеров Zig-Zag.xlsx',
  COURIER_WORK_HINT: 'Режим работы курьера',
  COURIER_BREAK_HINT: 'Перерыв в рабочем времени курьера',
  COURIER_CARGO_HINT: 'Параметры груза в условных единицах. (кг, т, литры и др.) Если Вы измеряете груз в других единицах (например паллеты, бутыли и др.) укажите единицу измерения  перейдя в «Справочник единиц измерения» ',
  COURIER_PRICE_HINT: 'Расчет оплаты за работу курьера',
  COURIER_CLONE_SELECT: 'Копируемые курьеры',
  COURIER_CLONE_SELECT_HELP: 'Выберите курьеров, которые будут скопированны из справочника в текущий маршрут.',
  COURIER_CLONE_LINKED_POINTS: 'Добавить связанные точки',
  COURIER_CLONE_LINKED_POINTS_HELP: 'Если выбранные курьеры закреплены за какими-либо точками в спрвочнике, то они будут тоже скопированы',

  COURIERS_VEHICLE_TYPE_CAR: 'Легковой автомобиль',
  COURIERS_VEHICLE_TYPE_HGV: 'Грузовой автомобиль',
  COURIERS_VEHICLE_TYPE_FOOT: 'Пеший курьер',

  POINTS_TAB: 'Адреса текущего маршрута',
  POINTS_TAB_HINT: 'Адреса',
  POINTS_ADD_BUTTON: 'Адрес',
  POINTS_ADD_BUTTON_HINT: 'Добавить точку маршрута, режим работы и параметры груза точки маршрута',
  POINTS_ADD_BASE_BUTTON: 'Склад / Гараж',
  POINTS_ADD_BASE_BUTTON_HINT: 'Добавить склад / гараж, задать его адрес и режим работы',
  POINTS_INFO_TITLE: 'Данные точки',
  POINTS_INFO_WAYBILLS: 'Точки маршрутного листа',
  POINTS_INFO_WAYBILLS_MISSED: 'Точки с ошибками и предупреждениями',
  POINTS_EDIT_TITLE: 'Редактирование точки маршрута',
  POINTS_CREATE_TITLE: 'Создание точки маршрута',
  POINTS_EDIT_BASE_TITLE: 'Редактирование склада / гаража',
  POINTS_CREATE_BASE_TITLE: 'Создание склада / гаража',
  POINTS_LIST_TITLE: 'Список адресов',
  POINTS_DELETE_CONFIRM: 'Подтвердите удаление точки',
  POINTS_NAME: 'Наименование точки маршрута',
  POINTS_NAME_HINT: 'Назовите точку маршрута ("ТЦ «Город»", "Офис №56") ',
  POINTS_NAME_BASE: 'Название склада / гаража',
  POINTS_NAME_BASE_HINT: 'Введите название объекта ("Гараж №1", "Основной склад")',
  POINTS_ADDRESS: 'Адрес',
  POINTS_ADDRESS_HELP: `Адрес заполняется в следующей последовательности:<br/><br/>
    Населенный пункт, улица, дом/строение. Пример: "г. Москва, ул. Ленина, д.72" или "Москва, Ленина, 72"<br/><br/>
    Строка с адресом не должна содержать: номер телефона, режим работы, комментарии по оплате или приеме товара, прочие дополнения, не относящиеся к адресу.<br/><br/>
    Пример адреса, который не определится в системе: "Москва, Ленина, 72, вход со двора, приехать после 18 ч."<br/><br/>
    После ввода адреса Вам будет предложен список распознанных адресов. Выберите один из них для автоматического определения координат.
  `,
  POINTS_COORDS: 'Координаты',
  POINTS_COORDS_LAT: 'Широта',
  POINTS_COORDS_LON: 'Долгота',
  POINTS_COORDS_HELP: `При заполнении корректного адреса координаты заполняются автоматически. Если координаты не определились:<br/><br/>
  1. Введите их в ручном режиме, заполнив оба поля: "Широта" и "Долгота"<br/><br/>
  2. Проверьте расположение точки на карте и при необходимости передвиньте точку на нужный адрес, координаты обновятся автоматически`,
  POINTS_WEIGHT: 'Масса доставки груза',
  POINTS_VOLUME: 'Объём доставки груза',
  POINTS_DIRECT: 'Доставка',
  POINTS_BACKHAUL: 'Возврат',
  POINTS_BACKHAUL_WEIGHT: 'Масса возврата на склад',
  POINTS_BACKHAUL_VOLUME: 'Объём возврата на склад',
  POINTS_SHIPPING_TIME: 'Время обслуж.',
  POINTS_SHIPPING_TIME_HELP: 'Среднее время на погрузку/разгрузку в данной точке маршрута ',
  POINTS_SHIPPING_BASE_TIME: 'Время погрузки / разгрузки',
  POINTS_SHIPPING_BASE_TIME_HELP: 'Укажите примерное время на погрузку/разгрузку',
  POINTS_PRIORITY: 'Приоритет обслуж.',
  POINTS_PRIORITY_HINT: 'Добавьте приоритет обслуживания. Чем больше номер, тем выше приоритет по очередности выполнения  маршрута в данной точке. Диапазон приоритетов от 1 до максимального количества точек маршрута.',
  POINTS_COMMENT: 'Комментарий',
  POINTS_COMMENT_HINT: 'Дополнительные сведения для выполнения маршрута в данной точке (например: номер офиса/квартиры, название компании)',
  POINTS_GARAGE: 'Гараж',
  POINTS_DEPOT: 'Склад',
  POINTS_GARAGE_DEPOT_HINT: `Выберите создаваемый объект:<br/><br/>
    <b>Гараж</b> – место стоянки ТС<br/><br/>
    <b>Склад</b> – место погрузки/разгрузки.<br/><br/>
    Если у Вас один объект  выполняет функции и гаража и склада отметьте оба пункта.`,
  POINTS_COURIER: 'Обслуживающий курьер',
  POINTS_CONTACT_PERSON: 'Контактное лицо',
  POINTS_CONTACT_PERSON_HELP: 'ФИО контактного лица ответственного за погрузку/разгрузку ',
  POINTS_PHONE: 'Телефон',
  POINTS_CONTACT_HINT: 'Контактные данные для связи с ответственным лицом за погрузку/разгрузку',
  POINTS_COURIER_ID: 'Назначить курьера',
  POINTS_COURIER_HELP: `При назначении курьера  он выполняет маршрут в данной точке только один раз в течении выполнения одного маршрута.<br/><br/>
    Данное поле можно не заполнять, в этом случае курьер  будет назначен автоматически при построении маршрута.<br/><br/>
    При сбросе  деталей  маршрута  (количество  точек и курьеров,  время и километраж)  данные курьера  не сохранятся  в маршруте`,
  POINTS_ASSIGNED_COURIER_ID: 'Закрепить курьера',
  POINTS_ASSIGNED_COURIER_DELIVERY_HELP: `При закреплении курьера он автоматически  назначается на выполнение маршрута в данной точке.<br/><br/>
    При сбросе  деталей  маршрута  (количество  точек и курьеров,  время и километраж)  курьер останется закрепленным за этой точкой`,
  POINTS_ASSIGNED_COURIER_HELP: 'Выбранной курьер  будет начинать (и заканчивать)  маршрут в данном складе/гараже',
  POINTS_ASSIGNED_COURIER_LIST: 'Список закрепленных курьеров',
  POINTS_ASSIGNED_COURIER_LIST_HELP: 'Это поле отражает список курьеров, у которых данная точка выбрана гаражом. Его изменение ни на что не влияет, однако, можете выберать курьера, чтобы перейти к его карточке.',
  POINTS_DETAILS_TITLE: 'Данные по выделенным точкам',
  POINTS_TOTAL_WEIGHT: 'Суммарный вес к доставке',
  POINTS_TOTAL_VOLUME: 'Суммарный объем к доставке',
  POINTS_TOTAL_BACKHAUL_WEIGHT: 'Суммарный вес к возврату',
  POINTS_TOTAL_BACKHAUL_VOLUME: 'Суммарный объем к возврату',
  POINTS_SUM: 'Сумма',
  POINTS_SUM_HINT: 'Можете указать в этом поле значение суммы, не влияющие на расчет маршрута.',
  POINTS_DAY: 'День',
  POINTS_DAY_HINT: 'День недели для маршрута',
  POINTS_COUNT: 'Кол-во выбранных точек:',
  POINTS_MASS_EDIT: 'Изменить все выбранные точки',
  POINTS_MASS_DELETE: 'Удалить выбранные точки',
  POINTS_CARGO_HELP: 'Параметры груза для доставки в данную точку в условных единицах (кг, т, литры и др.). Если не используете в расчетах, то оставьте "0". Если Вы измеряете груз в других единицах (например паллеты, бутыли и др.) укажите единицу измерения  перейдя в <a to="/catalog/measures">Справочник единиц измерения</a>',
  POINTS_BACKHAUL_CARGO_HELP: 'Параметры груза для возврата из данной точки. Работают только в задаче развоза. Если с данной точки необходимо забрать груз, то укажите соответствующие значения. В задаче с направлениями возвратный груз идёт на склад, даже если поставка с другой точки.',
  POINTS_CLONE_SELECT: 'Копируемые адреса',
  POINTS_CLONE_SELECT_HELP: 'Выберите адреса, которые будут скопированны из справочника в текущий маршрут.',
  POINTS_CLONE_LINKED_COURIERS: 'Добавить связанных курьеров',
  POINTS_CLONE_LINKED_COURIERS_HELP: 'Если за выбранными точками закреплены какие-либо курьеры из справочника, то они будут тоже скопированы',
  POINTS_CLONE_LINKED_ZONES: 'Добавить связанные зоны',
  POINTS_CLONE_LINKED_ZONES_HELP: 'Если выбранные точки закреплены за какими-либо зонами из справочника, то они тоже будут скопированы',
  POINTS_TRACK_LINK: 'Ссылка для отслеживания',
  POINTS_MANDATOTY: 'Обязательно к посещению',
  POINTS_MANDATOTY_HELP: 'Обязательность к посещению - назначается тем точкам, которые должны быть посещены в обязательно. При этом маршрут строится оптимальным образом. Данная точка будет в произвольном порядковом месте в маршрутном листе. ',
  POINTS_CURBSIDE: 'Подъезд к точке',
  POINTS_CURBSIDE_HELP: 'Выбрать сторону машины с которой курьер будет подьезжать к точке',
  POINTS_CURBSIDE_RIGHT: 'Подьезжать справа',
  POINTS_CURBSIDE_LEFT: 'Подьезжать слева',

  SCHEDULE_WORK_START: 'Начало работы',
  SCHEDULE_WORK_END: 'Окончание работы',
  SCHEDULE_BREAK_START: 'Начало перерыва',
  SCHEDULE_BREAK_END: 'Окончание перерыва',
  POINTS_DELIVERY_SCHEDULE_HELP: 'Режим работы точки маршрута',
  POINTS_DELIVERY_BREAK_HELP: 'Перерыв в рабочем времени точки маршрута',
  POINTS_BASE_SCHEDULE_HELP: 'Режим работы склада / гаража',
  POINTS_BASE_BREAK_HELP: 'Перерыв в рабочем времени склада / гаража',
  POINTS_PICKUP: 'Промежуточный склад',
  POINTS_PICKUP_HELP: 'Выберите, если на данной точке находится груз для развоза в другие точки',
  POINTS_FROM: 'Источник груза',
  POINTS_FROM_HELP: 'Выберите точку, из которой необходимо доставить груз в текущую точку',
  POINTS_TEMPLATE_FILE_NAME: 'Импорт адресов Zig-Zag.xlsx',

  ZONES_TAB: 'Районы',
  ZONES_TAB_HINT: 'Районы',
  ZONES_LIST_TITLE: 'Список районов',
  ZONES_INFO_TITLE: 'Данные района',
  ZONES_ADD: 'Добавить новый район',
  ZONES_ADD_BUTTON: 'Добавить район',
  ZONES_GEOMETRY: 'Нарисовать район',
  ZONES_GEOMETRY_HELP: 'Деление территории на районы обслуживания. Нажмите кнопку "РИСОВАТЬ" и нарисуйте на карте многоугольник.',
  ZONES_COURIERS: 'Прикрепить курьеров',
  ZONES_COURIERS_HELP: 'Прикрепление курьера за районом обслуживания. Например, курьера можно прикрепить, если он хорошо ориентируется на данной территории, живет в этом районе или недалеко от него, выполнял маршрут в этом районе и уже знаком с клиентами',
  ZONES_POINTS: 'Привязать адреса',
  ZONES_POINTS_HELP: 'При импорте адресов точки маршрута автоматически привязываются к району согласно геопозиции. Если адреса проставлены без районов, то  их можно объединить в районы по нужным критериям (например: приоритетный район, режим работы только в будни)',
  ZONES_SET_GEOMETRY: 'Нарисовать район',
  ZONES_SELECT: 'Выбрать район',
  ZONES_CREATE_TITLE: 'Создать новый район',
  ZONES_EDIT_TITLE: 'Редактировать район',
  ZONES_NAME: 'Название района',
  ZONES_NAME_HINT: 'Введите название района для внесения его в систему и отображения на карте маршрута.',
  ZONES_DELETE_CONFIRM: 'Подтвердите удаление района',
  ZONES_CLONE_SELECT: 'Копируемые районы',
  ZONES_CLONE_SELECT_HELP: 'Выберите зоны, которые будут скопированны из справочника в текущий маршрут.',

  GROUPS_TAB: 'Группы',
  GROUPS_TAB_HINT: 'Группы',
  GROUPS_LIST_TITLE: 'Список групп',
  GROUPS_INFO_TITLE: 'Данные по группе',
  GROUPS_ADD: 'Добавить новую группу',
  GROUPS_ADD_BUTTON: 'Добавить группу',
  GROUPS_NAME: 'Название группы',
  GROUPS_SECTION: 'Тип элементов',
  GROUPS_SECTION_HELP: 'Выберите тип элементов, которые будут группированы',
  GROUPS_ENTITIES: 'Элементы',
  GROUPS_ENTITIES_HELP: 'Выберите элементы, относящиеся к данной группе.',
  GROUPS_SELECT: 'Выбрать группу',
  GROUPS_CREATE_TITLE: 'Создать новую группу',
  GROUPS_EDIT_TITLE: 'Редактировать группу',
  GROUPS_DELETE_CONFIRM: 'Подтвердите удаление района',
  GROUPS_CLONE_SELECT: 'Копируемые группы',
  GROUPS_CLONE_SELECT_HELP: 'Выберите группы, которые будут скопированны из справочника в текущий маршрут.',

  USERS_TAB: 'Пользователи',
  USERS_TAB_HINT: 'Пользов.',
  USERS_LIST_TITLE: 'Список пользователей',
  USERS_INFO_TITLE: 'Данные пользователя',
  USERS_ADD: 'Добавить нового пользователя',
  USERS_ADD_BUTTON: 'Добавить пользователя',
  USERS_ADD_BUTTON_HINT: 'Заполните  данные о пользователе для предоставления прав доступа к сервису и мобильному приложению',
  USERS_SELECT: 'Выбрать пользователя',
  USERS_CREATE_TITLE: 'Создать нового пользователя',
  USERS_EDIT_TITLE: 'Редактировать пользователя',
  USERS_NAME: 'Имя пользователя',
  USERS_NAME_HELP: 'Введите имя сотрудника (ФИО)',
  USERS_EMAIL: 'E-mail',
  USERS_EMAIL_HELP: 'Введите электронный адрес сотрудника для доступа в сервис. Он далжен быть вида your@email.ru. Можете оставить поле пустым, если создаете водителей, или пользователь не обладает особой ролью.',
  USERS_PASSWORD: 'Пароль',
  USERS_PASSWORD_HELP: 'Придумайте пароль для входа в личный кабинет сервиса Zig-Zag. Пароль должен быть не короче 5 символов, должен содержать латинские буквы и цифры.',
  USERS_PHONE: 'Телефон пользователя',
  USERS_PHONE_HELP: `Заполните номер телефона для авторизации и работы курьера в мобильном приложении Zig-Zag.<br/><br/>
    Hoмер должен состоять только из цифр, без дополнительных знаков и быть быть не короче 5 символов.<br/><br/>
    Пример: <b>89201002020</b>`,
  USERS_DRIVER: 'Курьер',
  USERS_DRIVER_HELP: 'Если сотрудник является курьером нажмите на данный пункт. Далее Вы можете заполнить график работы курьера в календаре',
  USERS_ROLE: 'Роль пользователя',
  USERS_ROLE_HELP: `Роли сотрудников для предоставления прав доступа к сервису внутри компании:<br/><br/>
    <b>Администратор</b> - полный доступ ко всем возможностям сервиса, в том числе назначение новых сотрудников на роли<br/><br/>
    <b>Управляющий</b> - возможность редактирования справочников, построения и удаления маршрутов<br/><br/>
    <b>Исполнитель</b> - просмотр справочников и маршрутов, без возможности их редактирования<br/><br/>`,
  USERS_SCHEDULE: 'График работы',
  USERS_SCHEDULE_HINT: 'Отметьте рабочие дни курьера в открывшемся календаре. Выбранные рабочие дни будут учтены при построении маршрута',
  USERS_DELETE_CONFIRM: 'Подтвердите удаление пользователя',

  FLAGS: 'Флаги соответствия',
  FLAGS_HELP: 'Выберите флаги соответсвия у курьеров и точек. Курьер поедет по адресу, только если выбранный флаг курьера выбран и у точки.<br/><br/>Вы можете обратиться в службу поддержки, чтобы установить уникальный список флагов по желанию.',

  MEASURES_TAB: 'Единицы изменения',
  MEASURES_TAB_HINT: 'Ед. изм.',
  MEASURES_LIST_TITLE: 'Список единиц изменения',
  MEASURES_INFO_TITLE: 'Данные единицы изменения',
  MEASURES_ADD: 'Добавить новую единицу измерения',
  MEASURES_ADD_BUTTON: 'Добавить новую ед. изм.',
  MEASURES_ADD_BUTTON_HELP: 'Добавление новой единицы измерения груза для точек маршрута (напр. паллеты, бутыли и др.). Если Вам нужны стандартные ед. изм. (кг, т, литры и др.), то они используются по умолчанию, данная функция не нужна.',
  MEASURES_SELECT: 'Выбрать для всех адресов',
  MEASURES_SELECT_HELP: 'Выбрать общую единицу измерения для всех точек маршрута.',
  MEASURES_CREATE_TITLE: 'Создать новую единицу измерения',
  MEASURES_EDIT_TITLE: 'Редактировать единицу измерения',
  MEASURES_NAME: 'Имя единицы измерения',
  MEASURES_DESCRIPTION: 'Описание единицы измерения',
  MEASURES_WEIGHT_UNITS: 'Равна единицам веса',
  MEASURES_VOLUME_UNITS: 'Равна единицам объема',
  MEASURES_UNITS_HELP: 'Укажите кол-во стандартных единиц веса/объема (целые числа или с точкой, напр. "10", "1.5", "0.2"), которые занимает новая единица.',
  MEASURES_DELETE_CONFIRM: 'Подтвердите удаление единицы измерения',
  MEASURES_APPLIED: 'Общая единица измерения',
  MEASURES_APPLIED_HINT: `Общая единица измерения для всех точек маршрута.
    В любой момент Вы можете создать и выбрать другую единицу измерения, а также использовать стандартные единицы измерения (кг, т, м3 и др.), выбрав '- Не назначено -', и используя их при заполнении данных <a to="/catalog/points">точкек маршрута</a>.`,

  DETAILS_TAB: 'Детали маршрута',
  DETAILS_TAB_HINT: 'Детали',
  DETAILS_STATUS_CALCULATED: 'Рассчитан',
  DETAILS_STATUS_PROCESSING: 'Идет вычисление',
  DETAILS_STATUS_ERROR: 'Ошибка в расчете',
  DETAILS_STATUS_ENQUEUED: 'Стоит в очереди на расчет',
  DETAILS_STATUS_READY: 'Готов к расчету',
  DETAILS_POINTS_COUNT: 'Кол-во точек',
  DETAILS_POINTS_COUNT_HINT: 'Количество точек маршрута, включая склады / гаражи и адреса погрузки/разгрузки',
  DETAILS_COURIERS_COUNT: 'Кол-во курьеров',
  DETAILS_COURIERS_COUNT_HINT: 'Количество курьеров, выполняющих маршрут',
  DETAILS_TOTAL_TIME: 'Общее время',
  DETAILS_TOTAL_TIME_HINT: 'Суммарное время выполнения маршрута всех курьеров',
  DETAILS_TOTAL_DISTANCE: 'Общий путь',
  DETAILS_TOTAL_DISTANCE_HINT: 'Общий километраж маршрута',
  DETAILS_TOTAL_WEIGHT: 'Общий вес',
  DETAILS_TOTAL_WEIGHT_HINT: 'Суммарный вес перевезенный в данном маршруте',
  DETAILS_TOTAL_COST: 'Стоимость маршрута',
  DETAILS_TOTAL_COST_HINT: 'Общая стоимость в условных единицах с учетом всех ценовых факторов каждого курьера (цена выезда, цена км. пути и т.д.)',
  DETAILS_CALC_TIME: 'Время расчета',
  DETAILS_CALC_TIME_HINT: 'Время работы расчетного модуля после последнего успешного запуска расчета',

  DETAILS_CLEAR_CALCULATION: 'Сбросить расчет',
  DETAILS_CLEAR_CALCULATION_CONFIRM: 'Все результаты расчёта, треки курьеров и сведения о выполненных точках будут уничтожены БЕЗВОЗВРАТНО. Сбросить маршрут?',
  DETAILS_CALCULATION_ERROR: 'Ошибка связи. Запустите расчет еще раз.',

  OPTIMIZATION_TAB: 'Оптимизация',
  OPTIMIZATION_TAB_HINT: 'Оптимиз.',
  OPTIMIZATION_RUN: 'Расчет маршрута',
  OPTIMIZATION_MANUAL: 'Редактир.',
  OPTIMIZATION_MANUAL_START: 'Начать редактирование маршрута вручную. После нажатия кликайте по точкам в соответствие с порядком маршрута. Для удаления точки из маршрута кликните по ней еще раз.',
  OPTIMIZATION_MANUAL_FINISH: 'Закончить редактирование маршрута',
  OPTIMIZATION_UPDATE: 'Дорассчитать маршрут',
  OPTIMIZATION_CONFIRM_RESET: 'Расчёт с ПОЛНЫМ СБРОСОМ текущего состояния маршрута',
  OPTIMIZATION_CONFIRM_UPDATE: `Расчёт с СОХРАНЕНИЕМ посещённых и привязанных точек`,
  OPTIMIZATION_CONFIRM_MANUAL: 'Оценка построенных вручную маршрутов',
  OPTIMIZATION_CONFIRM_STOP: 'Остановить процесс и восстановить последние рассчитанные данные?',
  OPTIMIZATION_CANCEL: 'Прервать расчет',

  OPTIMIZATION_FORM_TITLE: 'Параметры оптимизации',
  OPTIMIZATION_FORM_COMMON: 'Основные параметры',
  OPTIMIZATION_FORM_ADVANCED: 'Расширенные параметры',
  OPTIMIZATION_FORM_SUBMIT: 'Сохранить настройки',
  OPTIMIZATION_TASKNAME: 'Вид маршрута',
  OPTIMIZATION_CLUSTERIZE: 'Кластеризация',
  OPTIMIZATION_CLUSTERIZE_HELP: 'Если включена кластеризация, то задача разбивается на множество подзадач по кол-ву курьеров, и каждая подзадача считается отдельно. Точки объединяются на карте в небольшие группы, каждая из которых отводится одному из курьеров. Таким образом, каждый из курьеров работает в своей небольшой, автоматически построенной зоне.',
  OPTIMIZATION_SPECNAME: 'Параметр минимизации',
  OPTIMIZATION_SPECNAME_HELP: `Выберите минимизируемый параметр:<br/><br/>
    <b>Время между адресами:</b> Минимизирует время перемещения курьера между  адресами, не учитывая время от/до адресов «склад», «гараж»<br/><br/>
    <b>Расстояние между адресами:</b> Минимизирует  расстояние   между  адресами, не учитывая расстояние от/до адресов «склад», «гараж»<br/><br/>
    <b>Стоимость маршрута:</b> Программа уменьшает стоимость маршрута учитывая  заданные параметры курьера в справочнике <a to="/catalog/couriers">курьеры</a>:<br/>
    - Цена выезда на рейс<br/>
    - Цена за час работы<br/>
    - Цена за км. пути<br/>
    - Цена за кг./км. пути<br/>
    - Цена за один адрес<br/><br/>
    <b>Время всего маршрута:</b> Программа уменьшает  время выполнения маршрута в целом, вне зависимости от  пройденного километража. (например маршрут быстрее выполнить по объездной дороге, чем по пробкам).
    Этот параметр отличается от «Время между адресами» тем, что уменьшает общее время прохождения маршрута учитывая время движения от/до адреса «склад», «гараж» как при начале маршрута, так и при необходимости повторной погрузки/разгрузки.<br/><br/>
    <b>Задача направлений:</b> Особый тип задач, в которых курьеры одновременно развозят и собирают груз. Точки могут содержать груз для развоза.
    `,
  OPTIMIZATION_CLUSTERIZATION: 'Кластеризация',
  OPTIMIZATION_STRICT_MODE: 'Строгий режим',
  OPTIMIZATION_STRICT_MODE_HELP: 'Точки с проблемами в обслуживании (опоздание курьера, перегрузка машины...) не будут попадать в маршрутный лист.',
  OPTIMIZATION_NDAYS: 'Макс. количество дней в маршруте',
  OPTIMIZATION_MAXITER: 'Количество итераций расчёта',
  OPTIMIZATION_MAXITER_HELP: 'Количество повторений расчета маршрута для поиска самого оптимального варианта. Чем больше итераций, тем лучше результат, но дольше время расчета.',
  OPTIMIZATION_RUNS: 'Количество запусков расчёта',
  OPTIMIZATION_RUNS_HELP: 'Кол-во запусков расчета с выбранным кол-вом итераций/расчетов. Увеличение кол-ва запусков дает более надежный результат, но кратно увеличивает время расчета.',
  OPTIMIZATION_BYAGENT: 'По курьерам',
  OPTIMIZATION_BYZONE: 'По районам',
  OPTIMIZATION_BYZONE_HELP: 'Данный параметр используется для ускорения расчета большого количества адресов, путем деления на районы и последовательного расчета каждого района отдельно. Для работы с этим параметром необходимо создать районы на карте. Точки не попавшие в районы учитываются при построении маршрута в последнюю очередь.',
  OPTIMIZATION_NNEIGHBORS: 'Кол-во рассм. соседних точек',
  OPTIMIZATION_IMPROVE_CLUSTERS: 'Улучшать кластеры',
  OPTIMIZATION_BALANCE_BY_WEIGHT: 'Выравнивание кластеров по весу',
  OPTIMIZATION_DONT_MAKE_ROUTES: 'Не создавать маршруты из кластеров',
  OPTIMIZATION_TASK: 'Тип маршрута',
  OPTIMIZATION_TASK_HELP: `Выберите тип выполнения задачи маршрута:<br/><br/>
  <b>Сбор груза</b> – включает возврат на склад в случае полной загрузки автомобиля и после посещения последнего адреса.<br/><br/>
  <b>Развоз груза</b> – курьер всегда начинает движение со склада и возвращается на склад при полной разгрузке на маршруте.`,
  OPTIMIZATION_TASK_OPTION_DISTRIBUTION: 'Развоз груза',
  OPTIMIZATION_TASK_OPTION_COLLECT: 'Сбор груза',
  OPTIMIZATION_CROW_MATRICES: 'Расстояния по прямым линиям',
  OPTIMIZATION_SKIP_ROUTES: 'Не составлять геометрию маршрутов',
  OPTIMIZATION_SPEED_MULTIPLIER: 'Коэффициент скорости',
  OPTIMIZATION_SPEED_MULTIPLIER_HELP: `Искусственное замедление или увеличение скорости курьера, например по причинам пробок, ухудшения погодных условий и т.д. Средняя скорость автомобиля умножается на коэффициент скорости. (напр.: средняя скорость 25 км/ч, коэффициент скорости 2 – скорость 50 км/ч, коэффициент 0,5  - скорость 12,5 км/ч).`,
  OPTIMIZATION_TRAFFIC_JAMS: 'Учитывать пробки',
  OPTIMIZATION_TRAFFIC_JAMS_HELP: 'Замедление времени выполнения маршрута с учетом возможных пробок из статистических данных сервиса Yandex.Карты',
  OPTIMIZATION_PRESERVE_COURIERS: 'Сохранить данные курьеров',
  OPTIMIZATION_PRESERVE_COURIERS_HELP: 'После выполнения расчета маршрута точки будут закреплены за курьерами. При последующих расчетах маршрута данные точки останутся закрепленными за курьерами. В основном используется для оперативного вмешательства в существующий маршрут, например для удаления/добавления адресов в рамках действующего маршрутного листа. Пользователь может оперативно определить адреса, которые не смогут быть посещены в запланированное время понижая коэффициент скорости движения.',
  OPTIMIZATION_BALANCE_AGENTS: 'Балансировать по курьерам',
  OPTIMIZATION_BALANCE_AGENTS_HELP: 'Программа стремится распределить точки маршрута между курьерами для выравнивания времени их работы. Чем больше значение, тем меньше разница во времени работы между курьерами.',
  OPTIMIZATION_TRUCKER_DAYS: 'Кол-во дней в дальних рейсах',
  OPTIMIZATION_TRUCKER_DAYS_HELP: 'Для всех курьеров, у которых выбрано "Многоднейвный маршрут" устанавливает максимальное кол-во дней рейса, чтобы избежать слишком долгих маршрутов.',
  OPTIMIZATION_BY_GROUP: 'Автоматическая кластеризация',
  OPTIMIZATION_BY_GROUP_HELP: 'Оптимизация для больших задач.<br/><br/>Разбивает задачу на группы точек и добавляет их в расчёт по одной, фиксируя привязки к курьерам c расчёта предыдущих групп в соответствии с типом и флагами оптимизации.',

  OPTIMIZATION_OPTION_CLUSTERIZE: 'Кластеризация',
  OPTIMIZATION_OPTION_OPTIMIZE: 'Оптимизация',
  OPTIMIZATION_OPTION_MAXWEIGHT: 'Брать Макс. вес',
  OPTIMIZATION_OPTION_MINCLUSTER: 'Время между адресами',
  OPTIMIZATION_OPTION_MINDISTCLUSTER: 'Расстояние между адресами',
  OPTIMIZATION_OPTION_MINTIME: 'Время всего маршрута',
  OPTIMIZATION_OPTION_MINCOST: 'Стоимость маршрута',
  OPTIMIZATION_OPTION_DIRECTIONS: 'Задача направлений',
  OPTIMIZATION_OPTION_AUTO: 'Автоматически',
  OPTIMIZATION_OPTION_MANUAL: 'Ручной режим',
  OPTIMIZATION_OPTION_NONE: 'По умолчанию',

  CUSTOM_FORM_TAB: 'Клиентская интеграция',

  SUBMIT_DEFAULT: 'Готово',
  SUBMIT_SAVE: 'Сохранить',
  SUBMIT_CREATE: 'Создать',
  SUBMIT_SEND: 'Отправить',
  SUBMIT_CANCEL: 'Отмена',
  SUBMIT_DOWNLOAD: 'Скачать',
  SUBMIT_EXECUTE: 'Выполнить',

  FILTERS_TITLE: 'Фильтр по названию',
  FILTERS_DATE: 'Фильтр по дате',

  ITEM_DELETE: 'Удалить',
  ITEM_EDIT: 'Редактировать',
  ITEM_CLONE: 'Клонировать',
  ITEM_POWER_OFF: 'Выключить',
  ITEM_POWER_ON: 'Включить',
  ITEM_EDIT_WAYBILL: 'Редактировать маршрут',
  ITEM_GROUP_IMPORT: 'Импортировать в группу',

  ITEM_DOCUMENT_SIGNED : 'Подписать', 
  ITEM_DOCUMENT_DOWNLOAD: 'Загрузить',


  FIELD_NO_OPTIONS: 'Нет опций для выбора',
  FIELD_NO_DATA: 'Нет данных',
  FIELD_NO_ITEM: 'Не выбрано',
  FIELD_SELECTED_COUNT: 'Выбрано',
  FIELD_BEGIN_DRAW: 'Рисовать',
  FIELD_STOP_DRAW: 'Закончить',
  FIELD_START_SELECT: 'Выбрать',
  FIELD_START_CALENDAR: 'Календарь',
  FIELD_SELECT_ITEMS: 'Выберите элементы',
  FIELD_SELECT_FLAGS: 'Выберите флаги',
  FIELD_SELECT_DAYS: 'Выберите даты',
  FIELD_NULL_VALUE: '- Не назначено -',
  FIELD_UNDEFINED_VALUE: '- Не менять -',
  FIELD_HELP: 'Помощь',
  FIELD_CUSTOM_ACTION: 'Выполнить',
  FIELD_EXTRA_FIELDS: 'Дополнительные поля',

  WAYBILLS_FIELD_COURIER: 'Название курьера',
  WAYBILLS_FIELD_ADDRESS: 'Адрес точки',
  WAYBILLS_FIELD_CONTACT_PERSON: 'Контактное лицо',
  WAYBILLS_FIELD_PHONE: 'Телефон',
  WAYBILLS_FIELD_POINT_COMMENT: 'Комментарий к точке',
  WAYBILLS_FIELD_DRIVER_COMMENT: 'Комментарий водителя',
  WAYBILLS_FIELD_WORK_START: 'Начало работы',
  WAYBILLS_FIELD_WORK_END: 'Окончание работы',
  WAYBILLS_FIELD_BREAK_START: 'Начало перерыва',
  WAYBILLS_FIELD_BREAK_END: 'Окончание перерыва',
  WAYBILLS_ARRIVAL: 'Расчетное прибытие',
  WAYBILLS_DEPARTURE: 'Расчетное отбытие',
  WAYBILLS_WEIGHT: 'Вес груза клиента',
  WAYBILLS_WEIGHT_LOADED: 'Вес груза в кузове',
  WAYBILLS_VOLUME: 'Объем груза клиента',
  WAYBILLS_VOLUME_LOADED: 'Объем груза в кузове',
  WAYBILLS_TOTAL_DISTANCE: 'Суммарный путь (км.)',
  WAYBILLS_TOTAL_TIME: 'Суммарное время',
  WAYBILLS_TOTAL_COST: 'Суммарная стоимость',
  WAYBILLS_COMING_TIME: 'Время изменение статуса',
  WAYBILLS_STATUS: 'Статус',
  WAYBILLS_STATUS_CODE_1: 'В работе',
  WAYBILLS_STATUS_CODE_2: 'Выполнен',
  WAYBILLS_STATUS_CODE_5: 'Отказ',
  WAYBILLS_SUM: 'Сумма',
  WAYBILLS_PHOTO: 'Контрольные фото',
  WAYBILLS_ORDER: 'Порядок следования',

  WAYBILLS_ROUTE_CODE: 'Код состояния ',
  WAYBILLS_ROUTE_CODE_ST: 'Начало движения',
  WAYBILLS_ROUTE_CODE_UL: 'Погрузка / разгрузка',
  WAYBILLS_ROUTE_CODE_RL: 'Погрузка',
  WAYBILLS_ROUTE_CODE_RU: 'Разгрузка',
  WAYBILLS_ROUTE_CODE_LD: 'Погрузка для развоза по направлениям',
  WAYBILLS_ROUTE_CODE_UD: 'Разгрузка по направлениям',
  WAYBILLS_ROUTE_CODE_RG: 'Движение по маршруту',
  WAYBILLS_ROUTE_CODE_RT: 'Конец маршрута',
  WAYBILLS_ROUTE_CODE_EW: 'Возврат в гараж в конце рабочего дня',
  WAYBILLS_ROUTE_CODE_BR: 'Перерыв',
  WAYBILLS_ROUTE_CODE_LH: 'Ночевка при движении в точку',
  WAYBILLS_ROUTE_CODE_SR: 'Обслуживание точки',

  WAYBILLS_ROUTE_CODE_ERR: 'Ошибка в маршруте',
  WAYBILLS_ROUTE_CODE_T: 'Точка недостижима в рабочее время',
  WAYBILLS_ROUTE_CODE_W: 'Вес точки превышает грузоподъемность',
  WAYBILLS_ROUTE_CODE_S: 'Точка не поместилась в расписание',
  WAYBILLS_ROUTE_CODE_L: 'Курьер не попадает во рабочее время точки',
  WAYBILLS_ROUTE_CODE_OL: 'Обслуживание точки приведет к перегрузу',
  WAYBILLS_ROUTE_CODE_OLD: 'Груз для точки не загружен',
  WAYBILLS_ROUTE_CODE_WZ: 'Точка лежит вне зоны курьера',
  WAYBILLS_ROUTE_CODE_WV: 'Неподходящий тип курьра для обслуживания',
  WAYBILLS_ROUTE_CODE_PV: 'Нарушение приоритетности точек',
  WAYBILLS_ROUTE_CODE_NW: 'Не найден путь до точки в дорожном графе',
  WAYBILLS_ROUTE_CODE_NL: 'Последняя точка в маршруте не соответствует заданной у курьера',
  WAYBILLS_ROUTE_CODE_ND: 'Склад недоступен',

  EDIT_WAYBILLS_TITLE: 'Ручное редактирование маршрута',
  EDIT_WAYBILLS_COURIERS_FREE: 'Свободных маршрутов',
  EDIT_WAYBILLS_COURIERS_EDITED: 'Измененных маршрутов',
  EDIT_WAYBILLS_RESET_CURRENT: 'Сбросить текущий',
  EDIT_WAYBILLS_RESET_ALL: 'Сбросить все',
  EDIT_WAYBILLS_SAVE_WAYBILL: 'Сохранить маршрут',
  EDIT_WAYBILLS_NO_COURIERS_ERROR: 'Не найдено ни одного курьера для редактирования',
  EDIT_WAYBILLS_LINK: 'Редактировать маршруты',

  LOGIN_AUTORIZATION: 'Авторизация',
  LOGIN_LOGIN: 'Войти',
  LOGIN_LOGOUT: 'Выйти',
  LOGIN_SIGNUP: 'Регистрация',
  LOGIN_HELLO: 'Привет, ',
  LOGIN_RESTORE_PASSWORD: 'Восстановить пароль',

  USER_NAME: 'Логин',
  USER_PHONE: 'Телефон',
  USER_PASSWORD: 'Пароль',
  USER_CONFIRM_PASSWORD: 'Подтвердите пароль',
  USER_EMAIL: 'E-mail',

  DOCUMENT_INFO_TITLE: 'Список документов',
  DOCUMENT_SIGN_CONFIRM: 'При подписании документа вы безусловно акцептуете условия Договора-оферты',

  MISC_TEMPLATE_WORKSHEET: 'Шаблон',
  MISC_TEMPLATE_SHEET_NAME: 'Импорт',
  MISC_TEMPLATE_TITLE: 'Импорт адресов для расчета сервисом zig-zag.org.',
  MISC_TEMPLATE_WARNING_TITLE: 'Не удаляйте строки ниже, заполните данные, начиная с пустой строки.',
  MISC_TEMPLATE_DESCRIPTION: 'Широта и долгота - необязательные поля. Координаты без них сами определятся из адреса. Если адрес является вашим складом / гаражом, то заполните соотв. поля цифрой "1".',
  MISC_WAYBILLS_MANIFEST: 'Маршрутные листы маршрута (*).xlsx',
  MISC_WAYBILLS_COURIER: 'Маршрутные листы курьера (*).xlsx',
  MISC_WAYBILLS_SHEET_ALL: 'Все курьеры (общая статистика)',
  MISC_WAYBILLS_SHEET_ALL_DETAILED: 'Все курьеры (детально)',
  MISC_LIST_NO_ITEMS: 'Ничего не найдено',
  MISC_ITEM_CURRENT_MANIFEST: 'Создать в тек. маршруте',
  MISC_ITEM_FROM_CATALOG: 'Добавить из справочника',
  MISC_SELECT_ALL: 'Выбрать все',
  MISC_UNSELECT_ALL: 'Убрать все',
  MISC_DROP_DOWN_CONTAINER: 'Скрытое содержимое, нажмите, чтобы увидеть',
  MISC_DELETE_MULTIPLE: 'Удалить несколько элементов',
  MISC_DELETE_MULTIPLE_CONFIRM: 'Удалить выбранные элементы?',
  MISC_IMPORT_XLS: 'Импорт',
  MISC_IMPORT_XLS_HINT: 'Загрузите  заполненный шаблон со списком элементов в формате XLS. Скачать шаблон для заполнения можно нажав на соседнюю кнопку.',
  MISC_DOWNLOAD_XLS: 'Скачать шаблон XLS',
  MISC_DOWNLOAD_XLS_HINT: 'Скачайте шаблон для заполнения списка элементов в формате XLS и последующей загрузки',

  NOTIFY_INPROCESS: 'Операция выполняется',
  NOTIFY_ERROR: 'Произошла ошибка.',
  NOTIFY_SUCCESS: 'Успешно.',
  NOTIFY_DELETE: 'Удаление элемента.',
  NOTIFY_NO_DATA: 'Нет данных.',
  NOTIFY_IMPORT_POINTS_ERROR_DATA: 'Файл поврежден или шаблон заполнен некорректно.',
  NOTIFY_IMPORT_POINTS_ERROR_EMPTY: 'Не найдено ни одного элента для импорта.',
  NOTIFY_IMPORT_POINTS_ERROR_LINE: 'Строка: ',
  NOTIFY_IMPORT_POINTS_ERROR_FIELD: 'Поле: ',
  NOTIFY_IMPORT_POINTS_ERROR_NO_FIELD: 'Невозможно импортировать поле:',
  NOTIFY_IMPORT_TIMEOUT: 'Извините, у нас временное ограничение на импорт файлов из-за высокой нагрузки. Следующий импорт возможен:',
  NOTIFY_SUBSCRIPTION_EXPIRES: 'Пожалуйста, оплатите подписку на следующий период. Доступ в систему будет приостановлен',
  NOTIFY_UNSIGN_DOCUMENT: 'У Вас есть неподписанные документы, пожалуйста, подпишите Главное меню/Справочники/Документы',

  // Ошибки внутри клиента
  ERROR_C001: "Пароли должны совпадать",
  ERROR_C002: "Сервер не отвечает, пожалуйста свяжитесь с менеджером",
  ERROR_C003: 'Неверное имя пользователя или пароль',
  ERROR_C004: 'Ошибка регистрации, проверьте введенные данные',
  ERROR_C005: 'Заполните все поля',
  ERROR_C006: 'Регистрация прошла успешно. Скоро с Вами свяжется наш менеджер, чтобы провести ознакомление с возможностями сервиса. Пользователь еще не активен.',

  // Ошибки сервера приложений
  ERROR_1000: "Внутренняя ошибка сервера приложений",
  ERROR_1001: "Пользователь не найден или неверный пароль.",
  ERROR_1002: "Email не найден",
  ERROR_1003: "Пароль не найден",
  ERROR_1004: "Неизвестный формат данных: %format%, требуется \"json\" или \"xml\"",
  ERROR_1005: "Незвестная операция: %operation%",
  ERROR_1006: "Нет прав доступа к текущему манифесту",
  ERROR_1007: "Нет прав доступа к текущему каталогу",
  ERROR_1008: "Некоректный или недостающий параеметр: %param_name%",
  ERROR_1009: "Множественные ошибки сервера приложений", // {errors: [error]}
  ERROR_1010: "Сущность %id% не найдена",
  ERROR_1011: "Запрошенная задача не выполнена",
  ERROR_1012: "Маршрут уже находится в процессе рассчета",
  ERROR_1013: "Запрещенная операция",
  ERROR_1014: "Данный Email уже зарегистрирован",
  ERROR_1015: "Укажите номер телефона",
  ERROR_1016: "Ошибка формарирования отчета",
  ERROR_1017: "Пользователь заблокирован, пожалуйста, свяжитесь с менеджером",
  ERROR_1018: "Данный номер телефона уже зарегистрирован",

  // Ошибки очереди задач
  ERROR_2000: "Внутренняя ошибка очереди задач",
  ERROR_2001: "Роутер не ответил",
  ERROR_2002: "Ошибка роутера: %message%",
  ERROR_2003: "Маршрут не содержит курьеров",
  ERROR_2004: "Маршрут не содержит точки",

  // Ошибки решателя
  ERROR_3000: "Внутренняя ошибка рассчетного модуля",
  ERROR_3001: "Неизвестная спецификация",
  ERROR_3002: "Неизвестная задача",
  ERROR_3003: "Точки %point_id% не попала в кластер",
  ERROR_3004: "Поле %field_name% не заполнено (курьер: %agent_id%, точка: %point_id%)",
  ERROR_3005: "Неизвестная зона %zone_id% (курьер: %agent_id%, точка: %point_id%)",
  ERROR_3006: "Слишком много зон в маргруте, 63 максимум",
  ERROR_3007: "Задача распредения по зонам требует наличия только одной зоны у точки %point_id%",
  ERROR_3008: "Кол-во рассм. соседних точек должно быть не менее 1",
  ERROR_3009: "Маршрут не содержит складов",
  ERROR_3010: "Нет доступа к серверу лицензий",
  ERROR_3011: "Проблемы с лицензией",
  ERROR_3012: "IP заблокирован",
  ERROR_3013: "Превышение допустимых лимитов задачи",
  ERROR_3014: "Unknown point (курьер: %agent_id%, точка: %point_id%)",
  ERROR_3015: "Unknown agent (курьер: %agent_id%, точка: %point_id%)",

  ERROR_CODE_UNKNOWN: "Неизвестный код ошибки",

  DAYPICKER_WEEKDAYS_SHORT: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
  DAYPICKER_MONTHS: [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ],
  DAYPICKER_MONTHS_PARENTAL: [
    'Января',
    'Февраля',
    'Марта',
    'Апреля',
    'Мая',
    'Июня',
    'Июля',
    'Августа',
    'Сентября',
    'Октября',
    'Ноября',
    'Декабря',
  ],
  DAYPICKER_WEEKDAYS_LONG: [
    'Воскресенье',
    'Понедельник',
    'Вторник',
    'Среда',
    'Четверг',
    'Пятница',
    'Суббота',
  ],
  DAYPICKER_FIRST_DAY_OF_WEEK: 1,
  DAYPICKER_LABELS: {
    nextMonth: 'Следующий месяц',
    previousMonth: 'Предыдущий месяц'
  },
  DEFAULT_FLAGS: [
    "Красный",
    "Оранжевый",
    "Жёлтый",
    "Зелёный",
    "Голубой",
    "Синий",
    "Фиолетовый",
    "Серебристый",
    "Белый",
    "Розовый",
    "Золотой",
    "Каштановый",
    "Коричневый",
    "Бурый",
    "Оливковый",
    "Болотный",
    "Травяной",
    "Бирюзовый",
    "Аквамарин",
    "Малиновый",
    "Пурпурный",
    "Пунцовый",
    "Алый",
    "Циан",
    "Бордо",
    "Вишнёвый",
    "Шоколадный",
    "Бежевый",
    "Серый",
    "Черный",
  ]
};

export default i18n;
