import React from 'react';
import { PropTypes } from 'prop-types';
import { toast } from 'react-toastify';
import { push } from 'react-router-redux';

import { CRUD } from './queries';
import I18N from '../../common/i18n';
import { withApolloAsyncLoad, getSectionLinkBody } from '../../../base/utils';
import { store } from '../../..';
import { Sections } from '../../common/enums';
import GroupForm from './GroupForm';

export default
class GroupEdit extends React.PureComponent {
  static title = I18N.GROUPS_EDIT_TITLE

  static propTypes = {
    itemId: PropTypes.number.isRequired,
    manifestId: PropTypes.number,
    orgId: PropTypes.number,
  }

  _update = (update, data) => {
    const p = update({
      variables: {
        id: this.props.itemId,
        data: data
      }
    });
    p.then((res) => {
      toast.success(`${I18N.GROUPS_EDIT_TITLE}. ${I18N.NOTIFY_SUCCESS}`);
      store.dispatch(push(
        `${getSectionLinkBody(this.props.manifestId)}${Sections.GROUPS}`
      ));
    });
    p.catch((res) => {
      toast.error(`${I18N.GROUPS_EDIT_TITLE}. ${I18N.NOTIFY_ERROR}`);
      console.log('update error');
    });
    return p;
  }

  _renderForm = (group) => (
    CRUD.UPDATE(
      {},
      (update) => (
        <GroupForm
          key={group.id}
          {...this.props}
          submitText={I18N.SUBMIT_SAVE}
          item={group}
          submit={(data) => this._update(update, data)} />
      )
    )
  );

  render() {
    return (
      CRUD.GET_ONE(
        {id: this.props.itemId},
        (result) => withApolloAsyncLoad(this._renderForm, result)
      )
    );
  }
}
