import React from 'react';
import { PropTypes } from 'prop-types';
import block from 'bem-cn-lite';

import InfoBadge from '../../common/components/InfoBadge';
import ListItem from '../common/ListItem';
import I18N from '../../common/i18n';
import icons from '../../common/icons';
import { AppSections } from '../../common/enums';

const b = block('ListItem');

export default
class ManifestListItem extends React.PureComponent {

  static propTypes = {
    item: PropTypes.object.isRequired,
    onDelete: PropTypes.func.isRequired,
    manifestId: PropTypes.number.isRequired,
  }

  render() {
    const manifest = this.props.item;

    return (
      <ListItem
        id={manifest.id}
        link={`/${AppSections.MANIFEST}/${manifest.id}`}
        key={manifest.id}
        title={`${manifest.name} :: ${manifest.id}`}
        className={b({ 'Current': manifest.id === this.props.manifestId })}
      >
        <div className='ListItem__InfoBadges'>
          <InfoBadge iconName={icons.BADGE_DATE} value={I18N.formatDate(new Date(manifest.date))}/>
          <InfoBadge iconName={icons.BADGE_POINT} value={manifest.points_count}/>
          <InfoBadge iconName={icons.BADGE_CAR} value={manifest.couriers_count}/>
        </div>
      </ListItem>
    );
  }
}
