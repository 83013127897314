import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { ButtonKinds } from '../enums';

import Icon from './Icon';
import Hint from './Hint';

const b = block('Button');

class Button extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    icon: PropTypes.string,
    flipIcon: PropTypes.bool,
    leftHint: PropTypes.string,
    rightHint: PropTypes.string,
    disabled: PropTypes.bool,
    toggledOff: PropTypes.bool,
    clickOnce: PropTypes.bool,
    to: PropTypes.string,
    onClick: PropTypes.func,
    route: PropTypes.func,
    children: PropTypes.any,
    kind: PropTypes.oneOf(Object.keys(ButtonKinds)),
  };

  constructor() {
    super();
    this.state = {
      disabled: false,
      toggledOff: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      disabled: nextProps.disabled || false,
      toggledOff: nextProps.toggledOff || false,
    });
  }

  componentDidMount() {
    this.setState({
      disabled: this.props.disabled || false,
      toggledOff: this.props.toggledOff || false,
    });
  }

  _onClick = (e) => {
    if (!this.state.disabled) {
      this.props.onClick && this.props.onClick(e);
      this.props.to && this.props.route(this.props.to);
      this.setState({ disabled: this.props.clickOnce || false });
    }
  }

  _renderIcon() {
    if (this.props.icon)
      return (
        <div className={b('Icon', {'NoTitle': !this.props.children})}>
          <Icon svgName={ this.props.icon }/>
        </div>
      );
    return null;
  }

  _getStyleMod = () => ({
    'Default': !this.props.icon,
    'Iconed': Boolean(this.props.icon),
    'Disabled': !this.props.icon && this.props.disabled,
    'DisabledIconed': this.props.icon && this.props.disabled,
    'ToggledOff': !this.props.icon && this.props.toggledOff,
    'ToggledOffIconed': this.props.icon && this.props.toggledOff,
    'Warning': this.props.kind && this.props.kind === ButtonKinds.WARNING,
    'Danger': this.props.kind && this.props.kind === ButtonKinds.DANGER,
    'Unimportant': this.props.kind && this.props.kind === ButtonKinds.UNIMPORTANT,
  });

  render() {
    return (
      <div
        onClick={this._onClick}
        className={`${b(this._getStyleMod())} ${this.props.className || ''}`}
      >
        {!this.props.flipIcon && this._renderIcon()}
        {this.props.children && <div className={b('Title')}>{this.props.children}</div>}
        {this.props.flipIcon && this._renderIcon()}
        {(this.props.leftHint || this.props.rightHint) && <Hint {...this.props}/>}
      </div>
    );
  }
}

export default
connect(
  null,
  (dispatch) => ({ route: (link) => dispatch(push(link)) })
)(Button);
