import React from 'react';
import { push } from 'react-router-redux';
// import { PropTypes } from 'prop-types';
import { toast } from 'react-toastify';

import { CRUD } from './queries';
import I18N from '../../common/i18n';
import { store } from '../../..';
import { AppSections, Sections } from '../../common/enums';
import UserForm from './UserForm';

const defaultUser = Object.freeze({
  password: null,
});

export default
class UserCreate extends React.PureComponent {
  static title = I18N.USERS_CREATE_TITLE

  _create = (create, data) => {
    const p = create({
      variables: {
        'data': data
      }
    });
    p.then((res) => {
      toast.success(`${I18N.USERS_CREATE_TITLE}. ${I18N.NOTIFY_SUCCESS}`);
      store.dispatch(push(`/${AppSections.CATALOG}/${Sections.USERS}`));
    });
    p.catch((res) => {
      toast.error(`${I18N.USERS_CREATE_TITLE}. ${I18N.NOTIFY_ERROR}`);
      console.log('created error', res);
    });
    return p;
  }

  render() {
    return (
      CRUD.CREATE(
        null,
        (create) => (
          <UserForm
            {...this.props}
            submitText={I18N.SUBMIT_CREATE}
            item={defaultUser}
            submit={(data) => this._create(create, data)} />
        )
      )
    );
  }
}
