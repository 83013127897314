import { client } from '../../index';
import { queries } from './queries';
import { queries as pointQueries } from '../sections/points/queries';
import { queries as courierQueries } from '../sections/couriers/queries';
import { queries as manifestQueries } from '../sections/manifests/queries';
import { ApolloFetchPolicy } from '../common/enums';

const POINTS_WAYBILL_OFFSET = 500;

export
const loadWaybillsAsync = (manifestId) => {
  const loadWaybills = async (offsetMultiplier = 0, loadedData = []) => {
    const { data } = await client.query({
      query: queries.POINTS_WAYBILL_LIST,
      variables: {
        'manifest_id': manifestId,
        'offset': POINTS_WAYBILL_OFFSET * offsetMultiplier,
        'limit': POINTS_WAYBILL_OFFSET,
      },
    });

    if (!data.result)
      return loadedData;

    const mergedData = [...loadedData, ...data.result];
    return loadWaybills(offsetMultiplier + 1, mergedData);
  };

  return loadWaybills();
};

export
const loadPointsAsync = async (manifestId, titleFilter = '') => {
  const { data } = await client.query({
    query: pointQueries.LIST,
    variables: {
      'manifest_id': manifestId,
      'title_filter': titleFilter,
      'complete': true,
    },
  });

  return data.result || [];
};

export
const loadPointsDetailedAsync = async (manifestId, titleFilter = '') => {
  const { data } = await client.query({
    query: pointQueries.LIST_DETAILED,
    variables: {
      'manifest_id': manifestId,
      'title_filter': titleFilter,
      'complete': true,
    },
  });

  return data.result || [];
};

export
const loadPointsCoordsAsync = async (manifestId) => {
  const { data } = await client.query({
    query: queries.POINTS_COORDS_LIST,
    variables: {
      'manifest_id': manifestId
    },
  });

  return data.result || [];
};

export
const loadCouriersAsync = async (manifestId, titleFilter = '') => {
  const { data } = await client.query({
    query: courierQueries.LIST,
    variables: {
      'manifest_id': manifestId,
      'title_filter': titleFilter,
      'complete': true,
    },
  });

  return data.result || [];
};

export
const loadCouriersDetailedAsync = async (manifestId, titleFilter = '') => {
  const { data } = await client.query({
    query: courierQueries.LIST_DETAILED,
    variables: {
      'manifest_id': manifestId,
    },
  });

  return data.result || [];
};

export
const loadZonesAsync = async (manifestId, titleFilter = '') => {
  const { data } = await client.query({
    query: queries.ZONES_LIST,
    fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
    variables: {
      'manifest_id': manifestId,
      'title_filter': titleFilter
    },
  });

  return data.result || [];
};

export
const loadManifestDataAsync = async (manifestId) => {
  const { data } = await client.query({
    query: queries.QUERY_MANIFEST,
    variables: {
      'id': manifestId,
    },
  });

  return data.result;
};

export
const loadManifestsAsync = async (titleFilter) => {
  const { data } = await client.query({
    query: manifestQueries.LIST,
    variables: {
      'title_filter': titleFilter
    },
  });

  return data.result;
};
