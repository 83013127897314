import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';
import { toast } from 'react-toastify';

import I18N from '../../common/i18n';
import { Button } from '../../common/components';
import { 
  routeTo, 
  store 
} from '../../..';
import { actions as sectionActions} from '../common/actions';
import ItemSelectionList from './ItemSelectionList';
import { getSectionLinkBody } from '../../../base/utils';
import ConfirmButton from '../../common/components/ConfirmButton';
import { ITEMS_PER_PAGE } from '..';
import { Sections } from '../../common/enums';

const b = block('DeleteList');
const fieldName = 'to_delete';

export default
class DeleteList extends React.PureComponent {
  static title = I18N.MISC_DELETE_MULTIPLE

  static propTypes = {
    value: PropTypes.arrayOf(PropTypes.number),
    manifestId: PropTypes.number,
    orgId: PropTypes.number,
    itemElement: PropTypes.func,
    section: PropTypes.string,
    crud: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.itemsToDelete = props.value || [];
  }

  _deleteItems = (del) => {
    const p = del({
      variables: {
        data: this.itemsToDelete
      }
    });
    p.then((res) => {
      console.log('list deleted');
      routeTo(`${getSectionLinkBody(this.props.manifestId)}${this.props.section}`);
      toast.success(`${I18N.MISC_DELETE_MULTIPLE} ${I18N.NOTIFY_SUCCESS}`);

      if (this.props.section === Sections.POINTS)
        store.dispatch(sectionActions.pointsDeleted(res.data.result, 'form'));
    });
    p.catch((res) => {
      console.log('list delete error');
      toast.error(`${I18N.MISC_DELETE_MULTIPLE} ${I18N.NOTIFY_ERROR}`);
    });
  }

  componentDidMount() {
    sectionActions.fieldValueUpdated.subscribe((s, action) => {
      if (action.fieldName === fieldName)
        this.itemsToDelete = action.value;
    });
  }

  render() {
    return this.props.crud.DELETE_LIST(
      {
        'manifest_id': this.props.manifestId || -this.props.orgId,
        'limit': ITEMS_PER_PAGE,
        'offset': 0,
        'title_filter': "",
      },
      (del) => (
        <React.Fragment>
          <div className={b('Controls')}>
            <Button
              to={`${getSectionLinkBody(this.props.manifestId)}${this.props.section}`}
              className={b('Button')}>{I18N.SUBMIT_CANCEL}</Button>
            <ConfirmButton
              className={b('Button')}
              confirmMessage={I18N.MISC_DELETE_MULTIPLE_CONFIRM}
              onClick={() => this._deleteItems(del)}>{I18N.ITEM_DELETE}</ConfirmButton>
          </div>
          <ItemSelectionList
            {...this.props}
            className={b('List')}
            fieldName={fieldName}
          />
        </React.Fragment>
      )
    );
  }
}
