import React from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn-lite';
import { Link } from 'react-router-dom';

const b = block('TextLink');

export default class TextLink extends React.PureComponent {

  static propTypes = {
    children: PropTypes.any.isRequired,
    to: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func
  };


  render() {
    return (
      <Link
        className={`${b({ 'Disabled': this.props.disabled, 'Dashed': !this.props.to })} ${this.props.className && this.props.className}`}
        to={this.props.to || '#'}
        onClick={this.props.onClick || null}
      >
        {this.props.children}
      </Link>
    );
  }
}
