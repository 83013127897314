const icons = {
  COMMON_CLEAR_FIELD: 'Common_ClearField',
  COMMON_USER_BUTTON: 'Common_UserButton',
  COMMON_HELP_BUTTON: 'Common_HelpButton',
  COMMON_XLS_TEMPLATE: 'Common_XLSTemplate',
  COMMON_XLS_IMPORT: 'Common_XLSImport',
  COMMON_BACK: 'Tab_Back',
  COMMON_CLOSE: 'Tab_Close',
  COMMON_DELETE: 'Item_Can',
  COMMON_EDIT: 'Item_Edit',
  COMMON_CLONE: 'Item_Clone',
  COMMON_WAYBILL: 'Item_Waybill',
  COMMON_DOWNLOAD: 'Item_Download',
  COMMON_PHOTO: 'Item_Photo',
  COMMON_POWER_ON: 'Item_Power_On',
  COMMON_POWER_OFF: 'Item_Power_Off',
  COMMON_EDIT_WAYBILL: 'Item_Edit_Waybill',
  COMMON_ZOOM: 'Tab_Zoom',
  COMMON_DOTS: 'Common_Dots',

  BADGE_WEIGHT: 'ItemData_Weight',
  BADGE_VALUE: 'ItemData_Value',
  BADGE_DATE: 'ItemData_Date',
  BADGE_TIME: 'ItemData_Time',
  BADGE_POINT: 'ItemData_Waypoint',
  BADGE_CAR: 'ItemData_Cars',
  BADGE_BREAK: 'ItemData_Break',
  BADGE_CARGO: 'ItemData_Cargo',
  BADGE_SCHEDULE: 'ItemData_Schedule',
  BADGE_MAX_WEIGHT: 'ItemData_MaxWeight',
  BADGE_VOLUME_LOAD: 'ItemData_VolumeLoad',
  BADGE_WEIGHT_LOAD: 'ItemData_WeightLoad',
  BADGE_TIME_LOAD: 'ItemData_TimeLoad',
  BADGE_FROM_ID: 'ItemData_FromId',

  LOGO: 'ZigZag_Logo',
  LOGO_BLACK: 'ZigZag_Logo_Black',

  MENU_MANIFEST: 'AppMenu_Manifest',
  MENU_ORDERS: 'AppMenu_Request',
  MENU_DICTS: 'AppMenu_Dictiory',
  MENU_HISTORY: 'AppMenu_History',
  MENU_METRICS: 'AppMenu_Metrics',
  MENU_SETTINGS: 'AppMenu_Settings',
  MENU_COLLAPSE: 'AppMenu_Collapse',
  MENU_QUESTIONS: 'AppMenu_Questions',
  MENU_YOUTUBE: 'AppMenu_YouTube',
  MENU_SUPPORT: 'AppMenu_Support',

  MANIFEST_COURIERS: 'Manifest_Couriers',
  MANIFEST_POINTS: 'Manifest_Waypoints',
  MANIFEST_ZONES: 'Manifest_Zones',
  MANIFEST_DETAILS: 'Manifest_Details',
  MANIFEST_OPTIMIZATION: 'Manifest_Optimization',
  MANIFEST_CALC_RUN: 'Manifest_RunCalculation',
  MANIFEST_CALC_UPDATE: 'Manifest_UpdateCalculation',
  MANIFEST_CALC_CANCEL: 'Manifest_CancelCalculation',
  MANIFEST_DROP_DOWN: 'Manifest_DropDown',
  MANIFEST_LIST: 'Manifest_List',
  MANIFEST_SWITCH_ON: 'Manifest_SwitchOn',
  MANIFEST_SWITCH_OFF: 'Manifest_SwitchOff',
  MANIFEST_MANUAL_EDIT_START: 'Manifest_ManualEditStart',
  MANIFEST_MANUAL_EDIT_STOP: 'Manifest_ManualEditStop',

  CATALOG_USERS: 'Catalog_Drivers',
  CATALOG_COURIERS: 'Catalog_Couriers',
  CATALOG_POINTS: 'Catalog_Points',
  CATALOG_ZONES: 'Catalog_Zones',
  CATALOG_MEASURES: 'Catalog_Mesures',
  CATALOG_GROUPS: 'Catalog_Groups',

  MAP_VIEW_MAP: 'MapView_Landscape',
  MAP_VIEW_LINES: 'MapView_Lines',
  MAP_VIEW_PATH: 'MapView_Path',
  MAP_VIEW_ZONES: 'MapView_Zones',
  MAP_VIEW_POINTS: 'MapView_Waypoints',
  MAP_VIEW_CLUSTERS: 'MapView_Clusters',
  MAP_VIEW_TRACKS: 'MapView_Tracks',
  MAP_VIEW_LOCK: 'MapView_Lock',
  MAP_VIEW_LOCKED_POINTS: 'MapView_LockedPoints',

  MAP_CONTROLS_SELECT: 'MapControls_Select',
  MAP_CONTROLS_UNSELECT: 'MapControls_Unselect',

  MARKER_BASE: 'Marker_Base',
  MARKER_BREAK: 'Marker_Break',
  MARKER_DEPOT: 'Marker_Depot',
  MARKER_ERROR: 'Marker_Error',
  MARKER_FINISH: 'Marker_Finish',
  MARKER_FINISHPOINT: 'Marker_FinishPoint',
  MARKER_GARAGE: 'Marker_Garage',
  MARKER_GARAGEDEPOT: 'Marker_GarageDepot',
  MARKER_GARAGESLEEP: 'Marker_GarageSleep',
  MARKER_CRANE: 'Marker_Crane',
  MARKER_SLEEP: 'Marker_Sleep',
  MARKER_SLEEPDEPOT: 'Marker_SleepDepot',
  MARKER_START: 'Marker_Start',
  MARKER_STARTPOINT: 'Marker_StartPoint',
  MARKER_SUBSTITUTION: 'Marker_Substitution',
  MARKER_MOVE_SLEEP: 'Marker_MoveSleep',
  MARKER_MOVE_BREAK: 'Marker_MoveBreak',
  MARKER_PICKUPPOINT: 'Marker_PickupPoint',

  MINISTATUS_ENQUEUED: 'ManifestStatus_Queue',
  MINISTATUS_ERROR: 'ManifestStatus_Error',
  MINISTATUS_WARNING: 'ManifestStatus_Warning',
  MINISTATUS_SUCCESS: 'ManifestStatus_Success',

  WAYBILL_POINT_STATUS_OK: 'WPointStatus_Ok',
  WAYBILL_POINT_STATUS_REJECT: 'WPointStatus_Reject',
  WAYBILL_POINT_STATUS_PENDING: 'WPointStatus_Pending',

  SMILE_GOOD: 'Smile_Good',
  SMILE_BAD: 'Smile_Bad',

  ITEM_CAR: 'ItemSnippet_Car',
  ITEM_TRUCK: 'ItemSnippet_Truck',
  ITEM_WALK: 'ItemSnippet_Walk',
  ITEM_CAR_OFF: 'ItemSnippet_Car_Off',
  ITEM_TRUCK_OFF: 'ItemSnippet_Truck_Off',
  ITEM_WALK_OFF: 'ItemSnippet_Walk_Off',
  ITEM_WAYPOINT: 'ItemSnippet_Waypoint',
  ITEM_WAYPOINT_MISSED: 'ItemSnippet_WaypointMissed',
  ITEM_WAYPOINT_ERROR: 'ItemSnippet_WaypointError',
  ITEM_WAYPOINT_BASE: 'ItemSnippet_WaypointBase',
  ITEM_WAYPOINT_ADDRESS: 'ItemSnippet_WaypointAddress',
  ITEM_WAYPOINT_PICKUP: 'ItemSnippet_PickupPoint',
  ITEM_ZONE: 'ItemSnippet_Zone',
  ITEM_GROUP_POINTS: 'ItemSnippet_Group_Points',
  ITEM_GROUP_COURIERS: 'ItemSnippet_Group_Couriers',
  ITEM_GROUP_ZONES: 'ItemSnippet_Group_Zones',
};

export default icons;
