import gql from 'graphql-tag';
import bindApollo from '../common/queries';
import { Sections } from '../../common/enums';

const groupsDefaultFieds = `
  id,
  name,
  section,
`;

const groupListFields = `
  ${groupsDefaultFieds}
  items_count,
`;

const groupCardFields = `
  ${groupsDefaultFieds}
  items,
`;

export
const queries = {
  SECTION: Sections.GROUPS,

  CREATE: gql`
    mutation CREATE_GROUP($manifest_id: int!, $data: String!) {
      result(manifest_id: $manifest_id data: $data) {
        ${groupCardFields}
      }
    }
  `,

  DELETE: gql`
    mutation DELETE_GROUP($id: Int!) {
      result(id: $id)
    }
  `,

  DELETE_LIST: gql`
    mutation DELETE_GROUPS($data: Array!) {
      result(data: $data)
    }
  `,

  LIST: gql`
    query GROUPS ($manifest_id: Int) {
      result(manifest_id: $manifest_id crutch: "groups") {
        ${groupListFields}
      }
    }
  `,

  GET_ONE: gql`
    query GROUP ($id: Int!) {
      result(id: $id) {
        ${groupsDefaultFieds}
        ${groupCardFields}
      }
    }
  `,

  UPDATE: gql`
    mutation UPDATE_GROUP($manifest_id: int!, $data: String!) {
      result(manifest_id: $manifest_id data: $data) {
        ${groupCardFields}
      }
    }
  `,
};

export
const CRUD = bindApollo(queries);
