import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';

import SectionList from '../common/SectionList';
import { TextFilterInput } from './../../common/components';
import I18N from 'modules/common/i18n';
import { withSelectableListItem } from './../common/SelectableListItem';
import { actions as sectionActions } from './actions';
import { sections } from 'modules/sections';
import { store } from 'index';
import TextLink from '../../common/components/TextLink';
import { Sections } from '../../common/enums';
import {
  loadPointsCoordsAsync,
  loadCouriersAsync,
  loadZonesAsync,
  loadPointsAsync,
  loadManifestsAsync
} from '../../manifest/utils';
import { ITEMS_PER_PAGE } from '..';
import SectionListComplete from './SectionListComplete';
import { queries as gQueries} from '../groups/queries';
import { client } from '../../..';
import i18n from '../../common/i18n';

const b = block('ItemSelectionList');

export default
class ItemSelectionList extends React.Component {
  static title = I18N.FIELD_SELECT_ITEMS;

  static propTypes = {
    manifestId: PropTypes.number,
    orgId: PropTypes.number,
    className: PropTypes.string,
    section: PropTypes.string.isRequired,
    fieldName: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.arrayOf(PropTypes.number)
    ]),
    onClose: PropTypes.func,
    fromCatalog: PropTypes.bool,
    onlyValue: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      titleFilter: '',
      selectedItems: new Set(this.props.onlyValue ? [props.value] : props.value),
    };
    this.localItems = new Set(this.state.selectedItems);
    this.localGroups = new Set();
  }

  _getOriginId = () => (this.props.fromCatalog || !this.props.manifestId) ? -this.props.orgId : this.props.manifestId;

  _getSectionIds = (section) => {
    switch (section) {
    case Sections.POINTS:
      return this.state.titleFilter
        ? loadPointsAsync(this._getOriginId(), this.state.titleFilter)
        : loadPointsCoordsAsync(this._getOriginId());
    case Sections.COURIERS:
      return loadCouriersAsync(this._getOriginId(), this.state.titleFilter);
    case Sections.ZONES:
      return loadZonesAsync(this._getOriginId(), this.state.titleFilter);
    case Sections.MANIFEST_LIST:
      return loadManifestsAsync(this.state.titleFilter);
    default:
      return new Promise((resolved) => resolved([]));
    }
  }

  _selectAll = async (section) => {
    const newSet = new Set(this.state.selectedItems);
    const items = await this._getSectionIds(section);
    items.map((item) => newSet.add(item.id));
    this._submitSelection(newSet);
  }

  _deselectAll = () => {
    this._submitSelection(new Set());
  }

  selectItem = (id, selected) => {
    if (!selected) {
      this.localItems.delete(id);
    }
    else if (this.props.onlyValue) {
      this.localItems.clear();
      this.localItems.add(id);
      this.forceUpdate();
    }
    else {
      this.localItems.add(id);
    }

    this._submitLocalSelection(this.localItems);
  }

  selectGroup = async (id, selected) => {
    const { data } = await client.query({
      query: gQueries.GET_ONE,
      variables: { 'id': id },
    });

    if (data.result && data.result.items) {
      if (!selected) {
        this.localGroups.delete(id);
        for (const itemId of data.result.items)
          this.localItems.delete(itemId);
      }
      else {
        this.localGroups.add(id);
        for (const itemId of data.result.items)
          this.localItems.add(itemId);
      }
      this._submitSelection(this.localItems);
      this.forceUpdate();
    }
  }

  isItemSelected = (id) => this.localItems.has(id);

  isGroupSelected = (id) => this.localGroups.has(id);

  _submitLocalSelection = (newSet) => {
    store.dispatch(sectionActions.fieldValueUpdated(
      this.props.fieldName,
      [...newSet],
      'select_list'
    ));
  }

  _submitSelection = (newSet) => {
    this.localItems = newSet;
    this.setState({ selectedItems: this.localItems }, () => {
      store.dispatch(sectionActions.fieldValueUpdated(
        this.props.fieldName,
        [...this.localItems],
        'select_list'
      ));
    });
  }

  _applyTitleFilter = (newTitleFilter) => {
    this.setState({titleFilter: newTitleFilter});
  }

  render() {
    return (
      <div className={`${b()} ${this.props.className || ''}`}>
        <div className={b('Filters')}>
          <TextFilterInput
            placeHolder={I18N.FILTERS_TITLE}
            onChangeValue={this._applyTitleFilter}
            value={this.state.titleFilter} />
        </div>
        {!this.props.onlyValue && <div className={b('MassSelection')}>
          <TextLink onClick={() => this._selectAll(this.props.section)}>{I18N.MISC_SELECT_ALL}</TextLink>
          <TextLink onClick={() => this._deselectAll()}>{I18N.MISC_UNSELECT_ALL}</TextLink>
          <hr/>
        </div>}
        {this.props.fromCatalog && !this.props.onlyValue && <div className={b('Groups')}>
          <h2>{i18n.GROUPS_TAB}</h2>
          <SectionListComplete
            manifestId={-this.props.orgId}
            queries={sections[Sections.GROUPS].crud}
            itemsPerPage={ITEMS_PER_PAGE}
            propertiesFilter={(item) => item.section === this.props.section}
            variables={{
              'manifest_id': this._getOriginId(),
              'title_filter': this.state.titleFilter,
              'limit': ITEMS_PER_PAGE,
              'offset': 0
            }}
            itemElement={withSelectableListItem(
              sections[Sections.GROUPS].item,
              this.selectGroup,
              this.isGroupSelected,
            )}
          />
          <h2>{i18n.GROUPS_ENTITIES}</h2>
        </div>}
        <SectionList
          manifestId={this._getOriginId()}
          queries={sections[this.props.section].crud}
          itemsPerPage={ITEMS_PER_PAGE}
          variables={{
            'manifest_id': this._getOriginId(),
            'title_filter': this.state.titleFilter,
            'limit': ITEMS_PER_PAGE,
            'offset': 0
          }}
          itemElement={withSelectableListItem(
            sections[this.props.section].item,
            this.selectItem,
            this.isItemSelected,
          )}
        />
      </div>
    );
  }
}
