import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';

import ManifestCreate from '../../sections/manifests/ManifestCreate';
import I18N from '../../common/i18n';

const b = block('ManifestFirst');

export default
class ManifestFirst extends React.PureComponent {
  static propTypes = {
    manifestId: PropTypes.number.isRequired
  };

  render() {
    return <div className={b()}>
      <div className={b('Window')}>
        <div className={b('Message')}>{I18N.MANIFEST_FIRST_MESSAGE}</div>
        <ManifestCreate {...this.props}/>
      </div>
    </div>;
  }
}
