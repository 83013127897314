import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';

import Icon from './Icon';

const b = block('InfoBadge');

export default
class InfoBadge extends React.PureComponent {
  static propTypes = {
    iconName: PropTypes.string,
    value: PropTypes.any,
    to: PropTypes.string,
  }

  render() {
    return this.props.to
      ? <div className={b({'Linked': true})}>
        <Link to={this.props.to}>
          {this.props.iconName && <div className={b('Icon')}>
            <Icon svgName={this.props.iconName}/>
          </div>}
          <div className={b('Text')}>{this.props.value || '0'}</div>
        </Link>
      </div>
      : <div className={b()}>
        {this.props.iconName && <div className={b('Icon')}>
          <Icon svgName={this.props.iconName}/>
        </div>}
        <div className={b('Text')}>{this.props.value || '0'}</div>
      </div>;
  }
}
