export
const VALIDATE = {
  notEmpty: (val) => val !== undefined && val !== null && String(val).length > 0,
  numberOrNull: (val) => val ? isFinite(Number(val)) : true,
  timeOrNull: (val) => val ? (/^\d{1,2}:\d{2}$/u).test(String(val)) : true,
  oneOrZeroOrNull: (val) => val ? (/^[01]$/u).test(String(val)) : true,
  email: (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/u;
    return email && re.test(String(email).toLowerCase());
  },
  emailOrNull: (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/u;
    return email ? re.test(String(email).toLowerCase()) : true;
  },
  pass: (pass) => {
    const re = /^[a-zA-Z0-9]{5,}$/u;
    return pass !== null && re.test(String(pass));
  },
  phone: (phone) => {
    const re = /^[0-9]{5,}$/u;
    return phone && re.test(String(phone));
  }
};
