import React from 'react';
import block from 'bem-cn-lite';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';

import I18N from '../common/i18n';
import { Button } from '../common/components';
import { MapElements, AppSections } from '../common/enums';
import icons from '../common/icons';
import { reducer as mapReduce, actions as mapActions, OperationType } from './actions';
import { store } from '../../index';

const b = block('LeafletMapControls');

class LeafletMapControls extends React.PureComponent {
  static propTypes = {
    appSection: PropTypes.string.isRequired,
    sourceActions: PropTypes.object,
    sourceRecucer: PropTypes.object,
    mapState: PropTypes.object.isRequired,
    mapAction: PropTypes.object,
  }

  _changeMapDisplay = (mapElement) => {
    store.dispatch(this.props.sourceActions.changeMapElementsDisplay(mapElement));
    this.forceUpdate();
  };

  _getButtonStatus = (mapElement) => !store.getState()[this.props.sourceRecucer.name][mapElement];

  render() {
    return (
      <div className={b()}>
        <div className={b('Block')}>
          { this.props.mapState.operation === OperationType.SELECT_POINTS
            ? <Button
              key={'stopSelection'}
              onClick={this.props.mapAction.stopSelectPoints}
              className={b('Button')}
              leftHint={I18N.MAP_CONTROLS_UNSELECT}
              icon={icons.MAP_CONTROLS_UNSELECT}/>
            : <Button
              key={'startSelection'}
              onClick={this.props.mapAction.startSelectPoints}
              className={b('Button')}
              leftHint={I18N.MAP_CONTROLS_SELECT}
              icon={icons.MAP_CONTROLS_SELECT}/>}
          <hr className='vr'/>
          <Button
            toggledOff={this._getButtonStatus(MapElements.MAP)}
            onClick={() => this._changeMapDisplay(MapElements.MAP)}
            className={b('Button')}
            leftHint={I18N.MAP_VIEW_MAP}
            icon={icons.MAP_VIEW_MAP}/>
          {this.props.appSection === AppSections.MANIFEST && <Button
            toggledOff={this._getButtonStatus(MapElements.LINES)}
            onClick={() => this._changeMapDisplay(MapElements.LINES)}
            className={b('Button')}
            leftHint={I18N.MAP_VIEW_LINES}
            icon={icons.MAP_VIEW_LINES}/>}
          {this.props.appSection === AppSections.MANIFEST && <Button
            toggledOff={this._getButtonStatus(MapElements.PATH)}
            onClick={() => this._changeMapDisplay(MapElements.PATH)}
            className={b('Button')}
            leftHint={I18N.MAP_VIEW_PATH}
            icon={icons.MAP_VIEW_PATH}/>}
          {this.props.appSection === AppSections.MANIFEST && <Button
            toggledOff={this._getButtonStatus(MapElements.TRACKS)}
            onClick={() => this._changeMapDisplay(MapElements.TRACKS)}
            className={b('Button')}
            leftHint={I18N.MAP_VIEW_TRACKS}
            icon={icons.MAP_VIEW_TRACKS}/>}
          {this.props.appSection === AppSections.MANIFEST && <Button
            toggledOff={this._getButtonStatus(MapElements.POINTS_ASSIGNED)}
            onClick={() => this._changeMapDisplay(MapElements.POINTS_ASSIGNED)}
            className={b('Button')}
            leftHint={I18N.MAP_VIEW_ASSIGNED_POINTS}
            icon={icons.MAP_VIEW_LOCKED_POINTS}/>}
          <Button
            toggledOff={this._getButtonStatus(MapElements.POINTS)}
            onClick={() => this._changeMapDisplay(MapElements.POINTS)}
            className={b('Button')}
            leftHint={I18N.MAP_VIEW_POINTS}
            icon={icons.MAP_VIEW_POINTS}/>
          <Button
            toggledOff={this._getButtonStatus(MapElements.CLUSTERS)}
            onClick={() => this._changeMapDisplay(MapElements.CLUSTERS)}
            className={b('Button')}
            leftHint={I18N.MAP_VIEW_CLUSTERS}
            icon={icons.MAP_VIEW_CLUSTERS}/>
          <Button
            toggledOff={this._getButtonStatus(MapElements.ZONES)}
            onClick={() => this._changeMapDisplay(MapElements.ZONES)}
            className={b('Button')}
            leftHint={I18N.MAP_VIEW_ZONES}
            icon={icons.MAP_VIEW_ZONES}/>
        </div>
      </div>
    );
  }
}

export default
connect(
  (state) => ({
    mapState: state[mapReduce.name],
  }),
  (dispatch) => ({
    "mapAction": bindActionCreators(mapActions, dispatch),
  })
)(LeafletMapControls);
