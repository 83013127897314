import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';
import { ButtonKinds, Sections } from '../enums';
import { toast } from 'react-toastify';
import * as ExcelJs from 'exceljs/dist/exceljs.min.js';
import { connect } from 'react-redux';

import Button from './Button';
import { 
	client, 
	store 
} from '../../..';
import { fields as courierFields } from '../../sections/couriers/CourierForm';
import { importfields as pointFields } from '../../sections/points/PointForm';
import { defaultPoint } from '../../sections/points/PointCreate';
import I18N from '../i18n';
import { decodeTime, decodeTimeUTC } from '../../../base/utils';
import { reducer as authReducer } from '../../auth/actions';
import { queries as groupQueries } from '../../sections/groups/queries';

const b = block('Button');
const IMPORT_TIMEOUT = 0 * 60 * 1000;

function ImportError(message, lineNumber, fieldName) {
	this.name = 'ImportError';
	this.lineNumber = lineNumber;
	this.fieldName = fieldName;
	this.message = message
		+ (this.lineNumber ? ` ${I18N.NOTIFY_IMPORT_POINTS_ERROR_LINE} ${this.lineNumber}` : '')
		+ (this.fieldName ? ` ${I18N.NOTIFY_IMPORT_POINTS_ERROR_FIELD} ${this.fieldName}` : '');
	this.stack = (new Error()).stack;
}
ImportError.prototype = Object.create(Error.prototype);
ImportError.prototype.constructor = ImportError;

class ImmidiateUploadButton extends React.PureComponent {
	static propTypes = {
		className: PropTypes.string,
		icon: PropTypes.string,
		leftHint: PropTypes.string,
		rightHint: PropTypes.string,
		disabled: PropTypes.bool,
		clickOnce: PropTypes.bool,
		route: PropTypes.func,
		fieldsConvert: PropTypes.arrayOf(PropTypes.object.isRequired),
		uploadQuery: PropTypes.object.isRequired,
		manifestId: PropTypes.number.isRequired,
		children: PropTypes.any,
		sourceActions: PropTypes.object,
		section: PropTypes.string.isRequired,
		kind: PropTypes.oneOf(Object.keys(ButtonKinds)),
		extraReqs: PropTypes.object,
		groupId: PropTypes.number
	};

	constructor() {
		super();
		this.selectButton = null;
		this.state = {
			disabled: false,
		};
	}

	onClick = () => {
		this.selectButton && this.selectButton.click();
		return false;
	}

	_getFields = (section) => {
		switch (section) {
			case Sections.POINTS:
				return pointFields;
			case Sections.COURIERS:
				return courierFields;
			default:
				throw new Error('Cant find section import fields');
		}
	}

	_sendFileData = (elements) => {
			return client.mutate({
				mutation: this.props.uploadQuery,
				variables: {
					'manifest_id': this.props.manifestId,
					'data': elements,
				}
			})
			.then((res) => {
				toast.success(`${this.props.children}. ${I18N.NOTIFY_SUCCESS}`);
				store.dispatch(this.props.sourceActions.refetchData());
				return res.data.result
			})
			.catch((res) => {
				toast.error(`${this.props.children}. ${I18N.NOTIFY_ERROR}`);
				return [];
			});
	}

	_parseSheetData = (fileData) => {
		const workbook = new ExcelJs.Workbook();
		return workbook.xlsx.load(fileData)
		.then(() => {
			const worksheet = workbook.getWorksheet(1);
			let fieldNames = [];
			const extraFieldsMetaData = this.props.extraReqs
				&& this.props.extraReqs[this.props.section]
				&& this.props.extraReqs[this.props.section].fields;
			let rows = [];
			let items = [];

			worksheet.eachRow((row, rowNumber) => {
				row.eachCell({ includeEmpty: true }, (cell) => {
					if(cell.type === 4){
						cell.value = decodeTimeUTC(cell.value);
					} else {
						cell.value = cell.text;
					}
				});
				row.values.shift();
				if (rowNumber === 3){
					console.log(`Finderow names - '${row.values}'`);
					fieldNames.push(row.values);
				} else if (rowNumber > 3 && row.values.length){
					console.log(`Find row values - '${row.values}'`);
					rows.push(row.values);
				}
			});

			for (const row of rows) {
				let item = {};

				fieldNames[0].forEach((fieldName, i) => {
					const field = this._getFields(this.props.section).find((f) => f.name === fieldName);
					const extraField = !field && extraFieldsMetaData && extraFieldsMetaData.find((f) => f.name === fieldName);
					if (field) {
						if (field.validate && !field.validate(row[i])){
							throw new ImportError(I18N.NOTIFY_IMPORT_POINTS_ERROR_DATA, i, fieldName);
						}

						if(row[i]){
							let classInstance
							console.log(`field.name=${field.name}`)
							if([
								'curbside','mandatory','extra_reqs','vehicle_type',
								'garage_id','not_returning','driver_id',
								'current_point_id','longhaul','single_run',
								'garage', 'depot', 'outoforder', 'vehicle_flags'
							].indexOf(field.name) !== -1){
								classInstance = new field.inputClass()
							} else {
								classInstance = field.inputClass();
							}
							const fieldFromView = classInstance.fromView || classInstance.props.fromView;
							console.log(fieldFromView)
							item[fieldName] = fieldFromView(row[i]);
						} else if (defaultPoint[fieldName] !== undefined) {
							item[fieldName] = defaultPoint[fieldName];
						}
					} else if (extraField) {
						if (row[i]) {
							item['extra_reqs'] = item['extra_reqs'] || {};
							item['extra_reqs'][fieldName] = row[i];
						}
					}
				});
				if (item['lat'] && item['lon']){
					item['lat_lon'] = [Number(item['lat']), Number(item['lon'])];
				}
				items.push(item);
			}
			if (!items.length){
				throw new ImportError(I18N.NOTIFY_IMPORT_POINTS_ERROR_EMPTY);
			}
			return items;
		})
		.catch((e) => {
			toast.error(e.message);
			this.setState({ disabled: false });
		})
	}

	_updateGroupImportedTo = (groupId, newPoints) => client.query({
		query: groupQueries.GET_ONE,
		variables: { 'id': groupId }
	})
    .then(({ data: { result: group }}) => {
      	const items = [...group.items, ...newPoints];

		return client.mutate({
			mutation: groupQueries.UPDATE,
			update: (proxy) => {
				const vars = { 'manifest_id': this.props.manifestId, crutch: "groups" };
				const data = proxy.readQuery({ query: groupQueries.LIST, variables: vars });
				const indexToUpdate = data.result.findIndex((item) => item.id === groupId );
				data.result[indexToUpdate]['items_count'] = items.length;
				proxy.writeQuery({ query: groupQueries.LIST, variables: vars, data });
			},
			variables: {
				'id': groupId,
				'data': {
					'items': items
				},
			}
		});
    })
    .then(() => {
		toast.success(`${I18N.ITEM_GROUP_IMPORT}. ${I18N.NOTIFY_SUCCESS}`)
		return 
	})
    .catch(() => {
		toast.error(`${I18N.ITEM_GROUP_IMPORT}. ${I18N.NOTIFY_ERROR}`)
		return
	});

	// после выбора файла - действия
	_onFileSelected = (ev) => {
		this.setState({ disabled: true });
		const file = ev.target.files[0];
		const reader = new FileReader();

		reader.onload = async (e) => {
			const time = localStorage.getItem('import_timeout');

			if (!time || (time && time <= Date.now())) {
				const data = await this._parseSheetData(e.target.result);
				const result = await this._sendFileData(data);

				if (this.props.groupId) {
					const pointIds = result.map((p) => p.id);
					await this._updateGroupImportedTo(this.props.groupId, pointIds);
				}
				localStorage.setItem('import_timeout', Date.now() + IMPORT_TIMEOUT);
			} else {
				toast.warn(`${I18N.NOTIFY_IMPORT_TIMEOUT} ${decodeTime(Number(time))}` );
			}
			this.setState({ disabled: false });
		};
		reader.readAsArrayBuffer(file);
	}

	render() {
		const id = `file_select_${Math.round(Math.random() * 10000)}`;
		return(
			 <React.Fragment>
				<Button {...this.props} onClick={this.onClick} disabled={this.props.disabled || this.state.disabled}/>
				<input
					id={id}
					ref={(ref) => { this.selectButton = ref; } }
					className={b('FileSelect')}
					type="file"
					onChange={this._onFileSelected}
					onClick={() => { this.selectButton.value = null; }}
				/>
			</React.Fragment>
		)
	}
}

export default connect((state) => ({
		extraReqs: state[authReducer.name].user
		&& state[authReducer.name].user['ui_info']
		&& state[authReducer.name].user['ui_info'].extraFields
	})
)(ImmidiateUploadButton)
