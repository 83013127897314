import React from 'react';
import block from 'bem-cn-lite';
import { 
  Sections, 
  // AppSections, 
  RouteCode 
} from '../common/enums';
import _ from 'lodash';
import { DivIcon, Point } from 'leaflet';
import { connect } from 'react-redux';

import MarkerClusterGroup from 'react-leaflet-markercluster';
import LeafletMapMarker from './LeafletMapMarker';
import { PropTypes } from 'prop-types';
import { 
  routeTo, 
  store 
} from '../..';
import { getSectionLinkBody } from '../../base/utils';
import { OperationType } from './actions';
import { actions as mapActions } from '../map/actions';
import {reducer as updateStateReducer} from '../common/updateStateReducer';


const CLUSTER_RADIUS = 30;

const b = block('LeafletMap');

class LeafletMapPointsLayer extends React.Component {
  static propTypes = {
    manifestId: PropTypes.number,
    selectedPoints: PropTypes.arrayOf(PropTypes.number).isRequired,
    selectedCourierId: PropTypes.number,
    editingPointId: PropTypes.number,
    manifestLoaded: PropTypes.bool,
    mapPoints: PropTypes.object,
    waybillInfo: PropTypes.object,
    showClusters: PropTypes.bool,
    showRegularPoints: PropTypes.bool,
    showAssignedPoints: PropTypes.bool,
    operation: PropTypes.string,
    toggleSelectedPoint: PropTypes.func
  }

  _linkToThisPoint = (pointId) => pointId === this.props.editingPointId || routeTo(
    `${getSectionLinkBody(this.props.manifestId)}${Sections.POINTS}/${pointId}`
  )

  onClick = (pointId) => {
    if (this.props.operation === OperationType.CONNECT_POINTS)
      store.dispatch(mapActions.toggleSelectedPoint(pointId));
    else
      this._linkToThisPoint(pointId);
  }

  _renderClusterIcon = (cluster) => {
    const childCount = cluster.getChildCount();

    return new DivIcon({
      html: `
      <div class='${b('ClusterBody')}'>
        +${childCount}
      </div>`,
      className: `${b('Cluster')} `,
      iconSize: new Point(32, 32)
    });
  }

  _isPointFree = (pId) => !this.props.waybillInfo[pId]
      || !this.props.waybillInfo[pId].waybillCourierId
      || (this.props.waybillInfo[pId].pointCourierId
        && this.props.waybillInfo[pId].waybillCourierId !== this.props.waybillInfo[pId].pointCourierId)
      || this.props.waybillInfo[pId].depot
      || this.props.waybillInfo[pId].garage
      || false;

  _isPointMissed = (pId) => {
    if (!this.props.manifestLoaded || !this.props.waybillInfo[pId])
      return false;
    if (this.props.waybillInfo[pId].routeCodes[0] === "ERR")
      return true;
    return false;
  }

  _isPointHasWarning = (pId) => {
    if (!this.props.manifestLoaded || !this.props.waybillInfo[pId])
      return false;

    return Boolean(this.props.waybillInfo[pId].errorCodes.length);
  }

  _renderMarkers = (points) => {
    const selectedPoints = new Set(this.props.selectedPoints || []);

    const res = [];
    for (const p of points) {
      if (!p.coords || !p.coords[0] || !p.coords[1])
        continue;
      res.push(<LeafletMapMarker
        isEditing={p.id === this.props.editingPointId}
        isSelected={selectedPoints.has(p.id)}
        onClick={() => this.onClick(p.id)}
        isDimmed={this.props.selectedCourierId
          && !(p.depot || p.garage)
          && this.props.waybillInfo[p.id]
          && this.props.selectedCourierId !== this.props.waybillInfo[p.id].waybillCourierId}
        isLoaded={this.props.manifestLoaded}
        isMissed={this._isPointMissed(p.id)}
        hasWarning={this._isPointHasWarning(p.id)}
        isFree={this._isPointFree(p.id)}
        isNightStay={this.props.waybillInfo[p.id]
          && this.props.waybillInfo[p.id].routeCodes.includes(RouteCode.LH)}
        status={this.props.waybillInfo[p.id]
          && this.props.waybillInfo[p.id].status}
        manifestId={this.props.manifestId}
        key={p.id}
        point={{...p, ...this.props.waybillInfo[p.id], assignedCourierId: (this.props.courierAssignments[p.id] || {}).assigned_courier_id}}
      />);
    }
    return res;
  }

  render() {
    if (_.isEmpty(this.props.mapPoints))
      return null;

    const specialPoints = [];
    const assignedPoints = [];
    const regularPoints = [];

    for (const p of _.values(this.props.mapPoints)) {
      if (p.depot || p.garage)
        specialPoints.push(p);
      else if (p.pointCourierId)
        assignedPoints.push(p);
      else
        regularPoints.push(p);
    }

    return [
      <MarkerClusterGroup
        animateAddingMarkers={true}
        key={`${this.props.manifestLoaded}_${this.props.showClusters}`}
        showCoverageOnHover={false}
        maxClusterRadius={this.props.showClusters ? CLUSTER_RADIUS : 0}
        iconCreateFunction={this._renderClusterIcon}
      >
        {this.props.showRegularPoints && this._renderMarkers(regularPoints)}
        {this.props.showAssignedPoints && this.props.showRegularPoints && this._renderMarkers(assignedPoints)}
      </MarkerClusterGroup>,
      this._renderMarkers(specialPoints)
    ];
  }
}

export default
connect(
  (state) => ({
    courierAssignments: state[updateStateReducer.name].courierAssignments,
  })
)(LeafletMapPointsLayer);
