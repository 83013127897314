import { makeReducer } from '../../base/reducer';


const init = {
  open: false,
  content: null,
  contentProps: null
};

export const reducer = makeReducer('modal', init);

export const actions = {
  showModal: reducer.makeAction(
    (content, contentProps) => ({content, contentProps}),
    (state, action) => ({...state,
      open: true,
      content: action.content,
      contentProps: action.contentProps
    }),
    'SHOW_MODAL'
  ),

  closeModal: reducer.makeAction(
    null,
    (state, action) => ({
      open: false,
      content: null,
      contentProps: null
    }),
    'CLOSE_MODAL'
  ),
};
