
import React from 'react';
import { PropTypes } from 'prop-types';
import block from 'bem-cn-lite';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { toast } from 'react-toastify';
import _ from 'lodash';

import I18N from '../../common/i18n';
import DataBadge from '../common/DataBadge';
import { 
  // AppSections, 
  Sections 
} from '../../common/enums';
import { reducer as mapReducer, actions as mapActions, OperationType } from '../../map/actions';
import { routeTo } from '../../..';
import { LookupInput } from '../../inputs';
import { CRUD as courierCRUD } from '../couriers/queries';
import { actions as manifestActions } from '../../manifest/actions';
import { withApolloAsyncLoad, getSectionLinkBody } from './../../../base/utils';
import { CourierListItem } from '../couriers';
import { Button, TextLink } from '../../common/components';
import { loadPointsAsync, loadWaybillsAsync, loadCouriersAsync } from '../../manifest/utils';
import WaybillPointListItem from '../points/WaybillPointListItem';
import SectionListPreloaded from '../common/SectionListPreloaded';
import { ITEMS_PER_PAGE } from '..';

const b = block('WaybillEdit');

class WaybillEdit extends React.PureComponent {
  static title = I18N.EDIT_WAYBILLS_TITLE

  static propTypes = {
    manifestId: PropTypes.number.isRequired,
    updateSelectedPoints: PropTypes.func,
    refetchManifestData: PropTypes.func,
    startConnectPoints: PropTypes.func,
    stopSelectPoints: PropTypes.func,
    route: PropTypes.func,
    itemId: PropTypes.number,
    selectedPoints: PropTypes.arrayOf(PropTypes.number),
    updateRoute: PropTypes.func,
    clearRoutes: PropTypes.func,
    clearRouteByCourier: PropTypes.func,
    editedRoutes: PropTypes.object,
    isEditing: PropTypes.bool,
  }

  constructor(props) {
    super(props);
    this.state = {
      selectedPointItems: [],
      editedCouriers: [],
      freeCouriers: [],
    };
  }

  componentDidMount() {
    this.props.startConnectPoints();

    if (!this.props.itemId) {
      loadCouriersAsync(this.props.manifestId)
        .then((couriers) => {
          const firstOne = couriers[0];
          if (firstOne) {
            this._updateEditedCouriersList();
            routeTo(`${getSectionLinkBody(this.props.manifestId)}${Sections.EDIT_WAYBILL}/${firstOne.id}`);
          }
          else {
            toast.error(`${I18N.EDIT_WAYBILLS_NO_COURIERS_ERROR}`);
            routeTo(`${getSectionLinkBody(this.props.manifestId)}`);
          }
        });
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isEditing && this.props.isEditing) {
      this._updateEditedCouriersList();
      setTimeout(this._loadCourierWaybill, 10); // Avoiding difficult sync with drawing layer behavior
    }
    else if (this.props.isEditing) {
      if (!_.isEqual(prevProps.selectedPoints, this.props.selectedPoints))
        this._onPointsUpdated();

      if (prevProps.itemId !== this.props.itemId)
        this._loadCourierWaybill();

      if (_.get(prevProps, `editedRoutes[${this.props.manifestId}].length`, 0)
      !== _.get(this.props, `editedRoutes[${this.props.manifestId}].length`, 0))
        this._updateEditedCouriersList();
    }
  }

  componentWillUnmount() {
    this.props.stopSelectPoints();
  }

  _onPointsUpdated = () => {
    loadWaybillsAsync(this.props.manifestId)
      .then((waybills) => {
        loadPointsAsync(this.props.manifestId)
          .then((points) => {
            this.setState({
              selectedPointItems: this.props.selectedPoints
                .map((id) => points.find((p) => p.id === id))
                .filter((i) => i)
                .map((p) => ({
                  ...p,
                  'points_served': this.props.selectedPoints.findIndex((id) => id === p.id),
                  'route_code': 'RG',
                  'courier_id': this.props.itemId,
                }))
            });
          });
      });
  }

  _loadCourierWaybill = () => {
    const editedRoute = _.get(this.props, `editedRoutes[${this.props.manifestId}]`, [])
      .find((r) => r.agent === this.props.itemId);

    if (editedRoute)
      this.props.updateSelectedPoints(editedRoute.points.map((p) => p[0]));
    else
      this._loadCalculatedWaybill();
  }

  _loadCalculatedWaybill = () => {
    loadWaybillsAsync(this.props.manifestId)
      .then((waybills) => {
        loadPointsAsync(this.props.manifestId)
          .then((points) => {
            if (points.length && waybills.length) {
              const selectedCourierPoints = waybills
                .filter((w) => w['courier_id'] === this.props.itemId)
                .filter((w, i, arr) => !arr[i + 1] || arr[i + 1]['point_id'] !== w['point_id'])
                .map((w) => points.find((p) => p['id'] === w['point_id'])['id']);
              this.props.updateSelectedPoints(selectedCourierPoints);
            }
            else
              this.props.updateSelectedPoints([]);
          });
      });
  }

  _updateRoute = () => {
    if (this.props.selectedPoints.length) {
      loadWaybillsAsync(this.props.manifestId)
        .then((waybills) => {
          loadPointsAsync(this.props.manifestId)
            .then((points) => {
              const pointsSpecial = points.reduce((obj, p) => ({...obj, [p.id]: p.garage || p.depot }), {});
              const otherRoutes = (this.props.editedRoutes[this.props.manifestId] || [])
                .filter((r) => r.agent !== this.props.itemId);

              const editedRoute = {
                agent: this.props.itemId,
                points: this.state.selectedPointItems.map((p) => ([p.id, 'L;U']))
              };

              const otherEditedPoints = otherRoutes
                .map((r) => r.points.map((p) => p[0]))
                .flat();
              const busyPoints = new Set([...this.props.selectedPoints, ...otherEditedPoints]);
              const waybillsFiltered = waybills
                .filter((w) => pointsSpecial[w['point_id']] || !busyPoints.has(w['point_id']));
              const waybillsByCourier = _.groupBy(waybillsFiltered, 'courier_id');

              const fullRoutes = Object.keys(waybillsByCourier)
                .filter((k) => Number(k) !== this.props.itemId)
                .filter((k) => editedRoute.agent !== Number(k) && !otherRoutes.find((r) => r.agent === Number(k)))
                .map((k) => ({
                  agent: Number(k),
                  points: waybillsByCourier[k]
                    .filter((w, j, arr) => !arr[j + 1] || arr[j + 1]['point_id'] !== w['point_id'])
                    .map((w) => ([w['point_id'], 'L;U']))
                }))
                .concat([...otherRoutes, editedRoute]);

              this.props.updateRoute(this.props.manifestId, editedRoute, fullRoutes);
              toast.success(`${I18N.EDIT_WAYBILLS_SAVE_WAYBILL}. ${I18N.NOTIFY_SUCCESS}`);
            });
        });
    }
  }

  _selectNewCourier = (name, courierId) => courierId
    && routeTo(`${getSectionLinkBody(this.props.manifestId)}${Sections.EDIT_WAYBILL}/${courierId}`);

  _renderCourierItem = (courier) => <CourierListItem
    manifestId={this.props.manifestId}
    item={courier}
  />;

  _clearRouteByCourier = () => {
    this.props.clearRouteByCourier(this.props.manifestId, this.props.itemId);
    this._loadCalculatedWaybill();
    toast.success(`${I18N.EDIT_WAYBILLS_RESET_CURRENT}. ${I18N.NOTIFY_SUCCESS}`);
  }

  _clearRoutes = () => {
    this.props.clearRoutes(this.props.manifestId);
    this._loadCalculatedWaybill();
    toast.success(`${I18N.EDIT_WAYBILLS_RESET_ALL}. ${I18N.NOTIFY_SUCCESS}`);
  }

  _updateEditedCouriersList = () => {
    loadCouriersAsync(this.props.manifestId)
      .then((couriers) => {
        if (this.props.editedRoutes[this.props.manifestId]) {
          const editedCouriers = this.props.editedRoutes[this.props.manifestId]
            .map((r) => ({
              value: r.agent,
              caption: couriers.find((c) => c.id === r.agent).name
            }));
          const freeCouriers = couriers
            .filter((c) => !editedCouriers.find((ec) => ec.value === c.id))
            .map((c) => ({
              value: c.id,
              caption: c.name
            }));
          this.setState({
            editedCouriers: editedCouriers,
            freeCouriers: freeCouriers
          });
        }
        else {
          const freeCouriers = couriers
            .map((c) => ({
              value: c.id,
              caption: c.name
            }));
          this.setState({
            editedCouriers: [],
            freeCouriers: freeCouriers
          });
        }
      });
  }

  render() {
    return <div className={b()}>
      <div className={b('Head')}>
        <DataBadge
          caption={I18N.EDIT_WAYBILLS_COURIERS_EDITED}
          value={this.state.editedCouriers.length}
        />
        <DataBadge
          caption={I18N.EDIT_WAYBILLS_COURIERS_FREE}
          value={this.state.freeCouriers.length}
        />
        <LookupInput
          valueField={'id'}
          captionField={'name'}
          name={'courier'}
          items={this.state.editedCouriers}
          onChangeValue={this._selectNewCourier}
          value={this.props.itemId}
          hasNull={true}
        />
        <LookupInput
          valueField={'id'}
          captionField={'name'}
          name={'courier'}
          items={this.state.freeCouriers}
          onChangeValue={this._selectNewCourier}
          value={this.props.itemId}
          hasNull={true}
        />
      </div>
      {courierCRUD.GET_ONE(
        {id: this.props.itemId},
        (result) => withApolloAsyncLoad(this._renderCourierItem, result)
      )}
      <div className={b('Controls')}>
        <Button onClick={this._updateRoute}>{I18N.EDIT_WAYBILLS_SAVE_WAYBILL}</Button>
        <TextLink
          onClick={this._clearRouteByCourier}>
          {I18N.EDIT_WAYBILLS_RESET_CURRENT}
        </TextLink>
        <TextLink
          onClick={this._clearRoutes}>
          {I18N.EDIT_WAYBILLS_RESET_ALL}
        </TextLink>
      </div>
      <hr/>
      <h2 className={b('WaybillTitle')}>{I18N.POINTS_INFO_WAYBILLS}</h2>
      <SectionListPreloaded
        manifestId={this.props.manifestId}
        items={this.state.selectedPointItems}
        itemElement={WaybillPointListItem}
        variables={{
          'limit': ITEMS_PER_PAGE,
          'offset': 0
        }}
      />
    </div>;
  }
}

export default
connect(
  (state) => ({
    selectedPoints: state[mapReducer.name].selectedPoints,
    editedRoutes: state[mapReducer.name].editedRoutes,
    isEditing: state[mapReducer.name].operation === OperationType.CONNECT_POINTS,
  }),
  (dispatch) => ({
    updateSelectedPoints: (points) => dispatch(mapActions.updateSelectedPoints(points)),
    refetchManifestData: () => dispatch(manifestActions.refetchData()),
    route: (link) => dispatch(push(link)),
    updateRoute: (manifestId, editedRoute, fullRoutes) => dispatch(mapActions.updateRoute(manifestId, editedRoute, fullRoutes)),
    clearRoutes: (manifestId) => dispatch(mapActions.clearRoutes(manifestId)),
    clearRouteByCourier: (manifestId, courierId) => dispatch(mapActions.clearRouteByCourier(manifestId, courierId)),
    startConnectPoints: () => dispatch(mapActions.startConnectPoints()),
    stopSelectPoints: () => dispatch(mapActions.stopSelectPoints()),
  })
)(WaybillEdit);
