import React from 'react';
import fileDownload from 'js-file-download';
import * as ExcelJs from 'exceljs/dist/exceljs.min.js';
import _ from 'lodash';
import unstream from 'unstream';
import { toast } from 'react-toastify';
import moment from 'moment'

import LoadingIndicator from './../modules/common/components/LoadingIndicator';
import ErrorIndicator from './../modules/common/components/ErrorIndicator';
import { ENDPOINTS, API_ROOT } from './api';
import polyUtil from '@mapbox/polyline';
import { SvgColors, AppSections } from '../modules/common/enums';
import I18N from '../modules/common/i18n';

export
const closePolygon = (coords) => {
  if (coords.length && coords.length >= 3) {
    coords.forEach((coord, i) => {
      if (isNaN(coord[0]) || isNaN(coord[1]))
        coords.splice(i, 1);
    });

    if (_.isEqual(coords[0], coords[coords.length - 1]))
      return coords;
    return [...coords, coords[0]];
  }
  return [];
};

export
const decodeGeometry = (geometry) => {
  if (_.isEmpty(geometry))
    return [];
  const coords = polyUtil.decode(geometry);
  return coords.map((pair) => pair.reverse());
};

/* eslint-disable */
export
const decodeTrackData = (track) => {
  let cur = 0,
    final = false,
    data = [],
    j = 0,
    v = 0;

  const result = {
    coords: [],
    timestamps: [],
    gpsFlags: [],
  };

  for (const char of track) {
    v = char.charCodeAt() - 63;
    final = ((v & 32) === 0);
    v &= 31;
    cur |= (v << (j * 5));
    j += 1;
    if (final) {
      if (cur & 1 === 1)
        cur = ~cur;
        data.push(cur >> 1);
      j = 0;
      cur = 0;
    }
  }

  const pointNumber = data.length / 4;
  let lat = 0,
    lon = 0,
    timestamp = 0;

  for (let i = 0; i < pointNumber; i++) {
    lat += data.splice(0, 1)[0] / 1e5;
    lon += data.splice(0, 1)[0] / 1e5;
    timestamp += data.splice(0, 1)[0]; // Timestamp negative value problem exists!!!
    const gpsFlag = Boolean(data.splice(0, 1)[0]);

    result.coords.push([lat, lon]);
    result.timestamps.push(timestamp);
    result.gpsFlags.push(gpsFlag);
  }

  return result;
}
/* eslint-enable */

export
const getFile = (filePath, fileName) => {
  fetch(
    filePath, {
      method: 'GET',
      headers: {
        'Authorization': `JWT ${localStorage.getItem('token')}`,
        'Accept': '*',
      },
    })
    .then((res) => res.ok && res.blob())
    .then((fileData) => fileData && fileDownload(fileData, fileName))
    .catch((e) => console.log('Ошибка загрузки: ', e.message));
};

export
const metersToKilometers = (meters) => (Number(meters) / 1000).toFixed(2);

export
const getData = (filePath) => {
  const p = fetch(
    filePath, {
      method: 'GET',
      headers: {
        'Authorization': `JWT ${localStorage.getItem('token')}`,
        'Accept': '*',
      },
    })
    .then((res) => res.ok && res.arrayBuffer())
    .catch((e) => console.log('Ошибка загрузки: ', e.message));
  return p;
};


export
const queryApiUrl = async (url, query) => {
  const q = {...query};
  q.headers = {...q.headers, 'Authorization': `JWT ${localStorage.getItem('token')}`};
  const response = await fetch(`${API_ROOT}/${url}`, q);
  return response.text();
};

export
const queryApiMethod = async (query) => {
  const r = await queryApiUrl('graphql', {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(query)
  });
  const res = await JSON.parse(r);
  if (res.data)
    return res.data.result;
  throw new Error(res.message);
};

export
const nullOrObject = (obj) => {
  if (!obj)
    return null;

  if (obj.constructor === Object && Object.keys(obj).length === 0)
    return null;

  if (obj.constructor === Array && obj.length === 0)
    return null;

  return obj;
};

export
const encodeDate = (value) => {
  try {
    const dmy = value.split('-');
    return new Date(Date.UTC(
      dmy[0],
      dmy[1] - 1,
      dmy[2]
    )).toUTCString();
  }
  catch (e) {
    new Date().toUTCString();
  }
};

export
const getWaybillStatus = (status) => {
  let value = '';
  value = status === 1 ? I18N.WAYBILLS_STATUS_CODE_1 : value;
  value = status === 2 ? I18N.WAYBILLS_STATUS_CODE_2 : value;
  value = status === 5 ? I18N.WAYBILLS_STATUS_CODE_5 : value;
  return value;
};

export
const decodeTimeUTC = (date) => {
  if (!date)
    return '';
  const nDate = new Date(date);
  const h = nDate.getUTCHours();
  const m = `0${nDate.getUTCMinutes()}`.slice(-2);
  return `${h}:${m}`;
};

export
const decodeTime = (date) => {
  if (!date)
    return '';
  const nDate = new Date(date);
  const h = nDate.getHours();
  const m = `0${nDate.getMinutes()}`.slice(-2);
  return `${h}:${m}`;
};

export
const decodeDate = (date) => {
  const nDate = new Date(date);
  const d = `0${nDate.getDate()}`.slice(-2);
  const m = `0${nDate.getMonth() + 1}`.slice(-2);
  const y = `000${nDate.getFullYear()}`.slice(-4);
  return `${y}-${m}-${d}`;
};

export
const decodeDateRU = (date) => {
  const nDate = new Date(date);
  const d = `0${nDate.getDate()}`.slice(-2);
  const m = `0${nDate.getMonth() + 1}`.slice(-2);
  const y = `000${nDate.getFullYear()}`.slice(-4);
  return `${d}.${m}.${y}`;
};

export const decimalHoursToTime = (hours) => {
	if (hours === null)
		return '';
	const h = Math.floor(hours);
	const m = Math.round(hours % 1 * 60);
	return `${h < 10 ? '0' : ''}${h}:${m < 10 ? '0' : ''}${m}`;
}

export const timeToDecimalHours = (time) => {
	const [h, m] = time.split(":");
	if (isNaN(h) || isNaN(m))
    return null;
  let res = Number(h) + Number(m) / 60
	return parseFloat(res.toFixed(2))
}

// to timepicker
export const toTimepicker = (hours) => {
	let curt = moment().utc()
  if(!hours){
    curt = null
	} else {
		const h = Math.floor(hours);
		const m = Math.round(hours % 1 * 60);
		curt.set('hour', h);
		curt.set('minute', m);
  }
	return curt
}

// from timpicker
export const fromTimepicker = (hour) => {
	if(hour){
    let res
    switch(typeof hour){
      case 'string':
        res = timeToDecimalHours(hour)
      break
      case 'object':
        let h = hour.hour()
        let m = hour.minute()
        let md = parseFloat((m/60).toFixed(2))
        res = h + md
      break
      default:
        res = 0.0
      break
    }
		return res
	} else {
		return 0.0
	}
}


const numColor = 100;

export const getPaletteColorClass = (numOrSvgClass) => {
	let className = 'PaletteColor_Default';
	if (Number(numOrSvgClass)) {
		className = `PaletteColor_${numOrSvgClass % numColor}`;
	}
	else {
		className = numOrSvgClass === SvgColors.RED ? 'PaletteColor_Red' : className;
		className = numOrSvgClass === SvgColors.DARK ? 'PaletteColor_Dark' : className;
	}
	return className;
};

export
const withApolloAsyncLoad = (onReady, result, ...args) => {
	if (result.loading)
    return <LoadingIndicator key={'loadingIndicator'} />;
	else if (result.error)
		return <ErrorIndicator key={'errorIndicator'}/>;
	else if (result.data.result)
		return onReady(result.data.result, ...args);
	else if (result.data)
		return onReady([], ...args);
	return <ErrorIndicator key={'errorIndicator'}/>;
};

export const overlapArray = (arr1, arr2) => {
  const resultArray = [];

  if (arr1)
    arr1.forEach((el, i) => {
      resultArray[i] = arr2[i] || arr1[i];
    });
  else
    resultArray.concat(arr2);
  return resultArray;
};

export
const convertParamsToNumber = (params) => {
  for (const key of Object.keys(params)) {
    params[key] = isNaN(params[key]) ? params[key] : Number(params[key]);
  }
  return params;
};

export
const correctBreakTime = (state) => {
  if (state['work_end'].value) {
    if (!state['break_start'].value || state['break_start'].value > state['work_end'].value)
      state['break_start'].value = state['work_end'].value;
    if (!state['break_end'].value || state['break_end'].value > state['work_end'].value)
      state['break_end'].value = state['work_end'].value;
  }
};

export
const getYandexGeocodeOptions = (value) => (
  fetch(`https://geocode-maps.yandex.ru/1.x/?format=json&geocode=${value}`)
    .then((response) => {
      if (response.ok) {
        return Promise.resolve(response);
      }
      return Promise.reject(new Error('Failed to load yandex data'));
    })
    .then((response) => response.json()) // parse response as JSON
    .then((data) => {
      const options = data.response.GeoObjectCollection.featureMember
        .map((yaObj) => ({
          caption: `${yaObj.GeoObject.description} | ${yaObj.GeoObject.name}`,
          value: (yaObj.GeoObject.Point.pos).split(' ').reverse().map(Number)
        }));
      return Promise.resolve(options);
    })
    .catch((error) => Promise.reject(new Error(error.message)))
);

export
const getGeocodeOptions = (value) => (
  fetch(`${ENDPOINTS.geocodeHint}?q=${value}`, {
    method: 'GET',
    headers: {
      'Authorization': `JWT ${localStorage.getItem('token')}`,
      'Accept': '*',
    },
  })
    .then((response) => {
      if (response.ok) {
        return Promise.resolve(response.json());
      }
      return Promise.reject(new Error('Failed to load geocode options'));
    })
    .then((response) => {
      let options = [];
      if (response.data && response.data.length)
        options = _.uniqBy(response.data, 'name')
          .slice(0, 8)
          .map(({name, coords}) => ({caption: name, value: coords, key: coords}));
      return Promise.resolve(options);
    })
    .catch((error) => Promise.reject(new Error(error.message)))
);

export
const getGeocodeItem = (value) => (
  fetch(`${ENDPOINTS.geocode}?q=${value}`, {
    method: 'GET',
    headers: {
      'Authorization': `JWT ${localStorage.getItem('token')}`,
      'Accept': '*',
    },
  })
    .then((response) => {
      if (response.ok) {
        return Promise.resolve(response.json());
      }
      return Promise.reject(new Error('Failed to load geocode item'));
    })
    .then((response) => {
      if (response.data)
        return Promise.resolve({caption: response.data.name, coords: response.data.coords});
      return null;
    })
    .catch((error) => Promise.reject(new Error(error.message)))
);

class CanceledError extends Error {
  constructor() {
    super('Promise canceled');
    this.name = 'CanceledError';
    this.isCanceled = true;
  }
}

export
const makeCancelable = (promise) => {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      (val) => hasCanceled_ ? reject(new CanceledError()) : resolve(val),
      (error) => hasCanceled_ ? reject(new CanceledError()) : reject(error)
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    },
  };
};

export
const getSectionLinkBody = (manifestId) => '/'
  + `${manifestId && manifestId > 0 ? AppSections.MANIFEST : AppSections.CATALOG}`
  + `/${manifestId && manifestId > 0 ? `${manifestId}/` : ''}`;

export
const singleLineNoSpaces = (strings, ...values) => {
  // substitution vars first.
  let output = '';
  for (let i = 0; i < values.length; i++) {
    output += strings[i] + values[i];
  }
  output += strings[values.length];

  // Split on newlines.
  const lines = output.split(/(?:\r\n|\n|\r)/u);

  // Rip out the leading whitespace.
  return lines.map((line) => line.replace(/^\s+/ugm, '')).join('').trim();
};

export
const lookupFromView = (val) => {
  if (Number(val) > 0)
    return Number(val);
  else if (Number(val) === -1)
    return null;
  else if (Number(val) === -2)
    return undefined;
  return val;
};

export
const lookupToView = (val) => {
  if (val > 0)
    return val;
  else if (val === null)
    return -1;
  else if (val === undefined)
    return -2;
  return val;
};


export
const waybillOrderCounter = (p) => {
  waybillOrderCounter.counter = waybillOrderCounter.counter || 0;
  if (!p.garage && !p.depot)
    return ++waybillOrderCounter.counter;
  return (waybillOrderCounter.counter = 0);
};

export
const getWaybillXML = (points, waybills, docName) => {
  const headTitles = [
    I18N.WAYBILLS_FIELD_COURIER,
    I18N.WAYBILLS_ORDER,
    I18N.POINTS_NAME,
    I18N.WAYBILLS_FIELD_ADDRESS,
    I18N.WAYBILLS_FIELD_CONTACT_PERSON,
    I18N.WAYBILLS_FIELD_PHONE,
    I18N.WAYBILLS_FIELD_POINT_COMMENT,
    I18N.WAYBILLS_FIELD_DRIVER_COMMENT,
    I18N.WAYBILLS_FIELD_WORK_START,
    I18N.WAYBILLS_FIELD_WORK_END,
    I18N.WAYBILLS_FIELD_BREAK_START,
    I18N.WAYBILLS_FIELD_BREAK_END,
    I18N.WAYBILLS_ARRIVAL,
    I18N.WAYBILLS_DEPARTURE,
    I18N.WAYBILLS_WEIGHT,
    I18N.WAYBILLS_WEIGHT_LOADED,
    I18N.WAYBILLS_VOLUME,
    I18N.WAYBILLS_VOLUME_LOADED,
    I18N.WAYBILLS_TOTAL_DISTANCE,
    I18N.WAYBILLS_TOTAL_TIME,
    I18N.WAYBILLS_TOTAL_COST,
    I18N.WAYBILLS_ROUTE_CODE,
    I18N.WAYBILLS_SUM,
    I18N.WAYBILLS_STATUS,
    I18N.WAYBILLS_COMING_TIME,
  ];

  if (points && waybills) {
    const pointsArray = [];
    const waybillsArray = [];

    points.forEach((p) => (pointsArray[p.id] = p));
    waybills.forEach((w) => waybillsArray.push([
      w.courier.name,
      waybillOrderCounter(pointsArray[w.point_id]),
      pointsArray[w.point_id].name,
      pointsArray[w.point_id].address,
      pointsArray[w.point_id].contact_person,
      pointsArray[w.point_id].phone,
      pointsArray[w.point_id].comment,
      w.driver_comment,
      decimalHoursToTime(pointsArray[w.point_id].work_start),
      decimalHoursToTime(pointsArray[w.point_id].work_end),
      decimalHoursToTime(pointsArray[w.point_id].break_start),
      decimalHoursToTime(pointsArray[w.point_id].break_end),
      decimalHoursToTime(w.arrival_time),
      decimalHoursToTime(w.departure_time),
      pointsArray[w.point_id].weight,
      w.loaded_weight,
      pointsArray[w.point_id].volume,
      w.loaded_volume,
      metersToKilometers(w.total_distance),
      decimalHoursToTime(w.total_time),
      w.total_cost,
      w.route_code,
      w.error_code,
      pointsArray[w.point_id].summa,
      getWaybillStatus(w.status),
      decodeTime(w.last_status_change),
    ]));

    const sortedByCourier = _.groupBy(waybillsArray, '0');

    const workbook = new ExcelJs.Workbook();

    if (Object.keys(sortedByCourier).length > 1) {
      const allDetailedSheet = workbook.addWorksheet(I18N.MISC_WAYBILLS_SHEET_ALL_DETAILED);
      allDetailedSheet.addRows([...[headTitles], ...waybillsArray]);
    }

    _.forEach(_.reverse(sortedByCourier), (array, i) => {
      const currentCourierSheet = workbook.addWorksheet(array[0][0]);
      currentCourierSheet.addRows([...[headTitles], ...array]);
    });

    workbook.xlsx.write(unstream({}, (fileData) => {
      fileDownload(fileData, docName);
    }))
      .then(() => true)
      .catch(() => {
        toast.error(`${I18N.COURIERS_GET_WAYBILL}. ${I18N.NOTIFY_ERROR}`);
      });
  }

  else {
    console.log('No waybill data to export');
  }
};

export
const getXLSTemplate = (fields, extraFields, fileName) => {
  const fieldTitles = [];
  const fieldNames = [];
  for (const field of fields) {
    fieldNames.push(field.name);
    fieldTitles.push(field.caption);
  }

  if (extraFields) {
    for (const field of extraFields) {
      fieldNames.push(field.name);
      fieldTitles.push(field.caption);
    }
  }

  const workbook = new ExcelJs.Workbook();
  const workSheet = workbook.addWorksheet(I18N.MISC_TEMPLATE_WORKSHEET);
  workSheet.addRows([[I18N.MISC_TEMPLATE_WARNING_TITLE], fieldTitles, fieldNames]);
  workSheet.getRow(3).height = 0.1;
  workbook.xlsx.write(unstream({}, (fileData) => {
    fileDownload(fileData, fileName);
  }))
    .then(() => {
      toast.success(`${I18N.MISC_DOWNLOAD_XLS}. ${I18N.NOTIFY_SUCCESS}`);
    })
    .catch(() => {
      toast.error(`${I18N.MISC_DOWNLOAD_XLS}. ${I18N.NOTIFY_ERROR}`);
    });
};

export
const getNumberPrecision = (a) => {
  if (!isFinite(a)) return 0;
  let e = 1,
    p = 0;
  while (Math.round(a * e) / e !== a) {
    e *= 10;
    p++;
  }
  return p;
};

export
const decToMask = (dec) => (dec || 0).toString(2).split('').reverse().map((el, i) => Number(el) ? i : null).filter((i) => i);

export
const maskToDec = (arr) => arr.reduce((acc, i) => acc + Math.pow(2, i), 0);

export
const getCoordsGlobalPrecision = () => 6;

export
const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};
