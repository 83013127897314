import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ButtonKinds } from '../enums';
import ConfirmDialog from '../../dialogs/ConfirmDialog';
import Button from './Button';
import { actions } from '../../dialogs/actions';


class ConfirmButton extends React.PureComponent {
  static propTypes = {
    confirmMessage: PropTypes.string,
    confirmKind: PropTypes.oneOf(Object.keys(ButtonKinds)),
    onClick: PropTypes.func,
    confirmDialog: PropTypes.any,
    confirmDialogProps: PropTypes.object,
  };

  _onConfirm = (...args) => {
    this.props.onClick && this.props.onClick(...args);
  }

  _onClick = () => {
    this.props.actions.showModal(
      this.props.confirmDialog || ConfirmDialog,
      {
        message: this.props.confirmMessage,
        confirmKind: this.props.confirmKind || ButtonKinds.WARNING,
        onConfirm: this._onConfirm,
        ...this.props.confirmDialogProps
      }
    );
  }

  render() {
    return <Button {...this.props} onClick={this._onClick}/>;
  }
}

export default
connect(
  null,
  (dispatch) => ({
    "actions": bindActionCreators(actions, dispatch)
  })
)(ConfirmButton);
