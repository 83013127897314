import * as ExcelJs from 'exceljs/dist/exceljs.min.js';
import Stream from 'stream';
import { decimalHoursToTime, getData, decodeDateRU } from 'base/utils';
import fileDownload from 'js-file-download';
import {
  loadWaybillsAsync,
  loadPointsAsync,
  loadManifestDataAsync,
  loadCouriersAsync
} from 'modules/manifest/utils';

const CustomActions = {
  getGarbageWaybillXML: async (manifestId) => {
    const DATA_STARTS_FROM = 7;

    const data = await getData('/static/custom_solutions/tbo_blesk/template.xlsx');
    const manifestData = await loadManifestDataAsync(manifestId);
    const waybills = await loadWaybillsAsync(manifestId);
    const points = await loadPointsAsync(manifestId);
    const couriers = await loadCouriersAsync(manifestId);

    const workbook = new ExcelJs.Workbook();
    workbook.xlsx.load(data)
      .then(() => {
        const dest = new Stream();
        dest.write = function(fileData) {
          fileDownload(fileData, `Маршрут №${manifestId} от ${decodeDateRU(manifestData.date)} (КК Блеск).xlsx`);
        };

        const pointsArray = [];
        points.forEach((p) => (pointsArray[p.id] = p));
        const worksheet = workbook.getWorksheet(1);

        couriers.forEach((c) => {
          const newSheet = workbook.addWorksheet('empty');
          newSheet.model = {...worksheet.model, name: c.name, id: c.id };

          const courierData = [];
          const courierWaybills = waybills.filter((w) => w.courier_id === c.id);
          const driverName = c.driver ? c.driver.name : '';
          let totalWeight = 0;

          courierWaybills.forEach((w, i) => {
            totalWeight = w.total_weight || totalWeight;
            courierData.push([
              w.points_served,
              pointsArray[w.point_id].address,
              pointsArray[w.point_id].name,
              w.arrival_time > 0 ? `${decodeDateRU(manifestData.date)} ${decimalHoursToTime(w.arrival_time)}` : ' - ',
              ' - ',
              ' - ',
              pointsArray[w.point_id].backhaul_volume || ' - ',
              pointsArray[w.point_id].backhaul_weight || ' - ',
              pointsArray[w.point_id].backhaul_volume || ' - ',
              pointsArray[w.point_id].depot ? decimalHoursToTime(w.arrival_time) : ' - ',
              pointsArray[w.point_id].depot ? pointsArray[w.point_id].name : ' - ',
              pointsArray[w.point_id].depot ? courierWaybills[i - 1].loaded_volume : ' - ',
              pointsArray[w.point_id].depot ? courierWaybills[i - 1].loaded_weight : ' - ',
              driverName
            ]);
          });

          newSheet.spliceRows.apply(newSheet, [DATA_STARTS_FROM, 0, ...courierData]);

          newSheet.getCell('D1').value = `
            Маршрутный журнал учета и транспортировки ТКО на полигон "Круглово" №6 ${new Date(manifestData.date).getUTCFullYear()} год.
          `;
          newSheet.getCell('E2').font = { name: 'Arial', size: 13 };
          newSheet.getCell('E2').value = `
            Начат:  ${decodeDateRU(manifestData.date)} 0:00:00
            Окончен:  ${decodeDateRU(manifestData.date)} 23:59:00
            Ответственные (ФИО, должность)
            Некрасова Е.Н. / логист
            ______________/_______________
            ЕСОО / ООО "БЛЕСК ПРОФИ"
          `;

          newSheet.mergeCells('D1', 'K1');
          newSheet.mergeCells('E2', 'I2');
          newSheet.mergeCells('E4', 'H4');
          newSheet.mergeCells('L4', 'M4');
          newSheet.mergeCells('E5', 'F5');

          newSheet.mergeCells('A4', 'A6');
          newSheet.mergeCells('B4', 'B6');
          newSheet.mergeCells('C4', 'C6');
          newSheet.mergeCells('D4', 'D6');
          newSheet.mergeCells('I4', 'I6');
          newSheet.mergeCells('J4', 'J6');
          newSheet.mergeCells('K4', 'K6');
          newSheet.mergeCells('N4', 'N6');

          newSheet.mergeCells('G5', 'G6');
          newSheet.mergeCells('H5', 'H6');
          newSheet.mergeCells('L5', 'L6');
          newSheet.mergeCells('M5', 'M6');

          for (const i of [4, 5, 6]) {
            for (const j of ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N']) {
              newSheet.getCell(`${j}${i}`).border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
              };
            }
          }

          newSheet.eachRow((row, rowNumber) => {
            row.eachCell.call(row, (cell) => {
              if (rowNumber >= DATA_STARTS_FROM && rowNumber <= courierData.length + DATA_STARTS_FROM) {
                cell.border = {
                  top: { style: 'thin' },
                  left: { style: 'thin' },
                  bottom: { style: 'thin' },
                  right: { style: 'thin' }
                };
                cell.alignment = {
                  vertical: 'middle',
                  horizontal: 'center',
                  wrapText: true
                };
              }
            });
          });
        });
        workbook.removeWorksheet(worksheet.id);
        workbook.xlsx.write(dest);
      });
  }
};

export default CustomActions;
