import * as ExcelJs from 'exceljs/dist/exceljs.min.js';
import Stream from 'stream';
import { getData, decodeDateRU, decodeTime } from 'base/utils';
import fileDownload from 'js-file-download';
import {
  loadWaybillsAsync,
  loadPointsDetailedAsync,
  loadManifestDataAsync,
  loadCouriersDetailedAsync
} from 'modules/manifest/utils';

const CustomActions = {
  getMultipleWaybills: (manifestId, formState) => {
    const getWaybill = this.a.getGarbageWaybillXML;
    let ids = [manifestId];

    if (formState.manifests.value && formState.manifests.value.length)
      ids = formState.manifests.value;

    let p = getWaybill(ids[0]);
    for (let i = 1; i < ids.length; i++)
      p = p.then(() => getWaybill(ids[i]));

    return p;
  },
  getGarbageWaybillXML: async (manifestId) => {
    const DATA_STARTS_FROM = 4;

    const data = await getData('/static/custom_solutions/bassv/template.xlsx');
    const manifestData = await loadManifestDataAsync(manifestId);
    const waybills = await loadWaybillsAsync(manifestId);
    const points = await loadPointsDetailedAsync(manifestId);
    const couriers = await loadCouriersDetailedAsync(manifestId);

    const workbook = new ExcelJs.Workbook();
    workbook.xlsx.load(data)
      .then(() => {
        const dest = new Stream();
        dest.write = function(fileData) {
          return fileDownload(fileData, `Маршрут №${manifestId} от ${decodeDateRU(manifestData.date)} (bassv).xlsx`);
        };

        const pointsArray = [];
        points.forEach((p) => (pointsArray[p.id] = p));
        const worksheet = workbook.getWorksheet(1);

        couriers.forEach((c) => {
          const courierData = [];
          const courierWaybills = waybills.filter((w) => w.courier_id === c.id);
          if (!courierWaybills.length)
            return;

          const newSheet = workbook.addWorksheet('empty');
          newSheet.model = {...worksheet.model, name: c.name, id: c.id };

          courierWaybills.sort((a, b) => {
            if (!b['last_status_change'])
              return -1;
            return Date.parse(a['last_status_change']) - Date.parse(b['last_status_change']);
          });

          courierWaybills.forEach((w, i) => {
            const pointReqs = pointsArray[w.point_id]['extra_reqs'] || {};
            const courierReqs = c['extra_reqs'] || {};
            const volumeFact = pointReqs['mobile_volume_fact'] || pointsArray[w.point_id].comment;

            courierData.push([
              w.points_served,
              decodeDateRU(manifestData.date),
              c.name,
              pointReqs.district || '',
              pointReqs.locality || '',
              pointReqs.street || '',
              pointReqs.house || '',
              pointReqs.comsumer_kind || '',
              pointsArray[w.point_id].name || '',
              Number(pointsArray[w.point_id].comment),
              Number(pointsArray[w.point_id].backhaul_volume || 0),
              w.status === 2 ? Number(volumeFact) : 0,
              w.status === 2 ? Number(pointsArray[w.point_id].backhaul_volume || 0) * Number(volumeFact) : 0,
              w.status === 2 ? (pointReqs.kgo || '') : '',
              w.status === 2 ? 'Полигон' : '',
              pointReqs.note || '',
              decodeTime(w['last_status_change']) || '',
              w['last_status_change'] ? decodeDateRU(w['last_status_change']) : '',
              // (c.driver && c.driver.name) || '',
              w['driver_comment'] || '',
              courierReqs.drivers || '',
              courierReqs.loaders || '',
              courierReqs.loader_1 || '',
              courierReqs.loader_2 || '',
            ]);
          });

          [
            ['A2', 'A3'],
            ['B2', 'B3'],
            ['C2', 'C3'],
            ['D2', 'D3'],
            ['E2', 'G2'],
            ['H2', 'H3'],
            ['I2', 'I3'],
            ['J2', 'J3'],
            ['K2', 'K3'],
            ['L2', 'L3'],
            ['M2', 'M3'],
            ['N2', 'N3'],
            ['O2', 'O3'],
            ['P2', 'P3'],
            ['Q2', 'Q3'],
            ['R2', 'R3'],
            ['S2', 'S3'],
            ['T2', 'T3'],
            ['U2', 'U3'],
            ['V2', 'V3'],
            ['W2', 'W3'],
          ].forEach((pair) => {
            newSheet.mergeCells(pair[0], pair[1]);
            newSheet.getCell(pair[0]).border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' }
            };
          });

          newSheet.spliceRows.apply(newSheet, [DATA_STARTS_FROM, 0, ...courierData]);

          newSheet.eachRow((row, rowNumber) => {
            row.eachCell.call(row, (cell) => {
              if (rowNumber >= DATA_STARTS_FROM && rowNumber <= courierData.length + DATA_STARTS_FROM) {
                cell.border = {
                  top: { style: 'thin' },
                  left: { style: 'thin' },
                  bottom: { style: 'thin' },
                  right: { style: 'thin' }
                };
              }
            });
          });
        });

        workbook.removeWorksheet(worksheet.id);

        return workbook.xlsx.write(dest);
      });
  }
};

export default CustomActions;
