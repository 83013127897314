import React from 'react';
import { PropTypes } from 'prop-types';
import { toast } from 'react-toastify';
import { push } from 'react-router-redux';

import { CRUD } from './queries';
import I18N from '../../common/i18n';
import { store } from '../../..';
import { 
  // AppSections, 
  Sections 
} from '../../common/enums';
import ZoneForm from './ZoneForm';
import { decodeGeometry, closePolygon } from 'base/utils';
import { actions as sectionActions} from '../common/actions';
import { actions as mActions} from '../../manifest/actions';
import { getSectionLinkBody } from '../../../base/utils';
import FormSwitcher from '../common/FormSwitcher';
import * as inputs from '../../inputs';
import ItemForm from '../common/ItemForm';

const cloneFields = [
  {
    inputClass: inputs.CheckboxInput,
    caption: I18N.COURIER_CLONE_LINKED_POINTS,
    name: 'with_linked_points',
    help: I18N.COURIER_CLONE_LINKED_POINTS_HELP,
  },
  {
    inputClass: inputs.CheckboxInput,
    caption: I18N.POINTS_CLONE_LINKED_COURIERS,
    name: 'with_linked_couriers',
    help: I18N.POINTS_CLONE_LINKED_COURIERS_HELP,
  },
  {
    inputClass: inputs.HiddenInput,
    caption: '',
    name: 'with_couriers_points',
    help: '',
  },
  {
    inputClass: inputs.SelectItemsInput,
    caption: I18N.ZONES_CLONE_SELECT,
    name: 'ids',
    section: Sections.ZONES,
    help: I18N.ZONES_CLONE_SELECT_HELP,
    fromCatalog: true,
  }
];

const defaultClone = Object.freeze({
  'with_linked_points': true,
  'with_linked_couriers': true,
  'with_couriers_points': false,
  'ids': [],
});

export default
class ZoneCreate extends React.PureComponent {
  static title = I18N.ZONES_CREATE_TITLE

  static propTypes = {
    manifestId: PropTypes.number,
    orgId: PropTypes.number,
  }

  constructor(props) {
    super(props);
    this.state = {
      fromCatalog: false
    };
  }

  _create = (create, data) => {
    const p = create({
      variables: {
        'manifest_id': this.props.manifestId || -this.props.orgId,
        'data': data
      }
    });
    p.then((res) => {
      toast.success(`${I18N.ZONES_CREATE_TITLE}. ${I18N.NOTIFY_SUCCESS}`);
      store.dispatch(push(
        `${getSectionLinkBody(this.props.manifestId)}${Sections.ZONES}`
      ));
      store.dispatch(sectionActions.zoneCreated(res.data.result.id, closePolygon(decodeGeometry(res.data.result.geometry))));
    });
    p.catch((res) => {
      toast.error(`${I18N.ZONES_CREATE_TITLE}. ${I18N.NOTIFY_ERROR}`);
      console.log('created error', res);
    });
    return p;
  }

  _clone = (clone, data) => {
    const p = clone({
      variables: {
        'from_id': -this.props.orgId,
        'to_id': this.props.manifestId,
        'ids': data.ids,
        'with_linked_points': data.with_linked_points,
        'with_linked_couriers': data.with_linked_couriers,
        'with_couriers_points': data.with_couriers_points
      }
    });
    p.then((res) => {
      toast.success(`${I18N.MISC_ITEM_FROM_CATALOG}. ${I18N.NOTIFY_SUCCESS}`);
      store.dispatch(push(
        `${getSectionLinkBody(this.props.manifestId)}${Sections.ZONES}`
      ));
      store.dispatch(mActions.refetchData());
    });
    p.catch((res) => {
      toast.error(`${I18N.MISC_ITEM_FROM_CATALOG}. ${I18N.NOTIFY_ERROR}`);
      console.log('clone error');
    });
    return p;
  }

  render() {
    return <div>
      {this.props.manifestId && <FormSwitcher
        formComponent={this}
        stateValueName={'fromCatalog'}
        options={[
          { caption: I18N.MISC_ITEM_CURRENT_MANIFEST, value: false },
          { caption: I18N.MISC_ITEM_FROM_CATALOG, value: true },
        ]}
      />}
      {this.state.fromCatalog
        ? CRUD.CLONE(
          {
            'manifest_id': this.props.manifestId || -this.props.orgId,
          },
          (clone) => <ItemForm
            {...this.props}
            item={defaultClone}
            submitText={I18N.SUBMIT_DEFAULT}
            fields={cloneFields}
            submit={(data) => this._clone(clone, data)}
          />)
        : CRUD.CREATE(
          {
            'manifest_id': this.props.manifestId || -this.props.orgId,
          },
          (create) => (
            <ZoneForm
              {...this.props}
              item={{id: -1}}
              submitText={I18N.SUBMIT_CREATE}
              submit={(data) => this._create(create, data)} />
          )
        )}
    </div>;
  }
}
