import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import I18N from '../../common/i18n';
import { withApolloAsyncLoad } from '../../../base/utils';
import { CRUD } from './queries';
import ZoneListItem from './ZoneListItem';
import { AppSections, Sections } from '../../common/enums';
import { ITEMS_PER_PAGE } from '..';

const b = block('ZoneView');

class ZoneView extends React.PureComponent {
  static title = I18N.ZONES_INFO_TITLE

  static propTypes = {
    itemId: PropTypes.number.isRequired,
    manifestId: PropTypes.number,
    route: PropTypes.func,
  }

  _delete = (del) => {
    const p = del({
      variables: {
        id: this.props.itemId
      }
    });
    p.then((res) => {
      this.props.route(`/${AppSections.MANIFEST}/${this.props.manifestId}/${Sections.ZONES}`);
      toast.success(`${I18N.NOTIFY_DELETE} ${I18N.NOTIFY_SUCCESS}`);
    });
    p.catch((res) => {
      console.log('update error');
    });
  }

  _renderView = (zone) => <div className={b()}>
    {CRUD.DELETE(
      {
        'limit': ITEMS_PER_PAGE,
        'manifest_id': this.props.manifestId,
        'offset': 0,
        'title_filter': ""
      },
      (del) => <ZoneListItem
        onDelete={() => this._delete(del)}
        manifestId={this.props.manifestId}
        item={zone}/>
    )}
    {`${zone.name}_${zone.geometry}`}
  </div>;

  render() {
    return (
      CRUD.GET_ONE(
        {id: this.props.itemId},
        (result) => withApolloAsyncLoad(this._renderView, result)
      )
    );
  }
}

export default
connect(
  null,
  (dispatch) => ({
    route: (link) => dispatch(push(link))
  })
)(ZoneView);
