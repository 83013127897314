import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import ManifestListItem from './ManifestListItem';
import { TextFilterInput, DateFilterInput } from './../../common/components';
import { CRUD } from './queries';
import Button from '../../common/components/Button';
import ConfirmButton from '../../common/components/ConfirmButton';
import I18N from '../../common/i18n';
import icons from '../../common/icons';
import { SectionMatchParams as SMP, Sections, AppSections } from '../../common/enums';
import TabTitle from '../common/TabTitle';
import { ITEMS_PER_PAGE } from '..';
import SectionListComplete from '../common/SectionListComplete';

const b = block('ManifestList');

class ManifestList extends React.PureComponent {
  static title = I18N.MANIFEST_TITLE

  static propTypes = {
    [SMP.MANIFEST_ID]: PropTypes.number.isRequired,
    route: PropTypes.func,
    sourceActions: PropTypes.object,
  };

  constructor(props) {
    super();
    this.state = {
      titleFilter: '',
      dateFilter: null,
    };
  }

  applyTitleFilter = (newTitleFilter) => {
    this.setState({
      titleFilter: newTitleFilter
    });
  }

  applyDateFilter = (newDateFilter) => {
    this.setState({
      dateFilter: newDateFilter
    });
  }

  _deleteManifest = (deleteManifest) => {
    const p = deleteManifest({variables: {'id': this.props[SMP.MANIFEST_ID]}});
    p.then((res) => {
      toast.success(`${I18N.MANIFEST_DELETE_CONFIRM}. ${I18N.NOTIFY_SUCCESS}`);
      this.props.route(`/${AppSections.MANIFEST}`);
      console.log('deleted');
    });
    p.catch((res) => {
      toast.error(`${I18N.MANIFEST_DELETE_CONFIRM}. ${I18N.NOTIFY_ERROR}`);
      console.log('delete error');
    });
  }

  render() {
    return (
      <div className={b()}>
        <div className={b('Controls')}>
          <Button
            className={b('CreateManifest')}
            to={`/${AppSections.MANIFEST}/${this.props[SMP.MANIFEST_ID]}/${Sections.MANIFEST_CREATE}`}
          >{I18N.MANIFEST_ADD}</Button>
          <hr className='vr'/>
          <div className={b('CurrentManifest')}>{I18N.MANIFEST_CURRENT}</div>
          <Button
            icon={icons.COMMON_EDIT}
            to={`/${AppSections.MANIFEST}/${this.props[SMP.MANIFEST_ID]}/${Sections.MANIFEST_EDIT}`}
            leftHint={I18N.MANIFEST_EDIT_HINT}
          />
          <Button
            icon={icons.COMMON_CLONE}
            to={`/${AppSections.MANIFEST}/${this.props[SMP.MANIFEST_ID]}/${Sections.MANIFEST_CLONE}`}
            leftHint={I18N.MANIFEST_CLONE_HINT}
          />
          {CRUD.DELETE(
            {
              'manifest_id': this.props[SMP.MANIFEST_ID]
            },
            (deleteManifest) => (
              <ConfirmButton
                confirmMessage={`${I18N.MANIFEST_DELETE_CONFIRM}`}
                icon={icons.COMMON_DELETE}
                leftHint={I18N.MANIFEST_DELETE_HINT}
                onClick={() => this._deleteManifest(deleteManifest)}
              />
            )
          )}
        </div>
        <hr/>
        <TabTitle>{I18N.MANIFEST_LIST_TITLE}</TabTitle>
        <div className={b('Filters')}>
          <TextFilterInput
            placeHolder={I18N.FILTERS_TITLE}
            onChangeValue={this.applyTitleFilter}
            value={this.state.titleFilter} />
          <DateFilterInput
            sourceActions={this.props.sourceActions}
            placeHolder={I18N.FILTERS_DATE}
            onChangeValue={this.applyDateFilter}
            value={this.state.titleFilter} />
        </div>
        <SectionListComplete
          manifestId={this.props[SMP.MANIFEST_ID]}
          queries={CRUD}
          variables={{
            'title_filter': this.state.titleFilter,
            'date_filter': this.state.dateFilter,
            'limit': ITEMS_PER_PAGE,
            'offset': 0
          }}
          itemElement={ManifestListItem}
        />
      </div>
    );
  }
}

export default
connect(
  null,
  (dispatch) => ({ route: (link) => dispatch(push(link)) })
)(ManifestList);
