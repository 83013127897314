import React from 'react';
import block from 'bem-cn-lite';

// import _ from 'lodash';
import polyUtil from '@mapbox/polyline';
import { Polyline } from 'react-leaflet';

import { getPaletteColorClass, getSectionLinkBody } from './../../base/utils';
import { 
  // AppSections, 
  Sections 
} from '../common/enums';
import { routeTo } from '../../index';

import { PropTypes } from 'prop-types';

const b = block('LeafletMap');

export default
class LeafletMapRoutesLayer extends React.Component {
  static propTypes = {
    manifestId: PropTypes.number,
    mapPoints: PropTypes.object,
    waybills: PropTypes.object,
    selectedCourierId: PropTypes.number,
    showRoutes: PropTypes.bool,
    showStraightRoutes: PropTypes.bool,
  }

  _routeToRelatedCourier = (e) => {
    routeTo(`${getSectionLinkBody(this.props.manifestId)}${Sections.COURIERS}/${e.target.options.courierId}`);
  }

  _renderRoute = (courierId, waypoints, dimmed, straight) => ([
    <Polyline
      className={b('PolylineShadow', {'Path': !straight, 'Dimmed': dimmed})}
      key={`${straight ? 'line' : 'path'}_${dimmed}_geometry`}
      positions={waypoints}
    />,
    <Polyline
      className={`${b('Polyline', {'Path': !straight, 'Dimmed': dimmed})} ${getPaletteColorClass(Number(courierId))}`}
      key={`${straight ? 'line' : 'path'}_${dimmed}_geometry_shadow`}
      positions={waypoints}
    />,
    <Polyline
      courierId={courierId}
      className={b('PolylineSelectArea')}
      key={`${straight ? 'line' : 'path'}_${dimmed}_select_area`}
      positions={waypoints}
      onClick={this._routeToRelatedCourier}
    />
  ])

  _renderRoutesStraight = () => {
    const res = [];
    if (this.props.mapPoints && Object.keys(this.props.mapPoints).length) {
      for (const courierId of Object.keys(this.props.waybills)) {
        const waypoints = this.props.waybills[courierId]
          .filter((p) => this.props.mapPoints[p.pointId])
          .map((p) => this.props.mapPoints[p.pointId].coords);
        const dimmed = this.props.selectedCourierId && this.props.selectedCourierId !== Number(courierId);
        res.push(this._renderRoute(courierId, waypoints, dimmed, true));
      }
    }
    return res;
  }

  _renderRoutes = () => {
    const res = [];
    for (const courierId of Object.keys(this.props.waybills)) {
      const waypoints = [].concat(this.props.waybills[courierId].map((p) => polyUtil.decode(p.path || "")));
      const dimmed = this.props.selectedCourierId && this.props.selectedCourierId !== Number(courierId);
      res.push(this._renderRoute(courierId, waypoints, dimmed, false));
    }
    return res;
  }

  render() {
    return [
      this.props.showStraightRoutes && this._renderRoutesStraight(),
      this.props.showRoutes && this._renderRoutes(),
    ];
  }
}
