
import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';

import { Button } from '../../common/components';
import i18n from '../../common/i18n';
import { CheckboxInput, NumberInput, TimeInput, CoordsInput } from 'modules/inputs';
import { makeCancelable } from '../../../base/utils';
import FlexibleScroll from './FlexibleScroll';
import icons from '../../common/icons';
import { AuxilaryTabKinds } from '../../common/enums';
import { HiddenInput } from '../../inputs/SimpleInput';
import { reducer as mReducer} from '../../manifest/actions';
import { reducer as authReducer } from '../../auth/actions';

const b = block('ItemForm');

class ItemForm extends React.PureComponent {
  static propTypes = {
    submitText: PropTypes.string,
    item: PropTypes.object,
    fields: PropTypes.arrayOf(PropTypes.object),
    submit: PropTypes.func,
    className: PropTypes.string,
    onNewStateReady: PropTypes.func,
    submitInInputs: PropTypes.bool,
    submitDisabled: PropTypes.bool,
    sourceActions: PropTypes.object,
    showHelp: PropTypes.func,
    helpFieldOpened: PropTypes.string,
    onRef: PropTypes.func,
    extraReqs: PropTypes.object,
  }

  constructor(props) {
    super(props);
    this.dispatchTimeout = null;
    this.state = this._stateFromProps(props);
    props.onNewStateReady && props.onNewStateReady({}, this.state);
    props.onRef && props.onRef(this);
  }

  componentDidUpdate(prevProps) {
    const updatedFields = {};
    for (const i in this.props.fields) {
      if (prevProps.fields[i] && this.props.fields[i]) {
        const prev = prevProps.fields[i];
        const cur = this.props.fields[i];
        if (prev.name !== cur.name || prev.caption !== cur.caption)
          updatedFields[this.props.fields[i].name] = {...this.props.fields[i], value: this.props.item && this.props.item[this.props.fields[i].name]};
      }
    }

    this.setState({ ...this.state, ...updatedFields });
  }

  componentWillUnmount() {
    this.awaitDataTask && this.awaitDataTask.cancel();
  }

  setFieldValue = (name, value) => {
    this.setState((state) => ({...state, [name]: {...state[name], value: value}}));
  }

  _stateFromProps = (props) => {
    const state = {
      submitDisabled: false,
    };

    if (props.fields)
      for (const f of props.fields)
        state[f.name] = {...f, value: props.item && props.item[f.name]};
    return state;
  }

  _itemFromState = () => {
    const item = {};

    if (this.props.fields)
      for (const f of this.props.fields)
        item[f.name] = this.state[f.name].value;
    return item;
  }

  _onInputChangeValue = (fieldName, fieldValue) => {
    this.setState((state) => {
      const newState = {...state, [fieldName]: {...state[fieldName], value: fieldValue}} || null;
      this.props.onNewStateReady && this.props.onNewStateReady(state, newState);
      return newState;
    });
  }

  _getInputModifires = (field, inputClass) => ({
    'Size_1': field.size === 1,
    'Size_2': field.size === 2,
    'Size_3': field.size === 3,
    'Size_4': field.size === 4,
    'Hidden': Object.is(inputClass, HiddenInput),
    'Time': Object.is(inputClass, TimeInput),
    'Number': Object.is(inputClass, NumberInput),
    'Checkbox': Object.is(inputClass, CheckboxInput),
    'Coords': Object.is(inputClass, CoordsInput),
    'EmptySpace': Object.is(inputClass, null),
    'Invalid': field.invalid,
  });

  _getSpaceSize = (spaces) => ({
    'Size_1': spaces === 1,
    'Size_2': spaces === 2,
    'Size_3': spaces === 3,
    'Size_4': spaces === 4,
  })

  _renderInputs = () => {
    const inputs = [];

    if (this.props.fields)
      for (const f of this.props.fields)
        f.inputClass && this.state[f.name] && inputs.push(this._makeInput(this.state[f.name]));
    return inputs;
  }

  _makeInput = (field) => {
    const Input = field.inputClass;

    if (field.name === 'extra_reqs') {
      const fieldsMetaData = this.props.extraReqs
        && this.props.extraReqs[field.section]
        && this.props.extraReqs[field.section].fields;

      if (fieldsMetaData)
        field.fieldsMetaData = fieldsMetaData;
      else
        return null;
    }

    return [
      <div key={Input.forceUpdatable ? field.name + field.value : field.name}
        className={b('Input', this._getInputModifires(field, Input))}
      >
        <div className={b('InputCaption')}>
          {field.caption
            && <label htmlFor={field.name}>
              {field.caption}
            </label>}
          {field.help
            && <Button
              className={b('HelpButton', { 'Opened': this.props.helpFieldOpened === field.name })}
              icon={icons.COMMON_HELP_BUTTON}
              onClick={() => this.props.showHelp(field, this.props)}
            />}
        </div>
        <Input {...field} sourceActions={this.props.sourceActions} onChangeValue={this._onInputChangeValue} />
      </div>,
      field.spaces ? <div key={`${field.name}_clear_right`} className={b('Input', this._getSpaceSize(field.spaces))} /> : null,
    ];
  }

  _trySubmit = () => {
    let noErrors = true; 
    const newState = {};

    for (const key of Object.keys(this.state)) {
      const field = this.state[key];
      if (field.validate && !field.validate(field.value)) {
        noErrors = false;
        newState[field.name] = { ...field, invalid: true };
      }
      else
        newState[field.name] = { ...field, invalid: false };
    }

    if (noErrors)
      this._submitData();
    else
      this.setState(newState); 
  }

  _submitData = async () => {
    this.awaitDataTask = makeCancelable(this.props.submit(this._itemFromState()));
    this.setState({ submitDisabled: true });
    const promise = this.awaitDataTask.promise
      .then(() => {
        this.setState({ submitDisabled: false });
      })
      .catch((err) => {
        !err.isCanceled && this.setState({ submitDisabled: false });
      });
    await promise;
  }

  render() {
    return (
      <div className={`${b()} ${this.props.className || ''}`}>
        <FlexibleScroll>
          <div className={b('Inputs')}>
            { this._renderInputs(this.props.fields) }
            { this.props.submitInInputs && this.props.submit && <div className={b('InputsSubmit')}>
              <Button
                disabled={this.props.submitDisabled || this.state.submitDisabled}
                onClick={this._trySubmit}>{this.props.submitText || i18n.SUBMIT_DEFAULT}
              </Button>
            </div>}
          </div>
        </FlexibleScroll>
        {!this.props.submitInInputs && this.props.submit && <div className={b('Submit')}>
          <Button
            disabled={this.props.submitDisabled || this.state.submitDisabled}
            onClick={this._trySubmit}>{this.props.submitText || i18n.SUBMIT_DEFAULT}
          </Button>
        </div>}
      </div>
    );
  }
}

export default
connect(
  (state) => ({
    helpFieldOpened: state[mReducer.name].helpFieldName,
    extraReqs: state[authReducer.name].user
      && state[authReducer.name].user['ui_info']
      && state[authReducer.name].user['ui_info'].extraFields
  }),
  (dispatch) => ({
    "showHelp": (field, props) => dispatch(props.sourceActions.showText(
      AuxilaryTabKinds.RAW_TEXT,
      field.help,
      field.caption || (`${field.captionLat} / ${field.captionLon}`),
      field.name
    ))
  })
)(ItemForm);
