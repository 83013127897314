import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';

import I18N from '../../common/i18n';
import { Icon, Button } from '../../common/components';
import { ENDPOINTS } from '../../../base/api';
import TextLink from './../../common/components/TextLink';
import icons from '../../common/icons';
import { TextInput, PasswordInput } from '../../inputs';

const b = block('LoginForm');

const Actions = {
  LOGIN: 'login',
  SINGUP: 'singup',
  RESTORE: 'restore',
};

export default
class LoginForm extends React.PureComponent {
  static propTypes = {
    loadCurrentUser: PropTypes.func,
    loginUser: PropTypes.func
  }

  constructor(props) {
    super(props);
    this.state = {
      failureCode: null,
      action: Actions.LOGIN,
      username: '',
      email: '',
      phone: '',
      password: '',
      confirmPassword: '',
    };
  }

  _handleLogin = () => {
    if (!this.state.password || !this.state.username) {
      this.setState({ failureCode: 'C005' });
      return;
    }

    fetch(
      ENDPOINTS.login, {
        method: 'POST',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          username: this.state.username,
          password: this.state.password,
        }),
      })
      .then((res) => {
        if (!res) {
          this.setState({ failureCode: 'C002' });
          return;
        }
        return res.json();
      })
      .then((res) => {
        if (!res)
          return;

        if (!res.result) {
          this.setState({ failureCode: res.code });
          return;
        }

        this.setState({ failureCode: null });
        this.props.loginUser(res.token);
        // this.props.loadCurrentUser();
      });
  }

  _handleSignup = () => {
    if (!this.state.password || !this.state.email || !this.state.phone) {
      this.setState({ failureCode: 'C005' });
      return;
    }
    if (!this.state.password || this.state.password !== this.state.confirmPassword) {
      this.setState({ failureCode: 'C001' });
      return;
    }

    fetch(
      ENDPOINTS.signup, {
        method: 'POST',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: this.state.email,
          password: this.state.password,
          phone: this.state.phone,
          roistat_cookie: document.cookie.split('; ')
            .filter((c) => c.match('roistat'))
            .reduce((acc, c) => ({ ...acc, [c.split('=')[0]]: c.split('=')[1] }), {})
        }),
      })
      .then((res) => {
        if (!res) {
          this.setState({ failureCode: 'C004', });
          return;
        }
        return res.json();
      })
      .then((res) => {
        if (!res)
          return;

        if (!res.result) {
          this.setState({ failureCode: res.code });
          return;
        }

        this.setState({
          failureCode: 'C006',
          action: Actions.LOGIN
        });

        // Roisted event is needed for pipedrive
        window.roistat.event.send('reg_form', { 'url': document.location.href });

        // this.props.loginUser(res.token);
        // this.props.loadCurrentUser();
      });
  }

  _onInputChange = (name, value) => {
    this.setState({
      [name]: value,
      failureCode: null,
    });
  }

  _renderRestore = () => <div className={b('FieldsBox')} onKeyPress={(e) => this._onEnterPressed(e, 'restore')}>
    <div className={b('Title')}>{I18N.LOGIN_RESTORE_PASSWORD}</div>
    <div className={b('Input')}>
      <TextInput className={b('Input')}
        placeHolder={I18N.USER_EMAIL}
        name='email'
        value={this.state.email}
        onChangeValue={this._onInputChange}
      />
    </div>
    <div className={b('Submit')}>
      <Button>
        {I18N.SUBMIT_SEND}
      </Button>
    </div>
  </div>;

  _renderLogin = () => <div className={b('FieldsBox')} onKeyPress={(e) => this._onEnterPressed(e, 'login')}>
    <div className={b('Title')}>{I18N.LOGIN_AUTORIZATION}</div>
    {this.state.failureCode && <div className={b('Validation')}>{I18N[`ERROR_${this.state.failureCode}`]}</div>}
    <div className={b('Input')}>
      <TextInput className={b('Input')}
        placeHolder={I18N.USER_NAME}
        name='username'
        value={this.state.username}
        onChangeValue={this._onInputChange}
      />
    </div>
    <div className={b('Input')}>
      <PasswordInput className={b('Input')}
        placeHolder={I18N.USER_PASSWORD}
        name={'password'}
        value={this.state.password}
        onChangeValue={this._onInputChange}
      />
    </div>
    <div className={b('Submit')}>
      <Button onClick={this._handleLogin}>
        {I18N.LOGIN_LOGIN}
      </Button>
    </div>
  </div>;

  _renderSingup = () => <div className={b('FieldsBox')} onKeyPress={(e) => this._onEnterPressed(e, 'signup')}>
    <div className={b('Title')}>{I18N.LOGIN_SIGNUP}</div>
    {this.state.failureCode && <div className={b('Validation')}>{I18N[`ERROR_${this.state.failureCode}`]}</div>}
    <div className={b('Input')}>
      <TextInput className={b('Input')}
        placeHolder={I18N.USER_EMAIL}
        name='email'
        value={this.state.email}
        onChangeValue={this._onInputChange}
      />
    </div>
    <div className={b('Input')}>
      <TextInput className={b('Input')}
        autoComplete={'off'}
        placeHolder={I18N.USER_PHONE}
        name={'phone'}
        value={this.state.phone}
        onChangeValue={this._onInputChange}
      />
    </div>
    <div className={b('Input')}>
      <PasswordInput className={b('Input')}
        autoComplete={'off'}
        placeHolder={I18N.USER_PASSWORD}
        name={'password'}
        value={this.state.password}
        onChangeValue={this._onInputChange}
      />
    </div>
    <div className={b('Input')}>
      <PasswordInput className={b('Input')}
        autoComplete={'off'}
        placeHolder={I18N.USER_CONFIRM_PASSWORD}
        name={'confirmPassword'}
        value={this.state.confirmPassword}
        onChangeValue={this._onInputChange}
      />
    </div>
    <div className={b('Submit')}>
      <Button onClick={this._handleSignup}>
        {I18N.LOGIN_SIGNUP}
      </Button>
    </div>
  </div>;

  _onEnterPressed = (e, source) => {
    if (e.key === 'Enter') {
      source === 'login' && this._handleLogin();
      source === 'signup' && this._handleSignup();
      source === 'restore' && console.log('restore');
    }
  }

  _switchForm = (action) => this.setState({
    action: action,
    failureCode: null,
  })

  render() {
    return (
      <div className={b()}>
        <div className={b('Window')}>
          <div className={b('Logo')}>
            <Icon svgName={icons.LOGO}/>
          </div>
          {this.state.action === Actions.LOGIN && this._renderLogin()}
          {this.state.action === Actions.SINGUP && this._renderSingup()}
          {this.state.action === Actions.RESTORE && this._renderRestore()}
          <div className={b('Splitter')}></div>
          <div className={b('Extra')}>
            {this.state.action !== Actions.SINGUP && <TextLink onClick={() => this._switchForm(Actions.SINGUP)}>{I18N.LOGIN_SIGNUP}</TextLink>}
            {this.state.action !== Actions.RESTORE && <TextLink onClick={() => this._switchForm(Actions.RESTORE)}>{I18N.LOGIN_RESTORE_PASSWORD}</TextLink>}
            {this.state.action !== Actions.LOGIN && <TextLink onClick={() => this._switchForm(Actions.LOGIN)}>{I18N.LOGIN_LOGIN}</TextLink>}
          </div>
        </div>
        <script>{
          /* eslint-disable */
          (function(w, d, s, h, id) {
            w.roistatProjectId = id; w.roistatHost = h;
            var p = d.location.protocol == "https:" ? "https://" : "http://";
            var u = /^.*roistat_visit=[^;]+(.*)?$/.test(d.cookie) ? "/dist/module.js" : "/api/site/1.0/"+id+"/init";
            var js = d.createElement(s); js.charset="UTF-8"; js.async = 1; js.src = p+h+u; var js2 = d.getElementsByTagName(s)[0]; js2.parentNode.insertBefore(js, js2);
          }(window, document, 'script', 'cloud.roistat.com', 'f392afbd81f486e32d3cb7256918d7d6'))
          /* eslint-enable */
        }</script>
      </div>
    );
  }
}
