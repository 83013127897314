import React from 'react';
import block from 'bem-cn-lite';
import { Query } from 'react-apollo';
import { PropTypes } from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import { AppSections, SectionMatchParams as SMP, ApolloFetchPolicy } from '../../common/enums';
import { OptimizationStatus } from '../enums';
import { queries } from '../queries';
import { ErrorIndicator, LoadingIndicator } from '../../common/components';
import Icon from '../../common/components/Icon';
import ManifestControls from './ManifestControls';
import LeafletMap from '../../map/LeafletMap';
import LeafletMapControls from '../../map/LeafletMapControls';
import icons from '../../common/icons';
import { client } from '../../../index';
import UserBadge from '../../auth/components/UserBadge';
import ManifestFirst from './ManifestFirst';
import ManifestUserForms from './ManifestUserForms';
import { reducer, actions } from '../actions';
import SectionTab from '../../sections/common/SectionTab';
import AuxiliaryTab from '../../sections/common/AuxiliaryTab';
import { convertParamsToNumber } from '../../../base/utils';
import { reducer as authReducer } from '../../auth/actions';
import SubscriptionNotification from './SubscriptionNotification';
import I18N from '../../common/i18n';

const defaultManifestState = Object.freeze({
  calculated: false,
  name: null,
  optimizationStatus: null,
  recalculationRequired: false,
  zonesReady: false,
  manifestReady: false,
  manifestError: false,
  distribution: false,
});

const b = block('Manifest');

class Manifest extends React.PureComponent {
  static propTypes = {
    auxilaryTabKind: PropTypes.string,
    selectListSection: PropTypes.string,
    selectListOnlyValue: PropTypes.bool,
    selectListfromCatalog: PropTypes.bool,
    editingFieldName: PropTypes.string,
    rawText: PropTypes.string,
    photos: PropTypes.arrayOf(PropTypes.string),
    caption: PropTypes.string,
    editingFieldValue: PropTypes.any,
    closeAuxilaryTab: PropTypes.func.isRequired,
    orgId: PropTypes.number,
    match: PropTypes.shape({
      params: PropTypes.shape({
        [SMP.MANIFEST_ID]: PropTypes.any,
        [SMP.SECTION]: PropTypes.any,
        [SMP.ITEM_ID]: PropTypes.any,
        [SMP.ITEM_STATUS]: PropTypes.any,
      }),
    }),
    tabs: PropTypes.arrayOf(PropTypes.object),
  }

  constructor(props) {
    super(props);
    this.state = defaultManifestState;
    this.unsubscribeResetStoreEvent = client.onResetStore(() => this._loadManifest());
  }

  componentDidMount() {
    this._loadManifest();
    this.refetchDataEvent = actions.refetchData.subscribe(() => client.resetStore());
    this.changeNameEvent = actions.changeManifestName.subscribe((s, action) => this.setState({ name: action.newName }));
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (this.state.optimizationStatus && !nextState.optimizationStatus)
      client.resetStore();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.match.params[SMP.MANIFEST_ID] !== prevProps.match.params[SMP.MANIFEST_ID]) {
      this._loadManifest();
      if (!this.state.optimizationStatus && prevState.optimizationStatus)
        toast.success(`${I18N.OPTIMIZATION_RUN}. ${I18N.NOTIFY_SUCCESS}`);
    }

    if (this.state.optimizationStatus
    && prevState.optimizationStatus
    && this.state.optimizationStatus.match('error')
    && !prevState.optimizationStatus.match('error'))
      toast.error(`${I18N.OPTIMIZATION_RUN}. ${I18N.NOTIFY_ERROR}`);
  }

  componentWillUnmount() {
    clearInterval(this.checkStatus);
    this.refetchDataEvent.unsubscribe();
    this.changeNameEvent.unsubscribe();
    this.unsubscribeResetStoreEvent();
  }

  _loadManifest = () => {
    this.setState(defaultManifestState);
    this._setPollingInterval();
    this._refetchManifestData();
  }

  _setPollingInterval() {
    clearInterval(this.checkStatus);
    this.checkStatus = setInterval(this._checkCalculationProcess, 1000);
  }

  _refetchManifestData = async () => {
    try {
      const { data } = await client.query({
        query: queries.QUERY_MANIFEST,
        fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
        variables: {
          'id': Number(this.props.match.params[SMP.MANIFEST_ID]),
        },
      });

      this.setState({
        name: data.result['name'],
        optimizationStatus: data.result['optimization_status'],
        optimizationError: data.result['optimization_error_info'],
        calculated: data.result['calculated'],
        hasVisitedPoints: data.result['has_visited_points'],
        recalculationRequired: data.result['recalculation_required'],
        missedPointsCount: data.result['missed_points_count'],
        warningPointsCount: data.result['warning_points_count'],
        distribution: data.result['distribution'],
        manifestReady: true,
      });
    }
    catch (e) {
      this.setState({ manifestError: true });
    }
  }

  _checkCalculationProcess = async () => {
    if (!this._canRunOptimization(this.state.optimizationStatus)) {
      const { data } = await client.query({
        query: queries.OPTIMIZATION_STATUS,
        fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
        variables: {
          'manifest_id': Number(this.props.match.params[SMP.MANIFEST_ID]),
        },
      });

      this.setState({
        optimizationStatus: data.result['optimization_status'],
        optimizationError: data.result['optimization_error_info'],
        calculated: data.result['calculated'],
        recalculationRequired: data.result['recalculation_required'],
      });
    }
  }

  _canRunOptimization = (status) => !status || status.match(OptimizationStatus.ERROR);

  _renderCenteredIndicator(indicatorComponent) {
    return (
      <div className={b('LoadingIndicator')}>
        {indicatorComponent}
      </div>
    );
  }

  _redirectToLastManifest = () => <Query query={queries.FIRST_MANIFEST_QUERY}
    fetchPolicy={'network-only'}
    variables={{
      'limit': 1,
      'offset': 0,
    }}
  >
    {(result) => {
      if (result.loading)
        return this._renderCenteredIndicator(<LoadingIndicator/>);
      else if (result.error)
        return this._renderCenteredIndicator(<ErrorIndicator/>);
      else if (result.data && !result.data.result)
        return <ManifestFirst manifestId={Number(this.props.match.params[SMP.MANIFEST_ID])}/>;
      else if (result.data.result)
        return <Redirect to={`/${AppSections.MANIFEST}/${result.data.result[0].id}`} />;
      return this._renderCenteredIndicator(<ErrorIndicator/>);
    }}
  </Query>;

  render() {
    // Пофиксить редирект
    if (!this.props.match.params[SMP.MANIFEST_ID])
      return this._redirectToLastManifest();
    else if (this.state.manifestError)
      return this._redirectToLastManifest();
    return (
      <div className={b()}>
        <div className={b('Map')}>
          <LeafletMap
            manifestId={Number(this.props.match.params[SMP.MANIFEST_ID])}
            sourceRecucer={reducer}
          />
        </div>
        <div className={b('Logo')}>
          <Icon svgName={icons.LOGO}/>
        </div>
        <div className={b('Header')}>
          <div className={b('HeaderLeft')}>
            <ManifestControls
              {...this.state}
              setManifestState={(state) => this.setState({...state})}
              manifestId={Number(this.props.match.params[SMP.MANIFEST_ID])}
              canRunOptimization={Boolean(this._canRunOptimization(this.state.optimizationStatus))}
            />
          </div>
          <div className={b('HeaderRight')}>
            <ManifestUserForms manifestId={Number(this.props.match.params[SMP.MANIFEST_ID])}/>
            <LeafletMapControls
              sourceActions={actions}
              sourceRecucer={reducer}
              appSection={AppSections.MANIFEST}
            />
            <div>
              <UserBadge/>
              <SubscriptionNotification/>
            </div>
          </div>
        </div>
        <div className={b('Body')}>
          <div className={b('Tabs')}>
            <div className={b('LeftTabDynamicIndent')} />
            <div className={b('LeftTab')}>
              <SectionTab
                {...convertParamsToNumber(this.props.match.params)}
                orgId={this.props.orgId}
                sourceActions={actions}
              />
            </div>
            <div className={b('RightTab')}>
              <div className={b('RightTabTopIndent')}/>
              <AuxiliaryTab
                {...convertParamsToNumber(this.props.match.params)}
                orgId={this.props.orgId}
                onClose={this.props.closeAuxilaryTab}
                fromCatalog={this.props.selectListfromCatalog}
                onlyValue={this.props.selectListOnlyValue}
                section={this.props.selectListSection}
                fieldName={this.props.editingFieldName}
                rawText={this.props.rawText}
                caption={this.props.caption}
                photos={this.props.photos}
                auxilaryTabKind={this.props.auxilaryTabKind}
                value={this.props.editingFieldValue}
              />
            </div>
            <div className={b('RightTabDynamicIndent')} />
          </div>
        </div>
      </div>
    );
  }
}
export default
connect(
  (state) => ({
    auxilaryTabKind: state[reducer.name].auxilaryTabKind,
    selectListSection: state[reducer.name].selectListSection,
    selectListOnlyValue: state[reducer.name].selectListOnlyValue,
    selectListfromCatalog: state[reducer.name].selectListfromCatalog,
    editingFieldName: state[reducer.name].editingFieldName,
    editingFieldValue: state[reducer.name].editingFieldValue,
    rawText: state[reducer.name].rawText,
    caption: state[reducer.name].caption,
    photos: state[reducer.name].photos,
    orgId: state[authReducer.name].user && state[authReducer.name].user['org_id'],
  }),
  (dispatch) => ({
    "closeAuxilaryTab": () => dispatch(actions.closeAuxilaryTab())
  })
)(Manifest);
