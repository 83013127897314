import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';
import { Mutation } from 'react-apollo';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import _ from 'lodash';

import I18N from '../../common/i18n';
import { queries as measureQueries } from './queries';
import { DBLookupInput } from '../../inputs';
import { queries as orgQueries } from '../../organization/queries';
import ItemForm from '../common/ItemForm';
import { client } from '../../..';
import { actions as authActions, reducer as authReducer } from '../../auth/actions';
import { AppSections, Sections } from '../../common/enums';

const b = block('ListSection');

const fields = [
  {inputClass: DBLookupInput,
    name: 'measure_id',
    caption: I18N.MEASURES_APPLIED,
    queryParams: {
      query: measureQueries.LIST,
      variables: {
        'manifest_id': null,
      }
    },
    valueField: 'id',
    captionField: 'name',
    size: 2,
    help: I18N.MEASURES_APPLIED_HINT,
  },
];

class MeasureSelect extends React.PureComponent {
  static title = I18N.MEASURES_LIST_TITLE

  static propTypes = {
    orgId: PropTypes.number,
    updateMesure: PropTypes.func,
    route: PropTypes.func,
    measure: PropTypes.object,
  }

  _onNewStateReady = (oldState, newState) => {
    newState['measure_id'].queryParams.variables['manifest_id'] = -this.props.orgId;
  }

  _update = (update, data, orgId) => {
    const p = update({
      variables: {
        'id': orgId,
        'data': data,
      }
    });
    p.then(({ data: { result: currentUser } }) => {
      if (!currentUser['measure_id']) {
        this.props.updateMesure(null);
        this.props.route(`/${AppSections.CATALOG}/${Sections.MEASURES}`);
        return;
      }

      const p2 = client.query({ query: measureQueries.GET_ONE, variables: { id: currentUser['measure_id'] } });
      p2.then((result) => {
        if (result.data) {
          const measure = result.data.result;
          this.props.updateMesure(measure);
          this.props.route(`/${AppSections.CATALOG}/${Sections.MEASURES}`);
        }
      });
      p2.catch((error) => {
        console.log(error);
      });

    });
    p.catch((res) => {
      console.log('update error');
    });
    return p;
  }

  render() {
    const measureId = this.props.measure && this.props.measure.id;
    return (
      <Mutation mutation={orgQueries.UPDATE}>
        {(update) => <div className={b()}>
          <ItemForm
            {...this.props}
            key={measureId}
            item={{measure_id: measureId}}
            fields={fields}
            submit={(data) => this._update(update, data, this.props.orgId)}
            onNewStateReady={this._onNewStateReady} />
        </div>}
      </Mutation>
    );
  }
}

export default
connect(
  (state) => ({
    measure: _.get(state, `${authReducer.name}.user.measure`, null),
  }),
  (dispatch) => ({
    updateMesure: (measure) => dispatch(authActions.updateMesure(measure)),
    route: (link) => dispatch(push(link))
  })
)(MeasureSelect);
