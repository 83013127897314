import React from 'react';
import block from 'bem-cn-lite';
import TextLink from '../../common/components/TextLink';
import { PropTypes } from 'prop-types';

const b = block('FormSwitcher');

const FormSwitcher = ({formComponent, stateValueName, options}) => {
	formComponent._renderOptions = () => {
    	const optComponents = []

    	for(const o of options){
      		optComponents.push(
			  	<TextLink
        			key={o.value}
        			disabled={formComponent.state[stateValueName] === o.value}
        			onClick={() => formComponent.setState({ [stateValueName]: o.value })}
      			>
					{o.caption}
				</TextLink>
			)
		}
		return optComponents
	}

	return (
		<div className={b()}>
			{formComponent._renderOptions()}
		</div>
	)
}

FormSwitcher.propTypes = {
	formComponent: PropTypes.object,
	stateValueName: PropTypes.string,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			caption: PropTypes.string,
			value: PropTypes.any
		})
	),
};

export default FormSwitcher
