import { makeReducer } from '../../../base/reducer';
import { Sections } from '../../common/enums';

const init = {
  listScrollPosition: {
    [Sections.POINTS]: 0,
    [Sections.COURIERS]: 0,
    [Sections.ZONES]: 0,
  },
};

export const reducer = makeReducer('section', init);

export const actions = {
  'pointCreated': reducer.makeAction(
    (id, point, source) => ({id, point, source}),
    null,
    'POINT_CREATED'
  ),
  'pointCoordsUpdated': reducer.makeAction(
    (id, coords, source) => ({id, coords, source}),
    null,
    'POINT_COORDS_UPDATED'
  ),
  'pointUpdated': reducer.makeAction(
    (id, point, source) => ({id, point, source}),
    null,
    'POINT_UPDATED'
  ),
  'pointsDeleted': reducer.makeAction(
    (ids, source) => ({ids, source}),
    null,
    'POINTS_DELETED'
  ),
  'massPointsUpdated': reducer.makeAction(
    (source) => ({source}),
    null,
    'MASS_POINTS_UPDATED'
  ),

  'zoneCreated': reducer.makeAction(
    (id, geometry, source) => ({id, geometry, source}),
    null,
    'ZONE_CREATED'
  ),
  'zoneGeometryUpdated': reducer.makeAction(
    (id, geometry, source) => ({id, geometry, source}),
    null,
    'ZONE_GEOMETRY_UPDATED'
  ),
  'zoneDeleted': reducer.makeAction(
    (id, source) => ({id, source}),
    null,
    'ZONE_DELETED'
  ),

  'fieldValueUpdated': reducer.makeAction(
    (fieldName, value, source) => ({fieldName, value, source}),
    null,
    'FIELD_VALUE_UPDATED'
  ),

  'saveScrollPosition': reducer.makeAction(
    (section, position) => ({section: section, position: position}),
    (state, action) => ({ ...state, listScrollPosition: {...state.listScrollPosition, [action.section]: action.position}}),
    'SAVE_SCROLL_POSITION'
  ),
};
