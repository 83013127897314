import React from 'react';
import { PropTypes } from 'prop-types';
import { toast } from 'react-toastify';
import { push } from 'react-router-redux';

import { CRUD } from './queries';
import I18N from '../../common/i18n';
import { withApolloAsyncLoad } from '../../../base/utils';
import { store } from '../../..';
import { AppSections, SectionMatchParams as SMP } from '../../common/enums';
import ManifestForm from './ManifestForm';
import { actions as mActions } from '../../manifest/actions';


export default
class ManifestEdit extends React.PureComponent {
  static title = I18N.MANIFEST_EDIT_TITLE

  static propTypes = {
    manifestId: PropTypes.number.isRequired,
  }

  _update = (update, data) => {
    const p = update({
      variables: {
        id: this.props.manifestId,
        data: data
      }
    });
    p.then((res) => {
      if (res && res.data.result) {
        toast.success(`${I18N.MANIFEST_EDIT_TITLE}. ${I18N.NOTIFY_SUCCESS}`);
        store.dispatch(push(`/${AppSections.MANIFEST}/${this.props.manifestId}`));
        store.dispatch(mActions.changeManifestName(res.data.result.name));
      }
    });
    p.catch((res) => {
      toast.error(`${I18N.MANIFEST_EDIT_TITLE}. ${I18N.NOTIFY_ERROR}`);
      console.log('update error');
    });
    return p;
  }

  _renderForm = (manifest) => CRUD.UPDATE(
    {},
    (update) => (
      <ManifestForm
        {...this.props}
        submitText={I18N.SUBMIT_SAVE}
        item={manifest}
        submit={(data) => this._update(update, data)} />
    )
  );

  render() {
    return (
      CRUD.GET_ONE(
        {id: this.props[SMP.MANIFEST_ID]},
        (result) => withApolloAsyncLoad(this._renderForm, result)
      )
    );
  }
}
