import React from 'react';
import { PropTypes } from 'prop-types';
// import _ from 'lodash';
import block from 'bem-cn-lite';
import { connect } from 'react-redux';

import I18N from '../../common/i18n';
import { queries as courierQueries } from '../couriers/queries';
import { DBLookupInput } from '../../inputs';
import {reducer as updateStateReducer, actions as updateStateActions} from '../../common/updateStateReducer';

class PointViewForm extends React.PureComponent {

  static propTypes = {
    item: PropTypes.object,
    manifestId: PropTypes.number,
  }

  _onInputChangeValue = (fieldName, fieldValue) => {
    this.props.assignCourier(this.props.item.id, fieldValue);
  }

 render() {
    const b = block('ItemForm');
    return (
      <div className={b('Input', "Size_2")}>
        <div className={b('InputCaption')}>
          <label htmlFor={'courier_id'}>{I18N.POINTS_COURIER_ID}</label>
        </div>
        <DBLookupInput
          name='courier_id'
          queryParams={{
            query: courierQueries.LIST,
            variables: {
              'manifest_id': this.props.manifestId,
            }
          }}
          hasNull={true}
          hasUndefined={true}
          value={(this.props.courierAssignments[this.props.item.id] || {}).assigned_courier_id}
          valueField='id'
          captionField='name'
          onChangeValue={this._onInputChangeValue}
        />
      </div>
    );
  }
}


export default
connect(
  (state) => ({
    courierAssignments: state[updateStateReducer.name].courierAssignments,
  }),
  (dispatch) => ({
    assignCourier: (pointId, courierId) => dispatch(updateStateActions.assignCourier(pointId, courierId)),
  })
)(PointViewForm);
