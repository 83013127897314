import React from 'react';
import block from 'bem-cn-lite';
import { connect } from 'react-redux';

import { reducer } from './../actions';
import { Icon } from '../../common/components';
import { LayoutMainMenuItem } from '.';
import I18N from '../../common/i18n';
import icons from '../../common/icons';
import { AppSections } from '../../common/enums';

const b = block('LayoutMainMenu');

const LayoutMainMenu = ({
  collapsed,
  actions
}) => (
  <div className={b({'Collapsed': collapsed})}>
    <div className={b('Logo', {'Hidden': collapsed})}>
      <Icon svgName={icons.LOGO_BLACK}/>
    </div>
    <div className={b('Items')}>
      <div onClick={() => actions.collapseMenu(!collapsed)} className={b('Collapse', {'On': collapsed})}>
        <Icon svgName={icons.MENU_COLLAPSE}/>
      </div>
      <LayoutMainMenuItem
        link={`/${AppSections.MANIFEST}`}
        title={I18N.MANIFEST_TAB}
        hint={I18N.MANIFEST_TAB_HINT}
        itemIcon={icons.MENU_MANIFEST} />
      {/* <LayoutMainMenuItem
        link={`/${AppSections.REQUEST}`}
        title={I18N.ORDERS_TAB}
        hint={I18N.ORDERS_TAB_HINT}
        disabled={true}
        itemIcon={icons.MENU_ORDERS} />
      <LayoutMainMenuItem
        link={`/${AppSections.HISTORY}`}
        title={I18N.HISTORY_TAB}
        hint={I18N.HISTORY_TAB_HINT}
        disabled={true}
        itemIcon={icons.MENU_HISTORY} />
      <LayoutMainMenuItem
        link={`/${AppSections.REPORTS}`}
        title={I18N.REPORTS_TAB}
        hint={I18N.REPORTS_TAB_HINT}
        disabled={true}
        itemIcon={icons.MENU_METRICS} /> */}
      <LayoutMainMenuItem
        link={`/${AppSections.CATALOG}`}
        title={I18N.DICTS_TAB}
        hint={I18N.DICTS_TAB_HINT}
        itemIcon={icons.MENU_DICTS} />
      {/* <LayoutMainMenuItem
        link={`/${AppSections.SETTINGS}`}
        title={I18N.SETTINGS_TAB}
        hint={I18N.SETTINGS_TAB_HINT}
        disabled={true}
        itemIcon={icons.MENU_SETTINGS} /> */}
      <LayoutMainMenuItem
        // link={`skype:live:vlad.homiakow?chat`}
        link={`mailto:support@zig-zag.org`}
        title={I18N.SUPPORT_TAB}
        hint={I18N.SUPPORT_TAB_HINT}
        itemIcon={icons.MENU_SUPPORT} />
      <LayoutMainMenuItem
        link={`https://www.youtube.com/channel/UCWsLQPhhjcHhOaffC5y4aBA/videos`}
        title={I18N.YOUTUBE_TAB}
        hint={I18N.YOUTUBE_TAB_HINT}
        itemIcon={icons.MENU_YOUTUBE} />
    </div>
  </div>
);

export default
connect(
  (state) => ({
    collapsed: state[reducer.name].menuCollapsed
  })
)(LayoutMainMenu);
