import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';

import I18N from '../../common/i18n';
import { withApolloAsyncLoad } from '../../../base/utils';
import { CRUD } from './queries';
import UserListItem from './UserListItem';

const b = block('UserView');

export default
class UserView extends React.PureComponent {
  static title = I18N.USERS_INFO_TITLE

  static propTypes = {
    itemId: PropTypes.number.isRequired,
  }

  _renderView = (user) => <div className={b()}>
    <UserListItem item={user}/>
  </div>;

  render() {
    return (
      CRUD.GET_ONE(
        {id: this.props.itemId},
        (result) => withApolloAsyncLoad(this._renderView, result)
      )
    );
  }
}
