import * as ExcelJs from 'exceljs/dist/exceljs.min.js';
import Stream from 'stream';
import { decimalHoursToTime, getData, decodeDateRU } from 'base/utils';
import fileDownload from 'js-file-download';
import {
  loadWaybillsAsync,
  loadPointsAsync,
  loadManifestDataAsync,
  loadCouriersAsync
} from 'modules/manifest/utils';

const CustomActions = {
  getGarbageWaybillXML: async (manifestId) => {
    const DATA_STARTS_FROM = 21;

    const data = await getData('/static/custom_solutions/udmurt_vtor_product/template.xlsx');
    const manifestData = await loadManifestDataAsync(manifestId);
    const waybills = await loadWaybillsAsync(manifestId);
    const points = await loadPointsAsync(manifestId);
    const couriers = await loadCouriersAsync(manifestId);

    const workbook = new ExcelJs.Workbook();
    workbook.xlsx.load(data)
      .then(() => {
        const dest = new Stream();
        dest.write = function(fileData) {
          fileDownload(fileData, `Маршрут №${manifestId} от ${decodeDateRU(manifestData.date)} (УдмуртВторПродукт).xlsx`);
        };

        const pointsArray = [];
        points.forEach((p) => (pointsArray[p.id] = p));
        const worksheet = workbook.getWorksheet(1);

        couriers.forEach((c) => {
          const newSheet = workbook.addWorksheet('empty');
          newSheet.model = {...worksheet.model, name: c.name, id: c.id };

          const courierData = [];
          const courierWaybills = waybills.filter((w) => w.courier_id === c.id);
          const driverName = c.driver ? c.driver.name : '';
          let rows = 0;
          let totalWeight = 0;

          courierWaybills.forEach((w, i) => {
            totalWeight = w.total_weight || totalWeight;
            courierData.push([
              w.points_served,
              pointsArray[w.point_id].lat_lon.join(' - '),
              decimalHoursToTime(w.departure_time),
              'Н',
              pointsArray[w.point_id].depot ? pointsArray[w.point_id].address : ' - ',
              pointsArray[w.point_id].depot && w.arrival_time > 0 ? decimalHoursToTime(w.arrival_time) : ' - ',
              pointsArray[w.point_id].depot ? courierWaybills[i - 1].loaded_weight : ' - ',
              pointsArray[w.point_id].comment || ' - ',
            ]);
          });

          newSheet.spliceRows.apply(newSheet, [DATA_STARTS_FROM, 0, ...courierData]);

          newSheet.getCell('A3').value = `Маршрутный журнал № ${manifestId}, ${new Date(manifestData.date).getUTCFullYear()} г. о движении мусоровоза и загрузке (выгрузке) твердых коммунальных отходов`;
          newSheet.getCell('A12').value = c.name;
          newSheet.getCell('C15').value = driverName || ' - ';
          newSheet.getCell('B16').value = decodeDateRU(manifestData.date);
          newSheet.getCell('B17').value = decodeDateRU(manifestData.date);
          newSheet.getCell('A20').value = `Дата: ${decodeDateRU(manifestData.date)}\r\n г. Ижевск`;

          newSheet.mergeCells('A1', 'H1');
          newSheet.mergeCells('A20', 'C20');
          newSheet.mergeCells('D20', 'E20');
          newSheet.mergeCells('F20', 'H20');

          newSheet.eachRow((row, rowNumber) => {
            rows = rowNumber;
            row.eachCell.call(row, (cell) => {
              if (rowNumber >= DATA_STARTS_FROM - 2 && rowNumber <= courierData.length + DATA_STARTS_FROM) {
                cell.border = {
                  top: { style: 'thin' },
                  left: { style: 'thin' },
                  bottom: { style: 'thin' },
                  right: { style: 'thin' }
                };

                cell.alignment = {
                  vertical: 'middle',
                  horizontal: 'center',
                  wrapText: true
                };
              }
            });
          });

          newSheet.mergeCells(`A${rows - 3}`, `D${rows - 3}`);
          newSheet.getCell(`G${rows - 3}`).value = totalWeight;
        });
        workbook.removeWorksheet(worksheet.id);
        workbook.xlsx.write(dest);
      });
  }
};

export default CustomActions;
