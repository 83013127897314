import React from 'react'
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';

const b = block('Input');


// table input field 
export default class TableInput extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			values: []
		}
	}

	static fieldName = 'table'

	componentDidMount(){
		console.log(this.props)
	}

	// шатка таблицы
	confHead(){
		let config = this.props.config ? this.props.config : []
		let content = []
		config.map((x, id) => {
			content.push(
				<th key={id}>
					{x.name}
				</th>
			)
		})
		content.push(
			<th key='act'>
				<button
					onClick={() => this.addEmpty()}
				>
					Добавить
				</button>
			</th>
		)
		return content
	}

	// удалить строку
	removeEmpty(id){
		console.log('del id = ' + id)
		// let values = this.state.values
		let values = this.props.value || []
		values.splice(id, 1)
		// this.setState({ values: values })
		this.props.onChangeValue(this.props.name, values)
	}

	// добавить строку
	addEmpty(){
		console.log('add')
		let config = this.props.config ? this.props.config : []
		// let values = this.state.values
		let values = this.props.value || []
		let tmp = {}
		config.map((x, id) => { tmp[x.name] = '' })
		values.push(tmp)
		console.log(values)
		// this.setState({ values: values })
		console.log(this.props.name + ' *****')
		console.log(this.props.value)
		this.props.onChangeValue(this.props.name, values)
	}

	// установить значение полю в строке
	setValue(id, f, val){
		// let values = this.state.values
		let values = this.props.value || []
		values[id][f] = val
		// this.setState({ values: values })
		this.props.onChangeValue(this.props.name, values)
	}


	// тело таблицы
	confBody(){
		let config = this.props.config
		let values = this.props.value || []
		let result = []
		values.map((v, id) => {
			let content = []
			// let fields = Object.keys(v)
			let fields = config.map( x => { return x.name })
			fields.map((f, idf) => {
				let type = config[idf].type
				content.push(
					<td key={id + '-' + idf}>
						<input 
							className={b()}
							type={type} 
							placeholder='значение'
							value={v[f]} 
							onChange={(e) => this.setValue(id, f, e.target.value)}
						/>
					</td>
				)
			})
			content.push(
				<td key={id + '-remove'}>
					<button
						onClick={() => this.removeEmpty(id)}
					>
						X
					</button>
				</td>
			)
			result.push(
				<tr key={id}>{content}</tr>
			)
		})
		return result
	}

	render(){
		
		return(
			<table className='table-field'>
				<thead>
					<tr>
						{ this.confHead() }
					</tr>
				</thead>
				<tbody>
					{ this.confBody() }
				</tbody>
			</table>
		)
	}
}


