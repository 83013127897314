import gql from 'graphql-tag';

export
const queries = {
  CURRENT_USER: gql`
    query CURRENT_USER {
        result {
            id
            name
            email
            ui_info
            measure
            reports
            org_id,
            use_tracking,
            subscription_expires
        }
    }
  `
};
