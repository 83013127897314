import React from 'react';
import { PropTypes } from 'prop-types';

import { 
  AppSections, 
  SectionMatchParams as SMP, 
  // Forms 
} from '../../common/enums';
import { sections } from '../index';
import ManifestTab from '../../manifest/components/ManifestTab';
import { store } from '../../..';


export default
class SectionTab extends React.PureComponent {
  static propTypes = {
    [SMP.MANIFEST_ID]: PropTypes.number,
    [SMP.SECTION]: PropTypes.string,
    [SMP.ITEM_ID]: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    [SMP.ITEM_STATUS]: PropTypes.string,
    sourceActions: PropTypes.object.isRequired,
    orgId: PropTypes.number,
  }

  UNSAFE_ыcomponentWillUpdate() {
    store.dispatch(this.props.sourceActions.closeAuxilaryTab());
  }

  _getComponent = (section, itemId, itemStatus) => {
    if (!itemId)
      return sections[section].main;
    else if (!isNaN(itemId)) {
      if (!itemStatus)
        return sections[section].view;
      else if (itemStatus === 'edit')
        return sections[section].edit;
    }
    else
      return sections[section][itemId];
    return new Error('Bad tab data');
  }

  render() {
    if (!this.props[SMP.SECTION])
      return null;

    const Component = this._getComponent(
      this.props[SMP.SECTION],
      this.props[SMP.ITEM_ID],
      this.props[SMP.ITEM_STATUS]
    );

    return <ManifestTab
      title={Component.title}
      noBackButton={false}
      closeLink={this.props[SMP.MANIFEST_ID]
        ? `/${AppSections.MANIFEST}/${this.props[SMP.MANIFEST_ID]}`
        : `/${AppSections.CATALOG}`
      }
    >
      <Component
        {...this.props}
        manifestId={this.props.manifestId || -this.props.orgId}
        itemElement={sections[this.props[SMP.SECTION]].item}
        crud={sections[this.props[SMP.SECTION]].crud} />
    </ManifestTab>;
  }
}
