import React from 'react';
import { PropTypes } from 'prop-types';
import { toast } from 'react-toastify';
import { push } from 'react-router-redux';

import { CRUD } from './queries';
import I18N from '../../common/i18n';
import { withApolloAsyncLoad, getSectionLinkBody } from '../../../base/utils';
import { store } from '../../..';
import { Sections } from '../../common/enums';
import PointForm from './PointForm';
import { actions as sectionActions} from '../common/actions';

export default
class PointEdit extends React.PureComponent {
  static title = ''

  static propTypes = {
    itemId: PropTypes.number.isRequired,
    manifestId: PropTypes.number,
  }

  _update = (update, data, pointTitle) => {
    const p = update({
      variables: {
        id: this.props.itemId,
        data: data
      }
    });
    p.then((res) => {
      toast.success(`${pointTitle}. ${I18N.NOTIFY_SUCCESS}`);
      store.dispatch(push(
        `${getSectionLinkBody(this.props.manifestId)}${Sections.POINTS}/${res.data.result.id}`
      ));
      store.dispatch(sectionActions.pointCoordsUpdated(this.props.itemId, data.lat_lon, 'form'));
      store.dispatch(sectionActions.pointUpdated(this.props.itemId, res.data.result, 'form'));
    });
    p.catch((res) => {
      toast.error(`${pointTitle}. ${I18N.NOTIFY_ERROR}`);
      console.log('update error');
    });
    return p;
  }

  _renderForm = (point) => CRUD.UPDATE(
    {},
    (update) => {
      const pointTitle = (point.garage || point.depot) ? I18N.POINTS_EDIT_BASE_TITLE : I18N.POINTS_EDIT_TITLE;
      return <PointForm
        {...this.props}
        submitText={I18N.SUBMIT_SAVE}
        item={point}
        title={pointTitle}
        isBase={point.garage || point.depot}
        submit={(data) => this._update(update, data, pointTitle)} />;
    }
  )

  render() {
    return (
      CRUD.GET_ONE(
        {id: this.props.itemId},
        (result) => withApolloAsyncLoad(this._renderForm, result)
      )
    );
  }
}
