import React from 'react';
import block from 'bem-cn-lite';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { LayoutMainMenu } from '.';
import { actions as layoutActions } from '../actions';

const b = block('Layout');

const Layout = ({
  children,
  actions
}) => (
  <div className={b()}>
    <div className={b('Menu')}>
      <LayoutMainMenu actions={actions}/>
    </div>
    <div className={b('Content')}>
      {children}
    </div>
  </div>
);

export default
connect(
  null,
  (dispatch) => ({
    "actions": bindActionCreators(layoutActions, dispatch)
  })
)(Layout);
