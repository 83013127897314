import gql from 'graphql-tag';

import bindApollo from '../common/queries';
import { Sections } from '../../common/enums';

const listFragment = `
  id,
  name,
  address,
  weight,
  volume,
  backhaul_weight,
  backhaul_volume,
  garage,
  depot,
  lat_lon,
  work_start,
  work_end,
  break_start,
  break_end,
  shipping_time,
  pickup_point,
  from_id,
`;

const pointFragment = `
  ${listFragment}
  priority,
  comment,
  courier_id,
  contact_person,
  phone,
  extra_reqs,
  summa,
  mandatory,
  vehicle_flags,
  track_code,
  curbside
`;

const crud = {
  SECTION: Sections.POINTS,

  CREATE: gql`
    mutation CREATE_MANIFEST_POINT($manifest_id: int!, $data: String!) {
      result(manifest_id: $manifest_id data: $data) {
        ${pointFragment}
      }
    }
  `,

  DELETE: gql`
    mutation DELETE_MANIFEST_POINT($id: Int!) {
      result(id: $id)
    }
  `,

  DELETE_LIST: gql`
    mutation DELETE_MANIFEST_POINTS($data: Array!) {
      result(data: $data)
    }
  `,

  LIST: gql`
    query MANIFEST_POINTS ($limit: Int $offset: Int $manifest_id: Int $title_filter: String $complete: Boolean) {
      result(limit: $limit offset: $offset manifest_id: $manifest_id title_filter: $title_filter complete: $complete)
      @connection(key: "points", filter: ["title_filter", "manifest_id", "complete"]) {
        ${listFragment}
      }
    }
  `,

  GET_ONE: gql`
    query MANIFEST_POINT ($id: Int!) {
      result(id: $id) {
        ${pointFragment}
      }
    }
  `,

  UPDATE: gql`
    mutation UPDATE_MANIFEST_POINT($id: Int!, $data: Point!) {
      result(id: $id, data: $data) {
        ${pointFragment}
      }
    }
  `,

  CLONE: gql`
    mutation COPY_POINTS($from_id: Int $to_id: Int $with_linked_couriers: Bool $with_linked_zones: Bool) {
      result(from_id: $from_id to_id: $to_id with_linked_couriers: $with_linked_couriers with_linked_zones: $with_linked_zones) {
        null
      }
    }
  `
};

export
const queries = {
  ...crud,

  LIST_SPECIAL: gql`
    query MANIFEST_SPECIAL_POINTS ($manifest_id: Int) {
      result(manifest_id: $manifest_id crutch: "special_points") {
        ${pointFragment}
      }
    }
  `,

  IMPORT_POINTS: gql`
    mutation MANIFEST_POINTS_IMPORT($manifest_id: Int!, $data: Array!) {
      result(manifest_id: $manifest_id, data: $data) {
        ${pointFragment}
      }
    }
  `,

  UPDATE_POINTS: gql`
    mutation UPDATE_MANIFEST_POINTS($id: Int!, $data: Point!) {
      result(data: $data) {
        ${pointFragment}
      }
    }
  `,

  LIST_DETAILED: gql`
    query MANIFEST_POINTS_DETAILED ($manifest_id: Int) {
      result(manifest_id: $manifest_id)
      @connection(key: "points", filter: ["title_filter", "manifest_id"]){
        ${pointFragment}
      }
    }
  `
};

export
const CRUD = bindApollo(crud);
