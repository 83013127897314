import React from 'react';
import { PropTypes } from 'prop-types';
import { toast } from 'react-toastify';

import IncrementalList from '../../sections/common/IncrementalList';
import { withApolloAsyncLoad } from '../../../base/utils';
import I18N from '../../common/i18n';

class SectionListView extends React.PureComponent {
	static propTypes = {
		qListQuery: PropTypes.object.isRequired,
		qDeleteQuery: PropTypes.func.isRequired,
		itemsPerPage: PropTypes.number,
		variables: PropTypes.object,
		itemElement: PropTypes.func.isRequired,
		manifestId: PropTypes.number,
		onDeleted: PropTypes.func
	};

	componentDidMount() {
		this.moreData = true;
	}

	componentDidUpdate(prevProps) {
	  if (prevProps.variables !== this.props.variables) {
	    this.props.qListQuery.refetch(this.props.variables);
	    this.moreData = true;
	  }
	}

	loadMore = () => {
		const data = this.props.qListQuery.data;
		if (data && data.result && this.moreData && this.props.qListQuery.variables.limit) {
			this.moreData = false;
			this.props.qListQuery.fetchMore({
			  variables: {
			    ...this.props.variables,
			    'offset': data.result.length,
			  },
			  updateQuery: (previousResult, { fetchMoreResult }) => {
			    this.moreData = fetchMoreResult.result && fetchMoreResult.result.length === this.props.itemsPerPage;
			    return {
			      ...previousResult ? previousResult.result : [],
			      result: [
			        ...previousResult.result ? previousResult.result : [],
			        ...fetchMoreResult.result ? fetchMoreResult.result : [],
			      ],
			    };
			  }
			});
		}
	}

	doDelete = (itemId) => {
		const p = this.props.qDeleteQuery({
			variables: {
				id: itemId
			}
		});
		p.then((res) => {
			this.props.onDeleted && this.props.onDeleted(itemId);
			toast.success(`${I18N.NOTIFY_DELETE} ${I18N.NOTIFY_SUCCESS}`);
			// console.log('deleted');
			this.forceUpdate()
		});
		p.catch((res) => {
			toast.error(`${I18N.NOTIFY_DELETE} ${I18N.NOTIFY_ERROR}`);
			console.log('delete error');
		});
	}

	_renderItemList = (items) => <IncrementalList
		manifestId={this.props.manifestId}
		loadMore={this.loadMore}
		doDelete={this.doDelete}
		itemElement={this.props.itemElement}
		items={items}
	/>;

	render() {
		const result = this.props.qListQuery;
		return withApolloAsyncLoad(this._renderItemList, result);
	}
}

export default function SectionList(props) {
	return (
		props.queries.LIST(
			props.variables,
			(qListQuery) => (
				props.queries.DELETE(
					props.variables,
					(qDeleteQuery) => (
						<SectionListView
							qListQuery={qListQuery}
							qDeleteQuery={qDeleteQuery}
							{...props}
						/>
					)
				)
			)
		)
	);
}
