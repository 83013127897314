import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';

import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider, KeyboardTimePicker } from "@material-ui/pickers"

import {
	toTimepicker,
	fromTimepicker,
	// decimalHoursToTime,
	// timeToDecimalHours,
	encodeDate,
	decodeDate,
} from '../../base/utils';

const b = block('Input');

class SimpleInput extends React.PureComponent {
	static propTypes = {
		size: PropTypes.number,
		caption: PropTypes.string,
		type: PropTypes.string.isRequired,
		name: PropTypes.string,
		placeHolder: PropTypes.string,
		value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
		onChangeValue: PropTypes.func,
		toView: PropTypes.func,
		fromView: PropTypes.func,
		disabled: PropTypes.bool,
		autoComplete: PropTypes.string,
		min: PropTypes.number,
		max: PropTypes.number,
		step: PropTypes.number,
	}

	_onChange = (e) => {
		if(this.props.type !== 'time'){
			this.props.onChangeValue
			&& this.props.onChangeValue(e.target.name, this.props.fromView ? this.props.fromView(e.target.value) : e.target.value);
		} else {
			let tt = fromTimepicker(e)
			this.props.onChangeValue(this.props.name, tt);
		}
	}

	render() {
		return (
			<React.Fragment>
				{
					this.props.type !== 'time' ? (
						<input
							autoComplete={this.props.autoComplete}
							className={b()}
							id={this.props.name}
							min={this.props.min}
							max={this.props.max}
							step={this.props.step}
							type={this.props.type}
							name={this.props.name}
							placeholder={this.props.placeHolder}
							value={(this.props.toView ? this.props.toView(this.props.value) : this.props.value) || ''}
							onChange={this._onChange}
							disabled={this.props.disabled}
						/>
					) : (
						<MuiPickersUtilsProvider utils={MomentUtils}>
							<KeyboardTimePicker
								className='timepicker-div'
								clearable
								ampm={false}
								format="HH:mm"
								disabled={this.props.disabled}
								value={this.props.toView ? this.props.toView(this.props.value) : this.props.value}
								inputProps={{
									className: 'timepicker-ui',
									name: this.props.name
								}}
								onChange={this._onChange}
								okLabel='ОК'
								cancelLabel='Отмена'
								clearLabel='Сброс'
								invalidDateMessage=''
							/>
						</MuiPickersUtilsProvider>
					)
				}
			</React.Fragment>
		);
	}
}

export const HiddenInput = (props) => (
	<SimpleInput
		fromView={String}
		type='hidden'
		{...props} 
	/>
);
HiddenInput.fieldName = 'hidden';

export const TextInput = (props) => (
	<SimpleInput
		fromView={String}
		type='text'
		{...props} 
	/>
);
TextInput.fieldName = 'string';

export const DateInput = (props) => (
	<SimpleInput
		toView={decodeDate}
		fromView={encodeDate} 
		type='date'
		{...props}
	/>
);
DateInput.fieldName = 'date';

export const TimeInput = (props) => (
	<SimpleInput
		// toView={decimalHoursToTime}
		// fromView={timeToDecimalHours}
		toView={toTimepicker}
		fromView={fromTimepicker}
		type='time'
		{...props}
	/>
);
TimeInput.fieldName = 'time';

export const NumberInput = (props) => (
	<SimpleInput
		toView={(val) => val === null ? '' : String(val)}
		fromView={(val) => {
		if (val.length) {
			let num = Number(val.replace(',', '.'));
			num = props && isFinite(props.min) && num < props.min ? props.min : num;
			num = props && isFinite(props.max) && num > props.max ? props.min : num;
			return num;
		}
		return null;
		} }
		type='number'
		{...props}
	/>
);
NumberInput.fieldName = 'number';

export const RangeInput = (props) => (
  <div className={b('Range')}>
    <SimpleInput
		toView={(val) => val === null ? '' : String(val)}
		fromView={(val) => val.length ? Number(val) : null }
		type='range'
		{...props}
    />
    <div className={b('RangeValue')}>{props.value}</div>
  </div>
);
RangeInput.fieldName = 'range';

export const PasswordInput = (props) => (
	<SimpleInput
		fromView={String}
		type='password'
		{...props}
	/>
);
PasswordInput.fieldName = 'password';

export const EmailInput = (props) => (
	<SimpleInput
		fromView={(val) => val.length ? String(val) : null}
		type='email'
		{...props}
	/>
);
EmailInput.fieldName = 'email';
