import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';
import { Sections } from '../../common/enums';
import { connect } from 'react-redux';
import { reducer, actions } from './actions';
import I18N from '../../common/i18n';

const b = block('IncrementalList');

class IncrementalList extends React.PureComponent {
  static propTypes = {
    loadMore: PropTypes.func.isRequired,
    doDelete: PropTypes.func,
    itemElement: PropTypes.any,
    items: PropTypes.array,
    manifestId: PropTypes.number,
    [Sections.POINTS]: PropTypes.number.isRequired,
    [Sections.COURIERS]: PropTypes.number.isRequired,
    [Sections.ZONES]: PropTypes.number.isRequired,
    saveScrollPosition: PropTypes.func,
    getKey: PropTypes.func
  }

  constructor() {
    super();
    this.scrollBox = null;
    this.scrollCheckDelay = true;
  }

  componentDidUpdate() {
    if (this._scrolledForNewData())
      this.props.loadMore(); 
  }

  componentDidMount() {
    if (this.props.itemElement.section)
      this._setScrollPosition(this.props.itemElement.section);

    if (this._scrolledForNewData())
      this.props.loadMore();
  }

  componentWillUnmount() {
    if (this.props.itemElement.section)
      this.props.saveScrollPosition(this.props.itemElement.section, this.scrollBox.scrollTop);
  }

  _setScrollPosition = (section) => {
    this.scrollBox.scrollTop = this.props[section];
  }

  _scrolledForNewData = () => {
    const box = this.scrollBox;
    const sizeTolerance = 2;
    return box && box.scrollHeight <= box.scrollTop + box.offsetHeight + sizeTolerance;
  }

  _fetchScrollCheck = () => {
    if (this.scrollCheckDelay && this._scrolledForNewData()) {
      this.props.loadMore();
      this.scrollCheckDelay = false;
      setTimeout(() => (this.scrollCheckDelay = true), 70);
    }
  }

  render() {
    const ListItem = this.props.itemElement;
    const elements = [];
    if (this.props.items.length)
      this.props.items.forEach((item, i) => elements.push(
        <ListItem
          key={this.props.getKey ? this.props.getKey(item) : item.id}
          id={item.id}
          listNumber={i + 1}
          manifestId={this.props.manifestId}
          onDelete={this.props.doDelete}
          item={item}/>
      ));
    else
      elements.push(<div key={'empty'} className={b('NoItems')}>{I18N.MISC_LIST_NO_ITEMS}</div>);
    return (
      <div className={b()}>
        <div className={b('ScrollBox')} onScroll={this._fetchScrollCheck} ref={(ref) => { this.scrollBox = ref; }} >
          <ul className={b('List')}>
            {elements}
          </ul>
        </div>
      </div>
    );
  }
}

export default
connect(
  (state) => ({
    [Sections.POINTS]: state[reducer.name].listScrollPosition[Sections.POINTS],
    [Sections.COURIERS]: state[reducer.name].listScrollPosition[Sections.COURIERS],
    [Sections.ZONES]: state[reducer.name].listScrollPosition[Sections.ZONES],
  }),
  (dispatch) => ({
    "saveScrollPosition": (section, position) => dispatch(actions.saveScrollPosition(section, position)),
  })
)(IncrementalList);
