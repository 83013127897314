import { makeReducer } from '../../base/reducer';


const init = {
  menuCollapsed: true
};

export const reducer = makeReducer('layout', init);

export const actions = {
  'collapseMenu': reducer.makeAction(
    (collapsed) => ({collapse: collapsed}),
    (state, action, store) => {
      store({menuCollapsed: action.collapse});
      return {...state, menuCollapsed: action.collapse};
    },
    'COLLAPSE_MENU'
  ),
};
