import React from 'react';
import { PropTypes } from 'prop-types';
import { Query, Mutation } from 'react-apollo';
import block from 'bem-cn-lite';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { store } from 'index';

import { queries as manifestQueries } from '../../manifest/queries';
import { actions as manifestActions } from '../../manifest/actions';
import { reducer as mapReducer, actions as mapActions } from '../../map/actions';
import {
  // reducer as updateStateReducer, 
  actions as updateStateActions
} from '../../common/updateStateReducer';
import I18N from '../../common/i18n';
import DataBadge from '../common/DataBadge';
import { OptimizationStatus } from '../../manifest/enums';
import ConfirmButton from '../../common/components/ConfirmButton';
import { ButtonKinds, AppSections, Sections } from '../../common/enums';
import { client, routeTo } from '../../..';
import WaybillPointListItem from '../points/WaybillPointListItem';
import { queries as pointQueries } from '../points/queries';
import SectionListPreloaded from '../common/SectionListPreloaded';
import { ITEMS_PER_PAGE } from '..';
import { 
  // TextLink, 
  Button 
} from '../../common/components';

const b = block('ManifestDetails');

class ManifestDetails extends React.PureComponent {
  static title = I18N.DETAILS_TAB

  static propTypes = {
    manifestId: PropTypes.number.isRequired,
    refetchManifestData: PropTypes.func,
    editedRoutes: PropTypes.object,
  }

  constructor(props) {
    super(props);
    this.state = {
      editedWaybills: props.editedRoutes[props.manifestId] ? props.editedRoutes[props.manifestId].length : 0,
      pointsWaybillMissed: [],
      pointsList: [],
    };
    this._loadPointsMissedWaybill();
    this._loadPoints();
  }

  _loadPointsMissedWaybill = async () => {
    const { data } = await client.query({
      query: manifestQueries.POINTS_WAYBILL_LIST,
      variables: {
        'manifest_id': this.props.manifestId,
      },
    });

    if (data.result && data.result.length) {
      const res = data.result.filter((v) => v.error_code);
      this.setState({ pointsWaybillMissed: res });
    }
  }

  _loadPoints = async () => {
    const { data } = await client.query({
      query: pointQueries.LIST,
      variables: {
        'manifest_id': this.props.manifestId,
      },
    });

    this.setState({ pointsList: data.result });
  }

  _getStatus = (optimizationStatus) => {
    const statusPart = optimizationStatus && optimizationStatus.split(' ')[0];

    if (!statusPart)
      return I18N.DETAILS_STATUS_READY;
    if (statusPart === OptimizationStatus.ACCEPTED
      || statusPart === OptimizationStatus.ENQUEUED
      || statusPart === OptimizationStatus.ACCEENQUEUEDPTED
    )
      return I18N.DETAILS_STATUS_ENQUEUED;
    if (statusPart === OptimizationStatus.PREPARING
      || statusPart === OptimizationStatus.PROCESSING
    )
      return I18N.DETAILS_STATUS_PROCESSING;
    if (statusPart === OptimizationStatus.ERROR)
      return I18N.DETAILS_STATUS_ERROR;

    return new Error('Unknown manifest status');
  };

  _getErrorMessage = (optimizationError) => {
    if (optimizationError && optimizationError['code']) {
      const regExp = /((.*?)%(\w+)%)+?/ugm;
      const msg = I18N[`ERROR_${optimizationError['code']}`];
      if (msg)
        return msg.replace(regExp, (match, g1, g2, g3) => `${g2}${optimizationError.params[g3]}`);
      return I18N.ERROR_CODE_UNKNOWN;
    }
    return '';
  }

  _getStatusKindModifires = (optimizationStatus) => {
    const statusPart = optimizationStatus && optimizationStatus.split(' ')[0];

    if (!statusPart)
      return {'Ok': true};
    if (statusPart === OptimizationStatus.ENQUEUED
      || statusPart === OptimizationStatus.ACCEPTED
      || statusPart === OptimizationStatus.PREPARING
      || statusPart === OptimizationStatus.PROCESSING
    )
      return {'Warning': true};
    if (statusPart === OptimizationStatus.ERROR)
      return {'Error': true};

    return new Error('Unknown manifest status');
  }

  _update = (update, manifestId) => {
    const p = update({
      variables: {
        'manifest_id': manifestId
      }
    });
    p.then((res) => {
      routeTo(`/${AppSections.MANIFEST}/${this.props.manifestId}`);
      this.props.clearAssignments();
      this.props.clearRoutes(this.props.manifestId);
      this.props.refetchManifestData();
      toast.success(`${I18N.DETAILS_CLEAR_CALCULATION}. ${I18N.NOTIFY_SUCCESS}`);
    });
    p.catch((res) => {
      toast.error(`${I18N.DETAILS_CLEAR_CALCULATION}. ${I18N.NOTIFY_ERROR}`);
    });
    return p;
  }

  componentWillUnmount() {
    store.dispatch(mapActions.updateSelectedPoints([]));
  }

  render() {
    const linkedPoints = this.state.pointsWaybillMissed.map((item, i) => ({
      ...item,
      ...this.state.pointsList.find((point) => point.id === item.point_id),
      id: i,
      onClick: () => store.dispatch(mapActions.updateSelectedPoints([item.point_id]))
    }));

    return <Query
      query={manifestQueries.QUERY_MANIFEST}
      variables={{'id': this.props.manifestId}}
      fetchPolicy={'cache-only'}
    >
      {(result) => {
        const manifest = result.data.result;
        if (manifest)
          return <div className={b()}>
            <div className={b('CommonInfo')}>
              <div className={b('Header')}>
                <div className={b('Status', this._getStatusKindModifires(manifest['optimization_status']))}>
                  {this._getStatus(manifest['optimization_status'])}
                </div>
                <div className={b('Message')}>
                  {this._getErrorMessage(manifest['optimization_error_info'])}
                </div>
              </div>
              <div className={b('Badges')}>
                <DataBadge rightHint={I18N.DETAILS_POINTS_COUNT_HINT} caption={I18N.DETAILS_POINTS_COUNT} value={manifest['points_count']}/>
                <DataBadge leftHint={I18N.DETAILS_TOTAL_TIME_HINT} caption={I18N.DETAILS_TOTAL_TIME} value={I18N.formatDecimalHours(manifest['total_time'])}/>
                <DataBadge rightHint={I18N.DETAILS_COURIERS_COUNT_HINT} caption={I18N.DETAILS_COURIERS_COUNT} value={manifest['couriers_count']}/>
                <DataBadge leftHint={I18N.DETAILS_TOTAL_DISTANCE_HINT} caption={I18N.DETAILS_TOTAL_DISTANCE} value={I18N.formatDistance(manifest['total_distance'])}/>
                <DataBadge rightHint={I18N.DETAILS_TOTAL_COST_HINT} caption={I18N.DETAILS_TOTAL_COST} value={manifest['total_cost']}/>
                <DataBadge leftHint={I18N.DETAILS_TOTAL_WEIGHT_HINT} caption={I18N.DETAILS_TOTAL_WEIGHT} value={manifest['total_weight']}/>
                <DataBadge rightHint={I18N.EDIT_WAYBILLS_TITLE} caption={I18N.EDIT_WAYBILLS_COURIERS_EDITED} value={this.state.editedWaybills}/>
                <DataBadge leftHint={I18N.DETAILS_CALC_TIME_HINT} caption={I18N.DETAILS_CALC_TIME} value={manifest['calc_time']}/>
              </div>
            </div>
            <div className={b('Controls')}>
              <Button to={`/${AppSections.MANIFEST}/${this.props.manifestId}/${Sections.EDIT_WAYBILL}`}>{I18N.EDIT_WAYBILLS_LINK}</Button>
              <Mutation mutation={manifestQueries.CLEAR_OPTIMIZATION_QUERY}>{
                (update) => <ConfirmButton
                  confirmMessage={I18N.DETAILS_CLEAR_CALCULATION_CONFIRM}
                  confirmKind={ButtonKinds.DANGER}
                  onClick={() => this._update(update, this.props.manifestId)}
                >{I18N.DETAILS_CLEAR_CALCULATION}</ConfirmButton>}
              </Mutation>
            </div>
            <hr/>
            {linkedPoints.length ? [
              <h2 key={'title'} >{I18N.POINTS_INFO_WAYBILLS_MISSED}</h2>,
              <SectionListPreloaded
                key={this.props.manifestId}
                manifestId={this.props.manifestId}
                items={linkedPoints}
                variables={{
                  'limit': ITEMS_PER_PAGE,
                  'offset': 0
                }}
                itemElement={WaybillPointListItem}
              />
            ] : null}
          </div>;
        return null;
      }}
    </Query>;
  }
}

export default
connect(
  (state) => ({
    'editedRoutes': state[mapReducer.name].editedRoutes
  }),
  (dispatch) => ({
    'clearRoutes': (manifestId) => dispatch(mapActions.clearRoutes(manifestId)),
    'refetchManifestData': () => dispatch(manifestActions.refetchData()),
    clearAssignments: () => dispatch(updateStateActions.clearAssignments()),
  })
)(ManifestDetails);
