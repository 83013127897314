import React from 'react';
import block from 'bem-cn-lite';
import DivIcon from 'react-leaflet-div-icon';
import parseHtml from 'html-react-parser';
import ReactDOMServer from 'react-dom/server';

import { PropTypes } from 'prop-types';
import { Sections, AppSections } from '../common/enums';
import { Icon } from '../common/components';
import { getPaletteColorClass } from './../../base/utils';
import icons from '../common/icons';
import { routeTo } from '../..';

const b = block('LeafletMapTrackMarker');

export default
class LeafletMapTrackMarker extends React.Component {

  static propTypes = {
    marker: PropTypes.object.isRequired,
    manifestId: PropTypes.number.isRequired,
  }

  constructor() {
    super();
    this.markerHtml = null;
    this.markerElement = null;
  }

  _updateMarkerElement = (props) => {
    const html = this._renderHtmlMarker(props.marker.courierId, props.marker.time);
    this.markerHtml = html;
    this.markerElement = parseHtml(html);
  }

  USAFE_componentWillMount() {
    this._updateMarkerElement(this.props);
  }

  UNSAFE_componentWillUpdate(nextProps) {
    this._updateMarkerElement(nextProps);
  }

  _renderHtmlMarker = (courierId, time) => `
    <div class='${b('BeakShadow')}'></div>
    <div class='${b('Beak')}'></div>
    <div class='${b('Body')}'>
      <span>${
  ReactDOMServer.renderToStaticMarkup(
    <Icon className={`${getPaletteColorClass(courierId)} ${b('Icon')}`} svgName={icons.ITEM_TRUCK}/>
  )}</span>
    </div>
  ${time && `<div class='${b('Time')}'>${time}</div>`}`;

  _routeToThisCourier = () => routeTo(`/${AppSections.MANIFEST}/${this.props.manifestId}/${Sections.COURIERS}/${this.props.marker.courierId}`)

  render() {
    return <DivIcon
      onClick={this._routeToThisCourier}
      className={b()}
      key={this.props.marker.courierId}
      iconSize={[32, 42]}
      iconAnchor={[16, 42]}
      position={this.props.marker.coords}
      html={this.markerHtml}
    >
      {this.markerElement}
    </DivIcon>;
  }
}
