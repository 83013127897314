import React from 'react';
import { PropTypes } from 'prop-types';

import ListItem from '../common/ListItem';
import { Sections } from '../../common/enums';

export default
class FlagListItem extends React.PureComponent {

  static section = Sections.ZONES;

  static propTypes = {
    item: PropTypes.object.isRequired,
  }

  render() {
    const flag = this.props.item;

    return (
      <ListItem
        id={flag.id}
        key={flag.id}
        title={flag.name}
      />
    );
  }
}
