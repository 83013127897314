import React from 'react';
import { PropTypes } from 'prop-types';
import block from 'bem-cn-lite';
import icons from '../icons';
import Button from './Button';
import I18N from '../i18n';

const b = block('DropDownContainer');

export default
class DropDownContainer extends React.PureComponent {
  static propTypes = {
    children: PropTypes.any,
  }

  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
    };
  }

  _containerToggle = () => this.setState({ collapsed: !this.state.collapsed});

  render() {
    return <div className={b()}>
      <Button
        onClick={this._containerToggle}
        className={b('Button')}
        icon={icons.COMMON_DOTS}
        leftHint={this.state.collapsed ? I18N.MISC_DROP_DOWN_CONTAINER : ''}
      />
      {!this.state.collapsed
        && <div className={b('Container')}>
          {this.props.children}
        </div>}
    </div>;
  }
}
