import React from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn-lite';

import Icon from './../Icon';
import icons from '../../icons';
import { TextInput } from '../../../inputs';

const b = block('TextFilterInput');

export default class TextFilterInput extends React.PureComponent {
	static propTypes = {
		placeHolder: PropTypes.string,
		onChangeValue: PropTypes.func.isRequired,
	}

	constructor(props) {
		super(props);
		this.state = {
			inputValue: '',
			filterValue: '',
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.filterValue !== this.state.filterValue)
		this.props.onChangeValue(this.state.filterValue);
	}

	clearFilter = () => {
		this.setState({
			inputValue: '',
			filterValue: '',
		});
	}

	confirmValue = (name, value) => {
		this.setState({inputValue: value});

		if (this.delayTimer)
			clearTimeout(this.delayTimer);

		this.delayTimer = setTimeout(() => {
			this.setState({filterValue: value});
		}, 800);
	}

	render() {
		return (
			<div className={b()}>
				<TextInput 
					value={this.state.inputValue} 
					placeHolder={this.props.placeHolder} 
					onChangeValue={this.confirmValue}
				/>
				<div 
					className={b('ClearButton')} 
					onClick={this.clearFilter}
				>
					<Icon 
						svgName={icons.COMMON_CLEAR_FIELD}
					/>
				</div>
			</div>
		);
	}
}
