import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';

import I18N from '../common/i18n';
import { Button, Icon } from '../common/components';
import { AppSections, Sections } from '../common/enums';
import icons from '../common/icons';

const b = block('LeafletMapNoPoints');

export default
class LeafletMapNoPoints extends React.Component {
  static propTypes = {
    manifestId: PropTypes.number.isRequired,
  };

  render() {
    return <div className={b()}>
      <div className={b('Window')}>
        <div className={b('Smile')}>
          <Icon svgName={icons.SMILE_GOOD}/>
        </div>
        <div className={b('Message')}>{I18N.MANIFEST_NO_MAP_POINTS}</div>
        <div className={b('Buttons')}>
          <Button to={`/${AppSections.MANIFEST}/${this.props.manifestId}/${Sections.POINTS}/new`}>{I18N.POINTS_ADD_BUTTON}</Button>
          <Button to={`/${AppSections.MANIFEST}/${this.props.manifestId}/${Sections.POINTS}/new/base`}>{I18N.POINTS_ADD_BASE_BUTTON}</Button>
        </div>
      </div>
    </div>;
  }
}
