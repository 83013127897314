import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import { ApolloClient } from 'apollo-client';
import { from } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloProvider } from 'react-apollo';
import { setContext } from 'apollo-link-context';
import { 
	push, 
	ConnectedRouter, 
	routerReducer, 
	routerMiddleware 
} from 'react-router-redux';
import { createBrowserHistory } from 'history';
import { composeWithDevTools } from 'redux-devtools-extension';

import { ENDPOINTS } from './base/api';
import { getAllReducers } from './base/reducer';
import { actions } from './modules/auth/actions';
import App from './App';
import './css/style.css';
import 'react-day-picker/lib/style.css';
import 'leaflet.tilelayer.pouchdbcached';

export const history = createBrowserHistory();

export const store = createStore(
	combineReducers({
		...getAllReducers(),
		router: routerReducer
	}),
	{},
	composeWithDevTools(
		applyMiddleware(routerMiddleware(history))
	)
);

export const routeTo = (link) => store.dispatch(push(link));

history.listen((location, action) => {
	if (action === 'PUSH' && location.pathname === history.prevPath)
		history.goBack();
	else
		history.prevPath = location.pathname;
});

const httpLink = new HttpLink({ uri: ENDPOINTS.graphql });

const middlewareLink = setContext(() => ({
	headers: {
		authorization: `JWT ${localStorage.getItem('token')}`,
		'Content-Type': `application/json`,
	}
}));

const logoutLink = onError(({ networkError }) => {
	if (networkError.statusCode === 401)
		store.dispatch(actions.logoutUser());
});

export const client = new ApolloClient({
	link: from([logoutLink, middlewareLink, httpLink]),
	cache: new InMemoryCache(),
	defaultOptions: {
		watchQuery: {
			fetchPolicy: 'network-only',
			errorPolicy: 'ignore',
		},
		query: {
			fetchPolicy: 'network-only',
			errorPolicy: 'all',
		},
		mutate: {
			errorPolicy: 'all',
		},
	}
});

ReactDOM.render(
  	<Provider store={store}>
    	<ApolloProvider client={client}>
      		<ConnectedRouter history={history}>
        		<App/>
      		</ConnectedRouter>
    	</ApolloProvider>
	</Provider>,
	document.getElementById('root')
);


