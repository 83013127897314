import { makeReducer } from '../../base/reducer';


const init = {
  token: localStorage.getItem('token'),
  user: null,
};

export const reducer = makeReducer('auth', init);

export const actions = {
  loginUser: reducer.makeAction(
    (token) => ({token}),
    (state, action) => {
      localStorage.setItem('token', action.token);
      return {...state, token: action.token};
    },
    'LOGIN_USER'
  ),

  loadUser: reducer.makeAction(
    (user) => ({user}),
    (state, action) => ({...state, user: action.user}),
    'LOAD_USER'
  ),

  updateMesure: reducer.makeAction(
    (measure) => ({ measure }),
    (state, action) => ({...state, user: {...state.user, ...{ measure: action.measure }}}),
    'UPDATE_MEASURE'
  ),

  logoutUser: reducer.makeAction(
    null,
    (state) => {
      localStorage.removeItem('token');
      return {...state, token: null, user: null};
    },
    'LOGOUT_USER'
  )
};
