import React from 'react';
import { PropTypes } from 'prop-types';
import block from 'bem-cn-lite';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import { toast } from 'react-toastify';
import _ from 'lodash';

import { actions as mActions } from '../../manifest/actions';
import I18N from '../../common/i18n';
import { AppSections } from '../../common/enums';
import { reducer } from 'modules/auth/actions';
import TabTitle from '../common/TabTitle';
import GeneratedForm from './GeneratedForm';
import { routeTo } from '../../..';

const b = block('CustomForm');

class CustomForm extends React.PureComponent {
  static title = I18N.CUSTOM_FORM_TAB

  static propTypes = {
    manifestId: PropTypes.number.isRequired,
    refetchManifestData: PropTypes.func,
    itemId: PropTypes.number.isRequired,
    customForms: PropTypes.array,
  }

  constructor(props) {
    super(props);

    this.state = {
      formData: null,
      fieldsData: null,
      mutationQuery: null,
    };
  }

  componentDidMount() {
    this._generateFormData();
  }

  componentDidUpdate() {
    this._generateFormData();
  }

  _generateFormData = () => {
    const formData = _.get(this.props.customForms, `[${this.props.itemId - 1}]`);

    if (formData)
      this.setState({
        formData: formData,
        fieldsData: formData.fields,
        mutationQuery: this._getIntegrationQuery(formData.fields, formData.operationName)
      });
  }

  _getIntegrationQuery = (uiFields, operationName) => uiFields.length
    ? gql`
      mutation ${operationName} ($data: Array $manifest_id: Number) {
        result(data: $data manifestId: $manifest_id) {
          ${uiFields.map((uiField) => uiField.name).join(',\n')}
        }
      }`
    : gql`
      mutation ${operationName} ($data: Array $manifest_id: Number) {
        result(data: $data manifestId: $manifest_id) Boolean
      }`;

  onSubmit = (update, data) => {
    const p = update({
      variables: {
        'data': data,
        'manifest_id': this.props.manifestId,
      }
    });
    p.then((res) => {
      toast.success(`${this.state.formData.operationName}. ${I18N.NOTIFY_SUCCESS}`);
      routeTo(`/${AppSections.MANIFEST}/${this.props.manifestId}`);
      this.props.refetchManifestData();
    });
    p.catch((res) => {
      toast.error(`${this.state.formData.operationName}. ${I18N.NOTIFY_ERROR}`);
      console.log('update error');
    });
    return p;
  }

  render() {
    if (!this.state.formData)
      return null;

    return <div className={b()}>
      <hr/>
      <TabTitle>{this.state.formData.operationHint}</TabTitle>
      <GeneratedForm
        {...this.props}
        submitText={I18N.SUBMIT_EXECUTE}
        onSubmit={this.state.formData.operationName && this.onSubmit}
        fieldsData={this.state.fieldsData}
        mutationQuery={this.state.mutationQuery}
      />
    </div>;
  }
}

export default
connect(
  (state) => ({
    customForms: _.get(state, `${reducer.name}.user.ui_info.customForms`, []),
  }),
  (dispatch) => ({
    'refetchManifestData': () => dispatch(mActions.refetchData()),
  })
)(CustomForm);
