import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import { Button } from '../../common/components';
import icons from '../../common/icons';
import { reducer } from 'modules/auth/actions';
import { 
  // AppSections, 
  Sections, 
  // Forms 
} from '../../common/enums';
import { getSectionLinkBody } from '../../../base/utils';
import CustomActions from '../../custom/utils';

const b = block('ManifestUserForms');

class ManifestUserForms extends React.PureComponent {
  static propTypes = {
    customForms: PropTypes.array,
    customActions: PropTypes.array,
    reports: PropTypes.array,
    manifestId: PropTypes.number.isRequired,
  };

  _renderReportsButtons = (buttons) => buttons && buttons.map((btn, i) => <div key={i} className={b('UserButton')}>
    <Button flipIcon={true}
      to={`${getSectionLinkBody(this.props.manifestId)}${Sections.REPORT_FORM}/${i + 1}`}
      icon={icons.COMMON_USER_BUTTON}
    >
      {btn.name}
    </Button>
  </div>);

  _renderCustomFormButtons = (buttons) => buttons && buttons.map((btn, i) => <div key={i} className={b('UserButton')}>
    <Button flipIcon={true}
      to={`${getSectionLinkBody(this.props.manifestId)}${Sections.CUSTOM_FORM}/${i + 1}`}
      icon={icons.COMMON_USER_BUTTON}
    >
      {btn.operationHint}
    </Button>
  </div>);

  _renderCustomActionButtons = (buttons) => buttons && buttons.map((btn, i) => <div key={i} className={b('UserButton')}>
    <Button flipIcon={true}
      onClick={() => CustomActions[btn.scope][btn.name](this.props.manifestId, btn.params || {})}
      icon={icons.COMMON_USER_BUTTON}
    >
      {btn.operationHint}
    </Button>
  </div>);

  render() {
    return <div className={b()}>
      {this._renderReportsButtons(this.props.reports)}
      {this._renderCustomFormButtons(this.props.customForms)}
      {this._renderCustomActionButtons(this.props.customActions)}
    </div>;
  }
}

export default
connect(
  (state) => ({
    customForms: _.get(state, `${reducer.name}.user.ui_info.customForms`),
    reports: _.get(state, `${reducer.name}.user.reports`),
    customActions: _.get(state, `${reducer.name}.user.ui_info.customActions`),
  })
)(ManifestUserForms);
