import React from 'react';
import { PropTypes } from 'prop-types';

import ManifestTab from '../../manifest/components/ManifestTab';
import { AuxilaryTabKinds } from '../../common/enums';
import DaysSelector from './DaysSelector';
import I18N from '../../common/i18n';
import InfoText from './InfoText';
import PhotoGallery from './PhotoGallery';
import ItemSelectionList from './ItemSelectionList';
import FlagSelectionList from './FlagSelectionList';

export default
class AuxiliaryTab extends React.PureComponent {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    section: PropTypes.string,
    fieldName: PropTypes.string,
    auxilaryTabKind: PropTypes.string,
    rawText: PropTypes.string,
    caption: PropTypes.string,
    photos: PropTypes.arrayOf(PropTypes.string),
    value: PropTypes.any,
    fromCatalog: PropTypes.bool,
    onlyValue: PropTypes.bool,
  }

  _getTabKindTitle = (auxilaryTabKind) => ((auxilaryTabKind === AuxilaryTabKinds.SELECT_ITEMS && ItemSelectionList.title)
    || (auxilaryTabKind === AuxilaryTabKinds.SELECT_DAYS && DaysSelector.title)
    || (auxilaryTabKind === AuxilaryTabKinds.RAW_TEXT && `${this.props.caption} (${I18N.FIELD_HELP})`)
    || (auxilaryTabKind === AuxilaryTabKinds.SHOW_PHOTOS && `${this.props.caption} (${I18N.WAYBILLS_PHOTO})`)
    || (auxilaryTabKind === AuxilaryTabKinds.SELECT_FLAGS && FlagSelectionList.title)
    || '');

  render() {
    if (!this.props.auxilaryTabKind)
      return null;

    return <ManifestTab
      title={this._getTabKindTitle(this.props.auxilaryTabKind)}
      onClose={this.props.onClose}
      noBackButton={true}
    >
      {this.props.auxilaryTabKind === AuxilaryTabKinds.SELECT_ITEMS && <ItemSelectionList {...this.props} />}
      {this.props.auxilaryTabKind === AuxilaryTabKinds.SELECT_DAYS && <DaysSelector {...this.props} />}
      {this.props.auxilaryTabKind === AuxilaryTabKinds.RAW_TEXT && <InfoText text={this.props.rawText} />}
      {this.props.auxilaryTabKind === AuxilaryTabKinds.SHOW_PHOTOS && <PhotoGallery photos={this.props.photos} />}
      {this.props.auxilaryTabKind === AuxilaryTabKinds.SELECT_FLAGS && <FlagSelectionList {...this.props} />}
    </ManifestTab>;
  }
}
