
import { CRUD as pointCRUD, queries as qPoint } from './points/queries';
import { CRUD as courierCRUD, queries as qCourier } from './couriers/queries';
import { CRUD as zoneCRUD, queries as qZone } from './zones/queries';
import { CRUD as groupCRUD, queries as qGroup } from './groups/queries';
import { CRUD as userCRUD, queries as qUser } from './users/queries';
import { CRUD as measureCRUD, queries as qMeasure } from './measures/queries';
import { CRUD as manifestCRUD, queries as qManifest } from './manifests/queries';

import * as couriers from './couriers';
import * as manifests from './manifests';
import * as points from './points';
import * as zones from './zones';
import * as users from './users';
import * as measures from './measures';
import * as groups from './groups';
import CloneManifest from './clone_manifest/CloneManifest';
import ManifestDetails from './details/ManifestDetails';
import OptimizationForm from './optimization/OptimizationForm';
import PointsDetails from './points_details/PointsDetails';
import WaybillEdit from './waybill_edit/WaybillEdit';
import { Sections } from 'modules/common/enums';
import DeleteList from './common/DeleteList';
import CustomForm from './custom_form/CustomForm';
import ReportForm from './custom_form/ReportForm';

export
const ITEMS_PER_PAGE = 20;

export
const sections = {
  [Sections.COURIERS]: {
    main: couriers.CourierList,
    list: couriers.CourierList,
    delete: DeleteList,
    item: couriers.CourierListItem,
    new: couriers.CourierCreate,
    edit: couriers.CourierEdit,
    view: couriers.CourierView,
    crud: courierCRUD
  },
  [Sections.POINTS]: {
    main: points.PointList,
    list: points.PointList,
    delete: DeleteList,
    item: points.PointListItem,
    new: points.PointCreate,
    view: points.PointView,
    edit: points.PointEdit,
    crud: pointCRUD
  },
  [Sections.ZONES]: {
    main: zones.ZoneList,
    list: zones.ZoneList,
    delete: DeleteList,
    item: zones.ZoneListItem,
    new: zones.ZoneCreate,
    edit: zones.ZoneEdit,
    view: zones.ZoneView,
    crud: zoneCRUD
  },
  [Sections.USERS]: {
    main: users.UserList,
    list: users.UserList,
    delete: DeleteList,
    item: users.UserListItem,
    new: users.UserCreate,
    edit: users.UserEdit,
    view: users.UserView,
    crud: userCRUD
  },
  [Sections.MEASURES]: {
    main: measures.MeasureList,
    list: measures.MeasureList,
    item: measures.MeasureListItem,
    new: measures.MeasureCreate,
    edit: measures.MeasureEdit,
    view: measures.MeasureView,
    select: measures.MeasureSelect,
    crud: measureCRUD
  },
  [Sections.GROUPS]: {
    main: groups.GroupList,
    list: groups.GroupList,
    delete: DeleteList,
    item: groups.GroupListItem,
    new: groups.GroupCreate,
    edit: groups.GroupEdit,
    view: groups.GroupEdit,
    crud: groupCRUD
  },
  [Sections.MANIFEST_LIST]: {
    main: manifests.ManifestList,
    list: manifests.ManifestList,
    item: manifests.ManifestListItem,
    crud: manifestCRUD,
  },
  [Sections.MANIFEST_CLONE]: { main: CloneManifest },
  [Sections.MANIFEST_CREATE]: { main: manifests.ManifestCreate },
  [Sections.MANIFEST_EDIT]: { main: manifests.ManifestEdit },
  [Sections.OPTIMIZATION]: { main: OptimizationForm },
  [Sections.POINTS_DETAILS]: { main: PointsDetails },
  [Sections.DETAILS]: { main: ManifestDetails },
  [Sections.CUSTOM_FORM]: { view: CustomForm },
  [Sections.REPORT_FORM]: { view: ReportForm },
  [Sections.EDIT_WAYBILL]: {
    main: WaybillEdit,
    view: WaybillEdit
  },
};

export
const queries = {
  [Sections.COURIERS]: {
    create: qCourier.CREATE,
    item: qCourier.GET_ONE,
    update: qCourier.UPDATE,
    delete: qCourier.DELETE,
    list: qCourier.LIST,
  },
  [Sections.POINTS]: {
    create: qPoint.CREATE,
    item: qPoint.GET_ONE,
    update: qPoint.UPDATE,
    delete: qPoint.DELETE,
    list: qPoint.LIST,
  },
  [Sections.ZONES]: {
    create: qZone.CREATE,
    item: qZone.GET_ONE,
    update: qZone.UPDATE,
    delete: qZone.DELETE,
    list: qZone.LIST,
  },
  [Sections.USERS]: {
    create: qUser.CREATE,
    item: qUser.GET_ONE,
    update: qUser.UPDATE,
    delete: qUser.DELETE,
    list: qUser.LIST,
  },
  [Sections.MEASURES]: {
    create: qMeasure.CREATE,
    item: qMeasure.GET_ONE,
    update: qMeasure.UPDATE,
    delete: qMeasure.DELETE,
    list: qMeasure.LIST,
  },
  [Sections.GROUPS]: {
    create: qGroup.CREATE,
    item: qGroup.GET_ONE,
    update: qGroup.UPDATE,
    delete: qGroup.DELETE,
    list: qGroup.LIST,
  },
  [Sections.MANIFEST_LIST]: {
    create: qManifest.CREATE,
    item: qManifest.GET_ONE,
    update: qManifest.UPDATE,
    delete: qManifest.DELETE,
    list: qManifest.LIST,
  },
};
