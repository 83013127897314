import { makeReducer } from '../../base/reducer';

const init = {
  selectedCourierId: null,
  selectedPoints: [],
  coords: [],
  operation: null,
  fieldName: null,
  editingPointId: null,
  editedRoutes: {},
  fullRoutes: {},
};

export const OperationType = {
  NEW_GEOMETRY: 'newGeometry',
  EDIT_GEOMETRY: 'editGeometry',
  SELECT_POINTS: 'selectPoints',
  CONNECT_POINTS: 'connectPoints'
};

export const reducer = makeReducer('map', init);

export const actions = {
  'selectCourier': reducer.makeAction(
    (courierId) => ({courierId: courierId}),
    (state, action) => ({selectedCourierId: action.courierId}),
    'SELECT_COURIER'
  ),

  'editPoint': reducer.makeAction(
    (pointId) => ({pointId: pointId}),
    (state, action) => ({editingPointId: action.pointId}),
    'EDIT_POINT'
  ),

  'startDrawGeometry': reducer.makeAction(
    (fieldName, coords, operation) => ({fieldName, coords, operation}),
    (state, action) => ({
      operation: action.operation,
      fieldName: action.fieldName,
      coords: action.coords || []
    }),
    'START_DRAW_GEOMETRY'
  ),

  'stopDrawGeometry': reducer.makeAction(
    null,
    (state, action) => {
      if (state.operation === OperationType.NEW_GEOMETRY || state.operation === OperationType.EDIT_GEOMETRY)
        return {
          operation: null,
          fieldName: null,
          coords: null
        };
      console.log(`Unexpected stopDrawGeometry: operation == ${state.operation}`);
    },
    'STOP_DRAW_GEOMETRY'
  ),

  'startSelectPoints': reducer.makeAction(
    null,
    () => ({
      operation: OperationType.SELECT_POINTS,
      fieldName: null,
      selectedPoints: []
    }),
    'START_SELECT_POINTS'
  ),

  'startConnectPoints': reducer.makeAction(
    null,
    () => ({
      operation: OperationType.CONNECT_POINTS,
      selectedPoints: []
    }),
    'START_CONNECT_POINTS'
  ),

  'stopSelectPoints': reducer.makeAction(
    null,
    (state) => {
      if (state.operation === OperationType.SELECT_POINTS)
        return {
          operation: null,
          fieldName: null,
          selectedPoints: []
        };
      else if (state.operation === OperationType.CONNECT_POINTS)
        return {
          operation: null,
          selectedPoints: []
        };
      console.log(`Unexpected stopSelectPoints: operation == ${state.operation}`);
    },
    'STOP_SELECT_POINTS'
  ),

  'updateSelectedPoints': reducer.makeAction(
    (pointsIds) => ({pointsIds: pointsIds}),
    (state, action) => ({
      selectedPoints: action.pointsIds || []
    }),
    'UPDATE_SELECTED_POINTS'
  ),

  'addSelectedPoint': reducer.makeAction(
    (pointId) => ({pointId: pointId}),
    (state, action) => ({
      selectedPoints: [...state.selectedPoints, action.pointId]
    }),
    'ADD_SELECTED_POINT'
  ),

  'toggleSelectedPoint': reducer.makeAction(
    (pointId, isSpecial) => ({pointId: pointId, isSpecial: isSpecial}),
    (state, action) => {
      const newPoints = [...state.selectedPoints];
      const existingPoint = newPoints.findIndex((id) => id === action.pointId);

      if (existingPoint >= 0 && !action.isSpecial)
        newPoints.splice(existingPoint, 1);
      else
        newPoints.push(action.pointId);
      return {
        selectedPoints: newPoints
      };
    },
    'TOGGLE_SELECTED_POINT'
  ),

  'updateRoute': reducer.makeAction(
    (manifestId, editedRoute, fullRoutes) => ({ manifestId, editedRoute, fullRoutes }),
    (state, action, store) => {
      const i = state.editedRoutes[action.manifestId]
        ? state.editedRoutes[action.manifestId].findIndex((r) => r.agent === action.editedRoute.agent)
        : -1;
      let newState = {};

      if (i >= 0) {
        const updatedRoutes = [...state.editedRoutes[action.manifestId]];
        updatedRoutes[i] = action.editedRoute;
        newState = {
          fullRoutes: { ...state.fullRoutes, [action.manifestId]: action.fullRoutes },
          editedRoutes: { ...state.editedRoutes, [action.manifestId]: updatedRoutes },
        };
      }
      else {
        newState = {
          fullRoutes: { ...state.fullRoutes, [action.manifestId]: action.fullRoutes },
          editedRoutes: { ...state.editedRoutes, [action.manifestId]: [...state.editedRoutes[action.manifestId] || [], action.editedRoute] }
        };
      }

      store(newState);
      return newState;
    },
    'UPDATE_ROUTE'
  ),

  'clearRoutes': reducer.makeAction(
    (manifestId) => ({ manifestId: manifestId }),
    (state, action, store) => {
      const newState = {
        fullRoutes: { ...state.fullRoutes, [action.manifestId]: null },
        editedRoutes: { ...state.editedRoutes, [action.manifestId]: [] }
      };
      store(newState);
      return newState;
    },
    'CLEAR_ROUTES'
  ),

  'clearRouteByCourier': reducer.makeAction(
    (manifestId, courierId) => ({ manifestId: manifestId, courierId: courierId }),
    (state, action, store) => {
      const updatedRoutes = state.editedRoutes[action.manifestId]
        ? state.editedRoutes[action.manifestId].filter((r) => r.agent !== action.courierId)
        : [];

      const newState = {
        fullRoutes: { ...state.fullRoutes, [action.manifestId]: updatedRoutes.length ? state.fullRoutes[action.manifestId] : null},
        editedRoutes: { ...state.editedRoutes, [action.manifestId]: updatedRoutes }
      };

      store(newState);
      return newState;
    },
    'CLEAR_ROUTE_BY_COURIER'
  ),
};
