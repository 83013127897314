import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';

import I18N from 'modules/common/i18n';
import Button from 'modules/common/components/Button';
import { store } from 'index';
import { actions as sectionActions } from 'modules/sections/common/actions';
import { AuxilaryTabKinds } from '../common/enums';

const b = block('Input');

export default
class SelectItemsInput extends React.PureComponent {
  static fieldName = 'items'

  static propTypes = {
    size: PropTypes.number,
    caption: PropTypes.string.isRequired,
    sourceActions: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    section: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.arrayOf(PropTypes.number)
    ]),
    onChangeValue: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    fromCatalog: PropTypes.bool,
    onlyValue: PropTypes.bool,
  }

  constructor() {
    super();
    this.closeOptionsTimer = null;

    this.state = {
      optionsVisible: false,
    };
  }

  componentDidMount() {
    this.selectionUpdateEvent = sectionActions.fieldValueUpdated.subscribe(
      (s, action) => {
        if (this.props.onChangeValue && action.fieldName === this.props.name) {
          if (this.props.onlyValue)
            this.props.onChangeValue(this.props.name, action.value[0]);
          else
            this.props.onChangeValue(this.props.name, action.value);
        }
      }
    );
  }

  componentWillUnmount() {
    this.selectionUpdateEvent.unsubscribe();
  }

  _onClick = () => {
    store.dispatch(this.props.sourceActions.openSelectList(
      AuxilaryTabKinds.SELECT_ITEMS,
      this.props.name,
      this.props.value,
      this.props.section,
      this.props.fromCatalog,
      this.props.onlyValue,
    ));
  }

  _toViewList = (value) => (value || []).length;

  _toViewOnly = (value) => value;

  render() {
    return (
      <div key={'body'} className={b('ButtonPick')}>
        <Button
          onClick={this._onClick}
        >{I18N.FIELD_START_SELECT}</Button>
        <div className={b('ButtonPickData')}>
          <div className={b('DataCount')}>
            {!this.props.onlyValue && [I18N.FIELD_SELECTED_COUNT, <b key='b'>{this._toViewList(this.props.value)}</b>]}
            {this.props.onlyValue && (this._toViewOnly(this.props.value) || I18N.FIELD_NO_ITEM) }
          </div>
        </div>
      </div>
    );
  }
}
