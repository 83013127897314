import React from 'react';
import block from 'bem-cn-lite';
import LeafletMap from '../../map/LeafletMap';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';

import icons from '../../common/icons';
import Icon from '../../common/components/Icon';
import LeafletMapControls from '../../map/LeafletMapControls';
import UserBadge from '../../auth/components/UserBadge';
import CatalogMenu from './CatalogMenu';
import SectionTab from '../../sections/common/SectionTab';
import AuxiliaryTab from '../../sections/common/AuxiliaryTab';
import { reducer, actions } from '../actions';
import { reducer as authReducer } from '../../auth/actions';
import { SectionMatchParams as SMP, AppSections } from '../../common/enums';
import { convertParamsToNumber } from '../../../base/utils';
import { client } from '../../..';

const b = block('Catalog');

class Catalog extends React.PureComponent {
  static propTypes = {
    auxilaryTabKind: PropTypes.string,
    selectListSection: PropTypes.string,
    selectListOnlyValue: PropTypes.bool,
    editingFieldName: PropTypes.string,
    rawText: PropTypes.string,
    caption: PropTypes.string,
    editingFieldValue: PropTypes.arrayOf(PropTypes.any),
    closeAuxilaryTab: PropTypes.func.isRequired,
    orgId: PropTypes.number,
    match: PropTypes.shape({
      params: PropTypes.shape({
        [SMP.SECTION]: PropTypes.any,
        [SMP.ITEM_ID]: PropTypes.any,
        [SMP.ITEM_STATUS]: PropTypes.any,
      }),
    }),
  }

  componentDidMount() {
    this.refetchDataEvent = actions.refetchData.subscribe(() => client.resetStore());
  }

  componentWillUnmount() {
    this.refetchDataEvent.unsubscribe();
  }

  render() {
    return (<div className={b()}>
      <div className={b('Map')}>
        <LeafletMap
          manifestId={-this.props.orgId || 0}
          sourceRecucer={reducer}
        />
      </div>
      <div className={b('Logo')}>
        <Icon svgName={icons.LOGO}/>
      </div>
      <div className={b('Header')}>
        <div className={b('HeaderLeft')}>
          <CatalogMenu/>
        </div>
        <div className={b('HeaderRight')}>
          <LeafletMapControls
            sourceActions={actions}
            sourceRecucer={reducer}
            appSection={AppSections.CATALOG}
          />
          <UserBadge/>
        </div>
      </div>
      <div className={b('Body')}>
        <div className={b('Tabs')}>
          <div className={b('LeftTabDynamicIndent')} />
          <div className={b('LeftTab')}>
            <SectionTab
              {...convertParamsToNumber(this.props.match.params)}
              orgId={this.props.orgId}
              sourceActions={actions}
            />
          </div>
          <div className={b('RightTab')}>
            <div className={b('RightTabTopIndent')}/>
            <AuxiliaryTab
              {...convertParamsToNumber(this.props.match.params)}
              orgId={this.props.orgId}
              onClose={this.props.closeAuxilaryTab}
              section={this.props.selectListSection}
              fieldName={this.props.editingFieldName}
              onlyValue={this.props.selectListOnlyValue}
              rawText={this.props.rawText}
              caption={this.props.caption}
              auxilaryTabKind={this.props.auxilaryTabKind}
              value={this.props.editingFieldValue}
            />
          </div>
          <div className={b('RightTabDynamicIndent')} />
        </div>
      </div>
    </div>);
  }
}

export default
connect(
  (state) => ({
    auxilaryTabKind: state[reducer.name].auxilaryTabKind,
    selectListSection: state[reducer.name].selectListSection,
    selectListOnlyValue: state[reducer.name].selectListOnlyValue,
    editingFieldName: state[reducer.name].editingFieldName,
    editingFieldValue: state[reducer.name].editingFieldValue,
    rawText: state[reducer.name].rawText,
    caption: state[reducer.name].caption,
    orgId: state[authReducer.name].user && state[authReducer.name].user['org_id'],
  }),
  (dispatch) => ({
    "closeAuxilaryTab": () => dispatch(actions.closeAuxilaryTab()),
  })
)(Catalog);
