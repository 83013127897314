import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';

import I18N from 'modules/common/i18n';
import Button from 'modules/common/components/Button';
import { store } from 'index';
import { actions as mapActions } from 'modules/map/actions';
import { closePolygon, decodeGeometry } from 'base/utils';
import { actions as sectionActions } from 'modules/sections/common/actions';
import polyUtil from '@mapbox/polyline';
import getPolygonArea from '@turf/area';
import { polygon as turfPolygon } from '@turf/helpers';
import booleanClockwise from '@turf/boolean-clockwise';
import { OperationType } from '../map/actions';
import { getCoordsGlobalPrecision } from '../../base/utils';

const b = block('Input');

const calculateGeometryArea = (geometry) => {
  const poly = closePolygon(geometry);
  if (poly.length < 4)
    return 0;
  const squareMeters = getPolygonArea(turfPolygon([poly]));
  return (squareMeters / 10000).toFixed(2);
};

const encodeGeometry = (coords) => {
  const fixedCoords = coords.map((pair) => pair.map((unit) => Number(unit.toFixed(getCoordsGlobalPrecision()))).reverse());
  return polyUtil.encode(booleanClockwise(fixedCoords) ? fixedCoords : fixedCoords.reverse());
};

export default
class GeometryInput extends React.PureComponent {
  static fieldName = 'geometry'

  static propTypes = {
    size: PropTypes.number,
    caption: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChangeValue: PropTypes.func.isRequired,
    disabled: PropTypes.bool
  }

  constructor(props) {
    super(props);

    this.state = {
      editing: false,
      value: decodeGeometry(props.value) || []
    };
  }

  componentDidMount() {
    this.zoneUpdateEvent = sectionActions.fieldValueUpdated.subscribe(
      (s, action) => {
        if (action.fieldName === 'geometry') {
          this.setState({value: action.value});
        }
      });
  }

  componentDidUpdate() {
    if (this.state.value.length >= 3 )
      // Common way rises "Reducers cant dispatch actions" error
      setTimeout(() => this.props.onChangeValue && this.props.onChangeValue(this.props.name, encodeGeometry(closePolygon(this.state.value))), 0);
  }

  componentWillUnmount() {
    if (this.state.editing)
      store.dispatch(mapActions.stopDrawGeometry());
    this.zoneUpdateEvent.unsubscribe();
  }

  _onClick = () => {
    if (this.state.editing) {
      store.dispatch(mapActions.stopDrawGeometry());
      this.setState({editing: false});
    }
    else {
      store.dispatch(mapActions.startDrawGeometry('geometry',
        this.state.value,
        this.props.value ? OperationType.EDIT_GEOMETRY : OperationType.NEW_GEOMETRY));
      this.setState({editing: true});
    }
  }

  render() {
    return (
      <div className={b('ButtonPick')}>
        <Button onClick={this._onClick}>
          {this.state.editing ? I18N.FIELD_STOP_DRAW : I18N.FIELD_BEGIN_DRAW}
        </Button>
        {this.props.value
          ? <div className={b('ButtonPickData')}>
            {I18N.AREA_HECTARE}
            <div className={b('DataCount')}>
              {calculateGeometryArea(this.state.value)}
            </div>
          </div>
          : <div className={b('ButtonPickData')}>{I18N.FIELD_NO_DATA}</div>}
      </div>
    );
  }
}
