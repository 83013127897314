import React from 'react';
import block from 'bem-cn-lite';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { queries } from './../../auth/queries'
import { client } from './../../../index'

import { 
	// reducer, 
	actions 
} from '../actions';
import I18N from '../../common/i18n';
import TextLink from './../../common/components/TextLink';

const b = block('UserBadge');

class UserBadge extends React.PureComponent {
	static propTypes = {
		actions: PropTypes.object,
		token: PropTypes.string,
		user: PropTypes.object,
	}

	componentWillMount(){
		this.getUserData()
	}

	getUserData(){
		if(this.props.token){
			const p = client.query({query: queries.CURRENT_USER});
			p.then((result) => {
				if(result.data && this.props){
					this.props.loadUser(result.data.result)
				}
			});
			p.catch(() => {
				this.props.logoutUser()
			});
		}
	}

	render() {
		return(
			<>
				{ this.props.user ? (
						<div className={b()}>
							<div className={b('Avatar')}></div>
							<div className={b('Title')}>
								<span className={b('Name')}>
									{this.props.user.name || this.props.user.email}
								</span>
								<TextLink onClick={this.props.logoutUser}>
									{I18N.LOGIN_LOGOUT}
								</TextLink>
							</div>
						</div>
					) : false
				}
			</>
		)
	}
}

function mapStateToProps(state){
	return{
		token: state.auth.token,
		user: state.auth.user
	}
}

function mapDispatchToProps(dispatch){
	return bindActionCreators(actions, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(UserBadge)

// export default connect(
//   (state) => ({
//     user: state[reducer.name].user
//   }),
//   (dispatch) => ({
//     "actions": bindActionCreators(actions, dispatch)
//   })
// )(UserBadge);
