import React from 'react';
import { PropTypes } from 'prop-types';
import { Mutation } from 'react-apollo';
import { toast } from 'react-toastify';

import ItemForm from '../common/ItemForm';
import { queries as mQueries } from '../../manifest/queries';
import { queries as mSectionQueries } from '../manifests/queries';
import I18N from '../../common/i18n';
import { store } from '../../..';
import { push } from 'react-router-redux';
import { AppSections, Sections } from '../../common/enums';
import * as inputs from '../../inputs';
import { VALIDATE } from '../../inputs/validate';
import { ITEMS_PER_PAGE } from '..';

const fields = [
  {
    inputClass: inputs.TextInput,
    caption: I18N.MANIFEST_NAME,
    name: 'name',
    validate: VALIDATE.notEmpty,
    size: 2,
    help: I18N.MANIFEST_NAME_HELP
  },
  {
    inputClass: inputs.DateInput,
    caption: I18N.MANIFEST_DATE,
    name: 'date',
    size: 2
  },
  {
    inputClass: inputs.CheckboxInput,
    caption: I18N.MANIFEST_SETTINGS,
    name: 'copy_settings'
  },
  {
    inputClass: inputs.CheckboxInput,
    caption: I18N.MANIFEST_SPECAIL_POINTS,
    name: 'copy_special_points'
  },
  {
    inputClass: inputs.CheckboxInput,
    caption: I18N.MANIFEST_REGULAR_POINTS,
    name: 'copy_regular_points'
  },
  {
    inputClass: inputs.CheckboxInput,
    caption: I18N.MANIFEST_COURIERS,
    name: 'copy_couriers'
  },
  {
    inputClass: inputs.CheckboxInput,
    caption: I18N.MANIFEST_ZONES,
    name: 'copy_zones'
  },
];

const defaultCloneManifest = Object.freeze({
  date: new Date().toUTCString(),
  copy_settings: true,
  copy_special_points: true,
  copy_regular_points: true,
  copy_couriers: true,
  copy_zones: true
});

export default
class CloneManifest extends React.PureComponent {
  static title = I18N.MANIFEST_CLONE_TITLE

  static propTypes = {
    manifestId: PropTypes.number.isRequired
  }

  _clone = (clone, formData) => {
    const p = clone({
      variables: {
        'manifest_id': this.props.manifestId,
        'data': { name: formData['name'], date: formData['date'] },
        'copy_settings': formData['copy_settings'],
        'copy_special_points': formData['copy_special_points'],
        'copy_regular_points': formData['copy_regular_points'],
        'copy_couriers': formData['copy_couriers'],
        'copy_zones': formData['copy_zones'],
      },
    });
    p.then((res) => {
      toast.success(`${I18N.MANIFEST_CLONE_TITLE}. ${I18N.NOTIFY_SUCCESS}`);
    });
    p.catch((res) => {
      toast.error(`${I18N.MANIFEST_CLONE_TITLE}. ${I18N.NOTIFY_ERROR}`);
      console.log('clone error');
    });
    return p;
  };

  render() {
    return <Mutation
      mutation={mQueries.COPY_MANIFEST}
      update={(proxy, { data: { result } }) => {
        const queryVars = {
          'offset': 0,
          'limit': ITEMS_PER_PAGE,
          'title_filter': "",
        };

        const cacheData = proxy.readQuery({query: mSectionQueries.LIST, variables: queryVars});

        if (cacheData && cacheData.result) {
          cacheData.result.unshift(result);
          proxy.writeQuery({ query: mSectionQueries.LIST, variables: queryVars, data: cacheData });
          store.dispatch(push(`/${AppSections.MANIFEST}/${this.props.manifestId}/${Sections.MANIFEST_LIST}`));
        }
      }}
    >{(clone) => (<ItemForm
        {...this.props}
        submitText={I18N.SUBMIT_CREATE}
        fields={fields}
        item={defaultCloneManifest}
        submit={(formData) => this._clone(clone, formData)} />)}
    </Mutation>;
  }
}
