import React from 'react';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { point as turfPoint, polygon as turfPolygon } from '@turf/helpers';
import block from 'bem-cn-lite';
import _ from 'lodash';
import { PropTypes } from 'prop-types';
import { routeTo } from '../..';
import { 
  // AppSections, 
  Sections 
} from '../common/enums';
import { closePolygon, getSectionLinkBody } from '../../base/utils';
import { Polygon } from 'react-leaflet';
import { OperationType } from './actions';
import { LeafletDrawUtils } from './leafletDrawUtils';

const b = block('LeafletMap');

export default
class LeafletDrawingPolygons extends React.PureComponent {
  static propTypes = {
    drawnCoords: PropTypes.arrayOf(PropTypes.shape([
      PropTypes.number,
      PropTypes.number
    ])),
    manifestId: PropTypes.number.isRequired,
    selectedPoints: PropTypes.arrayOf(PropTypes.number),
    mapPoints: PropTypes.object,
    selectedCourierId: PropTypes.number,
    waybills: PropTypes.object,
    updateSelectedPoints: PropTypes.func,
    fieldValueUpdated: PropTypes.func,
    fieldName: PropTypes.string,
    operation: PropTypes.string,
  }

  componentDidMount() {
    if (!this.props.drawnCoords.length)
      LeafletDrawUtils.startDrawPolygon();
    else
      LeafletDrawUtils.afterDraw();
  }

  componentWillUnmount() {
    LeafletDrawUtils.stopDraw();
  }

  componentDidUpdate(prevProps) {
    if (this.props.operation === OperationType.SELECT_POINTS)
      this._onSelectPointsUpdate(this.props.drawnCoords);
    if (this.props.operation === OperationType.NEW_GEOMETRY || this.props.operation === OperationType.EDIT_GEOMETRY)
      this._onDrawPolygonUpdate(this.props.drawnCoords);
    LeafletDrawUtils.afterDraw();
  }

  _getPointsInPolygon = (polygonCoords, points) => {
    const selectedPointsIds = [];
    if (polygonCoords.length >= 3 && points) {
      const polygon = turfPolygon([closePolygon(polygonCoords)]);
      for (const point of Object.values(points)) {
        if (point.coords && booleanPointInPolygon(turfPoint(point.coords), polygon))
          selectedPointsIds.push(point.id);
      }
    }
    return selectedPointsIds;
  }

  _onDrawPolygonUpdate = (coords) => {
    const selectedPoints = this._getPointsInPolygon(coords, this.props.mapPoints);
    if (!_.isEqual(selectedPoints, this.props.selectedPoints))
      this.props.updateSelectedPoints(selectedPoints);
    if (this.props.fieldName)
      this.props.fieldValueUpdated(this.props.fieldName, coords);
  }

  _onSelectPointsUpdate = (coords) => {
    const selectedPoints = this._getPointsInPolygon(coords, this.props.mapPoints);

    if (!_.isEqual(selectedPoints, this.props.selectedPoints)) {
      this.props.updateSelectedPoints(selectedPoints);
      routeTo(`${getSectionLinkBody(this.props.manifestId)}${Sections.POINTS_DETAILS}`);
    }
  }

  render() {
    return this.props.drawnCoords.length
      ? <Polygon
        className={b('Polygon')}
        key={this.props.drawnCoords}
        positions={closePolygon(this.props.drawnCoords)}
      />
      : null;
  }
}
