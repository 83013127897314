import { makeReducer } from '../../base/reducer';
import { MapElements } from '../common/enums';

export
const makeManifestLikeReducerActions = (reducerName) => {
  const auxilaryTabData = {
    auxilaryTabKind: null,
    editingFieldName: null,
    editingFieldValue: null,
    selectListSection: null,
    selectListfromCatalog: false,
    selectListOnlyValue: false,
    photos: null,
    rawText: null,
    caption: null,
    helpFieldName: null,
  };

  const init = {
    ...auxilaryTabData,
    [MapElements.LINES]: true,
    [MapElements.PATH]: true,
    [MapElements.POINTS]: true,
    [MapElements.POINTS_ASSIGNED]: true,
    [MapElements.CLUSTERS]: true,
    [MapElements.MAP]: true,
    [MapElements.ZONES]: true,
    [MapElements.TRACKS]: true
  };

  const r = makeReducer(reducerName, init);

  const a = {
    'changeMapElementsDisplay': r.makeAction(
      (mapElement) => ({element: mapElement}),
      (state, action, store) => {
        store({[action.element]: !state[action.element]});
        return {...state, [action.element]: !state[action.element]};
      },
      'CHANGE_MAP_ELEMENTS_DISPLAY'
    ),

    'showText': r.makeAction(
      (auxilaryTabKind, text, caption, helpFieldName) => ({auxilaryTabKind, text, caption, helpFieldName}),
      (state, action) => ({
        ...state,
        auxilaryTabKind: action.auxilaryTabKind,
        rawText: action.text,
        caption: action.caption,
        helpFieldName: action.helpFieldName
      }),
      'SHOW_TEXT'
    ),

    'showPhotos': r.makeAction(
      (auxilaryTabKind, caption, photos) => ({auxilaryTabKind, caption, photos}),
      (state, action) => ({
        ...state,
        caption: action.caption,
        auxilaryTabKind: action.auxilaryTabKind,
        photos: action.photos
      }),
      'SHOW_PHOTOS'
    ),

    'openSelectList': r.makeAction(
      (auxilaryTabKind, fieldName, fieldValue, listSection, fromCatalog, onlyValue) => ({
        auxilaryTabKind,
        fieldName,
        fieldValue,
        listSection,
        fromCatalog,
        onlyValue
      }),
      (state, action) => ({
        ...state,
        auxilaryTabKind: action.auxilaryTabKind,
        editingFieldName: action.fieldName,
        editingFieldValue: action.fieldValue,
        selectListSection: action.listSection,
        selectListfromCatalog: action.fromCatalog || false,
        selectListOnlyValue: action.onlyValue || false,
      }),
      'OPEN_SELECT_LIST'
    ),

    'closeAuxilaryTab': r.makeAction(
      null,
      (state) => ({...state, ...auxilaryTabData}),
      'CLOSE_AUXILARY_TAB'
    ),

    'refetchData': r.makeAction(
      null,
      null,
      'REFETCH_DATA'
    ),

    'changeManifestName': r.makeAction(
      (newName) => ({newName}),
      null,
      'CHANGE_MANIFEST_NAME'
    )
  };

  return { r, a };
};
