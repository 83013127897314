import React from 'react';
import { PropTypes } from 'prop-types';

import InfoBadge from '../../common/components/InfoBadge';
import Button from '../../common/components/Button';
import ConfirmButton from '../../common/components/ConfirmButton';
import ListItem from '../common/ListItem';
import I18N from '../../common/i18n';
import icons from '../../common/icons';
import { Sections } from '../../common/enums';
import { getSectionLinkBody } from '../../../base/utils';

export default
class ZoneListItem extends React.PureComponent {

  static section = Sections.ZONES;

  static propTypes = {
    item: PropTypes.object.isRequired,
    onDelete: PropTypes.func,
    manifestId: PropTypes.number,
  }

  render() {
    const zone = this.props.item;

    return (
      <ListItem
        id={zone.id}
        number={zone.id}
        icon={icons.ITEM_ZONE}
        iconStroke={4}
        link={`${getSectionLinkBody(this.props.manifestId)}${Sections.ZONES}/${zone.id}/edit`}
        key={zone.id}
        title={`${zone.name} :: ${zone.id}`}
      >
        <div className='ListItem__InfoBadges'>
          <InfoBadge iconName={icons.BADGE_POINT} value={zone.points_count}/>
          <InfoBadge iconName={icons.BADGE_WEIGHT} value={zone.points_weight}/>
          <InfoBadge iconName={icons.BADGE_CAR} value={zone.couriers_count}/>
        </div>
        <div className='ListItem__Controls'>
          {this.props.onDelete &&
            <ConfirmButton
              confirmMessage={`${I18N.ZONE_DELETE_CONFIRM} «${zone.name}»`}
              icon={icons.COMMON_DELETE} leftHint={I18N.ITEM_DELETE}
              onClick={() => this.props.onDelete(zone.id)}/>
          }
          <Button icon={icons.COMMON_EDIT} leftHint={I18N.ITEM_EDIT}
            to={`${getSectionLinkBody(this.props.manifestId)}${Sections.ZONES}/${zone.id}/edit`}
          />
        </div>
      </ListItem>
    );
  }
}
