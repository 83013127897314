import React from 'react';
import { PropTypes } from 'prop-types';
import { toast } from 'react-toastify';
import { push } from 'react-router-redux';
import block from 'bem-cn-lite';

import { CRUD } from './queries';
import I18N from '../../common/i18n';
import { store } from '../../..';
import { 
  Sections, 
  // AppSections, 
  SectionMatchParams as SMP 
} from '../../common/enums';
import PointForm from './PointForm';
import { actions as sectionActions} from '../common/actions';
import { getSectionLinkBody } from '../../../base/utils';
import FormSwitcher from '../common/FormSwitcher';
import * as inputs from '../../inputs';
import ItemForm from '../common/ItemForm';

const b = block('FormWrapper');

export
const defaultPoint = Object.freeze({
  'id': -1,
  'work_start': 0,
  'work_end': 23.99,
  'weight': 0,
  'volume': 0,
  'backhaul_weight': 0,
  'backhaul_volume': 0,
  'shipping_time': 0,
  'garage': false,
  'depot': false,
});

const cloneFields = [
  {
    inputClass: inputs.CheckboxInput,
    caption: I18N.POINTS_CLONE_LINKED_COURIERS,
    name: 'with_linked_couriers',
    help: I18N.POINTS_CLONE_LINKED_COURIERS_HELP,
  },
  {
    inputClass: inputs.CheckboxInput,
    caption: I18N.POINTS_CLONE_LINKED_ZONES,
    name: 'with_linked_zones',
    help: I18N.POINTS_CLONE_LINKED_ZONES_HELP,
  },
  {
    inputClass: inputs.SelectItemsInput,
    caption: I18N.POINTS_CLONE_SELECT,
    name: 'ids',
    section: Sections.POINTS,
    help: I18N.POINTS_CLONE_SELECT_HELP,
    fromCatalog: true,
  }
];

const defaultClone = Object.freeze({
  'with_linked_couriers': true,
  'with_linked_zones': true,
  'ids': [],
});

export default class PointCreate extends React.PureComponent {
  static title = ''

  static propTypes = {
    manifestId: PropTypes.number,
    orgId: PropTypes.number,
  }

  constructor(props) {
    super(props);
    this.state = {
      fromCatalog: false
    };
  }

  _create = (create, data, pointTitle) => {
    const p = create({
      variables: {
        'manifest_id': this.props.manifestId || -this.props.orgId,
        'data': data
      }
    });
    p.then((res) => {
      toast.success(`${pointTitle}. ${I18N.NOTIFY_SUCCESS}`);
      store.dispatch(push(
        `${getSectionLinkBody(this.props.manifestId)}${Sections.POINTS}`
      ));
      store.dispatch(sectionActions.pointCreated(res.data.result.id, res.data.result));
    });
    p.catch((res) => {
      toast.error(`${pointTitle}. ${I18N.NOTIFY_ERROR}`);
      console.log('created error');
    });
    return p;
  }

  _clone = (clone, data) => {
    const p = clone({
      variables: {
        'from_id': -this.props.orgId,
        'to_id': this.props.manifestId,
        'ids': data.ids,
        'with_linked_couriers': data.with_linked_couriers,
        'with_linked_zones': data.with_linked_zones
      }
    });
    p.then((res) => {
      toast.success(`${I18N.MISC_ITEM_FROM_CATALOG}. ${I18N.NOTIFY_SUCCESS}`);
      store.dispatch(push(
        `${getSectionLinkBody(this.props.manifestId)}${Sections.POINTS}`
      ));
    });
    p.catch((res) => {
      toast.error(`${I18N.MISC_ITEM_FROM_CATALOG}. ${I18N.NOTIFY_ERROR}`);
      console.log('clone error');
    });
    return p;
  }

  render() {
    const isBase = this.props[SMP.ITEM_STATUS] === 'base';
    const pointTitle = isBase ? I18N.POINTS_CREATE_BASE_TITLE : I18N.POINTS_CREATE_TITLE;
    const newPoint = { ...defaultPoint };
    isBase && (newPoint.garage = true);

    return <div className={b()}>
      {this.props.manifestId && <FormSwitcher
        formComponent={this}
        stateValueName={'fromCatalog'}
        options={[
          { caption: I18N.MISC_ITEM_CURRENT_MANIFEST, value: false },
          { caption: I18N.MISC_ITEM_FROM_CATALOG, value: true },
        ]}
      />}
      {this.state.fromCatalog
        ? CRUD.CLONE(
          {
            'manifest_id': this.props.manifestId || -this.props.orgId,
          },
          (clone) => <ItemForm
            {...this.props}
            item={defaultClone}
            submitText={I18N.SUBMIT_DEFAULT}
            fields={cloneFields}
            submit={(data) => this._clone(clone, data)}
          />)
        : CRUD.CREATE(
          {
            'manifest_id': this.props.manifestId || -this.props.orgId,
            'title_filter': ''
          },
          (update) => <PointForm
            {...this.props}
            submitText={I18N.SUBMIT_SAVE}
            item={newPoint}
            title={pointTitle}
            isBase={isBase}
            submit={(data) => this._create(update, data, pointTitle)} />
        )}
    </div>;
  }
}
