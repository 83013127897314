import React from 'react';
import block from 'bem-cn-lite';
// import { Link } from 'react-router-dom';

import icons from '../../common/icons';
import { AppSections, Sections } from '../../common/enums';
import { Button } from '../../common/components';
import I18N from '../../common/i18n';

const b = block('CatalogMenu');

export default
class CatalogMenu extends React.PureComponent {

  render() {
    return (
      <div className={b()}>
        <div className={b('Title')}>
          {I18N.CATALOG_TITLE}
        </div>
        <hr className='vr'/>
        <div className={b('SectionSelect')}>
          <Button
            className={b('SectionButton')}
            to={`/${AppSections.CATALOG}/${Sections.GROUPS}`}
            rightHint={I18N.CATALOG_GROUPS}
            icon={icons.CATALOG_GROUPS}>{I18N.GROUPS_TAB_HINT}</Button>
          <Button
            className={b('SectionButton')}
            to={`/${AppSections.CATALOG}/${Sections.USERS}`}
            rightHint={I18N.CATALOG_USERS}
            icon={icons.CATALOG_USERS}>{I18N.USERS_TAB_HINT}</Button>
          <Button
            className={b('SectionButton')}
            to={`/${AppSections.CATALOG}/${Sections.COURIERS}`}
            rightHint={I18N.CATALOG_COURIERS}
            icon={icons.CATALOG_COURIERS}>{I18N.COURIERS_TAB_HINT}</Button>
          <Button
            className={b('SectionButton')}
            to={`/${AppSections.CATALOG}/${Sections.POINTS}`}
            rightHint={I18N.CATALOG_POINTS}
            icon={icons.CATALOG_POINTS}>{I18N.POINTS_TAB_HINT}</Button>
          <Button
            className={b('SectionButton')}
            to={`/${AppSections.CATALOG}/${Sections.ZONES}`}
            rightHint={I18N.CATALOG_ZONES}
            icon={icons.CATALOG_ZONES}>{I18N.ZONES_TAB_HINT}</Button>
          <Button
            className={b('SectionButton')}
            to={`/${AppSections.CATALOG}/${Sections.MEASURES}`}
            rightHint={I18N.CATALOG_MEASURES}
            icon={icons.CATALOG_MEASURES}>{I18N.MEASURES_TAB_HINT}</Button>
        </div>
      </div>
    );
  }
}
