import React from 'react';
import block from 'bem-cn-lite';
import { client } from 'index';
import { PropTypes } from 'prop-types';
import { Polyline } from 'react-leaflet';
import { LineUtil } from 'leaflet';

import LeafletMapTrackMarker from './LeafletMapTrackMarker';
import { queries } from '../manifest/queries';
import { queries as courierQueries } from '../sections/couriers/queries';
import { ApolloFetchPolicy } from '../common/enums';
import { getPaletteColorClass, decodeTime, decodeTrackData } from './../../base/utils';

const b = block('LeafletMap');

const TIME_TO_REFETCH = 30;
const SIMPLIFY_FACTOR = 0.0001;
const ZOOM_SMOOTH_FACTOR = 5;

export default
class LeafletMapTrackingLayer extends React.Component {
  static propTypes = {
    manifestId: PropTypes.number,
    selectedCourierId: PropTypes.number,
    showTracks: PropTypes.bool,
  }

  constructor() {
    super();
    this.state = {
      couriersMarkers: [],
      couriersPaths: [],
    };
  }

  componentDidMount() {
    this._fetchTrackingData();
    this._setPollingInterval();
  }

  componentWillUnmount() {
    clearInterval(this.checkStatus);
  }

  _setPollingInterval() {
    clearInterval(this.checkStatus);
    this.checkStatus = setInterval(this._fetchTrackingData, TIME_TO_REFETCH * 1000);
  }

  _getMarkersData = (couriers) => {
    const markers = [];
    if (couriers && couriers.length)
      for (const c of couriers)
        c.last_position && markers.push({
          coords: c.last_position,
          time: decodeTime(c.last_position_time),
          courierId: c.id,
        });
    return markers;
  }

  _getPathsData = (tracks) => {
    const paths = [];
    if (tracks && tracks.length)
      for (const t of tracks)
        if (t.track) {
          const trackData = decodeTrackData(t.track);
          const leafletPoints = LineUtil
            .simplify(trackData.coords.map((p) => ({ x: p[0], y: p[1] })), SIMPLIFY_FACTOR)
            .map((p) => ([p.x, p.y]));
          paths.push({
            coords: leafletPoints,
            timestamps: trackData.timestamps,
            courierId: t.id,
          });
        }
    return paths;
  }

  _fetchTrackingData = async () => {
    if (this.props.showTracks) {
      const { data: tracksData } = await client.query({
        query: queries.COURIERS_TRACKS,
        fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
        variables: {
          'manifest_id': this.props.manifestId,
        },
      });

      const { data: courierData } = await client.query({
        query: courierQueries.LIST,
        fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
        variables: {
          'manifest_id': this.props.manifestId,
        },
      });

      const markersData = courierData ? this._getMarkersData(courierData.result) : [];
      const pathsData = tracksData ? this._getPathsData(tracksData.result) : [];

      this.setState({
        couriersMarkers: markersData,
        couriersPaths: pathsData,
      });
    }
  }

  _renderRoute = (courierId, waypoints, dimmed) => ([
    <Polyline
      smoothFactor={ZOOM_SMOOTH_FACTOR}
      className={b('PolylineShadow', {'Track': true, 'Dimmed': dimmed})}
      key={`${courierId}_${dimmed}_geometry`}
      positions={waypoints}
    />,
    <Polyline
      smoothFactor={ZOOM_SMOOTH_FACTOR}
      className={`${b('Polyline', {'Track': true, 'Dimmed': dimmed})} ${getPaletteColorClass(Number(courierId))}`}
      key={`${courierId}_${dimmed}_geometry_shadow`}
      positions={waypoints}
    />
  ])

  _renderPaths = (couriersPaths, selectedCourierId) => {
    let routes = [];
    for (const p of couriersPaths)
      routes = [
        ...routes,
        ...this._renderRoute(p.courierId, p.coords, selectedCourierId && p.courierId !== selectedCourierId)
      ];
    return routes;
  }

  _renderMarkers = (couriersMarkers) => {
    const markers = [];
    for (const cm of couriersMarkers) {
      markers.push(<LeafletMapTrackMarker
        manifestId={this.props.manifestId}
        key={`${cm.courierId}_marker`}
        marker={cm}
      />);
    }
    return markers;
  }

  render() {
    if (!this.props.showTracks)
      return null;
    return [
      ...this._renderMarkers(this.state.couriersMarkers, this.props.selectedCourierId),
      ...this._renderPaths(this.state.couriersPaths, this.props.selectedCourierId),
    ];
  }
}
