import gql from 'graphql-tag';
import bindApollo from '../common/queries';
import { Sections } from '../../common/enums';

const measureDefaultFieldsFragment = `
  id,
  name,
  description,
  weight_units,
  volume_units,
`;

export
const queries = {
  SECTION: Sections.MEASURES,

  CREATE: gql`
    mutation CREATE_MEASURE($data: String!) {
      result(data: $data) {
        ${measureDefaultFieldsFragment}
      }
    }
  `,

  DELETE: gql`
    mutation DELETE_MEASURE($id: Int!) {
      result(id: $id)
    }
  `,

  DELETE_LIST: gql`
    mutation DELETE_MEASURES($data: Array!) {
      result(data: $data)
    }
  `,

  LIST: gql`
    query MEASURES {
      result {
        ${measureDefaultFieldsFragment}
      }
    }
  `,

  GET_ONE: gql`
    query MEASURE($id: Int!) {
      result(id: $id) {
        ${measureDefaultFieldsFragment}
      }
    }
  `,

  UPDATE: gql`
    mutation UPDATE_MEASURE($data: String!) {
      result(data: $data) {
        ${measureDefaultFieldsFragment}
      }
    }
  `,
};

export
const CRUD = bindApollo(queries);
