import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';
import htmlParser from 'html-react-parser';
import { Link } from 'react-router-dom';

import I18N from 'modules/common/i18n';

const b = block('InfoText');

export default
class InfoText extends React.Component {
  static title = I18N.FIELD_HELP

  static propTypes = {
    text: PropTypes.string
  };

  render() {
    return (
      <div className={b()}>
        {htmlParser(String(this.props.text), {
          replace: (domNode) => {
            if (domNode.attribs && domNode.attribs.to)
              return <Link to={domNode.attribs.to}>{domNode.children.length && domNode.children[0].data}</Link>;
          }
        })}
      </div>
    );
  }
}
