import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';
import _ from 'lodash';

import SectionListPreloaded from '../common/SectionListPreloaded';
import { TextFilterInput } from './../../common/components';
import I18N from 'modules/common/i18n';
import { withSelectableListItem } from './../common/SelectableListItem';
import { actions as sectionActions } from './actions';
import { store } from 'index';
import TextLink from '../../common/components/TextLink';
import { ITEMS_PER_PAGE } from '..';
import { reducer } from '../../auth/actions';
import FlagListItem from '../flags/FlagListItem';
import { decToMask, maskToDec } from '../../../base/utils';

const b = block('ItemSelectionList');

export default
class FlagSelectionList extends React.Component {
  static title = I18N.FIELD_SELECT_FLAGS;

  static propTypes = {
    fieldName: PropTypes.string,
    value: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.state = {
      titleFilter: '',
      flagsInt: props.value,
    };
  }

  isItemSelected = (id) => decToMask(this.state.flagsInt).find((f) => f === id);

  _getFlagItems = () => {
    const state = store.getState()[reducer.name];
    const flagArray = _.get(state, 'user.ui_info.vehicleFlags', I18N.DEFAULT_FLAGS);
    return flagArray.map((el, i) => ({ name: el, id: i + 1}));
  }

  _selectAll = () => {
    const allFlags = this._getFlagItems().map((flag) => flag.id);
    this._submitSelection(allFlags);
  }

  _deselectAll = () => this._submitSelection([]);

  selectItem = (id, selected) => {
    const oldFlags = decToMask(this.state.flagsInt);
    const newFlags = !selected
      ? oldFlags.filter((f) => f !== id)
      : [...oldFlags, id];

    this._submitSelection(newFlags);
  }

  _submitSelection = (newFlags) => {
    this.setState({ flagsInt: maskToDec(newFlags) }, () => {
      store.dispatch(sectionActions.fieldValueUpdated(
        this.props.fieldName,
        this.state.flagsInt,
        'select_list'
      ));
    });
  }

  _applyTitleFilter = (newTitleFilter) => {
    this.setState({titleFilter: newTitleFilter});
  }

  render() {
    return (
      <div className={b()}>
        <div className={b('Filters')}>
          <TextFilterInput
            placeHolder={I18N.FILTERS_TITLE}
            onChangeValue={this._applyTitleFilter}
            value={this.state.titleFilter} />
        </div>
        <div className={b('MassSelection')}>
          <TextLink onClick={() => this._selectAll()}>{I18N.MISC_SELECT_ALL}</TextLink>
          <TextLink onClick={() => this._deselectAll()}>{I18N.MISC_UNSELECT_ALL}</TextLink>
          <hr/>
        </div>
        <SectionListPreloaded
          itemsPerPage={ITEMS_PER_PAGE}
          items={this._getFlagItems()}
          variables={{
            'title_filter': this.state.titleFilter,
            'limit': ITEMS_PER_PAGE,
            'offset': 0
          }}
          itemElement={withSelectableListItem(
            FlagListItem,
            this.selectItem,
            this.isItemSelected,
          )}
        />
      </div>
    );
  }
}
