/* eslint-disable react/display-name */
import React from 'react';
import { Query, Mutation } from 'react-apollo';
import { store } from '../../../index';
import { actions as mActions } from '../../manifest/actions';

export default
function bindApollo(queries) {

  return {
    CREATE: (variables, render) => (
      <Mutation
        mutation={queries.CREATE}
        update={(proxy, { data: { result } }) => {
          try {
            const data = proxy.readQuery({ query: queries.LIST, variables: variables });
            if (data) {
              data.result = data.result || [];
              data.result.unshift(result);
              proxy.writeQuery({ query: queries.LIST, variables: variables, data });
            }
          }
          catch (e) {
            console.log('cache problem has been prevented');
            console.log(e);
          }
        }}
      >
        {(mutation) => render(mutation)}
      </Mutation>),
    DELETE: (variables, render) => <Mutation
      mutation={queries.DELETE}
      update={(proxy, { data: { result } }) => {
        try {
          const data = proxy.readQuery({ query: queries.LIST, variables: variables });
          data.result = data.result.filter((item) => item.id !== result.id );
          proxy.writeQuery({ query: queries.LIST, variables: variables, data });
        }
        catch (e) {
          console.log('cache problem has been prevented');
          console.log(e);
        }
      }}
    >
      {(mutation) => render(mutation)}
    </Mutation>,
    DELETE_LIST: (variables, render) => <Mutation
      mutation={queries.DELETE_LIST}
      update={(proxy, { data: { result } }) => {
        try {
          const data = proxy.readQuery({ query: queries.LIST, variables: variables });
          data.result = data.result.filter((item) => !result.find((id) => id === item.id) );
          proxy.writeQuery({ query: queries.LIST, variables: variables, data });
        }
        catch (e) {
          console.log('cache problem has been prevented');
          console.log(e);
        }
      }}
    >
      {(mutation) => render(mutation)}
    </Mutation>,
    UPDATE: (variables, render) => <Mutation
      mutation={queries.UPDATE}
      update={(proxy, { data: { result } }) => {
        try {
          const data = proxy.readQuery({ query: queries.LIST, variables: variables });
          const indexToUpdate = data.result.findIndex((item) => item.id === result.id );
          data.result[indexToUpdate] = result;
          proxy.writeQuery({ query: queries.LIST, variables: variables, data });
        }
        catch (e) {
          console.log('cache problem has been prevented');
          console.log(e);
        }
      }}
    >
      {(mutation) => render(mutation)}
    </Mutation>,
    LIST: (variables, render) => <Query
      query={queries.LIST}
      variables={variables}
    >
      {(query) => render(query)}
    </Query>,
    GET_ONE: (variables, render) => <Query
      query={queries.GET_ONE}
      variables={variables}
    >
      {(query) => render(query)}
    </Query>,
    CLONE: (variables, render) => <Mutation
      mutation={queries.CLONE}
      update={(proxy, { data: { result } }) => {
        if (result)
          store.dispatch(mActions.refetchData());
      }}
    >
      {(mutation) => render(mutation)}
    </Mutation>
  };
}
