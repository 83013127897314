import React from 'react';
import block from 'bem-cn-lite';
import sprite from '../../../svg/sprite.svg';

const b = block('Icon');

const Icon = ({svgName, color, style, className, title}) => (
  <svg key={svgName} style={{...style, background: 'none'}} className={`${b()} ${className || ''}`}>
    <g className="icon-content">
      {title &&
        <g className="tooltip" transform="translate(20,20)" opacity="0.9">
          <text>{title}</text>
      </g>}
      <use xlinkHref={`${sprite}#SVG_${svgName}`} fill={color}></use>
    </g>
  </svg>
);

export default Icon;
