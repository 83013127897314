import React from 'react';

import ItemForm from '../common/ItemForm';
import I18N from '../../common/i18n';
// import _ from 'lodash';

import * as inputs from '../../inputs';
import { VALIDATE } from '../../inputs/validate';
import { Sections } from '../../common/enums';

const fields = [
  {
    inputClass: inputs.TextInput,
    caption: I18N.USERS_NAME,
    help: I18N.USERS_NAME_HELP,
    validate: VALIDATE.notEmpty,
    name: 'name',
    size: 2
  },
  {
    inputClass: inputs.PasswordInput,
    caption: I18N.USERS_PASSWORD,
    help: I18N.USERS_PASSWORD_HELP,
    validate: VALIDATE.pass,
    name: 'password',
    size: 2,
    autoComplete: 'user-password'
  },
  {
    inputClass: inputs.EmailInput,
    caption: I18N.USERS_EMAIL,
    help: I18N.USERS_EMAIL_HELP,
    validate: VALIDATE.emailOrNull,
    name: 'email',
    size: 2
  },
  // {
  //   inputClass: inputs.TextInput,
  //   caption: I18N.USERS_ROLE,
  //   name: 'role',
  //   size: 2,
  //   help: I18N.USERS_ROLE_HELP
  // },
  {
    inputClass: inputs.TextInput,
    caption: I18N.USERS_PHONE,
    help: I18N.USERS_PHONE_HELP,
    validate: VALIDATE.phone,
    name: 'phone',
    size: 2
  },
  {
    inputClass: inputs.CheckboxInput,
    caption: I18N.USERS_DRIVER,
    name: 'driver',
    size: 2,
    help: I18N.USERS_DRIVER_HELP
  },
  {
    inputClass: inputs.SelectDateInput,
    caption: I18N.USERS_SCHEDULE,
    name: 'schedule',
    size: 4,
    help: I18N.USERS_SCHEDULE_HINT
  },
  {
    inputClass: inputs.ComplexInput,
    name: 'extra_reqs',
    section: Sections.USERS
  },
];

export default
class UserForm extends React.PureComponent {

  render() {
    return (
      <ItemForm
        ref={(instance) => { this.itemForm = instance; }}
        {...this.props}
        fields={fields} />
    );
  }
}
