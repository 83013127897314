import React from 'react';

import ItemForm from '../common/ItemForm';
import I18N from '../../common/i18n';
// import _ from 'lodash';

import * as inputs from '../../inputs';
import { VALIDATE } from '../../inputs/validate';

const fields = [
  {
    inputClass: inputs.TextInput,
    caption: I18N.MANIFEST_NAME,
    name: 'name',
    validate: VALIDATE.notEmpty,
    size: 2,
    help: I18N.MANIFEST_NAME_HELP
  },
  {
    inputClass: inputs.DateInput,
    caption: I18N.MANIFEST_DATE,
    name: 'date',
    size: 2
  }
];

export default
class ManifestForm extends React.PureComponent {

  render() {
    return (
      <ItemForm
        ref={(instance) => { this.itemForm = instance; }}
        {...this.props}
        fields={fields} />
    );
  }
}
