/* eslint-disable max-depth */
/* eslint-disable indent */
import * as ExcelJs from 'exceljs/dist/exceljs.min.js';
import Stream from 'stream';
import { decimalHoursToTime, getData, decodeDateRU, decodeTime } from 'base/utils';
import fileDownload from 'js-file-download';
import {
  loadWaybillsAsync,
  loadManifestDataAsync,
  loadPointsDetailedAsync,
  loadCouriersAsync
} from 'modules/manifest/utils';
import i18n from '../../../common/i18n';

const CustomActions = {
  getGarbagePNGXML: async (manifestId) => {
    const template = await getData('/static/custom_solutions/ecoservice/form4c.xlsx');
    const manifestData = await loadManifestDataAsync(manifestId);
    const waybills = await loadWaybillsAsync(manifestId);
    const couriers = await loadCouriersAsync(manifestId);

    const workbook = new ExcelJs.Workbook();

    workbook.xlsx.load(template)
      .then(() => {
        const dest = new Stream();
        dest.write = function(fileData) {
          fileDownload(fileData, `Путевые листы №${manifestId} от ${decodeDateRU(manifestData.date)} (Экосервис).xlsx`);
        };

        const worksheet = workbook.getWorksheet(1);
        const manifestDate = new Date(manifestData.date);

        couriers.forEach((c) => {
          const courierWaybills = waybills.filter((w) => w.courier_id === c.id);
          if (!courierWaybills.length)
            return;

          const timeStart = courierWaybills[0]['departure_time'];
          const timeFinish = courierWaybills[courierWaybills.length - 1]['arrival_time'];
          const pathStart = courierWaybills[0]['total_distance'] / 1000;
          const pathFinish = courierWaybills[courierWaybills.length - 1]['total_distance'] / 1000;

          const newSheet = workbook.addWorksheet('empty');
          newSheet.model = {...worksheet.model, name: c.name, id: c.id };

          newSheet.getCell('C4').value = `${space(22)}${manifestDate.getUTCDate()}${space(12)}${i18n.DAYPICKER_MONTHS_PARENTAL[manifestDate.getUTCMonth() - 1].concat(space(10)).slice(0, 10)}${space(18)}${manifestDate.getUTCFullYear()}`;
          newSheet.getCell('B5').value = `ООО "Экосервис"`;
          newSheet.getCell('B9').value = c.name;
          newSheet.getCell('B10').value = `${space(10)}${c.name}`;
          newSheet.getCell('B11').value = c.driver ? c.driver.name : '';

          newSheet.getCell('E10').value = `${manifestDate.getUTCDate()}${space(6)}${manifestDate.getUTCMonth()}${space(9)}${decimalHoursToTime(timeStart).replace(':', space(7))}${space(17)}${pathStart}`;
          newSheet.getCell('E11').value = `${manifestDate.getUTCDate()}${space(6)}${manifestDate.getUTCMonth()}${space(9)}${decimalHoursToTime(timeFinish).replace(':', space(7))}${space(17)}${pathFinish}`;
          newSheet.getCell('G10').value = `${decodeDateRU(manifestDate)} ${decimalHoursToTime(timeStart)}`;
          newSheet.getCell('G11').value = `${decodeDateRU(manifestDate)} ${decimalHoursToTime(timeFinish)}`;

          newSheet.getCell('A22').value = "База";
          newSheet.getCell('A22').alignment = { vertical: 'middle', horizontal: 'center' };
          newSheet.getCell('B22').value = `${decimalHoursToTime(timeStart)}`;
          newSheet.getCell('B22').alignment = { vertical: 'middle', horizontal: 'center' };
          newSheet.getCell('C22').value = "По городу";
          newSheet.getCell('C22').alignment = { vertical: 'middle', horizontal: 'center' };
          newSheet.getCell('D22').value = "Полигон";
          newSheet.getCell('D22').alignment = { vertical: 'middle', horizontal: 'center' };
          newSheet.getCell('E22').value = "ТБО";
          newSheet.getCell('E22').alignment = { vertical: 'middle', horizontal: 'center' };
        });
        workbook.removeWorksheet(worksheet.id);
        workbook.xlsx.write(dest);
      });
  },

  getGarbageWaybillXML: async (manifestId) => {
    const DATA_STARTS_FROM = 9;

    const data = await getData('/static/custom_solutions/ecoservice/template.xlsx');
    const manifestData = await loadManifestDataAsync(manifestId);
    const waybills = await loadWaybillsAsync(manifestId);
    const points = await loadPointsDetailedAsync(manifestId);
    const couriers = await loadCouriersAsync(manifestId);

    const workbook = new ExcelJs.Workbook();
    workbook.xlsx.load(data)
      .then(() => {
        const dest = new Stream();
        dest.write = function(fileData) {
          fileDownload(fileData, `Маршрутный журнал №${manifestId} от ${decodeDateRU(manifestData.date)} (Экосервис).xlsx`);
        };

        const pointsArray = [];
        points.forEach((p) => (pointsArray[p.id] = p));
        const worksheet = workbook.getWorksheet(1);

        couriers.forEach((c) => {
          const newSheet = workbook.addWorksheet('empty');
          newSheet.model = {...worksheet.model, name: c.name, id: c.id };

          let totalFact = 0;
          const courierData = [];
          const unloadings = [];
          const courierWaybills = waybills.filter((w) => w.courier_id === c.id);
          const driverName = c.driver ? c.driver.name : '';
          const manifestDate = new Date(manifestData.date);

          courierWaybills
            .filter((w) => w['route_code'].match(/RG|UL/gu))
            .forEach((w, i) => {
              const extraReqs = pointsArray[w.point_id]['extra_reqs'] || {};
              const thisUnloading = w['route_code'].match('UL');
              const nextUnloading = courierWaybills[i + 2]['route_code'].match('UL');
              const nextW = courierWaybills[i + 2];

              if (!thisUnloading) {
                courierData.push([
                  w.points_served,
                  pointsArray[w.point_id].address,
                  pointsArray[w.point_id].name,
                  decodeTime(w.last_status_change),
                  pointsArray[w.point_id].backhaul_volume,
                  extraReqs['16476548364'] || '',
                  '',
                  nextUnloading ? decodeTime(nextW.last_status_change) : '',
                  nextUnloading ? pointsArray[nextW.point_id].name : '',
                  'Паринова Н.В.'
                ]);

                totalFact += Number(extraReqs['16476548364']) || 0;
              }
              else
                unloadings.push(i - unloadings.length - 1);
            });

          newSheet.mergeCells('A1', 'J1');

          [
            ['A7', 'A8'],
            ['B7', 'B8'],
            ['C7', 'C8'],
            ['D7', 'D8'],
            ['E7', 'F7'],
            ['G7', 'G8'],
            ['H7', 'H8'],
            ['I7', 'I8'],
            ['J7', 'J8'],
          ].forEach((pair) => {
            newSheet.mergeCells(pair[0], pair[1]);
            newSheet.getCell(pair[0]).border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' }
            };
            newSheet.getCell(pair[0]).alignment = {
              vertical: 'middle',
              horizontal: 'center',
              wrapText: true
            };
          });

          newSheet.spliceRows.apply(newSheet, [DATA_STARTS_FROM, 0, ...courierData]);

          newSheet.eachRow((row, rowNumber) => {
            row.eachCell.call(row, (cell) => {
              if (rowNumber >= DATA_STARTS_FROM && rowNumber <= courierData.length + DATA_STARTS_FROM)
                cell.border = {
                  top: { style: 'thin' },
                  left: { style: 'thin' },
                  bottom: { style: 'thin' },
                  right: { style: 'thin' }
                };
                cell.alignment = {
                  vertical: 'middle',
                  horizontal: 'left'
                };
            });
          });

          newSheet.getCell('A3').value = `"${manifestDate.getUTCDate()}" ${i18n.DAYPICKER_MONTHS_PARENTAL[manifestDate.getUTCMonth()]} ${manifestDate.getUTCFullYear()}`;
          newSheet.getCell('A5').value = `ООО "Экосервис+", марка а/м ${c.name}, г/н ${driverName}, вместимость кузова ${c.max_volume}, коэф.уплотнения 6`;

          // eslint-disable-next-line guard-for-in
          for (const i in unloadings) {
            const cur = unloadings[i],
                  prev = unloadings[i - 1] || 0;

            if (cur !== prev) {
              const from = DATA_STARTS_FROM + prev + 1,
                    to = DATA_STARTS_FROM + cur;

              newSheet.getCell(`I${from}`).value = newSheet.getCell(`I${to}`).value;
              newSheet.getCell(`J${from}`).value = newSheet.getCell(`J${to}`).value;
              newSheet.mergeCells(`I${from}`, `I${to}`);
              newSheet.mergeCells(`J${from}`, `J${to}`);
            }
          }

          newSheet.getCell(`F${DATA_STARTS_FROM + courierData.length + 1}`).value = totalFact;
          newSheet.getCell(`F${DATA_STARTS_FROM + courierData.length + 3}`).value = 'Обьяснительная прилагается';
        });
        workbook.removeWorksheet(worksheet.id);
        workbook.xlsx.write(dest);
      });
  }
};

function space(num) {
  let spaces = " ";
  while (spaces.length < num)
    spaces += " ";
  return spaces;
}

export default CustomActions;
