import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';

import I18N from 'modules/common/i18n';
import Button from 'modules/common/components/Button';
import { store } from 'index';
import { AuxilaryTabKinds } from '../common/enums';
import { actions as sectionActions } from 'modules/sections/common/actions';

const b = block('Input');

export default
class SelectDateInput extends React.PureComponent {
  static fieldName = 'calendar'

  static forceUpdatable = true;

  static propTypes = {
    size: PropTypes.number,
    caption: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.array,
    placeHolder: PropTypes.string,
    sourceActions: PropTypes.object.isRequired,
    onChangeValue: PropTypes.func.isRequired,
    showAllDates: PropTypes.bool,
    disabled: PropTypes.bool
  }

  componentDidMount() {
    this.selectionUpdateEvent = sectionActions.fieldValueUpdated.subscribe(
      (s, action) => {
        if (action.fieldName === this.props.name)
          this.props.onChangeValue && this.props.onChangeValue(this.props.name, action.value);
      }
    );
  }

  componentWillUnmount() {
    this.selectionUpdateEvent.unsubscribe();
  }

  _onClick = () => {
    store.dispatch(this.props.sourceActions.openSelectList(
      AuxilaryTabKinds.SELECT_DAYS,
      this.props.name,
      this.props.value));
  }

  _getAllDates = (dates) => {
    const val = [];
    let maxDates = 5;
    dates.length && dates
      .map((d) => new Date(d))
      .sort((d1, d2) => d1.getTime() - d2.getTime())
      .forEach((date) => {
        if (maxDates === 0) {
          val.push('...');
          maxDates = -1;
        }
        else if (maxDates > 0) {
          val.push(`${`0${date.getDate()}`.slice(-2)}.${`0${date.getMonth() + 1}`.slice(-2)}`);
          maxDates--;
        }
      });

    return val.join('; ');
  }

  _getFurtherDates = (dates) => {
    let maxDates = 5;
    const val = [];
    const yestDate = new Date().setDate(new Date().getDate() - 1);
    dates.length && dates
      .map((d) => new Date(d))
      .sort((d1, d2) => d1.getTime() - d2.getTime())
      .forEach((date) => {
        if (maxDates === 0) {
          val.push('...');
          maxDates = -1;
        }
        else if (maxDates > 0 && date >= yestDate) {
          val.push(`${`0${date.getDate()}`.slice(-2)}.${`0${date.getMonth() + 1}`.slice(-2)}`);
          maxDates--;
        }
      });

    return val.join('; ');
  }

  render() {
    return (
      <div className={b('ButtonPick')}>
        <Button onClick={this._onClick}>
          {I18N.FIELD_START_CALENDAR}
        </Button>
        {this.props.value
          ? <div className={b('ButtonPickData')}>
            {this.props.showAllDates
              ? [<span key='label'>{I18N.DATES_ALL}</span>, <div key='data' className={b('DataCount')}><b>{this._getAllDates(this.props.value)}</b></div>]
              : [<span key='label'>{I18N.DATES_CLOSEST}</span>, <div key='data' className={b('DataCount')}><b>{this._getFurtherDates(this.props.value)}</b></div>]
            }
          </div>
          : <div className={b('ButtonPickData')}>{this.props.placeHolder || I18N.FIELD_NO_DATA}</div>}
      </div>
    );
  }
}
