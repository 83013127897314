import React from 'react';
import { PropTypes } from 'prop-types';
import { toast } from 'react-toastify';
import { push } from 'react-router-redux';

import { CRUD } from './queries';
import I18N from '../../common/i18n';
import { store } from '../../..';
import { Sections } from '../../common/enums';
import GroupForm from './GroupForm';
import { getSectionLinkBody } from '../../../base/utils';

const defaultGroup = Object.freeze({
  'section': Sections.POINTS,
});

export default
class GroupCreate extends React.PureComponent {
  static title = I18N.GROUPS_CREATE_TITLE

  static propTypes = {
    manifestId: PropTypes.number,
    orgId: PropTypes.number,
  }

  constructor(props) {
    super(props);
    this.state = {
      fromCatalog: false
    };
  }

  _create = (create, data) => {
    const p = create({
      variables: {
        'manifest_id': this.props.manifestId || -this.props.orgId,
        'data': data
      }
    });
    p.then((res) => {
      toast.success(`${I18N.GROUPS_CREATE_TITLE}. ${I18N.NOTIFY_SUCCESS}`);
      store.dispatch(push(
        `${getSectionLinkBody(this.props.manifestId)}${Sections.GROUPS}`
      ));
    });
    p.catch((res) => {
      toast.error(`${I18N.GROUPS_CREATE_TITLE}. ${I18N.NOTIFY_ERROR}`);
      console.log('created error', res);
    });
    return p;
  }

  render() {
    return CRUD.CREATE(
      {
        'manifest_id': this.props.manifestId || -this.props.orgId,
      },
      (create) => (
        <GroupForm
          {...this.props}
          item={defaultGroup}
          submitText={I18N.SUBMIT_CREATE}
          submit={(data) => this._create(create, data)} />
      )
    );
  }
}
