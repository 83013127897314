import React from 'react';
// import _ from 'lodash';

import {TextInput} from '../../inputs/SimpleInput';
import { actions as mapActions } from '../../map/actions';
import { store } from '../../..';
import GeometryInput from '../../inputs/GeometryInput';
import SelectItemsInput from '../../inputs/SelectItemsInput';
import { Sections } from 'modules/common/enums';
import { actions as sectionActions} from '../common/actions';
import { decodeGeometry } from 'base/utils';
import ItemForm from '../common/ItemForm';
import I18N from '../../common/i18n';
import { VALIDATE } from '../../inputs/validate';

const fields = [
  {
    inputClass: TextInput,
    caption: I18N.ZONES_NAME,
    name: 'name',
    validate: VALIDATE.notEmpty,
    help: I18N.ZONES_NAME_HINT
  },
  {
    inputClass: GeometryInput,
    caption: I18N.ZONES_GEOMETRY,
    validate: VALIDATE.notEmpty,
    name: 'geometry',
    help: I18N.ZONES_GEOMETRY_HELP
  },
  {
    inputClass: SelectItemsInput,
    caption: I18N.ZONES_COURIERS,
    name: 'couriers',
    section: Sections.COURIERS,
    help: I18N.ZONES_COURIERS_HELP
  },
  {
    inputClass: SelectItemsInput,
    caption: I18N.ZONES_POINTS,
    name: 'points',
    section: Sections.POINTS,
    help: I18N.ZONES_POINTS_HELP
  }
];

export default
class ZoneForm extends React.PureComponent {

  componentDidMount() {
    this.pointUpdateEvent = mapActions.updateSelectedPoints.subscribe((s, action) => {
      if (action.pointsIds)
        this.itemForm.setFieldValue('points', action.pointsIds);
    });
    if (this.props.item.id === -1)
      store.dispatch(sectionActions.zoneCreated(-1, []));
  }

  componentWillUnmount() {
    this.pointUpdateEvent.unsubscribe();
    if (this.props.item.id === -1)
      store.dispatch(sectionActions.zoneDeleted(-1));
    else
      store.dispatch(sectionActions.zoneGeometryUpdated(this.props.item.id, decodeGeometry(this.props.item.geometry), 'form'));
    store.dispatch(mapActions.updateSelectedPoints([]));
  }

  onNewStateReady = (oldState, newState) => {
    if (!oldState.geometry || oldState.geometry.value !== newState.geometry.value) {
      store.dispatch(sectionActions.zoneGeometryUpdated(this.props.item.id, decodeGeometry(newState.geometry.value), 'form'));
    }
  }

  render() {
    return (
      <ItemForm
        onRef={(ref) => { this.itemForm = ref; } }
        {...this.props}
        fields={fields}
        onNewStateReady={this.onNewStateReady} />
    );
  }
}
