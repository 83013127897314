import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';

import I18N from 'modules/common/i18n';
import Button from 'modules/common/components/Button';
import CustomActions from '../custom/utils';

const b = block('Input');

export default
class CustomActionInput extends React.PureComponent {
  static fieldName = 'action'

  static propTypes = {
    size: PropTypes.number,
    caption: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    section: PropTypes.string,
    value: PropTypes.arrayOf(PropTypes.number),
    onChangeValue: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    arguments: PropTypes.arrayOf(PropTypes.any),
    scope: PropTypes.string.isRequired,
    fromCatalog: PropTypes.bool
  }

  _onClick = () => CustomActions[this.props.scope][this.props.name].apply(this, this.props.arguments || []);

  _toView = (value) => (value || []).length;

  render() {
    return <div className={b('ButtonPick')}>
      <Button onClick={this._onClick}>{I18N.FIELD_CUSTOM_ACTION}</Button>
    </div>;
  }
}
