export const OptimizationStatus = {
  ENQUEUED: 'enqueued',
  WAITING: 'waiting',
  ACCEPTED: 'accepted',
  PREPARING: 'preparing',
  PROCESSING: 'processing',
  ERROR: 'error',
};

export const MiniStatus = {
  DEFAULT: 'DEFAULT',
  SUCCESS: 'SUCCESS',
  WARNING: 'WARNING',
  WAITING: 'WAITING',
  ERROR: 'ERROR',
  PREPARING: 'PREPARING',
  PROCESSING: 'PROCESSING',
  ENQUEUED: 'ENQUEUED',
};
