import gql from 'graphql-tag';

const organizationDefaultFieldsFragment = `
  id,
  name,
  email,
  phone,
  measure_id,
  ui_info,
  use_tracking,
  subscription_expires
`;

export
const queries = {
  GET: gql`
  query CURRENT_ORGANIZATION($id: Int!) {
    result(id: $id) {
      ${organizationDefaultFieldsFragment}
    }
  }
  `,

  UPDATE: gql`
  mutation UPDATE_ORGANIZATION($data: String!) {
    result(data: $data) {
      ${organizationDefaultFieldsFragment}
    }
  }
  `
};
