import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';

const b = block('RadialProgressBar');

export default
class RadialProgressBar extends React.PureComponent {

  static propTypes = {
    progress: PropTypes.number.isRequired,
  }

  _percentToDeg(percent) {
    return Math.round(percent % 50 / 50 * 180);
  }

  _getClipperStyles(progress) {
    if (progress === 100)
      return {
        '50': {display: 'none'},
        '100': {display: 'none'},
      };
    if (progress <= 50)
      return {
        '50': {transform: `rotate(${this._percentToDeg(progress)}deg)`},
      };
    return {
      '100': {transform: `rotate(${this._percentToDeg(progress)}deg)`},
    };
  }

  render() {
    return <div className={b()}>
      <div style={{...this._getClipperStyles(this.props.progress)['50']}}className={b('Clipper50', {'Ready': this.props.progress > 50})}/>
      <div style={{...this._getClipperStyles(this.props.progress)['100']}}className={b('Clipper100')}/>
    </div>;
  }
}
