import React from 'react';
import block from 'bem-cn-lite';

const b = block('ErrorIndicator');

const ErrorIndicator = () => (
  <div className={b()}>
    <svg version="1.1" x="0px" y="0px" viewBox="0 0 448 448">
      <path className={b('IconLine')} d="M389.693,344.176 c0,25.138-20.379,45.517-45.517,45.517H105.47c-25.138,0-45.517-20.379-45.517-45.517V105.47c0-25.138,20.379-45.517,45.517-45.517 h238.706c25.138,0,45.517,20.379,45.517,45.517V344.176z"/>
      <path className={b('IconLine')} d="M149.882,179.858 c0-16.555,13.421-29.976,29.976-29.976s29.976,13.421,29.976,29.976"/>
      <path className={b('IconLine')} d="M269.787,179.858 c0-16.555,13.421-29.976,29.976-29.976c16.556,0,29.976,13.421,29.976,29.976"/>
      <path className={b('IconLine')} d="M179.858,309.756 c0-33.111,26.842-59.953,59.953-59.953s59.953,26.842,59.953,59.953"/>
    </svg>
    <div className={b('Message')}>Что-то не работает...</div>
  </div>
);

export default ErrorIndicator;
