import gql from 'graphql-tag';
import bindApollo from '../common/queries';
import { Sections } from '../../common/enums';

const userListFieldsFragment = `
  id,
  name,
  phone,
`;

const userDefaultFieldsFragment = `
  id,
  email,
  name,
  phone,
  driver,
  schedule,
  extra_reqs
`;

const driverDefaultFieldsFragment = `
  id,
  name,
  phone,
`;

const crud = {
  SECTION: Sections.USERS,

  CREATE: gql`
    mutation CREATE_USER($data: String!) {
      result(data: $data) {
        ${userDefaultFieldsFragment}
      }
    }
  `,

  DELETE: gql`
    mutation DELETE_USER($id: Int!) {
      result(id: $id)
    }
  `,

  DELETE_LIST: gql`
    mutation DELETE_USERS($data: Array!) {
      result(data: $data)
    }
  `,

  LIST: gql`
    query USERS {
      result {
        ${userListFieldsFragment}
      }
    }
  `,

  GET_ONE: gql`
    query USER($id: Int!) {
      result(id: $id) {
        ${userDefaultFieldsFragment}
      }
    }
  `,

  UPDATE: gql`
    mutation UPDATE_USER($data: String!) {
      result(data: $data) {
        ${userDefaultFieldsFragment}
      }
    }
  `,
};

export
const queries = {
  ...crud,

  LIST_DRIVERS: gql`
    query DRIVERS {
      result @connection(key: "drivers") {
        ${driverDefaultFieldsFragment}
      }
    }
  `,
};

export
const CRUD = bindApollo(crud);
