import { makeReducer } from '../../base/reducer';


const init = {
  courierAssignments: {}
};

export const reducer = makeReducer('updateState', init);

export const actions = {
  'clearAssignments': reducer.makeAction(
    () => ({}),
    (state, action, store) => {
      const s = {...state, courierAssignments: {}};
      store(s);
      return s;
    }
  ),
  'assignCourier': reducer.makeAction(
    (pointId, courierId) => ({pointId, courierId}),
    (state, action, store) => {
      const s = {...state,
        courierAssignments: {...state.courierAssignments, [action.pointId]: {assigned_courier_id: action.courierId}}
      };
      store(s);
      return s;
    }
  ),
};
