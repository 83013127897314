import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';
import Hint from '../../common/components/Hint';

const b = block('DataBadge');

export default
class DataBadge extends React.PureComponent {
  static propTypes = {
    caption: PropTypes.string.isRequired,
    value: PropTypes.any,
    leftHint: PropTypes.string,
    rightHint: PropTypes.string,
  };

  render() {
    return <div className={b()}>
      <div className={b('Caption')}>{this.props.caption}:</div>
      <div className={b('Value')}>{this.props.value || '0'}</div>
      {(this.props.leftHint || this.props.rightHint) && <Hint {...this.props}/>}
    </div>;
  }
}
