import React from 'react';
import { PropTypes } from 'prop-types';
import { Query } from 'react-apollo';

import { withApolloAsyncLoad, lookupFromView } from '../../base/utils';
import { LookupInput } from '.';

export default
class DBLookupInput extends React.PureComponent {
  static fieldName = 'db_lookup'

  static propTypes = {
    valueField: PropTypes.string.isRequired,
    captionField: PropTypes.string.isRequired,
    queryParams: PropTypes.object.isRequired,
    filter: PropTypes.func,
    fromView: PropTypes.func
  }

  constructor(props) {
    super(props);
    this.props = props || { fromView: lookupFromView };
  }

  _renderLookup = (items) => {
    const filteredItems = this.props.filter ? items.filter(this.props.filter) : items;
    const itemsList = Array.isArray(filteredItems)
      ? filteredItems.map((v) => ({
        value: v[this.props.valueField],
        caption: v[this.props.captionField]
      }))
      : [];
    return <LookupInput items={itemsList} hasNull={true} {...this.props}/>;
  }

  render() {
    return (
      <Query {...this.props.queryParams}>
        {(result) => withApolloAsyncLoad(this._renderLookup, result)}
      </Query>
    );
  }
}
