import React from 'react';
import { PropTypes } from 'prop-types';
import block from 'bem-cn-lite';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import { toast } from 'react-toastify';
import _ from 'lodash';
import fileDownload from 'js-file-download';

import I18N from '../../common/i18n';
import { reducer } from 'modules/auth/actions';
import TabTitle from '../common/TabTitle';
import GeneratedForm from './GeneratedForm';
import { b64toBlob } from '../../../base/utils';
import { LookupInput } from '../../inputs';

const b = block('ReportForm');

const CLIENT_DEFAULT_FORMAT = 'xlsx';

class ReportForm extends React.PureComponent {
  static title = I18N.CUSTOM_FORM_TAB

  static propTypes = {
    manifestId: PropTypes.number.isRequired,
    itemId: PropTypes.number.isRequired,
    reportsData: PropTypes.array,
  }

  constructor(props) {
    super(props);

    this.state = {
      report: null,
      formData: null,
      fieldsData: null,
      format: null,
      allowedFormats: null,
      mutationQuery: null
    };
  }

  componentDidMount() {
    this._generateFormData();
  }

  componentDidUpdate() {
    this._generateFormData();
  }

  _generateFormData = () => {
    const report = _.get(this.props.reportsData, `[${this.props.itemId - 1}]`);
    const formData = _.get(report, 'ui_info', {});

    if (report)
      this.setState({
        report: report,
        formData: formData,
        fieldsData: formData['fields'],
        format: this.state.format || formData['default_format'] || CLIENT_DEFAULT_FORMAT,
        allowedFormats: formData['allowed_formats'],
        mutationQuery: this._getReportQuery()
      });
  }

  _getReportQuery = () => gql`
    mutation GENERATE_REPORT ($id: Number $params: Array $format: String) {
      result(id: $id params: $params format: $format) {
        id
        params
        format
      }
    }`;

  onSubmit = (update, data) => {
    const params = [this.props.manifestId, ...Object.values(data)];
    const p = update({
      variables: {
        'id': this.state.report.id,
        'format': this.state.format,
        'params': params,
      }
    });
    p.then((res) => {
      if (_.get(res, 'data.result')) {
        fileDownload(b64toBlob(res.data.result), `${this.state.report.name}.${this.state.format}`);
        toast.success(`${this.state.report.name}. ${I18N.NOTIFY_SUCCESS}`);
      }
      else
        toast.error(`${this.state.report.name}. ${I18N.NOTIFY_NO_DATA}`);
    });
    p.catch((res) => {
      toast.error(`${this.state.report.name}. ${I18N.NOTIFY_ERROR}`);
      console.log('update error');
    });
    return p;
  }

  setNewFormat = (t, format) => {
    this.setState({ format: format });
  }

  render() {
    if (!this.state.formData)
      return null;

    return <div className={b()}>
      <hr/>
      <TabTitle>{_.get(this.state, 'report.name')}</TabTitle>
      {this.state.formData['allowed_formats']
        && <div className={b('SelectFormat')}>
          <div className={b('SelectFormatInput')}>
            <h4>Выберите формат:</h4>
            <LookupInput
              items={this.state.formData['allowed_formats'].map((f) => ({ caption: f, value: f }))}
              value={this.state.format}
              onChangeValue={this.setNewFormat}
            />
          </div>
          <hr/>
        </div>}
      <GeneratedForm
        {...this.props}
        submitText={I18N.SUBMIT_DOWNLOAD}
        onSubmit={this.onSubmit}
        fieldsData={this.state.fieldsData}
        mutationQuery={this.state.mutationQuery}
      />
    </div>;
  }
}

export default
connect(
  (state) => ({
    reportsData: _.get(state, `${reducer.name}.user.reports`, []),
  })
)(ReportForm);
