import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';
import { store } from 'index';
import _ from 'lodash';

import I18N from 'modules/common/i18n';
import Button from 'modules/common/components/Button';
import DayPicker, { DateUtils } from 'react-day-picker';
import { actions as sectionActions } from './actions';

const b = block('DaysSelector');

export default
class DaysSelector extends React.Component {
  static title = I18N.FIELD_SELECT_DAYS

  static propTypes = {
    fieldName: PropTypes.string,
    value: PropTypes.any,
    manifestId: PropTypes.number,
    onClose: PropTypes.func.isRequired,
  };


  constructor(props) {
    super(props);
    this.state = {
      selectedDays: (_.get(props, 'value') || []).map((date) => new Date(date)),
    };
  }

  _submitSelection = () => {
    store.dispatch(sectionActions.fieldValueUpdated(
      this.props.fieldName,
      this.state.selectedDays,
      'calendar'
    ));
    this.props.onClose();
  }

  _handleDayClick = (day, { selected }) => {
    const { selectedDays } = this.state;
    if (selected) {
      const selectedIndex = selectedDays.findIndex((selectedDay) => DateUtils.isSameDay(selectedDay, day));
      selectedDays.splice(selectedIndex, 1);
    } else {
      selectedDays.push(day);
    }
    this.setState({ selectedDays });
  }

  render() {
    return (
      <div className={b()}>
        <div className={b('Calendar')}>
          <DayPicker
            months={I18N.DAYPICKER_MONTHS}
            weekdaysLong={I18N.DAYPICKER_WEEKDAYS_LONG}
            weekdaysShort={I18N.DAYPICKER_WEEKDAYS_SHORT}
            firstDayOfWeek={I18N.DAYPICKER_FIRST_DAY_OF_WEEK}
            labels={I18N.DAYPICKER_LABELS}
            selectedDays={this.state.selectedDays}
            onDayClick={this._handleDayClick}
          />
        </div>
        <div className={b('Submit')}>
          <Button onClick={this._submitSelection}>{I18N.SUBMIT_DEFAULT}</Button>
        </div>
      </div>
    );
  }
}
