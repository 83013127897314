import React from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn-lite';

import Icon from './../Icon';
import icons from '../../icons';
import { SelectDateInput } from '../../../inputs';
// import I18N from '../../i18n';

const b = block('DateFilterInput');

export default class DateFilterInput extends React.PureComponent {
  static propTypes = {
    placeHolder: PropTypes.string,
    onChangeValue: PropTypes.func.isRequired,
    sourceActions: PropTypes.object.isRequired,
  }

  constructor() {
    super();
    this.state = {
      inputValue: [],
      filterValue: '',
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.filterValue !== this.state.filterValue)
      this.props.onChangeValue(this.state.filterValue);
  }

  clearFilter = () => {
    this.setState({
      inputValue: [],
      filterValue: '',
    });
  }

  confirmValue = (name, value) => {
    this.setState({
      inputValue: value,
      filterValue: value,
    });
  }

  render() {
    return (
      <div className={b()}>
        <SelectDateInput
          showAllDates={true}
          placeHolder={this.props.placeHolder}
          sourceActions={this.props.sourceActions}
          value={this.state.inputValue}
          onChangeValue={this.confirmValue}/>
        <div className={b('ClearButton')} onClick={this.clearFilter}>
          <Icon svgName={icons.COMMON_CLEAR_FIELD}/>
        </div>
      </div>
    );
  }
}
