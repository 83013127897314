import React from 'react';
import { PropTypes } from 'prop-types';

import {TextInput, TimeInput, NumberInput} from '../../inputs/SimpleInput';
import { LookupInput, DBLookupInput, CheckboxInput, SelectFlagsInput, ComplexInput } from '../../inputs';
import { actions as mapActions } from '../../map/actions';
import { queries as pointQueries } from '../points/queries';
import { queries as userQueries } from '../users/queries';
import { store } from '../../..';
import ItemForm from '../common/ItemForm';
import I18N from '../../common/i18n';
import { correctBreakTime } from '../../../base/utils';
import { ApolloFetchPolicy, Sections } from '../../common/enums';
import { VALIDATE } from '../../inputs/validate';

export
const fields = [
  {
    inputClass: TextInput,
    caption: I18N.COURIERS_NAME,
    name: 'name',
    validate: VALIDATE.notEmpty,
    help: I18N.COURIERS_NAME_HINT
  },
  {
    inputClass: LookupInput,
    caption: I18N.COURIERS_VEHICLE_TYPE,
    name: 'vehicle_type',
    size: 2,
    items: [
      {value: 'car', caption: I18N.COURIERS_VEHICLE_TYPE_CAR},
      {value: 'hgv', caption: I18N.COURIERS_VEHICLE_TYPE_HGV},
      {value: 'foot', caption: I18N.COURIERS_VEHICLE_TYPE_FOOT},
    ],
    help: I18N.COURIERS_VEHICLE_TYPE_HINT
  },
  {
    inputClass: DBLookupInput,
    caption: I18N.COURIERS_GARAGE,
    name: 'garage_id',
    size: 2,
    validate: VALIDATE.notEmpty,
    queryParams: {
      query: pointQueries.LIST_SPECIAL,
      fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
      variables: {
        'manifest_id': null,
      }
    },
    filter: (item) => item.garage,
    valueField: 'id',
    captionField: 'name',
    help: I18N.COURIERS_GARAGE_HINT
  },
  {
    inputClass: DBLookupInput,
    caption: I18N.COURIERS_DRIVER,
    name: 'driver_id',
    size: 2,
    queryParams: {
      query: userQueries.LIST_DRIVERS,
      fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
      variables: {
        'manifest_id': null,
      }
    },
    valueField: 'id',
    captionField: 'name',
    help: I18N.COURIERS_DRIVER_HINT
  },
  {
    inputClass: DBLookupInput,
    caption: I18N.COURIERS_CURRENT_POINT,
    name: 'current_point_id',
    size: 2,
    queryParams: {
      query: pointQueries.LIST_SPECIAL,
      fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
      variables: {
        'manifest_id': null,
      }
    },
    valueField: 'id',
    captionField: 'name',
    help: I18N.COURIERS_CURRENT_POINT_HINT
  },
  {
    inputClass: TimeInput,
    caption: I18N.SCHEDULE_WORK_START,
    name: 'work_start',
    help: I18N.COURIER_WORK_HINT
  },
  {
    inputClass: TimeInput,
    caption: I18N.SCHEDULE_WORK_END,
    name: 'work_end',
  },
  {
    inputClass: TimeInput,
    caption: I18N.SCHEDULE_BREAK_START,
    name: 'break_start',
    help: I18N.COURIER_BREAK_HINT
  },
  {
    inputClass: TimeInput,
    caption: I18N.SCHEDULE_BREAK_END,
    name: 'break_end',
  },
  {
    inputClass: NumberInput,
    caption: I18N.COURIERS_MAX_WEIGHT,
    name: 'max_weight',
    min: 0,
    help: I18N.COURIER_CARGO_HINT
  },
  {
    inputClass: NumberInput,
    caption: I18N.COURIERS_MAX_VOLUME,
    name: 'max_volume',
    min: 0,
  },
  {
    inputClass: NumberInput,
    caption: I18N.COURIERS_MAX_POINTS,
    name: 'max_points',
    min: 0,
    help: I18N.COURIERS_MAX_POINTS_HINT
  },
  {
    inputClass: NumberInput,
    caption: I18N.COURIERS_COST_ROUND,
    name: 'cost_round',
    min: 0,
    help: I18N.COURIER_PRICE_HINT
  },
  {
    inputClass: NumberInput,
    caption: I18N.COURIERS_COST_HOUR,
    name: 'cost_hour',
    min: 0,
    help: I18N.COURIER_PRICE_HINT
  },
  {
    inputClass: NumberInput,
    caption: I18N.COURIERS_COST_KM,
    name: 'cost_km',
    min: 0,
    help: I18N.COURIER_PRICE_HINT
  },
  {
    inputClass: NumberInput,
    caption: I18N.COURIERS_COST_KGKM,
    name: 'cost_kgkm',
    min: 0,
    help: I18N.COURIER_PRICE_HINT
  },
  {
    inputClass: NumberInput,
    caption: I18N.COURIERS_COST_POINT,
    name: 'cost_point',
    min: 0,
    help: I18N.COURIER_PRICE_HINT
  },
  {
    inputClass: CheckboxInput,
    caption: I18N.COURIERS_LONGHAUL,
    name: 'longhaul',
    size: 2,
    help: I18N.COURIERS_LONGHAUL_HINT
  },
  {
    inputClass: CheckboxInput,
    caption: I18N.COURIERS_OUTOFORDER,
    name: 'outoforder',
    size: 2,
    help: I18N.COURIERS_OUTOFORDER_HINT
  },
  {
    inputClass: CheckboxInput,
    caption: I18N.COURIERS_NOT_RETURNING,
    name: 'not_returning',
    size: 2,
    help: I18N.COURIERS_NOT_RETURNING_HINT
  },
  {
    inputClass: NumberInput,
    caption: I18N.COURIERS_N_RUNS,
    name: 'n_runs',
    min: 0,
    size: 2,
    help: I18N.COURIERS_N_RUNS_HINT
  },
  {
    inputClass: SelectFlagsInput,
    caption: I18N.FLAGS,
    name: 'vehicle_flags',
    size: 2,
    help: I18N.FLAGS_HELP
  },
  {
    inputClass: ComplexInput,
    name: 'extra_reqs',
    section: Sections.COURIERS
  }
];

export default
class CourierForm extends React.PureComponent {

  static propTypes = {
    item: PropTypes.object,
    manifestId: PropTypes.number,
  }

  onNewStateReady = (oldState, newState) => {
    newState['garage_id'].queryParams.variables['manifest_id'] = this.props.manifestId;
    newState['driver_id'].queryParams.variables['manifest_id'] = this.props.manifestId;
    newState['current_point_id'].queryParams.variables['manifest_id'] = this.props.manifestId;
    correctBreakTime(newState);
  }

  componentDidMount() {
    store.dispatch(mapActions.selectCourier(this.props.item.id));
  }

  componentWillUnmount() {
    store.dispatch(mapActions.selectCourier(null));
  }

  render() {
    return (
      <ItemForm
        ref={(instance) => { this.itemForm = instance; }}
        {...this.props}
        fields={fields}
        onNewStateReady={this.onNewStateReady} />
    );
  }
}
