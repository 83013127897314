import React from 'react'
import block from 'bem-cn-lite'
import PropTypes from 'prop-types'
import {
	Menu,
	Tooltip,
	MenuItem,
	Checkbox,
	ListItemText
} from '@material-ui/core'
import I18N from './../common/i18n'

const b = block('Input')


// компонент выбора дня недели
export default class WeekdaySelect extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			open: null,
			checked: []
		}
	}

	// отметить день в массиве
	checkDay(id){
		let c = this.state.checked
		let cid = c.indexOf(id)
		if(cid === -1){
			c.push(id)
		} else {
			c.splice(cid, 1)
		}
		this.setState({ checked: c })
		this.props.onChangeValue(this.props.name, c)
	}

	render(){
		return(
			<React.Fragment>
				<input 
					readonly='readonly'
					className={b()}
					value={inputtext(this.state.checked)}
					onClick={(ev) => this.setState({ open: ev.currentTarget })}
				/>
				<Menu
					open={Boolean(this.state.open)}
					anchorEl={this.state.open}
					onClose={() => this.setState({ open: null })}
				>
					<MenuItem 
						key='close' 
						onClick={() => this.setState({ open: null })}
					>
						Закрыть
					</MenuItem>
					<MenuItem 
						key={0} 
						value={0}
						onClick={() => this.checkDay(0)}
					>
						<Checkbox checked={this.state.checked.indexOf(0) !== -1} />
						<ListItemText primary='Все' />
					</MenuItem>
					{
						I18N.DAYPICKER_WEEKDAYS_SHORT.map((dw, id) => {
							return(
								<MenuItem 
									key={id} 
									value={id + 1} // значение увеличено так как все - это 0
									onClick={() => this.checkDay(id + 1)}
								>
									<Checkbox checked={this.state.checked.indexOf(id + 1) !== -1} />
									<ListItemText primary={dw} />
								</MenuItem>
							)
						})
					}
				</Menu>
			</React.Fragment>
		)
	}
}

// функция формирования теста в инпуте
function inputtext(checked){
	let txt = ''
	if(checked && checked.length > 0){
		if(checked.indexOf(0) !== -1){
			txt = 'Все'
		} else {
			checked.map((d, id) => {
				txt += I18N.DAYPICKER_WEEKDAYS_SHORT[d-1]
				if(checked[id + 1]){
					txt += ','
				}
			})
			txt = txt.length > 8 ? txt.substring(0, 8) + '...' : txt
		}
	} else {
		txt = ' - '
	}
	return txt
}

WeekdaySelect.propTypes = {
	value: PropTypes.string.isRequired
}

