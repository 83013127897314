import React from 'react';
import { PropTypes } from 'prop-types';
import { toast } from 'react-toastify';
import { push } from 'react-router-redux';

import { CRUD } from './queries';
import I18N from '../../common/i18n';
import { store } from '../../..';
import ManifestForm from './ManifestForm';
import { AppSections } from '../../common/enums';

export default
class ManifestCreate extends React.PureComponent {
  static title = I18N.MANIFEST_CREATE_TITLE


  static propTypes = {
    manifestId: PropTypes.number.isRequired
  }

  _create = (create, data) => {
    const p = create({
      variables: {
        'manifest_id': this.props.manifestId,
        'data': data
      }
    });
    p.then((res) => {
      toast.success(`${I18N.MANIFEST_CREATE_TITLE}. ${I18N.NOTIFY_SUCCESS}`);
      store.dispatch(push(`/${AppSections.MANIFEST}/${res.data.result.id}`));
    });
    p.catch((res) => {
      toast.error(`${I18N.MANIFEST_CREATE_TITLE}. ${I18N.NOTIFY_ERROR}`);
      console.log('created error', res);
    });
    return p;
  }

  render() {
    return (
      CRUD.CREATE(
        {'title_filter': ''},
        (create) => (
          <ManifestForm
            {...this.props}
            submitText={I18N.SUBMIT_CREATE}
            item={{date: new Date().toUTCString()}}
            submit={(data) => this._create(create, data)} />
        )
      )
    );
  }
}
