import React from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn-lite';
import { Button } from '../common/components';
import { ButtonKinds } from '../common/enums';

const b = block('ConfirmDialog');

export default class ConfirmDialog extends React.PureComponent {

  static propTypes = {
    closeModal: PropTypes.func,
    message: PropTypes.string.isRequired,
    onConfirm: PropTypes.func,
    confirmKind: PropTypes.oneOf(Object.keys(ButtonKinds)),
  };

  _onOverlayClick = (e) => {
    if (e.target.className === b('Overlay'))
      this.props.closeModal();
  }

  _onConfirm = () => {
    this.props.onConfirm();
    this.props.closeModal();
  }

  _onCancel = () => {
    this.props.closeModal();
  }

  render() {
    return (
      <div className={b()}>
        <div className={b('Overlay')} onClick={this._onOverlayClick}>
          <div className={b('WindowDynamicIndent')} />
          <div className={b('Window')}>
            <div className={b('Header')}></div>
            <div className={b('Message')}>{this.props.message}</div>
            <div className={b('Buttons')}>
              <Button kind={ButtonKinds.UNIMPORTANT} clickOnce={true} onClick={this._onCancel}>Отмена</Button>
              <Button kind={this.props.confirmKind} clickOnce={true} onClick={this._onConfirm}>Подтвердить</Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

