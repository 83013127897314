import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';

import I18N from '../../common/i18n';
import { withApolloAsyncLoad } from '../../../base/utils';
import { CRUD } from './queries';
import MeasureListItem from './MeasureListItem';

const b = block('MeasureView');

export default
class MeasureView extends React.PureComponent {
  static title = I18N.MEASURES_INFO_TITLE

  static propTypes = {
    itemId: PropTypes.number.isRequired,
  }

  _renderView = (measure) => <div className={b()}>
    <MeasureListItem item={measure}/>
  </div>;

  render() {
    return (
      CRUD.GET_ONE(
        {id: this.props.itemId},
        (result) => withApolloAsyncLoad(this._renderView, result)
      )
    );
  }
}
