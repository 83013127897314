import React from 'react';
import block from 'bem-cn-lite';

const b = block('Hint');

export default
function Hint({ leftHint, rightHint }) {
  return <div className={b()}>
    <div className={b('Wrapper', {
      'LeftHint': Boolean(leftHint),
      'RightHint': Boolean(rightHint)
    })}>
      <div className={b('Body', {
        'LeftHint': Boolean(leftHint),
        'RightHint': Boolean(rightHint)
      })}>
        {leftHint || rightHint}
      </div>
    </div>
  </div>;
}
